import React from 'react';
import { Link } from 'react-router-dom';

/**Importation des composants de la page */
import MapCmp from '../components/map/map.component';

/**Importation des styles de la page */
import './styles/contactUs.style.css';
import './styles/contactUs.responsive.style.css';

/**Importation des images*/
import radLogo from '../assets/logos/rad-logo.png';
import loader from '../assets/gif/loader2.gif';


/**Importation des helpers */
import Xanimation from '../helpers/x-animations';
import sendEmail from '../helpers/sendEmail.helper';


import radDataClass from '../helpers/data-class.helper';
import radDataAttr from '../helpers/data-attr.helper';
import radData from '../helpers/data.helper';

const animer = new Xanimation();
animer.init( 50 );


class ContactUsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            radMails: [],
            general_class: "",
            general_data: "",
        }

        this.radDataClass = new radDataClass();
        this.radData = new radData();
        this.radDataAttr = new radDataAttr();
    }

    componentDidMount() {
        // console.log( this.props.match.params.testvalue );
        // window.scrollTo(0, 0);
        window.onload = animer.start();

        this.setState({
            loading: true,
        });
        
        this._start_search_data();
    }

    _start_search_data() {
        this._searchDataClass( "general", ( general_class ) => {
            
            this._getDataAttr( "phones_numbers", ( phones_numbers_attrs)=> {
                this._getDataAttr( "email", ( email_attrs)=> {
                    this._getDataAttr( "social_network", ( social_network_attrs)=> {
                        let general_class_ = general_class;
                        general_class_.map((GN, index) => {
                            
                            this._searchData( GN.name, (general_data)=>{
                                let radMails_ = [];
        
                                general_data.map((data, index) => {
                                    if( data.classname === GN.id ) {

                                        email_attrs.map((attr, attrIndex) => {
                                            // console.log(attr);
                                            if( attr.data === data.id ) {
                                                radMails_= attr;
                                                radMails_.value = radMails_.value==="" ? [] : JSON.parse(radMails_.value);
                                            }
                                        });
        
                                        this.setState({
                                            general_data: data,
                                        });
                                    }
                                });
        
                                // console.log(general_class[0], radNumbers_, this.state.general_data);
        
                                this.setState({
                                    general_class: general_class[0],
                                    radMails: radMails_,
                                });
                            });
                        });
                        this.setState({
                            loading: false,
                        });
                        return true;
                    });
                    return true;
                });
                return true;
            });
        });
    }

    _getDataAttr( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            // console.log( response.data );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getDataAttr( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchData( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radData._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchData( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchDataClass( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataClass._getOne(search, page).then((response) => {
            response.data.results.map((result, index)=>{
                if( result.label === search ) {
                    tab = tab.concat( result );
                }
            });
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchDataClass( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    componentWillUnmount() {
        animer.stop();
    }

    _onSubmitForm( e ) {
        this.setState({
            loading: true,
        });

        e.preventDefault();

        let data = new FormData();

        data.append("name", document.forms["contact-us"]["name"].value);
        data.append("sender_email", document.forms["contact-us"]["email"].value);
        data.append("receiver_email", this.state.radMails);
        data.append("message", document.forms["contact-us"]["message"].value);

        
        sendEmail(data).then((response) => {
            this.setState({
                loading: false,
            });
            alert("Votre méssage a bien été transmis à l'équipe de RAD-ONG");
            // console.log( response.data );
        }).catch((e) => {
            // console.log( e );
            alert("Une erreur est survenue lors de la transmission de votre méssage.! Veuillez reéssayer SVP!");
        })
    }

    

    render() {
        return (
            <>
                <div className="ContactUsView">
                    <div className="ContactUsView_map">
                        <MapCmp
                            getPosition={(info) => {
                                return false;
                               
                            }}

                            // current = {}

                            back = {() => {
                                return false;
                            }}
                        />
                        <div className="mapInfoContainer">
                            <div className="mapInfoContainer_">
                                <div className="mapInfoContainer_head">
                                    <span>RAD-ONG</span>
                                </div>
                                <div className="mapInfoContainer_body">
                                    <p>
                                        Abomey-Calavi – Benin Immeuble jaune, 4ème rue après SOS en allant à l’université d'Abomey-Calavi, Bénin
                                    </p>
                                    <div className="mapInfoContainer_body_horaire">
                                        <p>
                                            Ouvert du lundi au vendredi de 8h-18h
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="scrollToForm"
                            onClick={() => {
                                window.scrollTo(0, document.getElementById("contact-form").offsetTop)
                            }}
                        >
                            <svg width="3em" height="3em" viewBox="0 0 16 16" class="bi bi-arrow-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                            </svg>
                        </div>
                    </div>

                    <div className="ContactUsView_form" id="contact-form">
                        <div className="ContactUsView_form_">
                            <div className="ContactUsView_form_left">
                                <div className="ContactUsView_form_left_title">
                                    <span>Laissez nous un message</span>
                                </div>
                                <form
                                    action="#"
                                    target="_self"
                                    method="post"
                                    name="contact-us"
                                    className="contact-us-form"
                                    onSubmit = {(e) => {
                                        this._onSubmitForm(e);
                                    }}
                                >
                                    <div className="contact-us-form_">
                                        <input name="name" required="true" className="contact-us-form-input" type="text" placeholder="Nom et prénom(s)" />
                                    </div>

                                    <div className="contact-us-form_">
                                        <input name="email" required="true" className="contact-us-form-input" type="email" placeholder="Email" />
                                    </div>

                                    <div className="contact-us-form_">
                                        <textarea name="message" required="true" className="contact-us-form-input contact-us-form-area" placeholder="Votre message ici"></textarea>
                                    </div>

                                    <div className="contact-us-form_submit">
                                        <input className="contact-us-form_submit_" type="submit" value="Envoyer" />
                                    </div>
                                </form>
                            </div>
                            <div className="ContactUsView_form_right">
                                <img className="ContactUsView_form_right_img" src={radLogo} alt="RAD-ONG" />
                                <span className="ContactUsView_form_right_radText title">RAD-ONG</span>
                                <span className="ContactUsView_form_right_radSlug title">Au cœur de l'agribusiness</span>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.loading &&
                    <div className='loading'>
                        <img src={loader} alt='' />
                    </div>
                }
            </>
        )
    }
}

export default ContactUsView;