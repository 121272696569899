class tools {
    _sortByDate(tab, key) {
        return (
            tab.sort(function(a, b) {
                return new Date(b[key]) - new Date(a[key]);
            })
        )
    }

    _sortByDate2(tab, key) {
        return (
            tab.sort(function(a, b) {
                return new Date(a[key]) - new Date(b[key]);
            })
        )
    }

    _containObject(object, list) {
        var i = 0;
        for (i in list) {
            if (list[i].name === object.name) {
                return true;
            }
        }
    }

    _monthBetween(date1, date2) {
        let month = (date2.getFullYear() - date1.getFullYear()) * 12
        month -= date1.getMonth();
        month += date2.getMonth();

        return month <= 0 ? 0 : month - 1;
    }

    _dayBetween(date1, date2) {
        // One day Time in ms (milliseconds)
        var one_day = 1000 * 60 * 60 * 24

        // To Calculate the result in milliseconds and then converting into days
        var Result = Math.round(date2.getTime() - date1.getTime()) / (one_day);

        // To remove the decimals from the (Result) resulting days value
        var Final_Result = Result.toFixed(0);
        return Final_Result;
    }

    _dateProgression(date1, date2) {
        let timeSpan1 = date1.getTime();
        let timeSpan2 = date2.getTime();
        let timeSpan = new Date().getTime();

        let progression = ((timeSpan - timeSpan1) * 100) / (timeSpan2 - timeSpan1);

        return (progression > 100 ? 100 : progression);
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
}

export default tools;