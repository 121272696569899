import React from 'react';

import './style/style.css';
import './style/responsive.style.css';
class Card2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

    }

    componentDidMount() {

    }


    render() {
        return (
            <div className="userCard">
                <img className="userCard_image" alt={this.props.title} src={this.props.image}/>
                <div className="userCard_body">
                    <div className="userCard_body_title">
                        <span>
                            {this.props.title}
                        </span>
                    </div>
                    <div className="userCard_body_subtitle">
                        <span>
                            {this.props.subtitle}
                        </span>
                    </div>
                    <div className='userCard_body_description'>
                        <p>
                            {
                                this.props.description
                            }
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Card2;