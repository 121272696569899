import React from 'react';
import parse from 'html-react-parser';

import './style/style.css';
import './style/responsive.style.css';

class SliderComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentViewIndex: 0,
            slide: false,
        }

        this._startSlide = this._startSlide.bind( this );

        this.timeOut = null;
    }

    componentDidMount() {
        // console.log( this.props.viewList.length );
        window.onload = this._startSlide();
        // this._startSlide();
    }
    componentWillUnmount() {
        this._stopSlide();
    }

    _startSlide() {
        this.timeOut = setInterval(() => {
            this.state.currentViewIndex < this.props.viewList.length - 1  ?
                this._changeCurrentView( this.state.currentViewIndex + 1 ) :
                this._changeCurrentView(0)
        }, this.props.delay);
    }

    _stopSlide() {
        clearInterval( this.timeOut );
    }

    _changeCurrentView( newViewIndex ) {
        this.setState({
            slide: true,
        });
        setTimeout(() => {
            if( newViewIndex > this.state.currentViewIndex ) {
                for( let i =  this.state.currentViewIndex; i < newViewIndex ; i++ ) {
                    let current = document.getElementById( "view" + i );
                    current.style.marginLeft = "-100%" ;
                }
            }

            else if( newViewIndex < this.state.currentViewIndex ) {
                for( let i =  this.state.currentViewIndex; i >= newViewIndex ; i-- ) {
                    let current = document.getElementById( "view" + i );
                    current.style.marginLeft = "0%" ;
                }
                // let current = document.getElementById( "image" + newViewIndex );
                // current.style.marginLeft = "0%" ;
            }

            this.setState({
                currentViewIndex: newViewIndex,
                slide: false,
            }, () => {
                this._stopSlide();
                this._startSlide();
            });
        }, 250)
    }

  
    render() {
        return (
            <div className="Slider_container">
                <div className="Slider_container_">
                    {
                        this.props.viewList.map((element, index) => (
                            <a id={"view" + index} target="_blank" rel="noreferrer" href={ element.link } className="Slider_container_item" key={"view" + index}>
                                <div className="Slider_container_item_logo">
                                    <img className="Slider_container_item_logo_" alt={element.name} src={ 'https://www.rad-ong.org/upload/' + element.logo } />
                                </div>
                                <div className="Slider_container_item_slug">
                                    <p>
                                        {parse(element.slug)}
                                    </p>
                                </div>
                                <div className="Slider_container_item_description textForm">
                                    <p>
                                        {parse(element.description)}
                                    </p>
                                </div>
                            </a>
                        ))
                    }
                </div>
                <div className="Slider_container_controller">
                    {
                        this.props.viewList.map((element, index) => (
                            <div
                                className="Slider_container_controller_"
                                key={index}
                                style={{
                                    color: index === this.state.currentViewIndex ? "#ffffff" : "#5dc269",
                                    transform: index === this.state.currentViewIndex ? "scale(0.8)" : "scale(1)",
                                    backgroundColor: index === this.state.currentViewIndex ? "#5dc269" : "#ffffff",
                                }}
                                onClick={() => {
                                    this._changeCurrentView( index );
                                }}
                            >
                                <span>
                                    { index + 1}
                                </span>
                            </div>
                        ))
                    }
                </div>
            </div>

        )
    }
}

export default SliderComponent;