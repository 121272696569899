import axios from "axios";
import URL from "./Url.helper";

function refreshConnection(refresh) {
    return (
        axios({
            method: 'post',
            url: URL + "api/auth/refresh/",
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                refresh: refresh
            }
        })
    )
}

export default refreshConnection;