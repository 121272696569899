import React from 'react';
import { Link } from 'react-router-dom';

import './style/style.css';
import './style/responsive.style.css';

import Compressor from '../../helpers/compressor.helper';

var projectLink = '';

class ProjectCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectLink: '',
            imgCompressSrc: ""
        }

        this.compressor = new Compressor();
    }

    componentDidMount() {
        projectLink = this.props.projectLink;
        // console.log(this.props.project);
        // window.onload = ()=>{
        //     document.getElementById(this.props.project.name).appendChild( this.compressor._image(this.props.project.cover, "projectCard_image") );
        // }
    }


    render() {
        return (
            <div className="projectCard">
                <div className="projectCard_imageContainer">
                    <Link to={"/Projet:?project=" + encodeURIComponent(this.props.project.name)} id={this.props.project.name}>
                        { this.state.canvas }
                        <img className="projectCard_image" alt={this.props.project.name} src={this.props.project.cover}/>
                    </Link>
                    <div className="projectCard_odd">
                        {
                            this.props.project.oddList.slice(0).reverse().map((odd, index) => (
                                <div key={"ODD" + index} className="odd">
                                    <Link to={"/ODD:?odd=" + encodeURIComponent(odd)}
                                        onClick={()=>{
                                            if( this.props.reload ) {
                                                this.props.actionOdd(odd);
                                            }
                                        }}
                                    >
                                        <img className="odd_" alt="odd" src={"./ODD/odd" +odd+ ".png"}/>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="projectCard_body">
                    <Link to={"/Projet:?project=" + encodeURIComponent(this.props.project.name)}>
                        <div className="projectCard_body_title">
                            <span>{ this.props.project.name }</span>
                        </div>
                    </Link>
                    <div className="card_separator_line"></div>
                    <div className="projectCard_body_text textForm lineHeight1">
                        <span>{ this.props.project.shortDescription }</span>
                    </div>
                    <div className="projectCard_competence">
                        {
                            this.props.project.competencesDomaine.map((competence, index) => (
                                <div key={"competence" + index} className="competence">
                                    <Link className="link" to={"/Domaine-de-competences:?competence=" + encodeURIComponent(competence)}
                                        onClick={()=>{
                                            if( this.props.reload2 ) {
                                                this.props.actionTag(competence);
                                            }
                                        }}
                                    >
                                        <span>
                                            { competence }
                                        </span>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="projectCard_footer">
                    <Link className="link" to={"/Projet:?project=" + encodeURIComponent(this.props.project.name)}>
                        <span>Voir plus ›</span>
                    </Link>
                    {/* <Link className="link" to={{
                        pathname: "/Projet:?project=" + this.props.project.name,
                    }}>
                        <span>Voir plus ›</span>
                    </Link> */}
                </div>
            </div>
        )
    }
}

export default ProjectCard;