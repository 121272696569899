import React from 'react';
import parse from 'html-react-parser';

/**Importation des composants de la page */
import { connect } from 'react-redux';


/**Importation des styles de la page */
import './styles/dashboard.rubrique.home.style.css';
import './styles/dashboard.rubrique.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';

/** Importation des helpers */
import uploadFiles from '../helpers/upload_file.helper'
import getPreviewFiles from '../helpers/getPreviewFiles.helper';
import radDataClass from '../helpers/data-class.helper';
import radDataAttr from '../helpers/data-attr.helper';
import radData from '../helpers/data.helper';
import refreshConnection from '../helpers/refresh.helper';
import imagesURL from '../helpers/imagesURL.helper';

import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}

class DashboardSavoirFaire extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            loading: false,

            updatePoste: false,

            uploadFiles: false,
            currentUploadProgression: 0,
            currentUploadMessage: '',

            typeUpdate: "",

            savoirFaires_class: {},
            savoirFaires_data: [],
            savoirFaires_attrs: [],
            updateVideo: false,

            currentVideo: {},
        }

        this.accessStore = new AccessStore(this.props.dispatch);

        this.currentUploadFiles = [];
        this.currentUploadFiles2 = [];

        this.radDataClass = new radDataClass();
        this.radData = new radData();
        this.radDataAttr = new radDataAttr();
        this._searchDataClass = this._searchDataClass.bind( this );
    }

    _makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++)
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    _setNewAccessToUser(callback) {
        refreshConnection(this.props.user.refresh).then((response) => {
            let newUser = this.props.user;
            newUser.access = response.data.access;

            if (response.data.refresh) {
                newUser.refresh = response.data.refresh;
            }

            this.accessStore.setRedux('SET_USER', newUser, callback);
        }).catch((e) => {
            if (e.response) {
                if (e.response.status === 401) {
                    this.accessStore.setRedux('LOG_OUT', {});
                }
            }
            if (e.toString() === "Error: Network Error") {
                alert("Aucun accès à internet :(");
            }
        });
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });

        this._start_search_data();
    }

    _start_search_data() {
        this._searchDataClass( "savoir-faire", ( savoirFaires_class ) => {
            
            this._getDataAttr( "videos", ( savoirFaires_attrs)=> {
                // console.log(savoirFaires_attrs);
                
                let savoirFaires_class_ = savoirFaires_class;
                savoirFaires_class_.map((SF, index) => {
                    
                    this._searchData( SF.name, (savoirFaires_data)=>{
                        let savoirFaires_attrs_ = [];

                        savoirFaires_data.map((data, index) => {
                            if( data.classname === SF.id ) {
                                
                                savoirFaires_attrs.map((attr, attrIndex) => {
                                    if( attr.data === data.id ) {
                                        savoirFaires_attrs_.push(attr);
                                    }
                                });

                                this.setState({
                                    savoirFaires_data: data,
                                });
                            }
                        });

                        this.setState({
                            savoirFaires_class: savoirFaires_class[0],
                            savoirFaires_attrs: savoirFaires_attrs_,
                        });
                    });
                });
                this.setState({
                    loading: false,
                });
                return true;
            });
        });
    }

    _getDataAttr( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            // console.log( response.data );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getDataAttr( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchData( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radData._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchData( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchDataClass( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataClass._getOne(search, page).then((response) => {
            response.data.results.map((result, index)=>{
                if( result.label === search ) {
                    tab = tab.concat( result );
                }
            });
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchDataClass( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _post_dataAttr( dataAttr, callback=()=>{} ) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._post(JSON.stringify(dataAttr), this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            // console.log( e );
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._post_dataAttr( dataAttr, callback ));
                }
                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            // alert("Ce poste existe déjà");
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _update_dataAttr(id, data, callback=()=>{}) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._update(id, JSON.stringify(data), this.props.user.access).then((response) => {
            this.setState({
                loading: false,
            });
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._update_dataAttr(id, data, callback) );
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _addVideo() {
        this.setState({
            loading: true,
        });

        let form = document.forms["savoir-faire"];

        if( form["video"].files.length > 0 && form["title"].value !== "" && form["description"].value !== "" ) {
            if( window.confirm("Voulez-vous vraiment ajouter cette video ?") === true ){
                this.setState({
                    uploadFiles: true,
                });

                uploadFiles( "savoir-faire-videos", form["video"].files, (event) => {
                    this.setState({
                        currentUploadProgression: Math.round((100 * event.loaded) / event.total),
                    });
                }).then((response) => {
                    this.setState({
                        uploadFiles: false,
                    });
                    let videoUpladed = "savoir-faire-videos/" + form["video"].files[0].name;

                    if( response.data.code === 201 ){
                        let video = {
                            label: form["title"].value,
                            desc: form["description"].value,
                            value: videoUpladed,
                            name: form["title"].value,
                            data: this.state.savoirFaires_data.id,
                            type: 2,
                        }
                        this._post_dataAttr(video, ()=>{
                            this._start_search_data();
                            alert("Video ajouté avec succès");
                            this.setState({
                                loading: false,
                            });
                        });
                    }
                    else {
                        alert('Echec de l\'envois du fichier. Veuillez réessayer');
                        this.setState({
                            uploadFiles: false,
                            loading: false,
                        });
                    }
                }).catch((e) => {
                    this.setState({
                        loading: false,
                    });
                    alert('Echec de l\'envois du fichier. Veuillez réessayer');
                    // console.log( e );
                });
            }
        }
        else {
            this.setState({
                loading: false,
            });
            alert("Vous devez renseigner toutes les informations");
        }
    }

    _deleteDataAttr(id, callback=()=>{}) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._delete(id, this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._deleteDataAttr(id, callback) );
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _startUpdateVideo() {
        if( window.confirm("Voulez-vous vraiment modifier cette video ?") === true ){
            let data = {
                name: this.state.currentVideo.name,
                desc: this.state.currentVideo.desc,
                data: this.state.currentVideo.data,
            };

            this._update_dataAttr(this.state.currentVideo.id, data, ()=>{
                alert("Modification effectuée avec succès");
                this.setState({
                    updateVideo: false,
                },() => {
                    this.setState({
                        currentVideo: {},
                    });
                });
            })
        }
    }

    render() {
        return (
            <>
                <div className='dashboardRubrique_home'>
                    <div className='dashboardRubrique_home_head title'>
                        <span className='title'>
                            Rubrique "Notre savoir faire"
                        </span>
                    </div>


                    <div className='textContent'>
                        <div className='textContent_ radSections_title_content'>
                            <div className='radSections_title radSections_title_label'>
                                <span className='radSections_title_'>
                                    Ajouter une video
                                </span>
                            </div>

                            <form
                                name='savoir-faire'
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    this._addVideo();
                                }}
                            >
                                <div className='add_content'>
                                    <div className='addSection_input'>
                                        <input className='choice_image' name='video' required type='file' accept="video/mp4,video/x-m4v,video/*"/>
                                    </div>

                                    <div className='addSection_input'>
                                        <span>Titre</span>
                                        <input name='title' required type='text' placeholder='Titre'/>
                                    </div>

                                    <div className='addSection_input'>
                                        <span>Description</span>
                                        <input name='description' required type='text' placeholder='Description'/>
                                    </div>

                                    <div className='updateSocial_inputSubmit_container'>
                                        <button className='updateSocial_inputSubmit'
                                            style={{border: "1px solid #00000000"}}
                                        >Envoyer</button>
                                    </div>
                                </div>
                            </form>
                            
                            
                            <div className='savoireFairee_videosList'>
                                {
                                    this.state.savoirFaires_attrs.map((attr, index) => (
                                        <div className='savoireFairee_videosList_elt'>
                                            <video
                                                src={ imagesURL + "/upload/" + attr.value}
                                                controls={true}
                                                className="videoComponent_video"
                                            ></video>
                                            <div className='savoireFairee_videosList_elt_'>
                                                <div className='savoireFairee_videosList_elt_title'>
                                                    <label>
                                                        {attr.name}
                                                    </label>
                                                </div>
                                                <div className='savoireFairee_videosList_elt_desc'>
                                                    <label>
                                                        {attr.desc}
                                                    </label>
                                                </div>
                                                <div className='porteffolio_bottom'>
                                                    <span className='update'
                                                        onClick={() => {
                                                            this.setState({
                                                                currentVideo: attr,
                                                            },() => {
                                                                this.setState({
                                                                    updateVideo: true,
                                                                });
                                                            });
                                                            return true;
                                                        }}
                                                    >
                                                        Modifier
                                                    </span>
                                                    <span className='delete'
                                                        onClick={() => {
                                                            if( window.confirm("Voulez-vous vraiment supprimer cette video ?") === true ){
                                                                this._deleteDataAttr(attr.id, ()=>{
                                                                    this._start_search_data();
                                                                });
                                                            }
                                                            return true;
                                                        }}
                                                    >
                                                        Supprimer
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    {
                        this.state.updateVideo&&
                        <div className='addSection'>
                            <div className='addSection_'>
                                <div className='addSection_title'>
                                    <span>
                                        Modifier
                                    </span>
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Titre
                                    </span>
                                    <input type='text'
                                        value={this.state.currentVideo.name}
                                        onChange={(e) => {
                                            let currentVideo = this.state.currentVideo;
                                            currentVideo.name = e.target.value;
                                            this.setState({
                                                currentVideo: currentVideo,
                                            });
                                            return true;
                                        }}
                                    />
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Description
                                    </span>
                                    <input type='text'
                                        value={this.state.currentVideo.desc}
                                        onChange={(e) => {
                                            let currentVideo = this.state.currentVideo;
                                            currentVideo.desc = e.target.value;
                                            this.setState({
                                                currentVideo: currentVideo,
                                            });
                                            return true;
                                        }}
                                    />
                                </div>

                                <div className='updateSocial_inputSubmit_container'>
                                    <span className='updateSocial_inputSubmit'
                                        onClick={(e) => {
                                            this._startUpdateVideo();
                                        }}
                                    >
                                        Valider
                                    </span>
                                    <span className='updateSocial_inputBack'
                                        onClick={() => {
                                            this.setState({
                                                updateVideo: false,
                                            },() => {
                                                this.setState({
                                                    currentVideo: {},
                                                });
                                            });
                                            return true;
                                        }}
                                    >
                                        Annuler
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.loading &&
                        <div className='loading'>
                            <img src={loader} alt='' />
                        </div>
                    }
                </div>
            </>
        )
    }
}

DashboardSavoirFaire = connect(mapStateToProps, null)(DashboardSavoirFaire);
export default DashboardSavoirFaire;