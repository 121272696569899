import axios from "axios";
const url = "https://www.rad-ong.org/rad-dev/directory-manager.php";
// const url = "http://localhost/test/directory-manager.php";

class directoryManager {
    _createDirectory(directory_name) {
        let data = new FormData();

        data.append("directory_name", directory_name);

        return (
            axios.post(
                url, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
        )
    }

    _renameDirectory(directory_name, new_directory_name) {
        let data = new FormData();

        data.append("directory_name", directory_name);
        data.append("new_directory_name", new_directory_name);
        data.append("put", true);

        return (
            axios.post(
                url, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
        )
    }


    _deleteDirectory(directory_name) {
        let data = new FormData();

        data.append("directory_name", directory_name);
        data.append("delete", true);

        return (
            axios.post(
                url, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
        )
    }
}

export default directoryManager;