import React from 'react';
import parse from 'html-react-parser';

import { connect } from 'react-redux';

/**Importation des composants de la page */


/** Importation des helpers */
import radDataClass from '../helpers/data-class.helper';
import radDataAttr from '../helpers/data-attr.helper';
import radData from '../helpers/data.helper';

import refreshConnection from '../helpers/refresh.helper';


/**Importation des styles de la page */
import './styles/dashboard.general.style.css';


/**Importation des images*/
import loader from '../assets/gif/loader2.gif';

import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}

class DashboardGeneral extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentView: 0,
            loading: false,
            radNumbers: {},
            radMails: [],
            radSocials: [],
            updateSocial: false,
            typeUpdate: '',
            currentSocial: {
                name: '',
                icon: '',
                link: '',
            },
            currentSocialIndex: 0,
            general_class: "",
            general_data: "",
        }

        this.radDataClass = new radDataClass();
        this.radData = new radData();
        this.radDataAttr = new radDataAttr();

        this.accessStore = new AccessStore(this.props.dispatch);
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });
        window.scrollTo(0, 0);

        this._start_search_data();
    }

    _start_search_data() {
        this._searchDataClass( "general", ( general_class ) => {
            
            this._getDataAttr( "phones_numbers", ( phones_numbers_attrs)=> {
                this._getDataAttr( "email", ( email_attrs)=> {
                    this._getDataAttr( "social_network", ( social_network_attrs)=> {
                        let general_class_ = general_class;
                        general_class_.map((GN, index) => {
                            
                            this._searchData( GN.name, (general_data)=>{
                                let radNumbers_ = [];
                                let radMails_ = [];
                                let radSocials_ = [];
        
                                general_data.map((data, index) => {
                                    if( data.classname === GN.id ) {
                                        
                                        phones_numbers_attrs.map((attr, attrIndex) => {
                                            // console.log(attr);
                                            if( attr.data === data.id ) {
                                                radNumbers_= attr;
                                                radNumbers_.value = radNumbers_.value==="" ? [] : JSON.parse(radNumbers_.value);
                                            }
                                        });

                                        email_attrs.map((attr, attrIndex) => {
                                            // console.log(attr);
                                            if( attr.data === data.id ) {
                                                radMails_= attr;
                                                radMails_.value = radMails_.value==="" ? [] : JSON.parse(radMails_.value);
                                            }
                                        });

                                        social_network_attrs.map((attr, attrIndex) => {
                                            // console.log(attr);
                                            if( attr.data === data.id ) {
                                                radSocials_= attr;
                                                radSocials_.value = radSocials_.value==="" ? [] : JSON.parse(radSocials_.value);
                                            }
                                        });
        
                                        this.setState({
                                            general_data: data,
                                        });
                                    }
                                });
        
                                // console.log(general_class[0], radNumbers_, this.state.general_data);
        
                                this.setState({
                                    general_class: general_class[0],
                                    radNumbers: radNumbers_,
                                    radMails: radMails_,
                                    radSocials: radSocials_
                                });

                                console.log({
                                    general_class: general_class[0],
                                    radNumbers: radNumbers_,
                                    radMails: radMails_,
                                    radSocials: radSocials_
                                });
                            });
                        });
                        this.setState({
                            loading: false,
                        });
                        return true;
                    });
                    return true;
                });
                return true;
            });
        });
    }

    _clickToModify( e, id ) {
        let inputContainer = document.getElementById(id);
        inputContainer.style.position = "relative";
        inputContainer.style.opacity = 1;
        inputContainer.style.transform = "scaleY(1)";
        inputContainer.style.zIndex = 100;
    }

    _clickToBackModify( id ) {
        let inputContainer = document.getElementById(id);
        inputContainer.style.position = "absolute";
        inputContainer.style.opacity = 0;
        inputContainer.style.transform = "scaleY(0)";
        inputContainer.style.zIndex = -1000;
    }

    _getDataAttr( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            // console.log( response.data );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getDataAttr( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchData( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radData._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchData( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchDataClass( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataClass._getOne(search, page).then((response) => {
            response.data.results.map((result, index)=>{
                if( result.label === search ) {
                    tab = tab.concat( result );
                }
            });
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchDataClass( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _post_dataAttr( dataAttr, callback=()=>{} ) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._post(JSON.stringify(dataAttr), this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            // console.log( e );
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._post_dataAttr( dataAttr, callback ));
                }
                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            // alert("Ce poste existe déjà");
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _update_dataAttr(id, data, callback=()=>{}) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._update(id, JSON.stringify(data), this.props.user.access).then((response) => {
            this.setState({
                loading: false,
            });
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._update_dataAttr(id, data, callback) );
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _setNewAccessToUser(callback) {
        refreshConnection(this.props.user.refresh).then((response) => {
            let newUser = this.props.user;
            newUser.access = response.data.access;

            if (response.data.refresh) {
                newUser.refresh = response.data.refresh;
            }

            // console.log( response, this.props.user, newUser );

            this.accessStore.setRedux('SET_USER', newUser, callback);
            // this._getRadExpertise();
        }).catch((e) => {
            if (e.response) {
                if (e.response.status === 401) {
                    this.accessStore.setRedux('LOG_OUT', {});
                }
            }
            if (e.toString() === "Error: Network Error") {
                alert("Aucun accès à internet :(");
            }
        });
    }

    /** Mettre a jour un email de RAD-ONG */
    _updateEmail( index ) {
        let promptValue = prompt("Modifier l'email ici");
        if( (promptValue) !== null ) {
            let data_ = this.state.radMails;
            data_.value[index] = promptValue;
            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.general_data.id,
                name: data_.name,
            };

            // console.log(data);
            
            this.setState({
                loading: true,
            });
            // console.log( data );
            this._update_dataAttr(this.state.radMails.id, data, (response) => {
                alert("Email modifié avec succès");
            });
        }
    }

    /** Mettre à jour un numéro de téléphone de RAD-ONG */
    _updateTel( index ) {
        let promptValue = prompt("Modifier le numéros ici");
        if( (promptValue) !== null ) {
            let data_ = this.state.radNumbers;
            data_.value[index] = promptValue;
            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.general_data.id,
                name: data_.name,
            };

            // console.log(data);
            
            this.setState({
                loading: true,
            });
            // console.log( data );
            this._update_dataAttr(this.state.radNumbers.id, data, (response) => {
                alert("Numéro de téléphone modifié avec succès");
            });
        }
    }

    /** Mettre à jour le lien d'un reseau social de RAD-ONG */
    _updateSocial( index, social ) {
        if( window.confirm("Voulez-vous vraiment modifier ce lien ?") === true ){
            let data_ = this.state.radSocials;
            data_.value[index] = social;
            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.general_data.id,
                name: data_.name,
            };

            // console.log(data);
            
            this.setState({
                loading: true,
            });
            // console.log( data );
            this._update_dataAttr(this.state.radSocials.id, data, (response) => {
                alert("Réseau social modifié avec succès");
                this.setState({
                    loading: false,
                    updateSocial: false,
                    currentSocial: {
                        name: '',
                        icon: '',
                        link: '',
                    },
                });
            });
        }
    }

    /** Supprimer un email de RAD-ONG */
    _deleteEmail( index ) {
        if( window.confirm("Voulez-vous vraiment surprimer l'email ?") === true ){
            this.setState({
                loading: true,
            });
            let data_ = this.state.radMails;
            data_.value.splice(index, 1);
            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.general_data.id,
                name: data_.name,
            };

            // console.log(data);
            
            this.setState({
                loading: true,
            });
            // console.log( data );
            this._update_dataAttr(this.state.radMails.id, data, (response) => {
                alert("Email supprimé avec succès");
            });
        }
    }

    /** Supprimer un numéro de téléphone de RAD-ONG */
    _deleteTel( index ) {
        if( window.confirm("Voulez-vous vraiment surprimer le numéro de téléphone ?") === true ){
            this.setState({
                loading: true,
            });
            let data_ = this.state.radNumbers;
            data_.value.splice(index, 1);
            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.general_data.id,
                name: data_.name,
            };

            // console.log(data);
            
            this.setState({
                loading: true,
            });
            // console.log( data );
            this._update_dataAttr(this.state.radNumbers.id, data, (response) => {
                alert("Numéro de téléphone supprimé avec succès");
            });
        }
    }

    _makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++)
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    /** AJouter un email de contact pour RAD-ONG */
    _addEmail() {
        let promptValue = prompt("Entrez l'email ici");
        if( (promptValue) !== null ) {
            let data_ = this.state.radMails;
            data_.value.push(promptValue);
            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.general_data.id,
                name: data_.name,
            };

            // console.log(data);
            
            this.setState({
                loading: true,
            });
            // console.log( data );
            this._update_dataAttr(this.state.radMails.id, data, (response) => {
                alert("Email ajouté avec succès");
            });
        }
    }

    /** AJouter un nemero de telephone pour RAD-ONG */
    _addTel() {
        let promptValue = prompt("Entrez le numéro de téléphone ici");
        if( (promptValue) !== null ) {
            let data_ = this.state.radNumbers;
            data_.value.push(promptValue);
            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.general_data.id,
                name: data_.name,
            };

            // console.log(data);
            
            this.setState({
                loading: true,
            });
            // console.log( data );
            this._update_dataAttr(this.state.radNumbers.id, data, (response) => {
                alert("Numéro de téléphone ajouté avec succès");
            });
        }
    }

    _selectSocial( select ) {
        let social = this.state.currentSocial;
        social.icon = select.options[select.selectedIndex].value;
        social.name = select.options[select.selectedIndex].text;
        this.setState({
            currentSocial: social,
        });
    }

    _setSocialLink( link ) {
        let social = this.state.currentSocial;
        social.link = link;
        this.setState({
            currentSocial: social,
        });
    }

    _addSocial( social ) {
        if( this.state.typeUpdate === 'add' ) {
            if( window.confirm("Voulez-vous vraiment ajouté?") === true ){
                let data_ = this.state.radSocials;
                data_.value.push(social);
                let data = {
                    value: JSON.stringify(data_.value),
                    data: this.state.general_data.id,
                    name: data_.name,
                };

                // console.log(data);
                
                this.setState({
                    loading: true,
                });
                // console.log( data );
                this._update_dataAttr(this.state.radSocials.id, data, (response) => {
                    alert("Réseau social ajouté avec succès");
                    this.setState({
                        loading: false,
                        updateSocial: false,
                        currentSocial: {
                            name: '',
                            icon: '',
                            link: '',
                        },
                    });
                });
            }
        }

        else if( this.state.typeUpdate === 'update' ) {
            if( window.confirm("Voulez-vous vraiment mettre à jour?") === true ){
                let data = {
                    data : {
                        name: social.name,
                        icon: social.icon,
                        link: social.link,
                    }
                }
                console.log( data );
                this.setState({
                    loading: true,
                });
                this.radSocial._update( social.id, data ).then((response) => {
                    // console.log( response );
                    this.radSocial._getAll().then((response) => {
                        this.setState({
                            loading: false,
                            radSocials: response.data.radsocials,
                            updateSocial: false,
                            currentSocial: {
                                name: '',
                                icon: '',
                                link: '',
                            },
                        });
                    }).catch((error) => {
                        console.log( error );
                    })
                }).catch((error) => {
                    this.setState({
                        loading: false,
                    });
                    console.log( error );
                });
            }
        }
    }
    _deleteSocial( index ) {
        if( window.confirm("Voulez-vous vraiment surprimer ?") === true ){
            this.setState({
                loading: true,
            });
            let data_ = this.state.radSocials;
            data_.value.splice(index, 1);
            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.general_data.id,
                name: data_.name,
            };

            // console.log(data);
            
            this.setState({
                loading: true,
            });
            // console.log( data );
            this._update_dataAttr(this.state.radSocials.id, data, (response) => {
                alert("Reseau social supprimé avec succès");
            });
        }
    }

    render() {
        return (
            <>
                <div className='dashboardGeneral'>
                    <div className='dashboardGeneral_top'>
                        <span className='title'>
                            Les informations générales de RAD-ONG
                        </span>
                    </div>
                    <div className='dashboardGeneral_content'>
                        <div className='dashboardGeneral_content_'>
                            <div className='dashboardGeneral_content_top'>
                                <span>
                                    Numéros de télephone :
                                </span>
                                <span className='dashboardGeneral_content_add update_2'
                                    onClick={() => {
                                        this._addTel();
                                    }}
                                >
                                    Ajouter +
                                </span>
                            </div>
                            <table className='table1'>
                                <thead>
                                <tr className='table1_trth'>
                                    <th className='table1_th table1_th_'>
                                        Numéro
                                    </th>
                                    <th className='table1_th'>
                                        modifier
                                    </th>
                                    <th className='table1_th'>
                                        supprimer
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.radNumbers.value && this.state.radNumbers.value.map((number, index) => (
                                        <tr
                                            className='table1_tr'
                                            key={"tabTel" + index }
                                            style={{
                                                backgroundColor: index % 2 !== 0 ? "#00000011" : "transparent"
                                            }}
                                        >
                                            <td className='table1_th'>
                                                {
                                                    number
                                                }
                                            </td>
                                            <td className='table1_th table1_th_center'>
                                                <span className='table1_th_Add'
                                                    onClick={() => {
                                                        this._updateTel(index)
                                                    }}
                                                >
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                </span>
                                            </td>
                                            <td className='table1_th table1_th_center'>
                                                <span className='table1_th_Add'
                                                    onClick={() => {
                                                        this._deleteTel(index)
                                                    }}
                                                >
                                                    <i className="fa fa-trash-o colorRed" aria-hidden="true"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>

                        <div className='dashboardGeneral_content_'>
                            <div className='dashboardGeneral_content_top'>
                                <span>
                                    Emails :
                                </span>
                                <span className='dashboardGeneral_content_add update_2'
                                    onClick={() => {
                                        this._addEmail();
                                    }}
                                >
                                    Ajouter +
                                </span>
                            </div>
                            <table className='table1'>
                                <thead>
                                <tr className='table1_trth'>
                                    <th className='table1_th table1_th_'>
                                        Email
                                    </th>
                                    <th className='table1_th'>
                                        modifier
                                    </th>
                                    <th className='table1_th'>
                                        supprimer
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.radMails.value && this.state.radMails.value.map((mail, index) => (
                                        <tr
                                            className='table1_tr'
                                            key={"tabTel" + index }
                                            style={{
                                                backgroundColor: index % 2 !== 0 ? "#00000011" : "transparent"
                                            }}
                                        >
                                            <td className='table1_th'>
                                                {
                                                    mail
                                                }
                                            </td>
                                            <td className='table1_th table1_th_center'>
                                                <span className='table1_th_Add'
                                                    onClick={() => {
                                                        this._updateEmail( index )
                                                    }}
                                                >
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                </span>
                                            </td>
                                            <td className='table1_th table1_th_center'>
                                                <span className='table1_th_Add'
                                                    onClick={() => {
                                                        this._deleteEmail( index )
                                                    }}
                                                >
                                                    <i className="fa fa-trash-o colorRed" aria-hidden="true"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>


                        <div className='dashboardGeneral_content_'>
                            <div className='dashboardGeneral_content_top'>
                                <span>
                                    Reseaux sociaux :
                                </span>
                                <span className='dashboardGeneral_content_add update_2'
                                    onClick={() => {
                                        this.setState({
                                            updateSocial: true,
                                            typeUpdate: 'add',
                                        });
                                    }}
                                >
                                    Ajouter +
                                </span>
                            </div>
                            <table className='table1'>
                                <thead>
                                <tr className='table1_trth'>
                                    <th className='table1_th'>
                                        nom
                                    </th>
                                    <th className='table1_th table1_th_'>
                                        lien
                                    </th>
                                    <th className='table1_th'>
                                        modifier
                                    </th>
                                    <th className='table1_th'>
                                        supprimer
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.radSocials.value && this.state.radSocials.value.map((social, index) => (
                                        <tr
                                            className='table1_tr'
                                            key={"tabSocial" + index }
                                            style={{
                                                backgroundColor: index % 2 !== 0 ? "#00000011" : "transparent"
                                            }}
                                        >
                                            <td className='table1_th'>
                                                <div className='dashboardGeneral_content_list_eltName'>
                                                    { social.name } {parse(social.icon)}
                                                </div>
                                            </td>
                                            <td className='table1_th'>
                                                {
                                                    social.link
                                                }
                                            </td>
                                            <td className='table1_th table1_th_center'>
                                                <span className='table1_th_Add'
                                                    onClick={() => {
                                                        this.setState({
                                                            updateSocial: true,
                                                            currentSocial: social,
                                                            typeUpdate: 'update',
                                                            currentSocialIndex: index,
                                                        });
                                                    }}
                                                >
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                </span>
                                            </td>
                                            <td className='table1_th table1_th_center'>
                                                <span className='table1_th_Add'
                                                    onClick={() => {
                                                        this._deleteSocial( index );
                                                    }}
                                                >
                                                    <i className="fa fa-trash-o colorRed" aria-hidden="true"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        this.state.updateSocial &&
                        <div className="updateSocial_container">
                            <div className='updateSocial_container_'>
                                <div className='updateSocial_input'>
                                    <span className='updateSocial_input_name'>
                                        Nom :
                                    </span>
                                    <div className='updateSocial_input_selected'>
                                        <span>
                                            {
                                                parse(this.state.currentSocial.icon)
                                            }
                                        </span>
                                        <select
                                            onChange={(e) => {
                                                this._selectSocial( e.target );
                                            }}
                                        >
                                            <option value=''>
                                                
                                            </option>
                                            <option value='<i className="fa fa-facebook facebookIcon"></i>'>
                                                Facebook
                                            </option>
                                            <option value='<i className="fa fa-linkedin linkedinIcon"></i>'>
                                                Linkedin
                                            </option>
                                            <option value='<i className="fa fa-twitter twitterIcon"></i>'>
                                                Twitter
                                            </option>
                                            <option value='<i className="fa fa-instagram instagramIcon"></i>'>
                                                Instagram
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div className='updateSocial_input'>
                                    <span className='updateSocial_input_name'>
                                        Lien :
                                    </span>
                                    <input value={this.state.currentSocial.link} className='updateSocial_input_balise'
                                        onChange={(e) => {
                                            this._setSocialLink( e.target.value );
                                        }}
                                    />
                                </div>

                                <div className='updateSocial_inputSubmit_container'>
                                    <span className='updateSocial_inputSubmit'
                                        onClick={() => {
                                            if(this.state.typeUpdate === "update") {
                                                this._updateSocial( this.state.currentSocialIndex, this.state.currentSocial)
                                            } else {
                                                this._addSocial( this.state.currentSocial );
                                            }
                                        }}
                                    >
                                        Valider
                                    </span>
                                    <span className='updateSocial_inputBack'
                                        onClick={() => {
                                            this.setState({
                                                updateSocial: false,
                                                currentSocial: {
                                                    name: '',
                                                    icon: '',
                                                    link: '',
                                                },
                                            });
                                        }}
                                    >
                                        Annuler
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.loading &&
                        <div className='loading'>
                            <img src={loader} alt='' />
                        </div>
                    }
                </div>
            </>
        )
    }
}

DashboardGeneral = connect(mapStateToProps, null)(DashboardGeneral);
export default DashboardGeneral;