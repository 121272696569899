import axios from "axios";

const uploadUrl = "https://www.rad-ong.org/rad-dev/upload_files.php";

// const uploadUrl = "http://localhost/test/upload_files.php";

function uploadFiles(folder, files, onUploadProgress) {
    let data = new FormData();

    for (let i = 0; i < files.length; i++) {
        data.append("files[]", files[i]);
    }

    // console.log(files);

    data.append("folder", folder);

    return (
        axios.post(
            uploadUrl, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress,
            },
        )
    )
}

export default uploadFiles;