import React from 'react';
import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import {Link} from 'react-router-dom';

/**Importation des composants de la page */
import DashboardRubriqueHome from './dashboard.rubrique.home.screen';
import DashboardRubriqueABoutUs from './dashboard.rubrique.aboutUs.screen';
import DashboardRubriqueMedia from './dashboard.rubrique.media.screen';
import DashboardSavoirFaire from './dashboard.rubrique.savoirFaire.screen';


/**Importation des styles de la page */
import './styles/dashboard.rubrique.style.css';
import './styles/dashboard.rubrique.responsive.style.css';

/**Importation des images*/


class DashboardRubrique extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentView: 0,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    _clickHomeRubrique() {
        this.setState({
            currentView: 1,
        });
    }

    _clickABoutUsRubrique() {
        this.setState({
            currentView: 2,
        });
    }

    _clickMediaRubrique() {
        this.setState({
            currentView: 3,
        });
    }

    _clickSavoirFaireRubrique() {
        this.setState({
            currentView: 4,
        });
    }

    _backToPreviews() {
        let dashboardRubrique_container_ = document.getElementsByClassName("dashboardRubrique_container_");
        // console.log( dashboardRubrique_container_ );

        for( var i=0; i< dashboardRubrique_container_.length; i++ ) {
            dashboardRubrique_container_[i].style.animation = "animToRight_2 500ms forwards";
            if( i === dashboardRubrique_container_.length - 1 ) {
                setTimeout(() => {
                    if( this.state.currentView === 1 ) {
                        this.setState({
                            currentView: 0,
                        });
                    }

                    else if( this.state.currentView === 2 ) {
                        this.setState({
                            currentView: 0,
                        });
                    }

                    else if( this.state.currentView === 3 ) {
                        this.setState({
                            currentView: 0,
                        });
                    }
                }, 300)
            }
        }
    }

    render() {
        return (
            <>
               <div className='dashboardRubrique'>
                    {
                        this.state.currentView === 0 &&
                        <>
                        <div className='dashboardRubrique_top title'>
                            <span>
                                Liste des rubriques :
                            </span>
                        </div>

                        <div className='dashboardRubrique_content'>
                            <div className='dashboardRubrique_content_'>
                                <span
                                    onClick={() => {
                                        this._clickHomeRubrique();
                                    }}
                                >
                                    <i className="fa fa-home menuIcon"></i> ACCUEIL
                                </span>
                            </div>

                            <div className='dashboardRubrique_content_'>
                                <span
                                    onClick={() => {
                                        this._clickABoutUsRubrique();
                                    }}
                                >
                                    <i className="fa fa-users menuIcon"></i> A PROPOS DE NOUS
                                </span>
                            </div>

                            {/* <div className='dashboardRubrique_content_'>
                                <span>
                                    <i className="fa fa-newspaper-o menuIcon"></i> A LA UNE
                                </span>
                            </div>

                            <div className='dashboardRubrique_content_'>
                                <span>
                                    <i className="fa fa-thumb-tack menuIcon"></i> NOS PROJETS
                                </span>
                            </div>

                            <div className='dashboardRubrique_content_'>
                                <span>
                                    <i className="fa fa-phone menuIcon menuIcon2"></i> NOUS CONTACTER
                                </span>
                            </div> */}

                            <div className='dashboardRubrique_content_'>
                                <span
                                    onClick={() => {
                                        this._clickMediaRubrique();
                                    }}
                                >
                                    <i className="fa fa-picture-o menuIcon"></i> MÉDIATHÈQUE
                                </span>
                            </div>

                            <div className='dashboardRubrique_content_'>
                                <span
                                    onClick={() => {
                                        this._clickSavoirFaireRubrique();
                                    }}
                                >
                                    <i className="fa fa-thumb-tack menuIcon"></i> NOTRE SAVOIR FAIRE
                                </span>
                            </div>
                        </div>
                        </>
                    }

                    {
                        this.state.currentView !== 0 &&
                        <div className="dashboardLeft_arrow">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-left-short dashboardLeft_arrow_svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                onClick={() => {
                                    this._backToPreviews();
                                }}
                            >
                                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                            </svg>
                        </div>
                    }

                    {
                        this.state.currentView === 1 &&
                        <div className='dashboardRubrique_container'>
                            <div className='dashboardRubrique_container_'>
                                <DashboardRubriqueHome/>
                            </div>
                        </div>
                    }

                    {
                        this.state.currentView === 2 &&
                        <div className='dashboardRubrique_container'>
                            <div className='dashboardRubrique_container_'>
                                <DashboardRubriqueABoutUs/>
                            </div>
                        </div>
                    }

                    {
                        this.state.currentView === 3 &&
                        <div className='dashboardRubrique_container'>
                            <div className='dashboardRubrique_container_'>
                                <DashboardRubriqueMedia/>
                            </div>
                        </div>
                    }

                    {
                        this.state.currentView === 4 &&
                        <div className='dashboardRubrique_container'>
                            <div className='dashboardRubrique_container_'>
                                <DashboardSavoirFaire/>
                            </div>
                        </div>
                    }
               </div>
            </>
        )
    }
}

export default DashboardRubrique;