import React from 'react';
import { connect } from 'react-redux';

/**Importation des composants de la page */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//--------Le menu de la page--------------



/** Importation des screens */


/**Importation des styles de la page */
import './styles/dashboard.expertise.style.css';
// import './styles/dashboard.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';


/** Importation des helpers */
import radExpertise from '../helpers/expertise.helper';
import radODD from '../helpers/ODD.helper';
import tools from '../helpers/tools.helper';
import refreshConnection from '../helpers/refresh.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}


class DashboardExpertise extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            radExpertise: [],
            radODD: [],
            loading: false,
        }

        this.accessStore = new AccessStore( this.props.dispatch);
        this.radExpertise = new radExpertise();
        this.radODD = new radODD();
        this.tools = new tools();
        this._getRadExpertise = this._getRadExpertise.bind( this );
    }

    componentDidMount() {
        this._getRadExpertise();
        // localStorage.removeItem("rad_user");
    }

    _makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++)
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    _setNewAccessToUser( callback ) {
        refreshConnection( this.props.user.refresh ).then((response) => {
            
            let newUser = this.props.user;
            newUser.access = response.data.access;
            
            if( response.data.refresh ) {
                newUser.refresh = response.data.refresh;
            }

            // console.log( response, this.props.user, newUser );

            this.accessStore.setRedux('SET_USER', newUser, callback);
            // this._getRadExpertise();
        }).catch((e) => {
            if( e.response.status === 401 ) {
                this.accessStore.setRedux('LOG_OUT', {});
            }
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    } 

    _getRadExpertise( tab = [], page = 1 ) {
        this.setState({
            loading: true,
            radExpertise: [],
        });
        this.radExpertise._getAll(false, this.props.user.access, page).then((response) => {
            // console.log( response );
            response.data.results.map((expertise, index) => {
                if( expertise.logo == null || expertise.logo === '' || expertise.logo === undefined) {
                    tab.push( expertise );
                }
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadExpertise( tab, page + 1 );
            }

            else {
                this.setState({
                    radExpertise: this.tools._sortByDate(tab, 'created_at'),
                    loading: false,
                });
            }
        }).catch((e) => {
            
            if( e.response.status === 401 ) {
                this._setNewAccessToUser( this._getRadExpertise );
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _isObjEmpty(obj) {
		for (var prop in obj) {
		  if (obj.hasOwnProperty(prop)) return false;
		}
		return true;
	}

    _updateExpertise( expertise, type ) {
        // console.log( expertise );
        this.setState({
            loading: true,
        });
        if( type === "update" ){
            this.radExpertise._update( expertise.tag, expertise, this.props.user.access).then((response) => {
                if( response.status === 200 ) {
                    this._getRadExpertise();
                }
                else {
                    alert("Une erreur est survenue lors de la mofification");
                }
            }).catch((e) => {
                this.setState({
                    loading: false,
                });
                if( e.response.status === 401 ) {
                    this._updateExpertise( expertise, type ) ;
                }
                if( e.toString() === "Error: Network Error" ) {
                    alert( "Aucun accès à internet :(" );
                }
            });
        }

        else if( type === "add" ) {
            this.radExpertise._post(expertise, this.props.user.access).then((response) => {
                if( response.status === 201 ) {
                    this._getRadExpertise();
                }
                else {
                    alert("Une erreur est survenue lors de l'ajout");
                }
            }).catch((e) => {
                this.setState({
                    loading: false,
                });
                if( e.response.status === 401 ) {
                    this._updateExpertise( expertise, type ) ;
                }
                if( e.toString() === "Error: Network Error" ) {
                    alert( "Aucun accès à internet :(" );
                }
            });
        }

        else if( type === "delete" ) {
            this.radExpertise._delete(expertise.tag, this.props.user.access).then((response) => {
                // console.log( response );
                if( response.status === 200 ) {
                    this._getRadExpertise();
                }
                else {
                    alert("Une erreur est survenue lors de la suppression");
                }
            }).catch((e) => {
                this.setState({
                    loading: false,
                });
                if( e.response.status === 401 ) {
                    this._updateExpertise( expertise, type ) ;
                }
                if( e.toString() === "Error: Network Error" ) {
                    alert( "Aucun accès à internet :(" );
                }
            });
        }
    }

    render() {
        return (
            <>
                <div>
                    <div className='radExpertise_title'>
                        <span className='radSections_title_ radExpertise_title_'>
                            Liste des domaines de compétence de RAD-ONG
                        </span>

                        <div className='radExpertise_title_button'>
                            <span className='update_2'
                                onClick={() => {
                                    let newName = window.prompt('Donnez le nom du domaine ici');
                                    if( newName !== '' && newName !== undefined && newName !== null ) {
                                        let newExpertise = {
                                            name: newName,
                                            tag: this._makeId(32),
                                        };
                                        this._updateExpertise( newExpertise, "add");
                                    }
                                }}
                            >
                                Ajouter un domaine de compétence +
                            </span>
                        </div>
                    </div>
                    <div className='radExpertise_container'>
                        {
                            this.state.radExpertise.map((expertise, index) => (
                            <div className='radExpertise_content' key={"expertise" + index }>
                                <div className='radExpertise'>
                                    <div className='radExpertise_text'>
                                        {
                                            expertise.name
                                        }
                                    </div>
                                    <span className='table1_th_Add'
                                        onClick={() => {
                                            
                                            let newName = window.prompt('Modofiez le nom du domaine ici', expertise.name);
                                            if( newName !== '' && newName !== undefined && newName !== null ) {
                                                let newExpertise = {
                                                    name: newName,
                                                    tag: expertise.tag,
                                                };
                                                this._updateExpertise( newExpertise, "update" );
                                            }
                                        }}
                                    >
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </span>
                                    <span className='table1_th_Add'
                                        onClick={() => {
                                            if( window.confirm("Voulez-vous vraiment supprimer ce domaine de compétence?") === true ){
                                                this._updateExpertise( expertise, "delete" );
                                            }
                                        }}
                                    >
                                        <i className="fa fa-trash-o colorRed" aria-hidden="true"></i>
                                    </span>
                                </div>

                                <div className='add_content_editor2'>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={expertise.desc !== null ? expertise.desc : ''}
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            // console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            
                                            let expertiseList = this.state.radExpertise;
                                            
                                            let newExpertise = {
                                                name: expertise.name,
                                                tag: expertise.tag,
                                                desc: data,
                                                details: expertise.details,
                                            };

                                            expertiseList[index] = newExpertise;

                                            // console.log( expertiseList );

                                            this.setState({
                                                radExpertise: expertiseList,
                                            });
                                        }}
                                    />
                                    <div className='updateRadContentButton'>
                                        <span className='update'
                                            onClick={() => {
                                                if( window.confirm("Voulez-vous vraiment modifier?") === true ){
                                                    let newExpertise = {
                                                        name: expertise.name,
                                                        tag: expertise.tag,
                                                        desc: expertise.desc,
                                                    };
                                                    this._updateExpertise( newExpertise, "update" );
                                                }
                                            }}
                                        >
                                            Modifier
                                        </span>
                                    </div>
                                </div>
                            </div>
                            ))
                        }
                    </div>
                </div>

                {
                    this.state.loading &&
                    <div className='loading'>
                        <img src={loader} alt='' />
                    </div>
                }
            </>
        )
    }
}

DashboardExpertise = connect(mapStateToProps, null)(DashboardExpertise);

export default DashboardExpertise;