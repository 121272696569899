import React from 'react';

import './style/style.css';
import './style/responsive.style.css';
class Card3 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

    }

    componentDidMount() {

    }


    render() {
        return (
            <div className="card3"
                style={{
                    borderBottom: "5px solid " + this.props.color,
                }}
            >
                <div className="card3_title"
                    style={{
                        backgroundColor: this.props.color,
                    }}
                >
                    <span>
                        { this.props.title }
                    </span>
                </div>
                <div  className="card3_content textForm">
                    <span>
                        { this.props.content }
                    </span>
                </div>
            </div>
        )
    }
}

export default Card3;