import React from 'react';
import { Route } from 'react-router';
// import { HashRouter } from 'react-router-dom';
import { BrowserRouter, Switch } from 'react-router-dom';

import Home from "../screens/home.screen";
import Dashboard from '../screens/dashboard.screen';

class Routes extends React.Component {
    render() {
        return (
            // <HashRouter>
                <BrowserRouter>
                    <Switch>
                        <Route path="/dashboard" component={Dashboard}/>
                        <Route path="/" component={Home}/>
                    </Switch>
                </BrowserRouter>
            // </HashRouter>
        )
    }
}

export default Routes;