import React from 'react';
import { connect } from 'react-redux';

/**Importation des styles de la page */
import './style/style.css';
import './style/responsive.style.css';


/**Importation des images*/
import loader from '../../assets/gif/loader2.gif';

/** Importation des helpers */
import radUser from '../../helpers/user.helper';

import AccessStore from '../../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };

}

class LoginScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            loading: false,
        }

        this.accessStore = new AccessStore( this.props.dispatch);
        this.radUser = new radUser();
    }

    componentDidMount() {
        
    }

    _onSubmitForm( e ) {
        e.preventDefault();
        if( this.state.username !== '' && this.state.password !== '' ) {
            this.setState({
                loading: true,
            });
            let data = {
                username: this.state.username,
                password: this.state.password
            }
            this.radUser._login( data ).then((response) => {
                this.setState({
                    loading: false,
                });
                // console.log( response );
                let user = {
                    username: this.state.username,
                    access: response.data.access,
                    refresh: response.data.refresh,
                };

                this.accessStore.setRedux('SET_USER', user );
            }).catch((e) => {
                this.setState({
                    loading: false,
                });
                // console.log( e.response );
                if( e.response && e.response.status === 401 ) {
                    alert( "Les informations entrée sont invalides" );
                }
                if( e.toString() === "Error: Network Error" ) {
                    alert( "Je n'arrive pas à me connecté à internet :(" );
                }
            })
        }
    }

   
    render() {
        return (
            <>
                <div className='login_container'>
                    <div className='login_formContainer'>
                        <div className='login_form'>
                            <div className='login_form_title'>
                                <span className='color_primary'>
                                    Se connecter
                                </span>
                            </div>

                            <div className='login_form_'>
                                <form
                                    className='login_form_fm'
                                    onSubmit = {(e) => {
                                        this._onSubmitForm(e);
                                    }}
                                >
                                    <div className='login_form_fm_'>
                                        <div className='login_form_fm_label'>
                                            <label className='color_primary inputLabel idLabel'>
                                                Nom d'utilisateur
                                            </label>
                                        </div>
                                        <div className='login_form_fm_inputContainer'>
                                            <div className='borderTop background_primary2'></div>
                                            <div className='borderLeft background_primary2'></div>
                                            <div className='borderRight background_primary2'></div>
                                            <div className='borderBottom background_primary2'></div>
                                            <input
                                                required
                                                className='login_form_fm_input emailInput'
                                                type='text'
                                                placeholder="Votre nom d'utilisateur ici"
                                                value={this.state.username}
                                                onChange={(e) => {
                                                   this.setState({
                                                       username: e.target.value,
                                                   });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='login_form_fm_'>
                                        <div className='login_form_fm_label'>
                                            <label className='color_primary inputLabel passwordLabel'>
                                                Mot de passe
                                            </label>
                                        </div>
                                        <div className='login_form_fm_inputContainer'>
                                            <div className='borderTop background_primary2'></div>
                                            <div className='borderLeft background_primary2'></div>
                                            <div className='borderRight background_primary2'></div>
                                            <div className='borderBottom background_primary2'></div>
                                            <input
                                                required
                                                className='login_form_fm_input passwordInput'
                                                type='password'
                                                placeholder='Votre mot de passe'
                                                value={this.state.password}
                                                onChange={(e) => {
                                                   this.setState({
                                                       password: e.target.value,
                                                   });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='login_form_fm_submit'>
                                        <input className='login_form_fm_submit_ background_primary2' type='submit' value="Valider"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.loading &&
                    <div className='loading'>
                        <img src={loader} alt='' />
                    </div>
                }
            </>
        )
    }
}

LoginScreen = connect(mapStateToProps, null)(LoginScreen);
export default LoginScreen;