import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

/**Importation des composants de la page */
import ProjectCard from '../components/projectCard/projectCard.component';
import TestimonyComponent from '../components/testimony/testimony.component';

/**Importation des styles de la page */
import './styles/knowHow.style.css';
// import './styles/KnowHowView.responsive.style.css';

/**Importation des images*/
// import conpetenceBack from '../assets/images/research.svg';
import testimony from '../assets/icons/discussions.png';

/**Importation des helpers */
import Xanimation from '../helpers/x-animations';
import radProject from '../helpers/project.helper';
import radArticle from '../helpers/article.helper';
import radODD from '../helpers/ODD.helper';
import radExpertise from '../helpers/expertise.helper';
import tools from '../helpers/tools.helper';
import radDataAttr from '../helpers/data-attr.helper';
import imagesURL from '../helpers/imagesURL.helper';

import radDataClass from '../helpers/data-class.helper';
import radData from '../helpers/data.helper';


// import video1 from '../assets/videos/Into The Nature - Cinematic Travel Video _ Sony a6300.webm';
// import video2 from "../assets/videos/LiSA Live performance Demon Slayer 'Gurenge'.webm";

const animer = new Xanimation();
animer.init( 300 );
class KnowHowView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            current_competence: {},
            loading: false,
            radODD: [],
            radExpertise: [],
            expertiseElt: {},
            articles: [],
            projects: [],
            testimonyList: [],
        }
        this.radProject = new radProject();
        this.radArticle = new radArticle();
        this.radODD = new radODD();
        this.radExpertise = new radExpertise();
        this.tools = new tools();
        this.radDataAttr = new radDataAttr();

        this._getRadArticles = this._getRadArticles.bind( this );
        this._getRadODD = this._getRadODD.bind( this );
        this._getRadExpertise = this._getRadExpertise.bind( this );
        this._start = this._start.bind( this );

        this._searchDataClass = this._searchDataClass.bind( this );
        this.radDataClass = new radDataClass();
        this.radData = new radData();
        this.radDataAttr = new radDataAttr();
    }

    componentDidMount() {
        this._start();
        this._start_search_data();
    }

    _start_search_data() {
        this._searchDataClass( "savoir-faire", ( savoirFaires_class ) => {
            
            this._getDataAttr( "videos", ( savoirFaires_attrs)=> {
                console.log(savoirFaires_attrs);
                
                let savoirFaires_class_ = savoirFaires_class;
                savoirFaires_class_.map((SF, index) => {
                    
                    this._searchData( SF.name, (savoirFaires_data)=>{
                        let savoirFaires_attrs_ = [];

                        savoirFaires_data.map((data, index) => {
                            if( data.classname === SF.id ) {
                                
                                savoirFaires_attrs.map((attr, attrIndex) => {
                                    if( attr.data === data.id ) {
                                        savoirFaires_attrs_.push(attr);
                                    }
                                });
                            }
                        });

                        this.setState({
                            testimonyList: savoirFaires_attrs_,
                        });
                    });
                });
                this.setState({
                    loading: false,
                });
                return true;
            });
        });
    }

    _getDataAttr( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            // console.log( response.data );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getDataAttr( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchData( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radData._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchData( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchDataClass( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataClass._getOne(search, page).then((response) => {
            response.data.results.map((result, index)=>{
                if( result.label === search ) {
                    tab = tab.concat( result );
                }
            });
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchDataClass( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _start( params = null ) {
        this.setState({
            loading: true,
            current_odd: {},
            articles: [],
            projects: [],
        });
        // window.scrollTo(0, 0);
        
        this._getRadExpertise(()=>{
            this._getRadODD(() => {
                this._getRadProjects(( projectList )=>{
                    let projects = this.state.projects;
                    projectList.map((project, index) => {
                        // console.log( project );
                        if( project.status === 2 ) {
                            project.tags.map((tag, i) => {
                                let expertiseElt = this.state.expertiseElt;
                                if(expertiseElt["expertise" + tag]){
                                    expertiseElt["expertise" + tag].projects.push(index);
                                }else {
                                    expertiseElt["expertise" + tag] = {
                                        projects: [index],
                                        articles: [],
                                    }
                                }
                                if( i === project.tags.length-1 ) {
                                    this.setState({
                                        expertiseElt: expertiseElt,
                                    });
                                }
                            });
                        }
                        let newProject = {
                            name: project.name,
                            shortDescription: parse(project.desc),
                            oddIDs: project.odds,
                            tagIDs: project.tags,
                            oddList: [],
                            cover: "",
                            imagesList: [],
                            competencesDomaine: [],
                            status: project.status,
                        }

                        this.state.radODD.map((odd) => {
                            if(  project.odds.includes(odd.id) ) {
                                newProject.oddList = newProject.oddList.concat( odd.logo );
                            }
                        });
    
                        this.state.radExpertise.map((expertise) => {
                            if(  project.tags.includes(expertise.id) ) {
                                newProject.competencesDomaine = newProject.competencesDomaine.concat( expertise.name );
                            }
                        });

                        this._getProjectFolder( project.name, ( projectFolder )=>{
                            newProject.cover = projectFolder.length > 0 ? imagesURL + "upload/" + projectFolder[0][0] : "";
                            newProject.imagesList = projectFolder;

                            projects.push( newProject );

                            this.setState({
                                projects: projects,
                                loading: false,
                            });
                        });
                    });
                });

                this._getRadArticles((articles)=>{
                    let articlesList = this.state.articles;
                    articles.map((article, index) => {
                        let newArticle = {
                            name: article.name,
                            title: article.title,
                            shortDescription: parse(article.desc),
                            content: parse(article.content.replace(/<p>&nbsp;<\/p>/g, '<br/>')),
                            oddIDs: article.odds,
                            tagIDs: article.tags,
                            oddList: [],
                            galerie: [],
                            cover: "",
                            cover2: "", 
                            start_date: article.start_date,
                            end_date: article.end_date,
                            competencesDomaine: [],
                            status: article.status,
                            article: article.article,
                            refs: article.refs,
                            created_at: article.created_at,
                        }

                        this.state.radODD.map((odd) => {
                            if(  article.odds.includes(odd.id) ) {
                                newArticle.oddList = newArticle.oddList.concat( odd.logo );
                            }
                        });
    
                        this.state.radExpertise.map((expertise) => {
                            if(  article.tags.includes(expertise.id) ) {
                                newArticle.competencesDomaine = newArticle.competencesDomaine.concat( expertise.name );
                            }
                        });

                        this._getProjectFolder( article.name, ( articleFolder )=>{
                            newArticle.cover = articleFolder.length > 0 ? imagesURL + "upload/" + articleFolder[0][0] : "";
                            newArticle.cover2 = articleFolder.length > 0 ? imagesURL + "upload/" + articleFolder[0][0] : "";
                            newArticle.imagesList = articleFolder;
    
                            for( let k=0; k<articleFolder.length; k ++) {
                                articleFolder[k] = {
                                    src: imagesURL + "upload/" + articleFolder[k][0],
                                    src2: imagesURL + "upload/" + articleFolder[k][0],
                                    text: articleFolder[k][1]
                                };
                            }
    
                            newArticle.galerie = articleFolder;
                            articlesList = articlesList.concat( newArticle );

                            if( index === articles.length - 1 ) {
                                articlesList = this.tools._sortByDate(articlesList, "created_at");
                                this.setState({
                                    loading: false,
                                    articles: this.tools._sortByDate(articlesList, "created_at"),
                                });
                            }
                        });
                    });
                });
            });
        })
    }

    _getRadArticles( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            loading: true,
            projects: [],
        });

        this.radArticle._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadArticles( callback,tab, page + 1 );
            }

            else {
                callback( this.tools._sortByDate(tab, 'created_at') );
            }
        }).catch((e) => {
            
            if( e.response ) {
                if( e.response.status === 401 ) {
                    
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadProjects(callback = ()=>{}, tab = [], page = 1 ) {
        this.radProject._getProjects(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadProjects( callback, tab, page + 1 );
            }

            else {
                callback( this.tools._sortByDate(tab, 'created_at') );
            }
        }).catch((e) => {
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getProjectFolder( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            response.data.results.map((expertise, index) => {
                tab.push( expertise );
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getProjectFolder( search, callback, tab, page + 1 );
            }

            else {
                if( tab.length > 0 ){
                    tab.map((element, index) => {
                        if( element.name === search ) {
                            let folder = JSON.parse( element.value );
                            for( let j=0; j<folder.length; j++ ) {
                                folder[j] = folder[j].split("*****");
                            }
                            return callback( folder );
                        }
                    });
                }
                else {
                    return callback([]);
                }
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadExpertise( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            radExpertise: [],
        });
        this.radExpertise._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadExpertise( callback, tab, page + 1 );
            }

            else {
                this.setState({
                    radExpertise: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    callback();
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadODD( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            radODD: [],
        });
        this.radODD._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadODD( callback, tab, page + 1 );
            }

            else {
                this.setState({
                    radODD: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    callback();
                });
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    render() {
        return (
            <>
                <div className="KnowHowView">
                    <div className="">
                        <div className='KnowHowView_testimony'>
                            {
                                this.state.testimonyList.length > 0 ?
                                    <TestimonyComponent list={this.state.testimonyList} defaultIcon={
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                    }/>
                                :
                                    <div className='no-video'>
                                        <span>
                                            Aucune video
                                        </span>
                                        
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-camera-video-off-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925l-10-14 .814-.58 10 14-.814.58z"/>
                                        </svg>
                                    </div>
                            }
                        </div>

                        <div className='competence_illustrator_2 not-search-container'>
                            {
                                this.state.radExpertise.map((expertise, index) => (
                                    this.state.expertiseElt["expertise" + expertise.id] && this.state.expertiseElt["expertise" + expertise.id].projects.length > 0 &&
                                    <div>
                                        <div className='card_container'>
                                            <div className='card_container-title'>
                                                <Link className="link_menu" variant="contained" to={"/Domaine-de-competences:?competence=" + expertise.name}>
                                                    <div className='card_container-title-text'>
                                                        <span>{expertise.name}</span>
                                                    </div>
                                                </Link>
                                                <div className='card_container-title-square'></div>
                                            </div>
    
                                            <div className='card_list'>
                                                {
                                                    this.state.expertiseElt["expertise" + expertise.id].projects.map((project, index) => (
                                                        index < 3 &&
                                                        <div className='card_list_content' key={"doc-list-1-" + index}>
                                                            {
                                                                this.state.projects[project] &&
                                                                <ProjectCard project={ this.state.projects[project]} />
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>  
                                ))
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default KnowHowView;