import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import './style/style.css';
import './style/responsive.style.css';

class Slider1Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            slide: false,
            currentView: 0,
        }

        this._changeCurrent = this._changeCurrent.bind( this );

        this.timeOut = null;
    }

    componentDidMount() {
        // console.log( this.props.viewList );
        // window.onload = this._startSlide();
        // this._startSlide();
    }
    componentWillUnmount() {
        
    }

    _changeCurrent( index ) {
        this.setState({
            currentView: index,
        });
    }
  
    render() {
        return (
            <div className="Slider1_container">
                <div className='Slider1_container_head'>
                    {
                        this.props.viewList.map((view, index) => (
                            <div className='Slider1_container_head_elt'
                                style={{
                                    backgroundColor: this.state.currentView === index ? "#5dc269" : "transparent",
                                    color: this.state.currentView === index ? "#ffffff" : "#373737",
                                    fontWeight: this.state.currentView === index ? "bold" : "normal",
                                }}
                                onClick={() => {
                                    this._changeCurrent(index);
                                }}
                            >
                                <div>
                                    
                                </div>
                                <span className='Slider1_container_head_elt_name'>
                                    {view.name}
                                </span>
                                <div className='Slider1_container_head_elt_line'
                                    style={{
                                        transform: this.state.currentView === index ? "scaleX(1)" : "scaleX(0)",
                                        height: this.state.currentView === index ? "3px" : "0px",
                                    }}
                                ></div>
                            </div>
                        ))
                    }
                </div>
                <div className='Slider1_container_body'>
                    <div className='Slider1_container_body_title titleStyle2'>
                        {
                            <Link className="" variant="contained" to={"/Domaine-de-competences:?competence=" + this.props.viewList[this.state.currentView].name}>
                                <span>
                                    {parse(this.props.viewList[this.state.currentView].name)}
                                </span>
                            </Link>
                        }
                    </div>
                    <div className='Slider1_container_body_content'>
                        <div className='Slider1_container_body_content_text'>
                            {
                                parse(this.props.viewList[this.state.currentView].desc)
                            }
                        </div>
                    </div>
                    <div className='Slider1_container_body_rect'></div>
                </div>
            </div>
        )
    }
}

export default Slider1Component;