class ImageConvertor {

    constructor() {
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('Access-Control-Allow-Credentials', 'true');
        this.headers.append('Accept', 'application/json');
    }

    // ***Here is the code for converting "image source" (url) to "Base64".***
    _toBase64 = url =>
        fetch(url, {
            // mode: 'no-cors',
            // credentials: 'include',
            method: 'GET',
            // headers: this.headers
        }).then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            // console.log(blob);
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))

    // ***Here is code for converting "Base64" to javascript "File Object".***
    _base64ToJavascriptFileObject(dataurl, filename) {
        // console.log(dataurl);
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    _UrlToFile(url, callback = () => {}) {
        this._toBase64(url).then(dataUrl => {
            // console.log(dataUrl);
            var fileData = this._base64ToJavascriptFileObject(dataUrl, "img.jpg");
            callback(fileData);
        });
    }
}

export default ImageConvertor;