import axios from "axios";
const url = "https://www.rad-ong.org/rad-dev/send_email.php";

// const url = "http://localhost/test/send_email.php";

function sendEmail(data) {
    // console.log(data);
    return (
        axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
        })
    )
}

export default sendEmail;