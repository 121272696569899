// import { ReactDOM } from 'react';
import React from 'react';

/**Importation des styles de la page */
import './style/style.css';
import './style/responsive.style.css';


/**Importation des images*/



class PageSliderComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            current_page: 0,
            limit: 0,
            list: [],
        }
    }

    componentDidMount() {
        let tab=[];
        for(let i=0; i<this.props.total; i++) {
            tab.push(i+1)
        }
        // console.log( this.props.current );
        this._changeCurrentPage(this.props.current - 1);
        this.setState({
            // current_page: this.props.current - 1,
            limit: this.props.limit,
            list: tab,
        });
    }

    _changeCurrentPage(newIndex) {
        this.setState({
            current_page: newIndex,
        });

        this.props.action(newIndex+1);

        if(newIndex > this.state.limit) {
            this.setState({
                limit: this.state.limit + 1,
            });
        } else if(newIndex < this.state.limit - this.props.limit) {
            this.setState({
                limit: this.state.limit - 1,
            });
        }
    }
    
    render() {
        return (
            <div className='PageSliderComponent_container'>
                <table className='PageSliderComponent_container_table'>
                    <tbody>
                        <tr>
                            <td className='chevron chevron_left'>
                                <a href={this.props.link + "?page=" + (this.state.current_page + 1)}
                                    onClick={(e) => {
                                        if( this.state.current_page > 0 ) {
                                            this._changeCurrentPage(this.state.current_page - 1);
                                        }
                                    }}
                                >
                                    <button>
                                        <span></span>
                                    </button>
                                </a>
                            </td>
                            {
                                this.state.list.map((index, i) => (
                                    i>=this.state.limit - this.props.limit && i<=this.state.limit ?
                                        <>
                                            <td className={i===this.state.current_page? "current" : "no_current"}>
                                                <a href={this.props.link + "?page=" + index }
                                                    onClick={(e) => {
                                                        this._changeCurrentPage(i);
                                                    }}
                                                >
                                                    <button>{index}</button>
                                                </a>
                                            </td>
                                            {
                                                i===this.state.limit &&
                                                    <td>
                                                        <button>...</button>
                                                    </td>
                                            }
                                        </>
                                    :
                                        (
                                            i>this.state.limit && i>this.state.list.length - 2 ?
                                                <td className={i===this.state.current_page? "current" : "no_current"}>
                                                    <a href={this.props.link + "?page=" + index }
                                                        onClick={(e) => {
                                                            this._changeCurrentPage(i);
                                                        }}
                                                    >
                                                        <button>{index}</button>
                                                    </a>
                                                </td>
                                            :
                                                null
                                        )

                                ))
                            }
                            <td className='chevron chevron_right'>
                                <a href={this.props.link + "?page=" + (this.state.current_page + 1)}
                                    onClick={(e) => {
                                        if( this.state.current_page < this.state.list.length-1 ) {
                                            this._changeCurrentPage(this.state.current_page + 1);
                                        }
                                    }}
                                >
                                    <button>
                                        <span></span>
                                    </button>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default PageSliderComponent;