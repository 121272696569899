import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser'

/**Importation des composants de la page */
//--------Le menu de la page--------------
import Menu from '../components/menu/menu.component';


/** Importation des screens */
// Screen de la page d'accueil
import HomeView from './homeView.screen';
import KnowHowView from './knowHow.screen';
import AboutUs from './aboutUs.screen';
import ProjectsView from './projectsView.screen';
import OnProjectView from './onProjectView.screen';
import OnActualityView from './onActuality.screen';
import ContactUs from './contactUs.screen';
import ActuView from './actu.screen';
import MediaView from './media.screen';
import OnCompetenceView from './onCompetence.screen';
import OnOddView from './onOdd.screen';
import DonateView from './donate.screen';
import CompetencesView from './competences.screen';
import EventsView from './eventsView.screen';
import Graph from './graph.screen';

/**Importation des styles de la page */
import './styles/home.style.css';
import './styles/home.responsive.style.css';

/**Importation des images*/
// import radLogo from '../assets/logos/rad-logo.png';
import loadGif from '../assets/gif/loader3.gif';


/** Importation des helpers */
import radDataClass from '../helpers/data-class.helper';
import radDataAttr from '../helpers/data-attr.helper';
import radData from '../helpers/data.helper';
import visiteHistory from '../helpers/visiteHistory.helper'

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contactPhoneNumber: "",
            contactMail: "",
            rad: "",
            slug: "",
            menuIsOpen: false,
            currentView: 0,
            fixedAdd: false,
            radSocials: {},
            radMails: {},
            radNumbers: {},
            loading: false,
        }

        this.contact_phoneNumber = "";
        this.contactMail = "";

        this.rad = "RAD-ONG";
        this.slug = "Au cœur de l'agribusiness"; 

        this.timeInterval1 = null;
        this.timeInterval2 = null;

        this._writePhoneNumber = this._writePhoneNumber.bind( this );
        this._onScrollPage = this._onScrollPage.bind( this );
        this._writeMail = this._writeMail.bind( this );
        this.visiteHistory = new visiteHistory();

        this.radDataClass = new radDataClass();
        this.radData = new radData();
        this.radDataAttr = new radDataAttr();
    }

    componentDidMount() {
        // window.onload = this._onScrollPage();
        // window.scrollTo(0, 0);
        // console.log( window.location.pathname );
        this.visiteHistory._post();
        this.setState({
            loading: true,
        });
        if( window.location.pathname === "/A-propos-de-nous") {
            this.setState({
                currentView: 1,
            });
        }
        else if( window.location.pathname === "/Nos-projets" || window.location.pathname === "/Projet:" || window.location.pathname === "/Actualité:") {
            this.setState({
                currentView: 3,
            });
        }

        else if( window.location.pathname === "/Nous-contacter" ) {
            this.setState({
                currentView: 4,
            });
        }

        else if( window.location.pathname === "/A-la-une" ) {
            this.setState({
                currentView: 2,
            });
        }
        else if( window.location.pathname === "/Mediatheque" ) {
            this.setState({
                currentView: 5,
            });
        }
        // else if( window.location.pathname === "/Nos-projets/#/voir") {
        //     this.setState({
        //         currentView: 3,
        //     });
        // }
        else if( window.location.pathname === "/") {
            this.setState({
                currentView: 0,
            });
        }
        

        // /**Adreese mail de RAD */
        // this.radEmail._getAll().then((response) => {
        //     response.data.rademails.map((mail, index) => {
        //         if( index + 1 === response.data.rademails.length ) {
        //             this.contactMail = this.contactMail + mail.value;
        //         }else {
        //             this.contactMail = this.contactMail + mail.value + "/";
        //         }
        //     });
        //     this.radTel._getAll().then((response) => {
                
        //         /**Liste des numeros de telephones de RAD */
        //         response.data.radtels.map((tel, index) => {
        //             if( index + 1 === response.data.radtels.length ) {
        //                 this.contact_phoneNumber = this.contact_phoneNumber + tel.value;
        //             }else {
        //                 this.contact_phoneNumber = this.contact_phoneNumber + tel.value + "/";
        //             }
        //         });

        //          /** Pour recuperer les medias sociaux de RAD */
        //         this.radSocial._getAll().then((response) => {
        //             this.setState({
        //                 loading: false,
        //                 radSocials: response.data.radsocials
        //             }, () => {
        //                 window.onload = this._onScrollPage();
        //                 window.scrollTo(0, 0);
        //             });

        //             /**Fonction qui ecris le numero de telephone de RAD  a l'ecran */
        //             this._writePhoneNumber();
                
        //         }).catch((error) => {
        //             this.setState({
        //                 loading: false,
        //             });
        //             console.log( error );
        //         });
        //     }).catch((error) => {
        //         console.log( error );
        //     })
        // }).catch((error) => {
        //     console.log( error );
        // });

        this._start_search_data();
    }

    _start_search_data() {
        this._searchDataClass( "general", ( general_class ) => {
            
            this._getDataAttr( "phones_numbers", ( phones_numbers_attrs)=> {

                this._getDataAttr( "email", ( email_attrs)=> {
                    this._getDataAttr( "social_network", ( social_network_attrs)=> {
                        let general_class_ = general_class;
                        general_class_.map((GN, index) => {
                            
                            this._searchData( GN.name, (general_data)=>{
                                let radNumbers_ = [];
                                let radMails_ = [];
                                let radSocials_ = [];
        
                                general_data.map((data, index) => {
                                    if( data.classname === GN.id ) {
                                        
                                        phones_numbers_attrs.map((attr, attrIndex) => {
                                            // console.log(attr);
                                            if( attr.data === data.id ) {
                                                radNumbers_= attr;
                                                radNumbers_.value = radNumbers_.value==="" ? [] : JSON.parse(radNumbers_.value);
                                            }
                                        });

                                        email_attrs.map((attr, attrIndex) => {
                                            // console.log(attr);
                                            if( attr.data === data.id ) {
                                                radMails_= attr;
                                                radMails_.value = radMails_.value==="" ? [] : JSON.parse(radMails_.value);
                                            }
                                        });

                                        social_network_attrs.map((attr, attrIndex) => {
                                            // console.log(attr);
                                            if( attr.data === data.id ) {
                                                radSocials_= attr;
                                                radSocials_.value = radSocials_.value==="" ? [] : JSON.parse(radSocials_.value);
                                            }
                                        });
                                    }
                                });
        
                                // console.log(general_class[0], radNumbers_, this.state.general_data);
        
                                this.setState({
                                    radNumbers: radNumbers_,
                                    radMails: radMails_,
                                    radSocials: radSocials_
                                }, ()=> {
                                    this.state.radMails.value.map((mail, index) => {
                                        if( index + 1 === this.state.radMails.value.length ) {
                                            this.contactMail = this.contactMail + mail;
                                        }else {
                                            this.contactMail = this.contactMail + mail + "/";
                                        }
                                    });
                                    this.state.radNumbers.value.map((tel, index) => {
                                        if( index + 1 === this.state.radNumbers.value.length ) {
                                            this.contact_phoneNumber = this.contact_phoneNumber + tel;
                                        }else {
                                            this.contact_phoneNumber = this.contact_phoneNumber + tel + "/";
                                        }
                                        return true;
                                    });
                                    this._writePhoneNumber();
                                });
                            });
                        });
                        this.setState({
                            loading: false,
                        });
                        return true;
                    });
                    return true;
                });
                return true;
            });
        });
    }


    _writePhoneNumber() {
        var i = 0;
        this.timeInterval1 = setInterval(() => {
            if( i < this.contact_phoneNumber.length ){
                this.setState({
                    contactPhoneNumber: this.state.contactPhoneNumber + this.contact_phoneNumber[i]
                })
            }

            else {
                clearInterval( this.timeInterval1 );
                this._writeMail();
            }

            i ++;
        }, 70);     
    }

    _writeMail() {
        var j = 0;
        this.timeInterval2 = setInterval(() => {
            if( j < this.contactMail.length ){
                this.setState({
                    contactMail: this.state.contactMail + this.contactMail[j]
                });
            }

            else {
                clearInterval( this.timeInterval2 );
                this._writeRade();
            }

            j ++;
        }, 70);
    }

    _writeRade() {
        var i = 0;
        this.timeInterval1 = setInterval(() => {
            if( i < this.rad.length ){
                this.setState({
                    rad: this.state.rad + this.rad[i]
                })
            }

            else {
                clearInterval( this.timeInterval1 );
                this._writeSlug();
            }

            i ++;
        }, 70); 
    }

    _writeSlug() {
        var i = 0;
        this.timeInterval2 = setInterval(() => {
            if( i < this.slug.length ){
                this.setState({
                    slug: this.state.slug + this.slug[i]
                })
            }

            else {
                clearInterval( this.timeInterval2 );
            }

            i ++;
        }, 70); 
    }

    _onScrollPage(){

        var scrollY = function(){
            var supportPageOffset = window.pageXOffset !== undefined ;
            var isCSS1Compat = (( document.compatMode || "" ) === "CSS1Compat" ) ;
    
            var y = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop ;
            return y ;
        }
    
        //Variables
        var nav = document.querySelector( ".nav" ) ;
        
        var rect = nav.getBoundingClientRect() ; 
        var top = rect.top + scrollY() ;
    
        var fake = document.createElement( "div" ) ;
        fake.style.width = rect.width + "px" ;
        fake.style.height = rect.height + 0 + "px" ;
    
        //Fonctions
    
        var onScroll = (e) => {
            var hasScrollClass = nav.classList.contains( 'fixed' ) ;
            
            if( scrollY() > top && !hasScrollClass ){
                nav.classList.add( "fixed" ) ;
                nav.style.width = rect.width + "px" ;
                
                nav.parentNode.insertBefore( fake , nav ) ;
            }
    
            else if( scrollY() <= top && hasScrollClass ){
                nav.classList.remove( "fixed" ) ;
                nav.parentNode.removeChild( fake ) ;
            }
            // console.log( e.target );

            // this._ScrollPage( e );
        }
    
        var onResize = function(){
            nav.style.width = "auto" ;
            nav.classList.remove( "fixed" ) ;
            fake.style.display = "none" ;
    
            rect = nav.getBoundingClientRect() ;
            top = rect.top + scrollY() ;
    
            fake.style.width = rect.width + "px" ;
            fake.style.height = rect.height + 50 + "px" ;
            fake.style.display = "block" ;
    
            onScroll() ;
        }
    
        //Listerner
        window.addEventListener( 'scroll' , onScroll ) ;
        window.addEventListener( 'resize' , onResize ) ;
    
    }

    _clickMenu() {
        this.setState({
            menuIsOpen: !this.state.menuIsOpen,
        });
    }

    _clickMenuItem( index ) {
        this._clickMenu();
        // document.getElementsByClassName("contain").innerHTML = "";
        if( index >= 0 ){
            this.setState({
                currentView: index,
            });
        }
    }

    _getDataAttr( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            // console.log( response.data );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getDataAttr( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchData( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radData._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchData( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchDataClass( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataClass._getOne(search, page).then((response) => {
            response.data.results.map((result, index)=>{
                if( result.label === search ) {
                    tab = tab.concat( result );
                }
            });
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchDataClass( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    render() {
        return (
            <>
                {/* <BrowserRouter> */}
                <div className="contact_info background_primary">
                    <div>
                        <div className="contact_info_left">
                            <div className="contact_info_left_phone">
                                <span className="contact_info_left_phone_">
                                    Tél: { this.state.contactPhoneNumber }
                                </span>
                            </div>
                            <div className="contact_info_left_mail">
                                <span className="contact_info_left_mail_">
                                    Email: { this.state.contactMail }
                                </span>
                            </div>
                        </div>
                        <Link className="link_menu" variant="contained" to="/donate">
                            <div className='don'>
                                <span>
                                    Participer à notre action
                                </span>
                            </div>
                        </Link>
                    </div>

                    <div className="contact_info_right">
                        <div className="contact_info_right_">
                            {/* <div className="contact_info_right_social scaleToHover3">
                                <a className="contact_info_right_social_link" href="./">
                                    <i className="fa fa-facebook facebookIcon"></i>
                                </a>
                            </div>
                            <div className="contact_info_right_social scaleToHover3">
                                <a className="contact_info_right_social_link" href="./">
                                    <i className="fa fa-linkedin linkedinIcon"></i>
                                </a>
                            </div>
                            <div className="contact_info_right_social scaleToHover3">
                                <a className="contact_info_right_social_link" href="./">
                                    <i className="fa fa-twitter twitterIcon"></i>
                                </a>
                            </div>
                            <div className="contact_info_right_social scaleToHover3">
                                <a className="contact_info_right_social_link" href="./">
                                    <i className="fa fa-instagram instagramIcon"></i>
                                </a>
                            </div> */}

                            {
                                this.state.radSocials.value && this.state.radSocials.value.map((social, index) => (
                                    <div key={"social" + index} className="contact_info_right_social scaleToHover3">
                                        <a className="contact_info_right_social_link" href={social.link} target="_blank">
                                            {parse(social.icon)}
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                        <div id="google_translate_element"></div>
                    </div>
                </div>

                <div className="nav" data-fixed>
                    <div className="nav_">
                        <a href="./" className="nav_icon">
                            <div className="nav_icon_"></div>
                            <div className="nav_icon_text">
                                <div className="nav_icon_text_name">
                                    <span>
                                        { this.state.rad }
                                    </span>
                                </div>
                                <div className="nav_icon_text_slug">
                                    <span>
                                        { this.state.slug }
                                    </span>
                                </div>
                            </div>
                        </a>
                        <div className="nav_menuIcon" onClick={() => {
                            this._clickMenu();
                        }}>
                            <svg width="54" height="54" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17V17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12V12ZM4 6C3.44772 6 3 6.44772 3 7V7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7V7C21 6.44772 20.5523 6 20 6H4Z" fill="#ffffff"/>
                            </svg>
                        </div>
                        <div className="nav_menu" style={{
                            marginLeft: this.state.menuIsOpen ? "0px" : "-100%",
                        }}>
                            <div className="nav_menu_">
                                <div className="nav_menu_cross" onClick={() => {
                                    this._clickMenu();
                                }}>
                                    <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#ffffff"/>
                                        <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#ffffff"/>
                                    </svg>
                                </div>
                                <Menu action = {( index) => {
                                    this._clickMenuItem( index );
                                }} current={ this.state.currentView }/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contain">
                    <Switch>
                        <Route exact path="/" component={HomeView}/>
                        <Route exact path="/A-propos-de-nous" component={AboutUs}/>
                        <Route exact path="/Nos-projets" component={ProjectsView}/>
                        <Route exact path="/Projet:" component={OnProjectView}/>
                        <Route exact path="/Actualité:" component={OnActualityView}/>
                        <Route exact path="/Nous-contacter" component={ContactUs}/>
                        <Route exact path="/A-la-une" component={ActuView}/>
                        <Route exact path="/Mediatheque" component={MediaView}/>
                        <Route exact path="/Domaine-de-competences:" component={OnCompetenceView}/>
                        <Route exact path="/ODD:" component={OnOddView}/>
                        <Route exact path="/donate" component={DonateView}/>
                        <Route exact path="/notre-savoir-faire" component={KnowHowView}/>
                        <Route exact path="/nos-domaines-de-competences" component={CompetencesView}/>
                        <Route exact path="/evenements" component={EventsView}/>
                        <Route exact path="/charte_graphique" component={Graph}/>
                    </Switch>
                </div>

                <footer className="footer">
                    {/* <img src="assets/images/shapes/footer-shape-1-3.png" className="site-footer__bg-shape-3" alt=""/> */}
                    {/* <img src="assets/images/shapes/footer-shape-1-4.png" className="site-footer__bg-shape-4" alt=""/> */}
                    <div className="site-footer__upper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="footer-widget footer-widget__about">
                                        <p></p>
                                        <div className="footer-widget__social">
                                            {/* <img src="/rad-logo.png" alt='RAD-ONG'/> */}
                                            <a href="./" className="nav_icon">
                                                <div className="nav_icon_"></div>
                                                <div className="nav_icon_text">
                                                    <div className="nav_icon_text_name">
                                                        <span>
                                                            { this.state.rad }
                                                        </span>
                                                    </div>
                                                    <div className="nav_icon_text_slug">
                                                        <span>
                                                            { this.state.slug }
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                            {
                                                this.state.radSocials.value && this.state.radSocials.value.map((social, index) => (
                                                    <a target="_blank" key={"social_foot" + index} href={social.link}>{parse(social.icon)}</a>
                                                ))
                                            }
                                            {/* <a href="##"><i className="fa fa-facebook-f"></i></a>
                                            <a href="##"><i className="fa fa-twitter"></i></a>
                                            <a href="##"><i className="fa fa-google-plus"></i></a>
                                            <a href="##"><i className="fa fa-linkedin"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="footer-widget footer-widget__links__2">
                                        <h3 className="footer-widget__title">Liens utiles</h3>
                                        <ul className="list-unstyled footer-widget__links-list">
                                            <li><a href="/A-propos-de-nous"><span className="fa fa-chevron-right mr-2"></span>À propos de nous</a></li>
                                            <li><a href="/A-la-une"><span className="fa fa-chevron-right mr-2"></span>À le une</a></li>                                            
                                            <li><a href="/Nos-projets"><span className="fa fa-chevron-right mr-2"></span>Nos projets</a></li>
                                            <li><a href="/Nous-contacte"><span className="fa fa-chevron-right mr-2"></span>Nous contacter</a></li>
                                            <li><a href="/Mediatheque"><span className="fa fa-chevron-right mr-2"></span>Médiathèque</a></li>
                                            <li><a href="/notre-savoir-faire"><span className="fa fa-chevron-right mr-2"></span>Notre savoir faire</a></li>
                                            <li><a href="/nos-domaines-de-competences"><span className="fa fa-chevron-right mr-2"></span>Nos domaines de compétences</a></li>
                                            <li><a href="/donate"><span className="fa fa-chevron-right mr-2"></span>Participer à nos actions</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="footer-contacts footer-widget footer-widget__contact">
                                        <h3 className="footer-widget__title">Contact</h3>
                                        <p><i className="fa fa-map-marker"></i>Abomey-Calavi – Benin Immeuble jaune, 4ème rue après SOS en allant à l’université d'Abomey-Calavi, Bénin</p>
                                        <p><i className="fa fa-calendar"></i>Ouvert du lundi au vendredi de 8h-18h</p>
                                        <p><a href={"mailto:"+this.state.contactMail}><i className="fa fa-envelope"></i>{ this.state.contactMail }</a></p>
                                        <p><a href={"tel:"+this.state.contactPhoneNumber}><i className="fa fa-phone"></i>{ this.state.contactPhoneNumber }</a></p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="site-footer__bottom">
                        <div className="container">
                            <p></p>
                            <span>
                                © 2024 <strong>RAD-ONG</strong> .Tous droits réservés
                            </span>

                            {/* <ul className="list-unstyled site-footer__bottom-menu confidential">
                                <li><a className="mt-2" href="##">Police de confidentialité</a></li>
                                <li>
                                
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </footer>

                {/* <footer className="footer background_secondary">
                    <div className="footer_text">
                        <span>
                            Réalisé par <a href="https://www.kondosia.com/">KONDOSIA</a>
                            © 2024 <strong>RAD-ONG</strong> .Tous droits réservés
                        </span>
                    </div>
                </footer> */}
                {/* </BrowserRouter> */}
                {
                    this.state.loading &&
                        <div className='loading'>
                            <img src={loadGif} alt="RAD-ONG"/>
                        </div>
                }
            </>
        )
    }
}

export default Home;