import React from 'react';
import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';

/**Importation des composants de la page */
import LoginScreen from '../components/login/login.component';

//--------Le menu de la page--------------
import DashboardMenu from './dashboard.menu.screen';



/** Importation des screens */
// Screen de la page d'accueil
import DashboardHome from './dashboard.home.screen';
import DashboardAddProject from './dashboard.addProject.screen';
import DashboardAddActivity from './dashboard.addActivity.screen';
import DashboardAddActuality from './dashboard.addActuality.screen';
import DashboardRubrique from './dashboard.rubrique.screen';
import DashboardGeneral from './dashboard.general.screen';
import DashboardExpertise from './dashboard.expertise.screen';
import DashboardODD from './dashboard.odd.screen';
import DashboardViewProject from './dashboard.viewProject.screen';
import DashboardViewArticle from './dashboard.viewArticle.screen';
import DashboardAddEvent from './dashboard.addEvent.screen';
import DashboardViewEvents from './dashboard.viewEvents.screen';


/**Importation des styles de la page */
import './styles/dashboard.style.css';
import './styles/dashboard.responsive.style.css';

/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };

}


class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }

        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        this.accessStore.init( 'rad_user', "INIT_USER");
    }

    _isObjEmpty(obj) {
		for (var prop in obj) {
		  if (obj.hasOwnProperty(prop)) return false;
		}
		return true;
	}

    render() {
        return (
            <>
                {
                    this._isObjEmpty( this.props.user ) ?
                        <div className='loginScreen'>
                            <div className='loginScreen_title'>
                                <span>
                                    RAD-ONG
                                </span>
                            </div>
                            <LoginScreen/>
                        </div>
                    :
                        <div className='dashboard'>
                            <div className='dashboard_menu'>
                                <div className='dashboard_menu_'></div>
                                <DashboardMenu/>
                            </div>
                            <div className="dashboard_contain">
                                <Switch>
                                    <Route path="/dashboard/add-event" component={DashboardAddEvent}/>
                                    <Route path="/dashboard/list-event" component={DashboardViewEvents}/>
                                    <Route path="/dashboard/add-project" component={DashboardAddProject}/>
                                    <Route path="/dashboard/add-activity" component={DashboardAddActivity}/>
                                    <Route path="/dashboard/add-actuality" component={DashboardAddActuality}/>
                                    <Route path="/dashboard/rubrique" component={DashboardRubrique}/>
                                    <Route path="/dashboard/general" component={DashboardGeneral}/>
                                    <Route path="/dashboard/expertise" component={DashboardExpertise}/>
                                    <Route path="/dashboard/odd" component={DashboardODD}/>
                                    <Route path="/dashboard/view-projects" component={DashboardViewProject}/>
                                    <Route path="/dashboard/view-actualities" component={DashboardViewArticle}/>
                                    <Route path="/dashboard" component={DashboardHome}/>
                                </Switch>
                            </div>
                        </div>
                }
            </>
        )
    }
}

Dashboard = connect(mapStateToProps, null)(Dashboard);

export default Dashboard;