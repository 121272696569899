import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

/**Importation des composants de la page */
import ImageSlider2 from '../components/imageSlider2/imageSlider2.component';
import ProjectCard from '../components/projectCard/projectCard.component';
import VideoSlider from '../components/videoSlider/videoSlider.component';

/**Importation des styles de la page */
import './styles/onActuality.style.css';
import './styles/onActuality.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';

import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
} from "react-share";


import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
  } from "react-share";


/**Importation des helpers */
import Xanimation from '../helpers/x-animations';
import radProject from '../helpers/project.helper';
import radArticle from '../helpers/article.helper';
import radODD from '../helpers/ODD.helper';
import radExpertise from '../helpers/expertise.helper';
import tools from '../helpers/tools.helper';
import radDataAttr from '../helpers/data-attr.helper';
import imagesURL from '../helpers/imagesURL.helper';


const animer = new Xanimation();
animer.init( 50 );


class OnActualityView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            radODD: [],
            radExpertise: [],
            copied: false,
            article: {
                name: "",
                shortDescription: "",
                oddList: [],
                cover: "",
                galerie: [],
                videos: [],
                competencesDomaine: [],
                start: "",
                status: 60,
                date: "",
            },
            projects: [],
            projectList: [],

        }
        this.timeout = null;
        this._copyToClipBoard = this._copyToClipBoard.bind( this );

        const search = this.props.location.search;
        this.query = new URLSearchParams( search );

        this.radProject = new radProject();
        this.radArticle = new radArticle();
        this.radODD = new radODD();
        this.radExpertise = new radExpertise();
        this.tools = new tools();
        this.radDataAttr = new radDataAttr();
    }

    componentDidMount() {
        // console.log( this.props.match.params.testvalue );
        // window.scrollTo(0, 0);
        window.onload = animer.start();
        
        this.setState({
            loading: true,
        });
        
        this._getOnArticle( this.query.get( "article" ), (article, allArticles) => {
            // console.log( 4 );
            this._getRadODD(() => {
                this._getRadExpertise(() => {
                    let newArticle = {
                        name: article.name,
                        title: article.title,
                        shortDescription: parse(article.desc),
                        content: parse(article.content.replace(/<p>&nbsp;<\/p>/g, '<br/>')),
                        oddIDs: article.odds,
                        tagIDs: article.tags,
                        oddList: [],
                        galerie: [],
                        videos: [],
                        cover: "",
                        cover2: "", 
                        start_date: article.start_date,
                        end_date: article.end_date,
                        competencesDomaine: [],
                        status: article.status,
                        article: article.article,
                        refs: article.refs,
                        created_at: new Date( article.created_at ).getDate() + " / " + ( new Date( article.created_at ).getMonth() + 1 ) + " / " + new Date( article.created_at ).getFullYear(),
                    }

                    this.state.radODD.map((odd) => {
                        if(  article.odds.includes(odd.id) ) {
                            newArticle.oddList = newArticle.oddList.concat( odd.logo );
                        }
                    });

                    this.state.radExpertise.map((expertise) => {
                        if(  article.tags.includes(expertise.id) ) {
                            newArticle.competencesDomaine = newArticle.competencesDomaine.concat( expertise.name );
                        }
                    });

                    this._getProjectFolder( article.name, ( articleFolder )=>{
                        newArticle.cover = articleFolder.length > 0 ? imagesURL + "upload/" + articleFolder[0][0] : "";
                        newArticle.cover2 = articleFolder.length > 0 ? imagesURL + "upload" + articleFolder[0][0] : "";
                        newArticle.imagesList = articleFolder;

                        for( let k=0; k<articleFolder.length; k ++) {
                            articleFolder[k] = {
                                src: imagesURL + "upload/" + articleFolder[k][0],
                                src2: imagesURL + "upload/" + articleFolder[k][0],
                                text: articleFolder[k][1]
                            };
                        }

                        newArticle.galerie = articleFolder;
                        // console.log( newArticle.galerie );

                        let galerie = newArticle.galerie;

                        for(let i=0; i<galerie.length; i++) {
                            let file_ =  galerie[i].src.split('.');
                            // console.log(file);
                            let extension = file_[file_.length - 1].toString().toLowerCase();
                            // console.log( extension );
                            if(
                                extension==="webm" ||
                                extension==="mp4" ||
                                extension==="m4p" ||
                                extension==="m4v" ||
                                extension==="mpg" ||
                                extension==="mp2" ||
                                extension==="mpeg" ||
                                extension==="mpe" ||
                                extension==="mpv" ||
                                extension==="ogg" ||
                                extension==="avi" ||
                                extension==="wmv" ||
                                extension==="mov" ||
                                extension==="qt" ||
                                extension==="flv" ||
                                extension==="swf" ||
                                extension==="avchd"
                            ) {
                                newArticle.videos.push(galerie[i]);
                                newArticle.galerie.splice(i, 1);
                                i--;
                            }
                        }

                        this.setState({
                            article: newArticle,
                        }, () => {
                            this._getRadProjects(( projectList )=>{
                                let projects = this.state.projects;
                                // console.log( projectList, article.refs );

                                for( let i=0; i<projectList.length; i++ ) {
                                    if( article.refs.includes(projectList[i].article) ) {
                                        let newProject = {
                                            name: projectList[i].name,
                                            shortDescription: parse(projectList[i].desc),
                                            oddIDs: projectList[i].odds,
                                            tagIDs: projectList[i].tags,
                                            oddList: [],
                                            cover: "",
                                            imagesList: [],
                                            competencesDomaine: [],
                                            status: projectList[i].status,
                                        }

                                        // console.log( newProject );

                                        this.state.radODD.map((odd) => {
                                            if(  projectList[i].odds.includes(odd.id) ) {
                                                newProject.oddList = newProject.oddList.concat( odd.logo );
                                            }
                                        });
                    
                                        this.state.radExpertise.map((expertise) => {
                                            if(  projectList[i].tags.includes(expertise.id) ) {
                                                newProject.competencesDomaine = newProject.competencesDomaine.concat( expertise.name );
                                            }
                                        });

                                        this._getProjectFolder( projectList[i].name, ( projectFolder )=>{
                                            newProject.cover = projectFolder.length > 0 ? imagesURL + "upload/" + projectFolder[0][0] : "";
                                            newProject.imagesList = projectFolder;
                    
                                            // console.log( newProject );
                                            projects.push( newProject );

                                            this.setState({
                                                projects: projects,
                                                loading: false,
                                            });
                                        });
                                    }

                                    if( i === projectList.length - 1 ) {
                                        this.setState({
                                            loading: false,
                                        });
                                    }
                                }
                            })
                        });
                        
                    });
                });
            })
        });
    }

    componentWillUnmount() {
        animer.stop();
    }

    _copyToClipBoard( text ) {
        var copyText = document.getElementById("element_to_copy");
        copyText.setAttribute("value", text );
        copyText.select();

        navigator.clipboard.writeText( copyText.value );

        clearTimeout( this.timeout );

        this.setState({
            copied: true,
        }, () => {
            this.timeout = setTimeout( () => {
                this.setState({
                    copied: false,
                });
            }, 5000);
        });
    }

    _getRadProjects(callback = ()=>{}, tab = [], page = 1 ) {
        this.radProject._getProjects(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadProjects( callback, tab, page + 1 );
            }

            else {
                callback( this.tools._sortByDate(tab, 'created_at') );
            }
        }).catch((e) => {
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getProjectFolder( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            response.data.results.map((expertise, index) => {
                tab.push( expertise );
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getProjectFolder( search, callback, tab, page + 1 );
            }

            else {
                if( tab.length > 0 ){
                    tab.map((element, index) => {
                        if( element.name === search || element.label === search ) {
                            let folder = JSON.parse( element.value );
                            for( let j=0; j<folder.length; j++ ) {
                                folder[j] = folder[j].split("*****");
                            }
                            return callback( folder );
                        }
                    });
                }
                else {
                    return callback([]);
                }
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadExpertise( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            radExpertise: [],
        });
        this.radExpertise._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadExpertise( callback, tab, page + 1 );
            }

            else {
                this.setState({
                    radExpertise: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    callback();
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadODD( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            radODD: [],
        });
        this.radODD._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadODD( callback, tab, page + 1 );
            }

            else {
                this.setState({
                    radODD: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    callback();
                });
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getOnArticle(search, callback = ()=>{}, tab = [], page = 1) {
        this.radArticle._getOne( true, search, null, page ).then((response) => {
            // console.log( response );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getOnArticle( search, callback, tab, page + 1 );
            }

            else {
                response.data.results.map((result, index) => {
                    if( result.title === search ) {
                        callback( result, response.data.results );
                        return true;
                    }
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    render() {
        return (
            <>
                <div className="OnActualityView">
                    <div className="OnActualityView_cover">
                        {
                            this.state.article.cover && this.state.article.cover !== "" &&
                            <img
                                className="OnActualityView_cover_img"
                                alt={"RAD-ONG" + this.state.article.name}
                                src={this.state.article.cover}
                                onError={(e) => {
                                    if( e.target.alt === "RAD-ONG" + this.state.article.name){
                                        e.target.alt = "RAD-ONG" + this.state.article.name + "-";
                                        e.target.src = this.state.article.cover2;
                                    }
                                }}
                            />
                        }
                    </div>

                    <div className="OnActualityView_title x-animation"
                        x-animation="from-bottom"
                        x-animation-delay="0s"
                        x-animation-duration="1s"
                    >
                        <span>
                            {this.state.article.title}
                        </span>
                    </div>

                    <div className="OnActualityView_date x-animation"
                        x-animation="from-bottom"
                        x-animation-delay="0s"
                        x-animation-duration="1s"
                    >
                        <span>
                            {this.state.article.created_at}
                        </span>
                    </div>

                    <div className="OnActualityView_competence x-animation"
                        x-animation="from-bottom"
                        x-animation-delay="0s"
                        x-animation-duration="1s"
                    >
                        <div className="OnActualityView_competence_title">
                            <span className="OnActualityView_competence_title_ title">
                                #Domaines_de_compétences
                            </span>
                        </div>

                        <div className="OnActualityView_top_competence">
                            {
                                this.state.article.competencesDomaine.map((competence, index) => (
                                    <Link to={"/Domaine-de-competences:?competence=" + competence} className="OnActualityView_top_competence_" key={index}>
                                        {/* https://rad-ong.org/Domaine-de-competences:?competence=Conseil */}
                                        <span className="OnActualityView_top_competence_text">
                                            { competence }
                                        </span>
                                    </Link>
                                ))
                            }
                        </div>
                    </div>

                    <div className="OnActualityView_share x-animation"
                        x-animation="from-bottom"
                        x-animation-delay="0s"
                        x-animation-duration="1s"
                    >
                        <div className="OnActualityView_competence_title OnActualityView_share_text">
                            <span className="OnActualityView_competence_title_ OnActualityView_share_text_ title">
                                Partager cette actualité
                            </span>
                            <div className="OnActualityView_share_button">
                                <span>
                                    <FacebookShareButton url={window.location.href}>
                                        <FacebookIcon size={32} round={true} />
                                    </FacebookShareButton>
                                </span>
                                <span>
                                    <LinkedinShareButton url={window.location.href}>
                                        <LinkedinIcon size={32} round={true} />
                                    </LinkedinShareButton>
                                </span>
                                <span>
                                    <TwitterShareButton url={window.location.href}>
                                        <TwitterIcon size={32} round={true} />
                                    </TwitterShareButton>
                                </span>
                                <span
                                    onClick={() => {
										this._copyToClipBoard(window.location.href);
									}}
                                >
                                    <input id="element_to_copy" readOnly={true} type="text" contentEditable={false}/>
									<svg width="27" height="29" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12.1429 16.625V18.1094C12.1429 18.6013 11.7351 19 11.2321 19H0.910714C0.407734 19 0 18.6013 0 18.1094V4.45312C0 3.96124 0.407734 3.5625 0.910714 3.5625H3.64286V14.5469C3.64286 15.6928 4.59611 16.625 5.76786 16.625H12.1429ZM12.1429 3.85938V0H5.76786C5.26488 0 4.85714 0.39874 4.85714 0.890625V14.5469C4.85714 15.0388 5.26488 15.4375 5.76786 15.4375H16.0893C16.5923 15.4375 17 15.0388 17 14.5469V4.75H13.0536C12.5527 4.75 12.1429 4.34922 12.1429 3.85938ZM16.7333 2.70791L14.231 0.260842C14.0602 0.0938283 13.8286 1.23499e-06 13.5871 0L13.3571 0V3.5625H17V3.33765C17 3.10146 16.9041 2.87493 16.7333 2.70791Z" fill={ this.state.copied ? " rgba(37, 174, 136, 0.38)" : "#00000044" }/>
									</svg>
								</span>
                            </div>
                        </div>
                    </div>

                    <div className="OnActualityView_body">
                        {/* <div className="OnProjectsView_body_head x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <div className="OnProjectsView_body_head_text title">
                                <span className="">Présentation</span>
                            </div>
                        </div> */}
                        <div className="OnProjectsView_body_subDescription text-sizes-1 left-border x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <p className="textForm">
                                { this.state.article.shortDescription }
                            </p>
                        </div>

                        <div className='content'>
                            {
                                this.state.article.content
                            }
                        </div>

                        {
                            this.state.article.galerie.length > 0 &&
                            <>
                                <div className="OnProjectsView_body_head OnProjectsView_body_head2 x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="1s"
                                >
                                    <div className="OnProjectsView_body_head_text title">
                                        <span className="">Galerie</span>
                                    </div>
                                </div>

                                <div className="OnProjectsView_body_galerie x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="1s"
                                >
                                    <ImageSlider2 projectName={this.state.article.name} imageList={this.state.article.galerie}/>
                                </div>
                            </>
                        }

                        {
                            this.state.article.videos.length > 0 &&
                            <>
                                <div className="OnProjectsView_body_head OnProjectsView_body_head2 x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="1s"
                                >
                                    <div className="OnProjectsView_body_head_text title">
                                        <span className="">Galerie vidéos du projet</span>
                                    </div>
                                </div>

                                <div className="OnProjectsView_body_galerie x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="1s"
                                >
                                    <VideoSlider projectName={this.state.article.name} videoList={this.state.article.videos}/>
                                </div>
                            </>
                        }

                        <div className="OnProjectsView_body_head OnProjectsView_body_head2 x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <div className="OnProjectsView_body_head_text title">
                                <span className="">Projets en rapport</span>
                            </div>
                        </div>

                        <div className="OnProjectsView_body_description x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <div className="OnProjectsView_body_actuality_">
                                {
                                    this.state.projects.map((project, index) => (
                                        <div key={index} className="OnProjectsView_body_actuality_card x-animation"
                                            x-animation="from-bottom"
                                            x-animation-delay="0s"
                                            x-animation-duration="2s"
                                        >
                                            <ProjectCard project={project} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    {
                        this.state.loading &&
                        <div className='loading'>
                            <img src={loader} alt='' />
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default OnActualityView;