import React from 'react';
// import parse from 'html-react-parser';

import './style/style.css';
import './style/responsive.style.css';

import VideoComponent from '../videoComponent/video.component';
// import loader from '../../assets/gif/loader5.gif';
import imagesURL from '../../helpers/imagesURL.helper';

class TestimonyComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTestimonyIndex: 0,
            viewMenu: false,
        }
    }

    componentDidMount() {
       
    }

    _changeCurrentTestimonyIndex(index) {
        this.setState({
            currentTestimonyIndex: index,
        });
    }

    render() {
        return (
            <div className="Testimony_container">
                <div className='Testimony_container_menu'
                    onClick={(e) => {
                        this.setState({
                            viewMenu: true,
                        });
                    }}
                >
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-card-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                        <path fill-rule="evenodd" d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"/>
                        <circle cx="3.5" cy="5.5" r=".5"/>
                        <circle cx="3.5" cy="8" r=".5"/>
                        <circle cx="3.5" cy="10.5" r=".5"/>
                    </svg>
                </div>
                <div className='Testimony_container_left'
                    style={{
                        transform: this.state.viewMenu ? "translateX(0%)" : "translateX(-100%)",
                    }}
                >
                    <div className='Testimony_container_left_content'>
                        <div className='Testimony_container_left_content_cross'
                            onClick={(e)=>{
                                this.setState({
                                    viewMenu: false,
                                });
                            }}
                        >
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </div>
                        <div className='Testimony_container_left_content_'>
                            {
                                this.props.list.map((elt, index) => (
                                    <div className='Testimony_container_left_content_elt'
                                        style={{
                                            transform:  this.state.currentTestimonyIndex===index ? "translateX(5px)" : "translateX(-5px)",
                                            boxShadow: this.state.currentTestimonyIndex===index ? "5px 5px 9px 3px #00000055" : "0 5px 9px 0 #00000055",
                                            backgroundColor: this.state.currentTestimonyIndex===index ? "#5dc269" : "#ffffff",
                                        }}
                                        onClick={() => {
                                            this._changeCurrentTestimonyIndex( index );
                                            this.setState({
                                                viewMenu: false,
                                            });
                                        }}
                                    >
                                        <div className='Testimony_container_left_content_elt_logo'>
                                            {/* <img src={this.props.defaultIcon} alt="rad-temoignage"/> */}
                                            {this.props.defaultIcon}
                                        </div>
                                        <div className='Testimony_container_left_content_elt_text'>
                                            <span className='Testimony_container_left_content_elt_text_title'
                                                style={{
                                                    fontWeight: this.state.currentTestimonyIndex===index ? "700" : "600",
                                                    display: this.state.currentTestimonyIndex===index ? "block" : "inline",
                                                    textAlign: this.state.currentTestimonyIndex===index ? "right" : "left",
                                                    color: this.state.currentTestimonyIndex===index ? "#ffffff" : "#000000aa",
                                                }}
                                            >
                                                {elt.name}
                                            </span>
                                            <div className='Testimony_container_left_content_elt_text_content'
                                                 style={{
                                                    fontWeight: this.state.currentTestimonyIndex===index ? "600" : "normal",
                                                    textAlign: this.state.currentTestimonyIndex===index ? "right" : "left",
                                                    color: this.state.currentTestimonyIndex===index ? "#d7f2da" : "#000000aa",
                                                }}
                                            >
                                                <span>
                                                    {elt.desc}
                                                </span>
                                            </div>
                                        </div>
                                        {/* {
                                            this.state.currentTestimonyIndex===index ?
                                                <div className='Testimony_container_left_content_elt_back'>
                                                    <img src={loader}/>
                                                </div>
                                            :
                                                null
                                        } */}
                                        
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='Testimony_container_right'>
                    <VideoComponent src={ imagesURL + "/upload/" + this.props.list[this.state.currentTestimonyIndex].value} />
                </div>
            </div>
        )
    }
}

export default TestimonyComponent;