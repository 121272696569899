import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import './style/style.css';
import './style/responsive.style.css';
class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }

    }

    componentDidMount() {
        if( window.location.href.split("notre-savoir-faire").length > 1 ||
            window.location.href.split("nos-domaines-de-competences").length > 1
        ) {
            this.props.action( 6 );
        }
    }

  
    render() {
        return (
            <div className="menu_container">
                <ul className="menu" id="menu">
                    <li className="menu_item accueil">
                        <span className="menu_item_title" onClick={() => {
                            this.props.action( 0 );
                        }}>
                            <Link className="link_menu" variant="contained" to="/">
                                <i className="fa fa-home menuIcon"></i> ACCUEIL
                            </Link>
                        </span>
                        <div className="menuItemUnderLine" style={{
                            width: this.props.current === 0 ? "100%" : "0px",
                            opacity: this.props.current === 0 ? 1 : 0,
                        }}></div>
                    </li>

                    <li className="menu_item about-us">
                        <span className="menu_item_title" onClick={() => {
                            this.props.action( 1 );
                        }}>
                            <Link className="link_menu" variant="contained" to="/A-propos-de-nous">
                                <i className="fa fa-users menuIcon"></i> A PROPOS DE NOUS
                            </Link>
                        </span>
                        <div className="menuItemUnderLine" style={{
                            width: this.props.current === 1 ? "100%" : "0px",
                            opacity: this.props.current === 1 ? 1 : 0,
                        }}></div>
                        <ul className="menu_item_subMenu">
                            <li className="menu_item_subMenu_text menu_item_subMenu_text_" onClick={() => {
                                this.props.action( 1 );
                            }}>
                                <HashLink className="link_menu" variant="contained" to="/A-propos-de-nous/#notre-equipe">
                                    Notre équipe
                                </HashLink>
                            </li>
                            <li className="menu_item_subMenu_text">
                                <a style={{color: "#ffffff"}} href="/charte_graphique">
                                    Notre charte graphique
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li className="menu_item une">
                        <span className="menu_item_title" onClick={() => {
                            this.props.action( 2 );
                        }}>
                            <Link className="link_menu" variant="contained" to="/A-la-une">
                                <i className="fa fa-newspaper-o menuIcon"></i> A LA UNE
                            </Link>
                        </span>
                        <div className="menuItemUnderLine" style={{
                            width: this.props.current === 2 ? "100%" : "0px",
                            opacity: this.props.current === 2 ? 1 : 0,
                        }}></div>
                        <ul className="menu_item_subMenu">
                            
                            <li className="menu_item_subMenu_text">
                                <a style={{color: "#ffffff"}} href="/evenements">
                                    Événements à venir
                                </a>
                            </li>
                        </ul>
                    </li>
                    
                    <li className="menu_item project">
                        <span className="menu_item_title" onClick={() => {
                            this.props.action( 3 );
                        }}>
                            <Link className="link_menu" variant="contained" to="/Nos-projets">
                                <i className="fa fa-thumb-tack menuIcon"></i> NOS PROJETS
                            </Link>
                        </span>
                        <div className="menuItemUnderLine" style={{
                            width: this.props.current === 3 ? "100%" : "0px",
                            opacity: this.props.current === 3 ? 1 : 0,
                        }}></div>
                    </li>

                    <li className="menu_item contactUs">
                        <span className="menu_item_title" onClick={() => {
                            this.props.action( 4 );
                        }}>
                            <Link className="link_menu" variant="contained" to="/Nous-contacter">
                                <i className="fa fa-phone menuIcon menuIcon2"></i> NOUS CONTACTER
                            </Link>
                        </span>
                        <div className="menuItemUnderLine" style={{
                            width: this.props.current === 4 ? "100%" : "0px",
                            opacity: this.props.current === 4 ? 1 : 0,
                        }}></div>
                    </li>
                    
                    <li className="menu_item media">
                        <span className="menu_item_title" onClick={() => {
                            this.props.action( 5 );
                        }}>
                            <Link className="link_menu" variant="contained" to="/Mediatheque">
                                <i className="fa fa-picture-o menuIcon"></i> MÉDIATHÈQUE
                            </Link>
                        </span>
                        <div className="menuItemUnderLine" style={{
                            width: this.props.current === 5 ? "100%" : "0px",
                            opacity: this.props.current === 5 ? 1 : 0,
                        }}></div>
                    </li>

                    <li className="menu_item plus">
                        <span className="menu_item_title" onClick={() => {
                            this.props.action( 6 );
                        }}>
                            PLUS...
                        </span>
                        <div className="menuItemUnderLine" style={{
                            width: this.props.current === 6 ? "100%" : "0px",
                            opacity: this.props.current === 6 ? 1 : 0,
                        }}></div>
                        <ul className="menu_item_subMenu">
                            <li className="menu_item_subMenu_text menu_item_subMenu_text_"
                                onClick={() => {
                                    this.props.action( 6 );
                                }}
                            >
                                <Link className="link_menu" variant="contained" to="/notre-savoir-faire">
                                    Notre savoir faire
                                </Link>
                            </li>
                            <li className="menu_item_subMenu_text menu_item_subMenu_text_"
                                onClick={() => {
                                    this.props.action( 6 );
                                }}
                            >
                                <Link className="link_menu" variant="contained" to="/nos-domaines-de-competences">
                                    Nos domaines de compétences
                                </Link>
                            </li>
                            <li className="menu_item_subMenu_text menu_item_subMenu_text_"
                                onClick={() => {
                                    this.props.action( 6 );
                                }}
                            >
                                <Link className="link_menu" variant="contained" to="/donate">
                                    Participer à nos actions
                                </Link>
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>

        )
    }
}

export default Menu;