import React from 'react';
import parse from 'html-react-parser';

/**Importation des composants de la page */
import { connect } from 'react-redux';
import ImageSelector from '../components/imageSelector/imageSelector.component';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

/**Importation des styles de la page */
import './styles/dashboard.rubrique.home.style.css';
import './styles/dashboard.rubrique.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';

/** Importation des helpers */
import uploadFiles from '../helpers/upload_file.helper'
import getPreviewFiles from '../helpers/getPreviewFiles.helper';
import radDataClass from '../helpers/data-class.helper';
import radDataAttr from '../helpers/data-attr.helper';
import radData from '../helpers/data.helper';
import refreshConnection from '../helpers/refresh.helper';
import imagesURL from '../helpers/imagesURL.helper';

import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}

class DashboardRubriqueABoutUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ImageSelectedList: [],
            ImageSelectedList2: [],

            ImageSelectedPreviewLink: [],
            ImageSelectedPreviewLink2: [],

            filesSelected: [],
            filesSelected2: [],

            loading: false,

            membersGroup: [],
            posts: [],

            currentPost: {},

            updatePoste: false,

            pageInfo: {
                classname: {},
                title1: {value: ""},
                title2: {value: ""},
                text: {value: ""},
                image1: {value: ""},
                image2: {value: ""},
            },
            
            uploadFiles: false,
            currentUploadProgression: 0,
            currentUploadMessage: '',

            typeUpdate: "",
        }

        this.accessStore = new AccessStore(this.props.dispatch);

        this.currentUploadFiles = [];
        this.currentUploadFiles2 = [];

        this.radDataClass = new radDataClass();
        this.radData = new radData();
        this.radDataAttr = new radDataAttr();
        this._searchDataClass = this._searchDataClass.bind( this );
        this._search_page_infos = this._search_page_infos.bind( this );
    }

    _makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++)
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    _setNewAccessToUser(callback) {
        refreshConnection(this.props.user.refresh).then((response) => {
            let newUser = this.props.user;
            newUser.access = response.data.access;

            if (response.data.refresh) {
                newUser.refresh = response.data.refresh;
            }

            this.accessStore.setRedux('SET_USER', newUser, callback);
        }).catch((e) => {
            if (e.response) {
                if (e.response.status === 401) {
                    this.accessStore.setRedux('LOG_OUT', {});
                }
            }
            if (e.toString() === "Error: Network Error") {
                alert("Aucun accès à internet :(");
            }
        });
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });

        this._start_search_data();
        this._search_page_infos();
    }

    _clear() {
        this.setState({
            updatePost: false,
            currentPost: {},
        });
        this.currentUploadFiles = [];
    }

    _search_page_infos() {
        this.setState({
            loading: true,
        });
        this._searchDataClass( "about-us", ( classes ) => {
            classes.map((classe, index) => {
                if( classe.name === "about-us" ) {
                    let pageInfo = {
                        classname: classe,
                        title1: {},
                        title2: {},
                        text: {},
                        image1: {},
                        image2: {},
                    };

                    this._searchData( classe.name, (infos)=>{

                        infos.map((info, info_index) => {
                            
                            if( info.classname === classe.id ) {

                                this._getDataAttr( info.name, (infoValues)=> {
                                    infoValues.map((infoValue, index) => {
                                        if( infoValue.name === "about-us-title1-value" ) {
                                            pageInfo.title1 = infoValue;
                                        }else if( infoValue.name === "about-us-title2-value" ){
                                            pageInfo.title2 = infoValue;
                                        }else if( infoValue.name === "about-us-text-value" ){
                                            pageInfo.text = infoValue;
                                        }else if( infoValue.name === "about-us-image1-value" ){
                                            pageInfo.image1 = infoValue;
                                        }else if( infoValue.name === "about-us-image2-value" ){
                                            pageInfo.image2 = infoValue;
                                        }
                                    });

                                    if( info_index === infos.length - 1 ) {
                                        this.setState({
                                            pageInfo: pageInfo,
                                            loading: false,
                                        });
                                    }
                                });
                            }
                        });
                    });
                }
            });
        });
    }

    _update_page_infos( info, type, file=[] ) {
        if( window.confirm("Voulez-vous vraiment modifier?") === true ){
            this.setState({
                loading: true,
            });
            if( type === "text" ) {
                let data = {
                    value: info.value,
                    name: info.name,
                    data: info.data,
                }

                this._update_dataAttr(info.id, data, ()=>{
                    alert("Texte modifié avec succès");
                    this.setState({
                        loading: false,
                    });
                    this._search_page_infos();
                });
            }

            else if( type === "image" ) {
                if( file.length > 0 ) {
                    this.setState({
                        uploadFiles: true,
                    });
                    uploadFiles( info.name, file, (event) => {
                        this.setState({
                            currentUploadProgression: Math.round((100 * event.loaded) / event.total),
                        });
                    }).then((response) => {
                        if( response.data.code === 201 ){
                            this.setState({
                                uploadFiles: false,
                                loading: true,
                            });
                            // console.log(response.data);
                            let data = {
                                value: response.data.files_uploaded_list.images[0].name,
                                name: info.name,
                                data: info.data,
                            }
                            this._update_dataAttr(info.id, data, ()=>{
                                alert("Image modifié avec succès");
                                this.setState({
                                    loading: false,
                                });
                                this._search_page_infos();
                            });
                        }
                    })
                }
    
            }
        }
    }

    _start_search_data() {
        this._searchDataClass( "member-group", ( membersGroup ) => {
            this._getDataAttr( "poste", ( posteAttrs)=> {
                
                let groups = membersGroup;

                if( posteAttrs.length>0 ) {
                    groups.map((group, index) => {
                        group.postes = [];
                        
                        this._searchData( group.name, (postes)=>{
    
                            postes.map((poste, index) => {
                                if( poste.classname === group.id ) {
                                    let newPoste = poste;
    
                                    group.postes = group.postes.concat( newPoste );
                                    
                                    posteAttrs.map((attr, attrIndex) => {
                                        if( attr.data === poste.id ) {
                                            if( attr.label === "name" ) {
                                                newPoste.memberName = attr;
                                            }
                                            else if( attr.label === "image" ) {
                                                newPoste.memberImage = attr;
                                            }
                                        }
                                    });
                                    
                                    this.setState({
                                        membersGroup: groups,
                                    });
                                    // console.log( groups );
                                }
                            });
                        })
                    });
                } else {
                    groups.map((group, index) => {
                        group.postes = [];
                    });
                    this.setState({
                        membersGroup: groups,
                    });
                }
                
                this.setState({
                    loading: false,
                });
                return true;
                });
        });
    }

    _getDataAttr( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            // console.log( response.data );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getDataAttr( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchData( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radData._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchData( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _changeGroupeName(group) {
        // console.log( group );
        let promptValue = prompt("Modifier le nom du groupe ici", group.name);
        if( (promptValue) !== null ) {
            let data = {
                name: promptValue
            }
            if( window.confirm("Voulez-vous vraiment modifier le groupe?") === true ){
                this.setState({
                    loading: true,
                });
                this._updateDataClass( group.id, data, ()=>{
                    this.setState({
                        loading: false,
                    });
                    alert("Groupe modifié avec succès");
                    this._start_search_data();
                });
            }
        }
    }

    _updateDataClass(id, data, callback=()=>{}) {
        this.setState({
            loading: true,
        });
        this.radDataClass._update(id, JSON.stringify(data), this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._updateDataClass(id, data, callback) );
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });

    }

    _searchDataClass( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataClass._getOne(search, page).then((response) => {
            response.data.results.map((result, index)=>{
                if( result.label === search ) {
                    tab = tab.concat( result );
                }
            });
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchDataClass( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _post_data( data, callback=()=>{} ) {
        this.setState({
            loading: true,
        });
        this.radData._post(JSON.stringify(data), this.props.user.access).then((response) => {
            callback( response.data );
        }).catch((e) => {
            // console.log( e );
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._post_data( data, callback ));
                }
                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            alert("Ce poste existe déjà");
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _post_dataAttr( dataAttr, callback=()=>{} ) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._post(JSON.stringify(dataAttr), this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            // console.log( e );
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._post_dataAttr( dataAttr, callback ));
                }
                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            // alert("Ce poste existe déjà");
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _updateData(id, data, callback=()=>{}) {
        this.setState({
            loading: true,
        });
        this.radData._update(id, JSON.stringify(data), this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._updateData(id, data, callback) );
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _update_dataAttr(id, data, callback=()=>{}) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._update(id, JSON.stringify(data), this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._update_dataAttr(id, data, callback) );
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _updatePoste( poste, typeUpdate) {
        this.setState({
            loading: true,
        });
        if( typeUpdate === "update" ) {
            if( window.confirm("Voulez-vous vraiment modifier ce membre ?") === true ){
                if( this.currentUploadFiles.length > 0 ) {
                    this.setState({
                        uploadFiles: true,
                    })

                    uploadFiles( "RAD-members", this.currentUploadFiles, (event) => {
                        this.setState({
                            currentUploadProgression: Math.round((100 * event.loaded) / event.total),
                        });
                    }).then((response) => {
                        this.setState({
                            uploadFiles: false,
                        });
                        if( response.data.code === 201 ){
                            this.setState({
                                uploadFiles: false,
                            });
                            let imageUpladed = "RAD-members/" + this.currentUploadFiles[0].name;
                            
                            let imgData = {
                                value: imageUpladed,
                                name: poste.memberImage.name,
                                data: poste.memberImage.data,
                            };
                            // console.log( response.data );
                            this._update_dataAttr(poste.memberImage.id, imgData, ()=>{

                                let posteData = {
                                    value: poste.memberName.value,
                                    name: poste.memberName.name,
                                    data: poste.memberName.data,
                                };
                                this._update_dataAttr(poste.memberName.id, posteData, ()=>{
                                    let data = {
                                        name: poste.name,
                                        classname: poste.classname,
                                        label: poste.label
                                    };
                                    this._updateData(poste.id, data, ()=>{
                                        this._start_search_data();
                                        this._clear();
                                        alert("Membre modifier avec succès");
                                        this.setState({
                                            loading: false,
                                        });
                                    })
                                });
                            });
                        }
                        else {
                            alert('Echec de l\'envois du fichier. Veuillez réessayer');
                            this.setState({
                                uploadFiles: false,
                                loading: false,
                            });
                        }
                    }).catch((e) => {
                        this.setState({
                            loading: false,
                        });
                        alert('Echec de l\'envois du fichier. Veuillez réessayer');
                        // console.log( e );
                    });
                }
                

                else {
                    let imgData = {
                        value: poste.memberImage.value,
                        name: poste.memberImage.name,
                        data: poste.memberImage.data,
                    };
                    this._update_dataAttr(poste.memberImage.id, imgData, ()=>{

                        let posteData = {
                            value: poste.memberName.value,
                            name: poste.memberName.name,
                            data: poste.memberName.data,
                        };
                        this._update_dataAttr(poste.memberName.id, posteData, ()=>{

                            let data = {
                                name: poste.name,
                                classname: poste.classname,
                                label: poste.label
                            };
                            this._updateData(poste.id, data, ()=>{
                                this._start_search_data();
                                this._clear();
                                alert("Membre modifier avec succès");
                                this.setState({
                                    loading: false,
                                });
                            })
                        });
                    });
                }
            }

            else {
                this.setState({
                    loading: false,
                });
            }
        }

        else if( typeUpdate === "add" ) {
            if( poste.name !== "" && poste.memberName !== "" ) {
                if( window.confirm("Voulez-vous vraiment ajouter ce membre ?") === true ){
                    if( this.currentUploadFiles.length > 0 ) {
                        this.setState({
                            uploadFiles: true,
                        });

                        uploadFiles( "RAD-members", this.currentUploadFiles, (event) => {
                            this.setState({
                                currentUploadProgression: Math.round((100 * event.loaded) / event.total),
                            });
                        }).then((response) => {
                            this.setState({
                                uploadFiles: false,
                            });
                            if( response.data.code === 201 ){
                                let imageUpladed = "RAD-members/" + this.currentUploadFiles[0].name;

                                let data = {
                                    label: "poste",
                                    name: poste.name,
                                    desc: "Le poste de " + poste.name + " de RAD-ONG",
                                    classname: poste.classname,
                                    max_dataref: -1,
                                };

                                this._post_data(data, (response)=>{
                                    let memberName = {
                                        label: "name",
                                        value: poste.memberName.name,
                                        name: poste.memberName.name,
                                        data: response.id,
                                        type: 2,
                                    };
                                    this._post_dataAttr(memberName, ()=>{
                                        let memberImage = {
                                            label: "image",
                                            value: imageUpladed,
                                            name: "image-" + poste.name,
                                            data: response.id,
                                            type: 2,
                                        }
                                        this._post_dataAttr(memberImage, ()=>{
                                            this._start_search_data();
                                            this._clear();
                                            alert("Membre ajouté avec succès");
                                            this.setState({
                                                loading: false,
                                            });
                                        });
                                    });
                                });
                            }
                            else {
                                alert('Echec de l\'envois du fichier. Veuillez réessayer');
                                this.setState({
                                    uploadFiles: false,
                                    loading: false,
                                });
                            }
                        }).catch((e) => {
                            this.setState({
                                loading: false,
                            });
                            alert('Echec de l\'envois du fichier. Veuillez réessayer');
                            // console.log( e );
                        });
                    }
                    

                    else {
                        let data = {
                            label: "poste",
                            name: poste.name,
                            desc: "Le poste de " + poste.name + " de RAD-ONG",
                            classname: poste.classname,
                            max_dataref: -1,
                        };

                        this._post_data(data, (response)=>{
                            let memberName = {
                                label: "name",
                                value: poste.memberName.name,
                                name: poste.memberName.name,
                                data: response.id,
                                type: 2,
                            };
                            this._post_dataAttr(memberName, ()=>{
                                let memberImage = {
                                    label: "image",
                                    value: "",
                                    name: "image-" + poste.name,
                                    data: response.id,
                                    type: 2,
                                }
                                this._post_dataAttr(memberImage, ()=>{
                                    this._start_search_data();
                                    this._clear();
                                    alert("Membre ajouté avec succès");
                                    this.setState({
                                        loading: false,
                                    });
                                });
                            });
                        });
                    }
                }
            }
            else {
                this.setState({
                    loading: false,
                });
                alert("Vous devez renseigner le nom du poste et l'occupant du poste");
            }
        }

        else if( typeUpdate === "delete" ) {
            if( window.confirm("Voulez-vous vraiment supprimé ce membre ?") === true ){
                this._deleteData(poste.id, ()=>{
                    this._start_search_data();
                    alert("Membre supprimé avec succès");
                });
            }
            else {
                this.setState({
                    loading: false,
                });
            }
        }
    }

    _deleteData(id, callback=()=>{}) {
        this.setState({
            loading: true,
        });
        this.radData._delete(id, this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._deleteData(id, callback) );
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _addGroup(){
        let promptValue = prompt("Entrez le nom du groupe ici");
        if( window.confirm("Voulez-vous vraiment ajouter ce groupe ?") === true ){
            let data = {
                label: "member-group",
                name: promptValue,
                desc: promptValue + " de RAD-ONG"
            };
            this._addClass(data, ()=>{
                alert("Groupe ajouté avec succès");
                this._start_search_data();
            })
        }
    }

    _addClass(dataClass, callback=()=>{} ) {
        this.setState({
            loading: true,
        });
        this.radDataClass._post(JSON.stringify(dataClass), this.props.user.access).then((response) => {
            this.setState({
                loading: false,
            });
            callback();
        }).catch((e) => {
            // console.log( e );
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._addClass( dataClass, callback ));
                }
                if( e.response.data ) {
                    console.log( e.response.data );
                    if( e.response.data.name ) {
                        if( e.response.data.name[0] === "Data classe with this name already exists.") {
                            alert("Ce groupe existe déjà");
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _deleteGroup(id) {
        if( window.confirm("Voulez-vous vraiment supprimé ce groupe ?") === true ){
            this._deleteClass(id, ()=>{
                alert("Groupe supprimé avec succès");
                this.setState({
                    loading: true,
                });
                this._start_search_data();
            });
        }
    }

    _deleteClass(id, callback=()=>{}) {
        this.setState({
            loading: true,
        });
        this.radDataClass._delete(id, this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._deleteClass(id, callback) );
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _deleteImage(imageIndex) {
        let ImageSelectedList = this.state.ImageSelectedList;
        let ImageSelectedPreviewLink = this.state.ImageSelectedPreviewLink;

        let filteredImageSelectedList = ImageSelectedList.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        let filteredImageSelectedPreviewLink = ImageSelectedPreviewLink.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        this.setState({
            ImageSelectedList: filteredImageSelectedList,
            ImageSelectedPreviewLink: filteredImageSelectedPreviewLink,
        });
    }

    _deleteAllImages() {
        this.setState({
            ImageSelectedList: [],
            ImageSelectedPreviewLink: [],
        });
    }

    _deleteImage2(imageIndex) {
        let ImageSelectedList2 = this.state.ImageSelectedList2;
        let ImageSelectedPreviewLink2 = this.state.ImageSelectedPreviewLink2;

        let filteredImageSelectedList2 = ImageSelectedList2.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        let filteredImageSelectedPreviewLink2 = ImageSelectedPreviewLink2.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        this.setState({
            ImageSelectedList2: filteredImageSelectedList2,
            ImageSelectedPreviewLink2: filteredImageSelectedPreviewLink2,
        });
    }

    _deleteAllImages2() {
        this.setState({
            ImageSelectedList2: [],
            ImageSelectedPreviewLink2: [],
        });
    }

    render() {
        return (
            <>
                <div className='dashboardRubrique_home'>
                    <div className='dashboardRubrique_home_head title'>
                        <span className='title'>
                            Rubrique "À propos de nous"
                        </span>
                    </div>


                    <div className='textContent'>
                        <div className='textContent_ radSections_title_content'>
                            <div className='radSections_title radSections_title_label'>
                                <span className='radSections_title_'>
                                    Titre 1 :
                                </span>
                            </div>

                            <div className='add_content'>
                                <div className='add_content_editor-2'>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={(this.state.pageInfo.title1 && this.state.pageInfo.title1.value && this.state.pageInfo.title1.value !== null && this.state.pageInfo.title1.value !== undefined) ? this.state.pageInfo.title1.value : ""}
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            // console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            let pageInfo = this.state.pageInfo;
                                            pageInfo.title1.value = data;
                                            this.setState({
                                                pageInfo: pageInfo,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='add_content_area_button'>
                                    <span className='update'
                                        onClick={() => {
                                            this._update_page_infos(this.state.pageInfo.title1, "text");
                                        }}
                                    >
                                        Modifier
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='textContent_ radSections_title_content'>
                            <div className='radSections_title radSections_title_label'>
                                <span className='radSections_title_'>
                                    Titre 2 :
                                </span>
                            </div>

                            <div className='add_content'>
                                <div className='add_content_editor-2'>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={(this.state.pageInfo.title2 && this.state.pageInfo.title2.value && this.state.pageInfo.title2.value !== null && this.state.pageInfo.title2.value !== undefined) ? this.state.pageInfo.title2.value : ""}
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            // console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            let pageInfo = this.state.pageInfo;
                                            pageInfo.title2.value = data;
                                            this.setState({
                                                pageInfo: pageInfo,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='add_content_area_button'>
                                    <span className='update'
                                        onClick={() => {
                                            this._update_page_infos(this.state.pageInfo.title2, "text");
                                        }}
                                    >
                                        Modifier
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div className='textContent_ radSections_title_content'>
                            <div className='radSections_title radSections_title_label'>
                                <span className='radSections_title_'>
                                    Texte :
                                </span>
                            </div>

                            <div className='add_content'>
                                <div className='add_content_editor-3'>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={(this.state.pageInfo.text && this.state.pageInfo.text.value && this.state.pageInfo.text.value !== null && this.state.pageInfo.text.value !== undefined) ? this.state.pageInfo.text.value : ""}
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            // console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            let pageInfo = this.state.pageInfo;
                                            pageInfo.text.value = data;
                                            this.setState({
                                                pageInfo: pageInfo,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='add_content_area_button'>
                                    <span className='update'
                                        onClick={() => {
                                            this._update_page_infos(this.state.pageInfo.text, "text");
                                        }}
                                    >
                                        Modifier
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div className='textContent_ radSections_title_content'>
                            <div className='radSections_title radSections_title_label'>
                                <span className='radSections_title_'>
                                    Image 1 :
                                </span>
                            </div>

                            <div className='add_content'>
                                <div>
                                    {
                                        this.state.pageInfo.image1.value && this.state.pageInfo.image1.value !== ""&&
                                        <img className="previewImageSelected" src={imagesURL + this.state.pageInfo.image1.value} alt="RAD-ONG"/>
                                    }
                                    
                                </div>
                                <div className='add_content_imageSelector'>
                                    <ImageSelector
                                        onChangeImage={async (e) => {
                                            const files = Array.from(e);

                                            let ImageSelectedList = this.state.ImageSelectedList;
                                            let filesSelected = this.state.filesSelected;
                                            filesSelected = [files[0]];
                                            // console.log( filesSelected );

                                            ImageSelectedList = {
                                                file: [files[0]],
                                            };

                                            // console.log( files );

                                            let tab = this.state.ImageSelectedPreviewLink;

                                            Promise.all(files.map(file => {
                                                return (
                                                    new Promise((resolve, reject) => {
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', (ev) => {
                                                            resolve(ev.target.result);
                                                        });
                                                        reader.addEventListener('error', reject);
                                                        reader.readAsDataURL(file);
                                                    })
                                                )
                                            })).then(images => {
                                                tab = [images[0]];
                                                // console.log( tab );
                                                this.setState({
                                                    filesSelected: filesSelected,
                                                    ImageSelectedList: ImageSelectedList,
                                                    ImageSelectedPreviewLink: tab,
                                                });
                                            });

                                        }}
                                    />
                                </div>
                                {
                                    this.state.ImageSelectedPreviewLink.length > 0 &&
                                    <div className='delete_all_image'>
                                        <span className='delete_all_image_text' onClick={() => {
                                            this._deleteAllImages();
                                        }}>
                                            <span>
                                                Supprimer toutes les images
                                            </span>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </span>
                                    </div>
                                }

                                <div className="previewImageSelected_container">
                                    {
                                        this.state.ImageSelectedPreviewLink.map((link, index) => (
                                            <div className='previewImageContainer'>
                                                <div className="previewImageSelected_">
                                                    <svg onClick={() => {
                                                        this._deleteImage(index);
                                                    }} className='previewImageSelected_cross' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529" />
                                                        <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529" />
                                                    </svg>
                                                    <img className="previewImageSelected" key={index} src={link} alt="RAD-ONG" />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className='add_content_area_button'>
                                    <span className='update'
                                        onClick={() => {
                                            this._update_page_infos(this.state.pageInfo.image1, "image", [this.state.filesSelected[0]]);
                                        }}
                                    >
                                        Modifier
                                    </span>
                                </div>
                            </div>
                        </div>



                        <div className='textContent_ radSections_title_content'>
                            <div className='radSections_title radSections_title_label'>
                                <span className='radSections_title_'>
                                    Image 2 :
                                </span>
                            </div>

                            <div className='add_content'>
                                <div>
                                    {
                                        this.state.pageInfo.image2.value && this.state.pageInfo.image2.value !== ""&&
                                        <img className="previewImageSelected" src={imagesURL + this.state.pageInfo.image2.value} alt="RAD-ONG"/>
                                    }
                                    
                                </div>
                                <div className='add_content_imageSelector'>
                                    <ImageSelector
                                        onChangeImage={async (e) => {
                                            const files = Array.from(e);

                                            let ImageSelectedList2 = this.state.ImageSelectedList2;
                                            let filesSelected2 = this.state.filesSelected2;
                                            filesSelected2 = [files[0]];
                                            // console.log( filesSelected );

                                            ImageSelectedList2 = {
                                                file: [files[0]],
                                            };

                                            // console.log( files );

                                            let tab = this.state.ImageSelectedPreviewLink2;

                                            Promise.all(files.map(file => {
                                                return (
                                                    new Promise((resolve, reject) => {
                                                        const reader = new FileReader();
                                                        reader.addEventListener('load', (ev) => {
                                                            resolve(ev.target.result);
                                                        });
                                                        reader.addEventListener('error', reject);
                                                        reader.readAsDataURL(file);
                                                    })
                                                )
                                            })).then(images => {
                                                tab = [images[0]];
                                                // console.log( tab );
                                                this.setState({
                                                    filesSelected2: filesSelected2,
                                                    ImageSelectedList2: ImageSelectedList2,
                                                    ImageSelectedPreviewLink2: tab,
                                                });
                                            });

                                        }}
                                    />
                                </div>
                                {
                                    this.state.ImageSelectedPreviewLink2.length > 0 &&
                                    <div className='delete_all_image'>
                                        <span className='delete_all_image_text' onClick={() => {
                                            this._deleteAllImages2();
                                        }}>
                                            <span>
                                                Supprimer toutes les images
                                            </span>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </span>
                                    </div>
                                }

                                <div className="previewImageSelected_container">
                                    {
                                        this.state.ImageSelectedPreviewLink2.map((link, index) => (
                                            <div className='previewImageContainer'>
                                                <div className="previewImageSelected_">
                                                    <svg onClick={() => {
                                                        this._deleteImage2(index);
                                                    }} className='previewImageSelected_cross' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529" />
                                                        <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529" />
                                                    </svg>
                                                    <img className="previewImageSelected" key={index} src={link} alt="RAD-ONG" />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className='add_content_area_button'>
                                    <span className='update'
                                        onClick={() => {
                                            this._update_page_infos(this.state.pageInfo.image2, "image", [this.state.filesSelected2[0]]);
                                        }}
                                    >
                                        Modifier
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <div className='dashboardRubrique_home_title'>
                        <span className='title'>
                            L'équipde de RAD-ONG :
                        </span>

                        <span className='update_2'
                            onClick={() => {
                                this._addGroup();
                                return true;
                            }}
                        >
                            Ajouter un groupe +
                        </span>
                    </div>
                    <br/>
                    <br/>

                    {
                        this.state.membersGroup.map((group, index) => (
                            <div className='radSections' key={"groupe" + index}>
                                <br/>
                                <div className='radSections_title'>
                                    <span className='radSections_title_'>
                                        { group.name } :
                                    </span>
                                    <span className='update_2'
                                        onClick={() => {
                                            this._changeGroupeName( group );
                                            return true;
                                        }}
                                    >
                                         <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </span>
                                
                                    <span className='update_2'
                                        onClick={() => {
                                            let currentPost = {
                                                name: "",
                                                memberImage: {
                                                    value: "",
                                                },
                                                memberName: {
                                                    name: "",
                                                },
                                                classname: group.id,
                                            };

                                            this.setState({
                                                currentPost: currentPost,
                                                updatePost: true,
                                                typeUpdate: "add",
                                            });
                                        }}
                                    >
                                        +
                                    </span>

                                    <span className='update_2'
                                        onClick={() => {
                                            this._deleteGroup(group.id)
                                            return true;
                                        }}
                                    >
                                        <i className="fa fa-trash-o colorRed" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <table className='table1'>
                                    <thead>
                                        <tr className='table1_trth'>
                                            <th className='table1_th'>
                                                Image
                                            </th>
                                            <th className='table1_th'>
                                                Poste
                                            </th>
                                            <th className='table1_th'>
                                                Nom et prénom
                                            </th>
                                            <th className='table1_th'>
                                                Description
                                            </th>
                                            <th className='table1_th'>
                                                modifier
                                            </th>
                                            <th className='table1_th'>
                                                supprimer
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            group.postes.map((poste, index) => (
                                                <tr
                                                    className='table1_tr'
                                                    key={"tabPartnaires" + index }
                                                    style={{
                                                        backgroundColor: index % 2 !== 0 ? "#00000011" : "transparent"
                                                    }}
                                                >
                                                    <td className='table1_th'>
                                                        {   poste.memberImage && poste.memberImage.value &&
                                                            <img className='partnaireLogo' src={ imagesURL + "/upload/" + poste.memberImage.value} alt="Personnel de RAD-ONG" />
                                                        }
                                                    </td>
                                                    <td className='table1_th'>
                                                        { parse(poste.name) }
                                                    </td>
                                                    <td className='table1_th'>
                                                        { poste.memberName && poste.memberName.name && parse(poste.memberName.name) }
                                                    </td>
                                                    <td className='table1_th'>
                                                        { poste.memberName && poste.memberName.value && parse(poste.memberName.value) }
                                                    </td>
                                                    
                                                    <td className='table1_th table1_th_center'>
                                                        <span className='table1_th_Add'
                                                            onClick={() => {
                                                                this.setState({
                                                                    currentPost: poste,
                                                                    updatePost: true,
                                                                    typeUpdate: "update",
                                                                });
                                                            }}
                                                        >
                                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                                        </span>
                                                    </td>
                                                    <td className='table1_th table1_th_center'>
                                                        <span className='table1_th_Add'
                                                            onClick={() => {
                                                                this._updatePoste( poste, "delete");
                                                                return true;
                                                            }}
                                                        >
                                                            <i className="fa fa-trash-o colorRed" aria-hidden="true"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <br/>
                                <br/>
                            </div>
                        ))
                    }

                    {
                        this.state.updatePost&&
                        <div className='addSection'>
                            <div className='addSection_'>
                                <div className='addSection_title'>
                                    <span>
                                        { this.state.typeUpdate === "add" ? "Ajouter un membre de RAD-ONG" : "Modifier un membre de RAD-ONG"}
                                    </span>
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Image du membre
                                    </span>
                                    {
                                        this.state.currentPost.memberImage&&
                                        <img className='currentLogoContainer' src={this.state.currentPost.memberImage.value} alt='' />
                                    }
                                    <div>
                                        <input className='choice_image' type="file" title="Changer l'image"
                                            onChange={(e) => {

                                                const files = Array.from( e.target.files );
                                                this.currentUploadFiles = e.target.files;

                                                getPreviewFiles( files ).then( images => {
                                                    let currentPost = this.state.currentPost;
                                                    currentPost.memberImage.value = images[0];
                                                    this.setState({
                                                        currentPost: currentPost,
                                                    });
                                                });
                                            }} 
                                        />
                                    </div>
                                    
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Nom du poste occupé
                                    </span>
                                    <input type='text'
                                        value={ this.state.currentPost.name ? this.state.currentPost.name : "" }
                                        onChange={(e) => {
                                            let currentPost = this.state.currentPost;
                                            currentPost.name = e.target.value;
                                            this.setState({
                                                currentPost: currentPost,
                                            });
                                        }}
                                    />
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Nom et prénom(s) de l'occupant du poste
                                    </span>
                                    <input type='text'
                                        value={this.state.currentPost.memberName ? this.state.currentPost.memberName.name : ""}
                                        onChange={(e) => {
                                            let currentPost = this.state.currentPost;
                                            currentPost.memberName.name = e.target.value;
                                            this.setState({
                                                currentPost: currentPost,
                                            });
                                        }}
                                    />
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Description de l'occupant du poste
                                    </span>
                                    <input type='text'
                                        value={this.state.currentPost.memberName ? this.state.currentPost.memberName.value : ""}
                                        onChange={(e) => {
                                            let currentPost = this.state.currentPost;
                                            currentPost.memberName.value = e.target.value;
                                            this.setState({
                                                currentPost: currentPost,
                                            });
                                        }}
                                    />
                                </div>

                                <div className='updateSocial_inputSubmit_container'>
                                    <span className='updateSocial_inputSubmit'
                                        onClick={() => {
                                            this._updatePoste( this.state.currentPost, this.state.typeUpdate);
                                        }}
                                    >
                                        Valider
                                    </span>
                                    <span className='updateSocial_inputBack'
                                        onClick={() => {
                                            this.setState({
                                                updatePost: false,
                                                currentPost: {},
                                            });
                                        }}
                                    >
                                        Annuler
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.uploadFiles&&
                        <div className='addSection'>
                            <div className='addSection_ uploadProgressPlatform'>
                                <div className='addSection_title'>
                                    <span>
                                        Envois des fichiers en cours
                                    </span>
                                </div>

                                <div className='addSection_input'>
                                    <div className='progress'>
                                        <div className='progressBar'
                                            style={{
                                                width: this.state.currentUploadProgression + "%"
                                            }}
                                        >

                                        </div>
                                    </div>
                                </div>

                                <div className="progressText">
                                    <span>
                                        {
                                            this.state.currentUploadProgression + "%"
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.loading &&
                        <div className='loading'>
                            <img src={loader} alt='' />
                        </div>
                    }
                </div>
            </>
        )
    }
}

DashboardRubriqueABoutUs = connect(mapStateToProps, null)(DashboardRubriqueABoutUs);
export default DashboardRubriqueABoutUs;