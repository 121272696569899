import axios from "axios";
const uploadUrl = "https://www.rad-ong.org/rad-dev/history.php";
// const uploadUrl = "http://localhost/test/history.php";

class visiteHistory {
    _getAll() {
        return (
            axios.get(
                uploadUrl, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                },
            )
        )
    }


    _post() {
        return (
            axios.post(
                uploadUrl, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                },
            )
        )
    }
}

export default visiteHistory;