import axios from "axios";

const url = "https://www.rad-ong.org/rad-dev/get-folder-content.php";

// const url = "http://localhost/test/get-folder-content.php";

function getAllContent(folder_name) {
    return (
        axios({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                getSubFolder: true,
                folder_name: folder_name,
            }
        })
    )
}

export default getAllContent;