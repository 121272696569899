import L from "leaflet";
import React from "react";
import { Map, TileLayer, Popup, Marker, LayersControl, Circle, LayerGroup, FeatureGroup, Rectangle } from "react-leaflet";
import Search from "react-leaflet-search";

import { geolocated } from 'react-geolocated';

import './style/style.css';
import './style/responsive.style.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const position = [6.435548, 2.351558]


class MapCmp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			search: null,
			maxZoom: 13,
			maxBounds: [
				[-90, -180],
				[90, 180]
			],
			bounds: [
				{
					lat: 33.100745405144245,
					lng: 24.510498046875
				},
			],
			longitude: -100,
			latitude: 48,
		
		}
		this.mapRef = React.createRef();
	}

	componentDidMount() {
		// this.setState({
		// 	longitude: this.props.coords.longitude,
		// 	latitude: this.props.coords.latitude,
		// });
		// if( this.props.current.raw ) {
		// 	this.setState({
		// 		search: this.props.current
		// 	});
		// }
	}


	customPopup(SearchInfo) {
		return (
			<Popup>
				<div>
					<p>Informations :</p>
					<p>
						latitude et longitude de la position :{" "}
						{SearchInfo.latLng.toString().replace(",", " , ")}
					</p>
					<p>Détails sur la position: {SearchInfo.info}</p>
					<p>
						{SearchInfo.raw &&
							SearchInfo.raw.place_id &&
							JSON.stringify(SearchInfo.raw.place_id)}
					</p>
				</div>
			</Popup>
		);
	}

	render() {
		// console.log( this.props.coords );
		return (
			<div className="MapCmp_container">
				{/* <Map
					className="simpleMap"
					scrollWheelZoom={false}
					zoom={16}
					// bounds={}
					style={{
						width: "100vw",
						height: "100vh",
					}}
					ref={this.mapRef}
					center={position}
					dragging={true}
					doubleClickZoom={false}
					attributionControl={false}
					zoomControl={false}
				>

					<LayersControl position="topright">
						<LayersControl.BaseLayer checked name="Carte normal">
							<TileLayer
								// attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
						</LayersControl.BaseLayer>
						<LayersControl.BaseLayer name="Carte en noir et blanc">
							<TileLayer
								// attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
							/>
						</LayersControl.BaseLayer>
					</LayersControl>
					<Marker position={position} >
						<Popup>
							RAD-ONG
						</Popup>
					</Marker>
				</Map> */}
				<div class="mapouter">
					<div class="gmap_canvas">
						<iframe
							id="gmap_canvas"
							src="https://maps.google.com/maps?q=6.435641,2.351711&t=&z=13&ie=UTF8&iwloc=&output=embed"
							frameborder="0" 
							scrolling="no"
							marginheight="0"
							marginwidth="0"
						>
						</iframe>
					</div>
				</div>
			</div>

		)
	}
}

// export default geolocated({
// 	positionOptions: {
// 		enableHighAccuracy: false
// 	},
// 	userDecisionTimeout: 10000
// })( MapCmp );
export default MapCmp;