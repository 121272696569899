import React from 'react';
import { connect } from 'react-redux';


/**Importation des composants de la page */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ImageSelector from '../components/imageSelector/imageSelector.component';


/** Importation des screens */
// Screen de la page d'accueil


/**Importation des styles de la page */
import './styles/dashboard.addProject.style.css';
import './styles/dashboard.addProject.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';


/**Importation des helpers */
import refreshConnection from '../helpers/refresh.helper';
// import radProject from '../helpers/project.helper';
import radEvent from '../helpers/event.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}

class DashboardAddEvent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ImageSelectedList: [],
            ImageSelectedPreviewLink: [],
            filesSelected: [],
            radExpertise: [],
            radODD: [],
            projectStatus: [],
            loading: false,

            uploadFiles: false,
            currentUploadProgression: 0,
            currentUploadMessage: '',

            title: '',
            name: '',
            shortDescription: '',
            ODDtags: [],
            EXPERTISEtags: [],
            startDate: '',
            endDate: '',
            status: 'en_attente',
            content: '',
            author: '',
        }

        
        // this.radProject = new radProject();
        this.radEvent = new radEvent();
        this._createEvent = this._createEvent.bind(this);
    }

    _setNewAccessToUser(callback) {
        refreshConnection(this.props.user.refresh).then((response) => {
            let newUser = this.props.user;
            newUser.access = response.data.access;

            if (response.data.refresh) {
                newUser.refresh = response.data.refresh;
            }

            // console.log( response, this.props.user, newUser );

            this.accessStore.setRedux('SET_USER', newUser, callback);
            // this._getRadExpertise();
        }).catch((e) => {
            if (e.response) {
                if (e.response.status === 401) {
                    this.accessStore.setRedux('LOG_OUT', {});
                }
            }
            if (e.toString() === "Error: Network Error") {
                alert("Aucun accès à internet :(");
            }
        });
    }

    componentDidMount() {
        
    }


    /** Pour creer un projet.
     * 
     * Ici on ajoute en premier les fichiers images du projet
     * Une fois que les fichiers ont ete bien envoyer on recupere la liste des noms de fichiers
     */

    _createEvent() {
        // console.log( this.state.ImageSelectedList );
        this.setState({
            loading: true,
        });
        if( window.confirm("Voulez-vous vraiment ajouter cet évènement?") === true ){
            let eventData = {
                "titre" : this.state.title,
                "nom" : this.state.name,
                "courte_description" : this.state.shortDescription,
                "description" : this.state.content,
                "date_debut" : this.state.startDate,
                "date_fin" : this.state.endDate,
                "statut" : this.state.status
            };
    
            this.radEvent._post( eventData, this.props.user.access ).then((response) => {
                alert("Évènement créer avec succès");
                this.setState({
                    loading: false,
                });
            }).catch((e) => {
                if( e.response ) {
                    // console.log( e.response.data );
                    if( e.response.status === 401 ) {
                        this._setNewAccessToUser( this._createEvent() );
                    }
                }
                this.setState({
                    loading: false,
                });
                if( e.toString() === "Error: Network Error" ) {
                    alert( "Aucun accès à internet :(" );
                }
            });;
        }
    }

    render() {
        return (
            <>
                <div className='add'>
                    <div className='add_head'>
                        <span>
                            Editez le contenu de votre évènement
                        </span>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_date'>
                            <div className='add_content_date_'>
                                <div className='add_content_label'>
                                    <span>
                                        Date de début
                                    </span>
                                </div>
                                <div className='add_content_input'>
                                    <input
                                        value={this.state.startDate}
                                        type='date'
                                        placeholder='Entrez la date de début ici'
                                        onChange={(e) => {
                                            this.setState({
                                                startDate: e.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='add_content_date_'>
                                <div className='add_content_label'>
                                    <span>
                                        Date de Fin
                                    </span>
                                </div>
                                <div className='add_content_input'>
                                    <input
                                        value={this.state.endDate}
                                        type='date'
                                        placeholder='Entrez la date de fin ici'
                                        onChange={(e) => {
                                            this.setState({
                                                endDate: e.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Titre de l'évènement
                            </span>
                        </div>
                        <div className='add_content_input'>
                            <input
                                value={this.state.title}
                                type='text'
                                placeholder="Entrez le titre de l'évènement ici"
                                onChange={(e) => {
                                    if( e.target.value.length >= 62 ) {
                                        e.target.style.border = "1px solid red";
                                    }
                                    else {
                                        e.target.style.border = "1px solid green";
                                    }
                                    this.setState({
                                        title: e.target.value
                                    })
                                }}
                            />
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Nom de l'évènement
                            </span>
                        </div>
                        <div className='add_content_input'>
                            <input
                                value={this.state.name}
                                type='text'
                                placeholder="Entrez le nom de l'évènement ici"
                                onChange={(e) => {
                                    if( e.target.value.length >= 62 ) {
                                        e.target.style.border = "1px solid red";
                                    }
                                    else {
                                        e.target.style.border = "1px solid green";
                                    }
                                    this.setState({
                                        name: e.target.value
                                    })
                                }}
                            />
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Statut de l'évènement
                            </span>
                        </div>
                        <div className='add_content_input'>
                            <select value={this.state.status} onChange={(e) => {
                                this.setState({
                                    status: e.target.value,
                                });
                            }}>
                                <option value={"en_attente"}>
                                    En attente
                                </option>
                                <option value={"a_venir"}>
                                    À venir
                                </option>
                                {/* {
                                    this.state.projectStatus.map((status, index) => (
                                        <option key={"status" + index} value={status.id}>
                                            {status.name}
                                        </option>
                                    ))
                                } */}
                            </select>
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Courte description de l'évènement
                                {/* Courte présentation du projet */}
                            </span>
                        </div>
                        <div className='add_content_editor2 radODD_editor'>
                            <CKEditor
                                editor={ClassicEditor}
                                data={this.state.de}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    // console.log( 'Editor is ready to use!', editor );
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // console.log( data );
                                    this.setState({
                                        shortDescription: data,
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Editez le contenu de votre évènement
                                {/* Courte présentation du projet */}
                            </span>
                        </div>
                        <div className='add_content_editor2 radODD_editor'>
                            <CKEditor
                                editor={ClassicEditor}
                                data={this.state.content}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    // console.log( 'Editor is ready to use!', editor );
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // console.log( data );
                                    this.setState({
                                        content: data,
                                    });
                                }}
                            />
                        </div>
                    </div>

                    {/* <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Domaines de compétences associés
                            </span>
                        </div>
                        <div className='add_content_checkBox'>
                            {
                                this.state.radExpertise.map((expertise, index) => (
                                    <label key={index} for={'dc' + index} className='add_content_checkBox_ competenceCheckbox'>
                                        <input id={'dc' + index} name={'dc' + index} type='checkbox'
                                            onChange={(e)=>{
                                                this.isTarget( e.target.checked, expertise, "expertise" );
                                            }}
                                        />
                                        <label for={'dc' + index}>
                                            {expertise.name}
                                        </label>
                                    </label>
                                ))
                            }
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Objectifs de développement associés
                            </span>
                        </div>
                        <div className='add_content_checkBox'>
                            {
                                this.state.radODD.map((odd, index) => (
                                    <label key={index} for={'odd' + index} className='add_content_checkBox_ oddCheckbox'>
                                        <input id={'odd' + index} name={'odd' + index} type='checkbox'
                                            onChange={(e)=>{
                                                this.isTarget( e.target.checked, odd, "odd" );
                                            }}
                                        />
                                        <label for={'odd' + index}>
                                            <img className='add_content_checkBox_odd' src={"/ODD/odd" + odd.numero + ".png"} alt={"ODD"} />
                                        </label>
                                    </label>
                                ))
                            }
                        </div>
                    </div> */}

                    {/* <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Editez le contenu de votre projet
                            </span>
                        </div>
                        <div className='add_content_editor'>
                            <CKEditor
                                editor={ClassicEditor}
                                data={this.state.content}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.setState({
                                        content: data,
                                    });
                                    // console.log({ event, editor, data });
                                }}
                            />
                        </div>
                    </div> */}

                    {/* <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Ajoutez des images
                            </span>
                        </div>
                        <div className='add_content_imageSelector'>
                            <ImageSelector
                                onChangeImage={async (e) => {
                                    const files = Array.from(e);

                                    let ImageSelectedList = this.state.ImageSelectedList;
                                    let filesSelected = this.state.filesSelected;
                                    filesSelected = filesSelected.concat(files);
                                    // console.log( filesSelected );

                                    files.map((file, index)=>{
                                        ImageSelectedList = ImageSelectedList.concat({
                                            file: file,
                                            description: '',
                                        });
                                    });

                                    // console.log( files );

                                    let tab = this.state.ImageSelectedPreviewLink;

                                    Promise.all(files.map(file => {
                                        return (
                                            new Promise((resolve, reject) => {
                                                const reader = new FileReader();
                                                reader.addEventListener('load', (ev) => {
                                                    resolve(ev.target.result);
                                                });
                                                reader.addEventListener('error', reject);
                                                reader.readAsDataURL(file);
                                            })
                                        )
                                    })).then(images => {
                                        tab = tab.concat(images);
                                        // console.log( tab );
                                        this.setState({
                                            filesSelected: filesSelected,
                                            ImageSelectedList: ImageSelectedList,
                                            ImageSelectedPreviewLink: tab,
                                        });
                                    });

                                }}
                            />
                        </div>

                        {
                            this.state.ImageSelectedPreviewLink.length > 0 &&
                            <div className='delete_all_image'>
                                <span className='delete_all_image_text' onClick={() => {
                                    this._deleteAllImages();
                                }}>
                                    <span>
                                        Supprimer toutes les images
                                    </span>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg>
                                </span>
                            </div>
                        }

                        <div className="previewImageSelected_container">
                            {
                                this.state.ImageSelectedPreviewLink.map((link, index) => (
                                    <div className='previewImageContainer'>
                                        <div className="previewImageSelected_">
                                            <svg onClick={() => {
                                                this._deleteImage(index);
                                            }} className='previewImageSelected_cross' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529" />
                                                <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529" />
                                            </svg>
                                            <img className="previewImageSelected" key={index} src={link} alt="RAD-ONG" />
                                        </div>
                                        <div className='imageDescription'>
                                            <input type="text" placeholder="Description de l'image ici" value={this.state.ImageSelectedList[index].description}
                                                onChange={(e) => {
                                                    let ImageSelectedList = this.state.ImageSelectedList;
                                                    ImageSelectedList[index].description = e.target.value;

                                                    this.setState({
                                                        ImageSelectedList: ImageSelectedList,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div> */}

                    <div className='add_button' onClick={() => {
                        this._createEvent( this.state.filesSelected );
                    }}>
                        <span>
                            Ajouter
                        </span>
                    </div>
                </div>

                    {
                        this.state.uploadFiles&&
                        <div className='addSection'>
                            <div className='addSection_ uploadProgressPlatform'>
                                <div className='addSection_title'>
                                    <span>
                                        Envois des fichiers en cours
                                    </span>
                                </div>

                                <div className='addSection_input'>
                                    <div className='progress'>
                                        <div className='progressBar'
                                            style={{
                                                width: this.state.currentUploadProgression + "%"
                                            }}
                                        >

                                        </div>
                                    </div>
                                </div>

                                <div className="progressText">
                                    <span>
                                        {
                                            this.state.currentUploadProgression + "%"
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                {
                    this.state.loading &&
                    <div className='loading'>
                        <img src={loader} alt='' />
                    </div>
                }
            </>
        )
    }
}


DashboardAddEvent = connect(mapStateToProps, null)(DashboardAddEvent);
export default DashboardAddEvent;