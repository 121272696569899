import React from 'react';
import { Link } from 'react-router-dom';

/**Importation des composants de la page */
import Card3 from '../components/card3/card3.component';

/**Importation des styles de la page */
import './styles/donate.style.css';
import './styles/donate.responsive.style.css';

/**Importation des images*/
import banniere from '../assets/images/banniere-faire-un-don.jpg';
// import img2 from '../assets/images/faire_un_don.png';
// import radLogo from '../assets/logos/rad-logo.png';

/**Importation des helpers */
import Xanimation from '../helpers/x-animations';

const animer = new Xanimation();
animer.init( 300 );
class DonateView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        // window.scrollTo(0, 0);
        window.onload = animer.start();
    }

    componentWillUnmount() {
        animer.stop();
    }

    render() {
        return (
            <>
                <div className="donateView">
                    <div className='banniere'>
                        <img src={banniere} alt="RAD-ONG"/>
                    </div>
                    <div className='donate_section1'>
                        <div className='donate_section1_title'>
                            <span>
                                SOUTENEZ NOS ACTIONS
                            </span>
                        </div>
                        <div className='donate_section1_subtitle'>
                            <span>
                                Votre générosité, la source de notre action humanitaire
                            </span>
                        </div>
                    </div>

                    <div className='donate_section2'>
                        <div className='donate_section2_logo'>
                            <span className='donate_section2_logo_title'>
                                RAD-ONG
                            </span>
                            <span className='donate_section2_logo_subTitle'>
                                Recherche Action Développement
                            </span>
                        </div>
                        {/* <div className='donate_section2_card_container'>
                            <div className='donate_section2_card'>
                                <div className='donate_section2_card_title'>
                                    <span>
                                        Donner pour les besoins les plus importants
                                    </span>
                                </div>
                                <div className='donate_section2_card_img'>
                                    <img src={img2} alt="RAD-ONG"/>
                                </div>
                                <div className='donate_section2_card_button'>
                                    <span>
                                        FAITES UN DON
                                    </span>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="donate_section3">
                        <div className="donate_section3_title">
                            <span>
                                Avec votre aide, nos actions peuvent avoir un plus grand impact
                            </span>
                        </div>
                        <div className="donate_section3_cardContainer">
                            <div className="donate_section3_card">
                                <Card3
                                    color="#019fcb"
                                    title="Pourquoi donner à la RAD-ONG ?"
                                    content="Parce que la RAD-ONG fait du bon travail...."/>
                            </div>

                            <div className="donate_section3_card">
                                <Card3
                                    color="#5dc269"
                                    title="Comment la RAD-ONG utilise-t-elle les dons ?"
                                    content="La RAD-ONG monte des actions humanitaires durables...."
                                />
                            </div>

                            <div className="donate_section3_card">
                                <Card3
                                    color="#963a86"
                                    title="Quel impact un don peut-il avoir sur le monde ?"
                                    content="Il peut sauver une vie..."
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "center"}}>
                        <button>
                            <a style={{
                                padding: "10px",
                                display: "block",
                                background: "#ffffff",
                                color: "green",
                                border: "1px solid #00000077"
                            }} target='blank' href="https://web.whatsapp.com/send?phone=+22997401112">Participer à nos actions</a>
                            
                        </button>
                    </div>
                    <br/>
                    <br/>
                </div>
            </>
        )
    }
}

export default DonateView;