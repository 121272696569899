import React from 'react';
import { connect } from 'react-redux';


/**Importation des composants de la page */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ImageSelector from '../components/imageSelector/imageSelector.component';


/** Importation des screens */
// Screen de la page d'accueil


/**Importation des styles de la page */
import './styles/dashboard.viewProject.style.css';
// import './styles/dashboard.addProject.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';


/**Importation des helpers */
import tools from '../helpers/tools.helper';
import refreshConnection from '../helpers/refresh.helper';
import radEvent from '../helpers/event.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}

class DashboardViewEvents extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            projects: [],

            isOpened: -1,
        }

        this.accessStore = new AccessStore(this.props.dispatch);
        this.tools = new tools();
        this.radEvent = new radEvent();

        this._getRadEvents = this._getRadEvents.bind( this );
    }

    _setNewAccessToUser(callback) {
        refreshConnection(this.props.user.refresh).then((response) => {
            let newUser = this.props.user;
            newUser.access = response.data.access;

            if (response.data.refresh) {
                newUser.refresh = response.data.refresh;
            }

            // console.log( response, this.props.user, newUser );

            this.accessStore.setRedux('SET_USER', newUser, callback);
            // this._getRadExpertise();
        }).catch((e) => {
            if (e.response) {
                if (e.response.status === 401) {
                    this.accessStore.setRedux('LOG_OUT', {});
                }
            }
            if (e.toString() === "Error: Network Error") {
                alert("Aucun accès à internet :(");
            }
        });
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });
        this._getRadEvents();
    }

    
    _getRadEvents( tab = [], page = 1 ) {
        this.setState({
            loading: true,
            projects: [],
        });

        this.radEvent._getEvents(false, this.props.user.access, page).then((response) => {
            tab = tab.concat( response.data.evenements );
            console.log( tab );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadEvents( tab, page + 1 );
            }

            else {
                this.setState({
                    projects: tab,
                    loading: false,
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this.componentDidMount );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }


    
    _changeProject( value, index, key ) {
        let projects = this.state.projects;
        projects[index][key] = value;
        this.setState({
            projects: projects,
        });
    }

    
    _deleteProject( projectIndex ) {
        let project = this.state.projects[ projectIndex ];
        this.setState({
            loading: true,
        });

        let confirm = window.confirm("Voulez-vous vraiment supprimer ce évènement?");

        if( confirm ) {
            this.radEvent._delete(project.id, this.props.user.access).then((response) => {
                this.setState({
                    loading: false,
                });
                this._getRadEvents();
                alert("Évènement supprimé avec succès");
            }).catch((e) => {
                if( e.response ) {
                    if( e.response.status === 401 ) {
                        this._setNewAccessToUser( this._deleteProject( projectIndex, true ) );
                    }

                    if( e.response.data ) {
                        // console.log( e.response.data );
                        if( e.response.data.non_field_errors ) {
                            
                        }
                    }
                }

                this.setState({
                    loading: false,
                });
                if( e.toString() === "Error: Network Error" ) {
                    alert( "Aucun accès à internet :(" );
                }
            });
        }

        else {
            this.setState({
                loading: false,
            });
        }
    }

    _UpdateProject(project, callback=()=>{}) {
        this.setState({
            loading: true,
        });
        this.radEvent._update(project.id, project, this.props.user.access).then((re) => {
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._UpdateProject(project, callback) );
                }
                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    
    _StartUpdateProject( projectIndex, retry=false ) {
        let project = this.state.projects[ projectIndex ];
        let confirm= window.confirm("Voulez-vous vraiment modifier cet Evenement?");
        if( confirm ){
            this.setState({
                loading: true,
            });
            this._UpdateProject( project , ()=>{
                alert( "Evenement modifié avec succès" );
                this.setState({
                    loading: false,
                });
                this._getRadEvents();
            });
        }
    }


    render() {
        return (
            <>
                <div className='add'>
                    <div className='add_head'>
                        <span>
                            Liste des évènements
                        </span>
                    </div>
                    <div className='ProjectsContainer'>
                    <div>
                                            {
                                                this.state.projects.map((project, index) => (
                                                    <div className='projects_' key={"rad-project--/--" + index}>
                                                        {
                                                          
                                                                <div>
                                                                    <div className='project-name'
                                                                        onClick={() => {
                                                                            if( this.state.isOpened === project.id ) {
                                                                                this.setState({
                                                                                    isOpened: -1,
                                                                                })
                                                                            }
                                                                            else {
                                                                                this.setState({
                                                                                    isOpened: project.id,
                                                                                })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {
                                                                                project.nom
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className='project-content-container'
                                                                        style={{
                                                                            height: this.state.isOpened === project.id ? "auto" : "0px"
                                                                        }}
                                                                    >
                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Titre du projet
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_input'>
                                                                                <input
                                                                                    value={project.titre}
                                                                                    type='text'
                                                                                    placeholder='Entrez le titre du projet ici'
                                                                                    onChange={(e) => {
                                                                                        this._changeProject(e.target.value, index, "titre");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content add_content_date'>
                                                                            <div className='add_content_date_'>
                                                                                <div className='add_content_label'>
                                                                                    <span>
                                                                                        Date de début
                                                                                    </span>
                                                                                </div>
                                                                                <div className='add_content_input'>
                                                                                    <input
                                                                                        value={project.date_debut !== null ? project.date_debut.split('T')[0] : ''}
                                                                                        type='date'
                                                                                        placeholder='Entrez la date de début ici'
                                                                                        onChange={(e) => {
                                                                                            this._changeProject(e.target.value, index, "date_debut");
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='add_content_date_'>
                                                                                <div className='add_content_label'>
                                                                                    <span>
                                                                                        Date de Fin
                                                                                    </span>
                                                                                </div>
                                                                                <div className='add_content_input'>
                                                                                    <input
                                                                                        value={project.date_fin !== null ? project.date_fin.split('T')[0] : ''}
                                                                                        type='date'
                                                                                        placeholder='Entrez la date de fin ici'
                                                                                        onChange={(e) => {
                                                                                            this._changeProject(e.target.value, index, "date_fin");
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Statut du projet
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_input'>
                                                                                <select value={project.statut} onChange={(e) => {
                                                                                    // console.log( project.statut );
                                                                                    this._changeProject(e.target.value, index, "statut");
                                                                                    // this._UpdateProject( index );
                                                                                }}>
                                                                                    <option value={"en_attente"}>
                                                                                        En attente
                                                                                    </option>
                                                                                    <option value={"a_venir"}>
                                                                                        À venir
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Courte description
                                                                                    {/* Courte présentation du projet */}
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_editor2 radODD_editor'>
                                                                                <CKEditor
                                                                                    editor={ClassicEditor}
                                                                                    data={project.courte_description}
                                                                                    onReady={editor => {
                                                                                        // You can store the "editor" and use when it is needed.
                                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                                    }}
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        this._changeProject(data, index, "courte_description");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Contenu de l'évènement
                                                                                    {/* Courte présentation du projet */}
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_editor2 radODD_editor'>
                                                                                <CKEditor
                                                                                    editor={ClassicEditor}
                                                                                    data={project.description}
                                                                                    onReady={editor => {
                                                                                        // You can store the "editor" and use when it is needed.
                                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                                    }}
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        this._changeProject(data, index, "description");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <div className='updateSocial_inputSubmit_container button-container'>
                                                                                <span className='updateSocial_inputSubmit'
                                                                                    onClick={() => {
                                                                                        this._StartUpdateProject( index );
                                                                                        return true;
                                                                                    }}
                                                                                >
                                                                                    Modifier l'évènement
                                                                                </span>
                                                                                <span className='updateSocial_inputBack'
                                                                                    onClick={() => {
                                                                                        this._deleteProject( index );
                                                                                        return true;
                                                                                    }}
                                                                                >
                                                                                    Supprimer l'évènement
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                        }
                                                        
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        
                    </div>
                </div>

                    

                {
                    this.state.loading &&
                    <div className='loading'>
                        <img src={loader} alt='' />
                    </div>
                }
            </>
        )
    }
}


DashboardViewEvents = connect(mapStateToProps, null)(DashboardViewEvents);
export default DashboardViewEvents;