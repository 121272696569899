import React from 'react';

/**Importation des composants de la page */

/**Importation des styles de la page */
import './styles/knowHow.style.css';
// import './styles/KnowHowView.responsive.style.css';

/**Importation des images*/
// import conpetenceBack from '../assets/images/research.svg';
import logo from '../assets/logos/rad-logo.png';
import logo2 from '../assets/logos/rad-logo2.png';

/**Importation des helpers */
import Xanimation from '../helpers/x-animations';


// import video1 from '../assets/videos/Into The Nature - Cinematic Travel Video _ Sony a6300.webm';
// import video2 from "../assets/videos/LiSA Live performance Demon Slayer 'Gurenge'.webm";

const animer = new Xanimation();
animer.init( 300 );
class Graph extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
        
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <>
                <div className="grapp">
                    <div className="grapp_title">
                        <h1>
                            Logos
                        </h1>
                    </div>

                    <div className="grapp_imgs">
                        <div>
                            <img src={logo} alt='RAD-ONG'/>
                            <span>Logo 1</span>
                        </div>

                        <div>
                            <img src={logo2} alt='RAD-ONG'/>
                            <span>Logo 2</span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Graph;