import axios from "axios";
import URL2 from "./url2.helper";

class radDataAttr {
    _getAll(_public, access, page = 0) {
        if (_public) {
            return (
                axios({
                    method: 'get',
                    url: (page > 1) ? URL2 + "api/cld/data-attrs/?page=" + page : URL2 + "api/cld/data-attrs/",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
            )
        } else {
            return (
                    axios({
                        method: 'get',
                        url: (page > 1) ? URL2 + "api/cld/data-attrs/?page=" + page : URL2 + "api/cld/data-attrs/",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    })
                )
                // return (
                //     axios({
                //         method: 'get',
                //         url: (page > 1) ? URL2 + "api/cld/admin/data-attr/?page=" + page : URL2 + "api/cld/admin/data-attr/",
                //         headers: {
                //             'Content-Type': 'application/json',
                //             "Authorization": "Bearer " + access
                //         },
                //     })
                // )
        }
    }

    _getOne(search, page = 0) {
        return (
            axios({
                method: 'get',
                url: (page > 1) ? URL2 + "api/cld/data-attrs/?search=" + search + "&page=" + page : URL2 + "api/cld/data-attrs/?search=" + search,
            })
        )

    }

    _update(id, data, access) {
        return (
            axios({
                method: 'put',
                url: URL2 + "api/cld/admin/data-attr/" + id + "/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _post(data, access) {
        return (
            axios({
                method: 'post',
                url: URL2 + "api/cld/admin/data-attr/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _delete(id, access) {
        return (
            axios({
                method: 'delete',
                url: URL2 + "api/cld/admin/data-attr/" + id,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }
}

export default radDataAttr;