import React from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

/**Importation des composants de la page */
import Card2 from '../components/card2/card2.component';

/**Importation des styles de la page */
import './styles/aboutUs.style.css';
import './styles/aboutUs.responsive.style.css';

/**Importation des images*/
// import radLogo from '../assets/logos/rad-logo.png';
// import team from "../assets/images/images_for_test/team.jpg";
import personal from "../assets/icons/user.jpg";
// import dg from "../assets/images/images_for_test/kinha.jpg";
import loader from '../assets/gif/loader2.gif';

/**Importation des helpers */
import radDataClass from '../helpers/data-class.helper';
import radDataAttr from '../helpers/data-attr.helper';
import radData from '../helpers/data.helper';
import imagesURL from '../helpers/imagesURL.helper';
import Xanimation from '../helpers/x-animations';

const animer = new Xanimation();
animer.init( 100 );

class AboutUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            membersGroup: [],
            posts: [],
            pageInfo: {
                classname: {},
                title1: {value: ""},
                title2: {value: ""},
                text: {value: ""},
                image1: {value: ""},
                image2: {value: ""},
            },
        }

        this._onScrollPage = this._onScrollPage.bind( this );
        this._searchDataClass = this._searchDataClass.bind( this );

        this.radDataClass = new radDataClass();
        this.radData = new radData();
        this.radDataAttr = new radDataAttr();
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });
        this._start_search_data();
        this._search_page_infos();

        window.onload = animer.start();
        // window.scrollTo(0, 0);
    }


    _start_search_data() {
        this._searchDataClass( "member-group", ( membersGroup ) => {
            this._getDataAttr( "poste", ( posteAttrs)=> {
                // console.log( posteAttrs );
                let groups = membersGroup;
                groups.map((group, index) => {
                    group.postes = [];
                    
                    this._searchData( group.name, (postes)=>{

                        postes.map((poste, index) => {
                            if( poste.classname === group.id ) {
                                let newPoste = poste;

                                group.postes = group.postes.concat( newPoste );
                                
                                posteAttrs.map((attr, attrIndex) => {
                                    if( attr.data === poste.id ) {
                                        if( attr.label === "name" ) {
                                            newPoste.memberName = attr;
                                        }
                                        else if( attr.label === "image" ) {
                                            newPoste.memberImage = attr;
                                        }
                                    }
                                });
                                
                                this.setState({
                                    membersGroup: groups,
                                });
                                // console.log( groups );
                            }
                        });
                    })
                });
                this.setState({
                    loading: false,
                });
                return true;
                });
        });
    }

    _search_page_infos() {
        this.setState({
            loading: true,
        });
        this._searchDataClass( "about-us", ( classes ) => {
            classes.map((classe, index) => {
                if( classe.name === "about-us" ) {
                    let pageInfo = {
                        classname: classe,
                        title1: {},
                        title2: {},
                        text: {},
                        image1: {},
                        image2: {},
                    };

                    this._searchData( classe.name, (infos)=>{

                        infos.map((info, info_index) => {
                            
                            if( info.classname === classe.id ) {

                                this._getDataAttr( info.name, (infoValues)=> {
                                    infoValues.map((infoValue, index) => {
                                        if( infoValue.name === "about-us-title1-value" ) {
                                            pageInfo.title1 = infoValue;
                                        }else if( infoValue.name === "about-us-title2-value" ){
                                            pageInfo.title2 = infoValue;
                                        }else if( infoValue.name === "about-us-text-value" ){
                                            pageInfo.text = infoValue;
                                        }else if( infoValue.name === "about-us-image1-value" ){
                                            pageInfo.image1 = infoValue;
                                        }else if( infoValue.name === "about-us-image2-value" ){
                                            pageInfo.image2 = infoValue;
                                        }
                                    });

                                    if( info_index === infos.length - 1 ) {
                                        this.setState({
                                            pageInfo: pageInfo,
                                            loading: false,
                                        });
                                    }
                                });
                            }
                        });
                    });
                }
            });
        });
    }

    _getDataAttr( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            // console.log( response.data );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getDataAttr( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchData( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radData._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchData( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchDataClass( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataClass._getOne(search, page).then((response) => {
            response.data.results.map((result, index)=>{
                if( result.label === search ) {
                    tab = tab.concat( result );
                }
            });
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchDataClass( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    componentWillUnmount() {
        animer.stop();
    }


    _onScrollPage( e ) {
        // console.log( window.pageYOffset + window.innerHeight - 100,  page_element1_ref.current.offsetTop );
        // if( window.pageYOffset + window.innerHeight - 100 >= page_element1_ref.current.offsetTop ) {
        //     this._translateFromBottom( page_element1_ref.current );
        // }
    }


    render() {
        return (
            <>
                <div className="AboutUs">
                    <div className="AboutUs_section1">
                        {
                            this.state.pageInfo.title1.value?
                                <div className="AboutUs_section1_title  titleStyle3">
                                    <span>{parse(this.state.pageInfo.title1.value)}</span>
                                </div>
                            :
                                null
                        }
                       
                        {
                            this.state.pageInfo.title2.value ?
                                <div className="AboutUs_section1_subtitle">
                                    {/* <span>TOUT SUR <span className="color_primary2 AboutUs_section1_subtitle_">RAD-ONG</span></span> */}
                                    <span>
                                        { parse(this.state.pageInfo.title2.value)}
                                    </span>
                                </div>
                            :
                                null
                        }
                        <div className="AboutUs_section1_contactUs">
                            <Link variant="contained" to="/Nous-contacter">
                                <span className="background_primary2">
                                    CONTACTEZ-NOUS
                                </span>
                            </Link>
                        </div>
                    </div>
                    <div className="AboutUs_section2">
                        <div
                            className="AboutUs_section2_left x-animation"
                            x-animation="from-left"
                            x-animation-delay="0s"
                            x-animation-duration="2s"
                        >
                            {/* <div className="AboutUs_section2_left_title">
                                <span>
                                    L’EQUIPE DERRIERE <span className="inlineText">LA RAD-ONG</span>
                                </span>
                            </div>
                            <div className="AboutUs_section2_left_text">
                                <p>
                                    Nous sommes une communauté, nous partageons la même 
                                    vision et les mêmes valeurs, et nous sommes toujours 
                                    prêts à relever de nouveaux défis.
                                </p>
                            </div> */}
                            {
                                this.state.pageInfo.text.value?
                                    <div className="AboutUs_section2_left_text">
                                        { parse(this.state.pageInfo.text.value) }
                                    </div>
                                :
                                    null
                            }
                            <div className="AboutUs_section2_left_logo">
                                {
                                    this.state.pageInfo.image2.value && this.state.pageInfo.image2.value !== ""&&
                                    <img className="AboutUs_section2_left_logo" src={imagesURL + this.state.pageInfo.image2.value} alt="RAD-ONG"/>
                                }
                                {/* <img src={radLogo} alt="RAD-ONG" className="AboutUs_section2_left_logo"/> */}
                            </div>
                        </div>
                        <div className="AboutUs_section2_right x-animation"
                            x-animation="from-right"
                            x-animation-delay="0s"
                            x-animation-duration="2s"
                        >
                            <div className="AboutUs_section2_right_image">
                                {
                                    this.state.pageInfo.image1.value && this.state.pageInfo.image1.value !== ""&&
                                    <img className="AboutUs_section2_right_image_" src={imagesURL + this.state.pageInfo.image1.value} alt="RAD-ONG"/>
                                }
                                {/* <img src={team} alt="RAD-ONG team" className="AboutUs_section2_right_image_"/> */}
                            </div>
                        </div>
                    </div>

                    <div className="AboutUs_section3" id="notre-equipe">
                        {
                            this.state.membersGroup.slice(0).reverse().map((group, index) => (
                                <>
                                    <div className="AboutUs_section3_title x-animation"
                                        x-animation="from-bottom"
                                        x-animation-delay="0s"
                                        x-animation-duration="2s"
                                    >
                                        <div className="AboutUs_section3_title_line lineL"></div>
                                        <div className="AboutUs_section2_left_title AboutUs_section3_title_text title">
                                            <span>{group.name}</span>
                                        </div>
                                        <div className="AboutUs_section3_title_line lineR"></div>
                                    </div>
                                    <div className="AboutUs_section3_body">
                                    {
                                        group.postes.map((poste, index) => (
                                            
                                            <div className="AboutUs_section3_body_card x-animation"
                                                x-animation="from-bottom"
                                                x-animation-delay="0s"
                                                x-animation-duration="2s"
                                            >
                                                <Card2
                                                    image={poste.memberImage && poste.memberImage.value ? imagesURL + "/upload/" + poste.memberImage.value : personal}
                                                    title={ poste.memberName && poste.memberName.name ? parse(poste.memberName.name) : ""}
                                                    subtitle={parse(poste.name)}
                                                    description={poste.memberName && poste.memberName.value ? parse(poste.memberName.value) : ""}
                                                />
                                            </div>
                                            
                                        ))
                                    }
                                    </div>
                                </>
                            ))
                        }
                    </div>
                    {
                        this.state.loading &&
                        <div className='loading'>
                            <img src={loader} alt='' />
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default AboutUs;