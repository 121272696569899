import axios from "axios";
import URL from "./Url.helper";

class radHomePortefolio {
    _getAll() {
        return (
            axios({
                method: 'get',
                url: URL + "api/data/radHomePortefolio/",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    _getOne(id) {
        return (
            axios({
                method: 'get',
                url: URL + "api/data/radHomePortefolio/" + id,
            })
        )
    }

    _update(id, data) {
        return (
            axios({
                method: 'put',
                url: URL + "api/data/radHomePortefolio/" + id + "/",
                data: data,
            })
        )
    }

    _post(id, data) {
        return (
            axios({
                method: 'post',
                url: URL + "api/data/radHomePortefolio/" + id + "/",
                data: data,
            })
        )
    }

    _delete(id) {
        return (
            axios({
                method: 'delete',
                url: URL + "api/data/radHomePortefolio/" + id,
            })
        )
    }
}

export default radHomePortefolio;