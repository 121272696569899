import axios from "axios";
import URL2 from "./url2.helper";

class radUser {
    _login(data) {
        return (
            axios({
                method: 'post',
                url: URL2 + "api/auth/",
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            })
        )
    }

    // _logout() {
    //     return (
    //         axios({
    //             method: 'get',
    //             url: URL + "api/data/radEmail/",
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         })
    //     )
    // }

    // _registration() {
    //     return (
    //         axios({
    //             method: 'get',
    //             url: URL + "api/data/radEmail/",
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         })
    //     )
    // }
}

export default radUser;