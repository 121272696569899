import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

/**Importation des composants de la page */
import ImageSlider1 from '../components/imageSlider1/imageSlider1.component';
import SliderComponent from '../components/slider/slider.component';
import ActualityCard from '../components/actualityCard/actualityCard.component';

/**Importation des styles de la page */
import './styles/homeView.style.css';
import './styles/homeView.responsive.style.css';

/**Importation des images*/

import radLogo from '../assets/logos/rad-logo.png';
import radPDG from '../assets/images/pdg.jpg';


/**Importation des helpers */
import Xanimation from '../helpers/x-animations';
import radHomePage from '../helpers/radHomePage.helper';
import radPartnaires from '../helpers/radPartnaires.helper';
import radSections from '../helpers/radSections.helper';
import radTables from '../helpers/radTables.helper';
import radHomePortefolio from '../helpers/radHomePortefolio.helper';
import radExpertise from '../helpers/expertise.helper';
import radODD from '../helpers/ODD.helper';
import tools from '../helpers/tools.helper';

import radProject from '../helpers/project.helper';
import radArticle from '../helpers/article.helper';
import radDataAttr from '../helpers/data-attr.helper';
import imagesURL from '../helpers/imagesURL.helper';

import radDataClass from '../helpers/data-class.helper';
import radData from '../helpers/data.helper';


const animer = new Xanimation();
animer.init( 300 );
class HomeView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageContent: [],
            partnaires: [],
            radSections: [],
            radTables: [],
            portefolioImageList: [],
            radExpertise: [],
            loading: false,
            articles: [],
            welcomeTexte: {},
            welcomeTextData: {},
        }

        this.radHomePage = new radHomePage();
        this.radPartnaires = new radPartnaires();
        this.radSections = new radSections();
        this.radTables = new radTables();
        this.radHomePortefolio = new radHomePortefolio();
        this.radExpertise = new radExpertise();
        this.radODD = new radODD();
        this.tools = new tools();

        this.radProject = new radProject();
        this.radArticle = new radArticle();

        this.radDataClass = new radDataClass();
        this.radData = new radData();
        this.radDataAttr = new radDataAttr();
    }

    _returnDataPars( data ) {
        let dataParse = data;
        for( var i=0; i< dataParse.length; i++ ) {
            dataParse[i].text = eval(dataParse[i].text);
        }
        return dataParse;
    }

    _getDataAttr( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            // console.log( response.data );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getDataAttr( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchData( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radData._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchData( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchDataClass( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataClass._getOne(search, page).then((response) => {
            response.data.results.map((result, index)=>{
                if( result.label === search ) {
                    tab = tab.concat( result );
                }
            });
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchDataClass( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    componentDidMount() {
        // window.scrollTo(0, 0);
        window.onload = animer.start();

        this._searchDataClass( "home_page", ( home_page_class ) => {
            
            this._getDataAttr( "portefolio", ( home_page_portefolio_attrs)=> {

                this._getDataAttr( "patnaires", ( home_page_patnaires_attrs)=> {
                    // console.log(home_page_patnaires_attrs);

                    this._getDataAttr( "tab_cf", ( home_page_tab_attrs)=> {
                        // console.log(home_page_tab_attrs);

                        this._getDataAttr( "sections", ( home_page_sections_attrs )=> {

                            this._getDataAttr( "title1", ( home_page_title1_attrs )=> {
                                this._getDataAttr( "text1", ( home_page_text1_attrs )=> {
                                    this._getDataAttr( "subtitle1", ( home_page_subtitle1_attrs )=> {
                                        
                                        this._getDataAttr( "title2", ( home_page_title2_attrs )=> {
                                            this._getDataAttr( "text2", ( home_page_text2_attrs )=> {
                                                this._getDataAttr( "subtitle2", ( home_page_subtitle2_attrs )=> {
                                                    this._getDataAttr( "wellcom_texte", ( home_page_wellcom_texte_attrs)=> {
                                                    // console.log(home_page_text1_attrs);

                                                    let home_page_class_ = home_page_class;
                                                    home_page_class_.map((HP, index) => {
                                                        
                                                        this._searchData( HP.name, (_data_)=>{
                                                            // console.log(_data_);
                                                            let portefolioImageList_ = [];
                                                            let partnaires_ = {};
                                                            let radTables_ = {};
                                                            let radSections_ = {}
                                                            let welcomeTexte_ = {};

                                                            let title1_ = {};
                                                            // let text1_ = {};
                                                            // let subtitle1_ = {};

                                                            // let title2_ = {};
                                                            // let text2_ = {};
                                                            // let subtitle2_ = {};
                                    
                                                            _data_.map((data, index) => {
                                                                // console.log(data.classname === HP.id);
                                                                if( data.classname === HP.id ) {
                                                                    let portefolio_data = {};
                                                                    let patnaires_data = {};
                                                                    let tab_data = {};
                                                                    let sections_data = {};
                                                                    let welcomeTexte_data = {};

                                                                    let title1_data = {};
                                                                    // let text1_data = {};
                                                                    // let subtitle1_data = {};

                                                                    // let title2_data = {};
                                                                    // let text2_data = {};
                                                                    // let subtitle2_data = {};

                                                                    home_page_portefolio_attrs.map((attr, attrIndex) => {
                                                                        if( attr.data === data.id ) {
                                                                            portefolio_data = data;
                                                                            portefolioImageList_= attr;
                                                                            portefolioImageList_.value = portefolioImageList_.value==="" ? [] : JSON.parse(portefolioImageList_.value);

                                                                            this.setState({
                                                                                portefolio_data: portefolio_data,
                                                                            });
                                                                        }
                                                                    });

                                                                    home_page_patnaires_attrs.map((attr, attrIndex) => {
                                                                        if( attr.data === data.id ) {
                                                                            patnaires_data = data;
                                                                            partnaires_= attr;
                                                                            partnaires_.value = partnaires_.value==="" ? [] : JSON.parse(partnaires_.value);

                                                                            this.setState({
                                                                                patnaires_data: patnaires_data,
                                                                            });
                                                                        }
                                                                    });

                                                                    home_page_tab_attrs.map((attr, attrIndex) => {
                                                                        console.log(attr);
                                                                        if( attr.data === data.id ) {
                                                                            tab_data = data;
                                                                            radTables_= attr;
                                                                            radTables_.value = radTables_.value==="" ? [] : JSON.parse(radTables_.value);

                                                                            this.setState({
                                                                                tab_data: tab_data,
                                                                            });
                                                                        }
                                                                    });

                                                                    home_page_sections_attrs.map((attr, attrIndex) => {
                                                                        if( attr.data === data.id ) {
                                                                            sections_data = data;
                                                                            radSections_= attr;
                                                                            radSections_.value = radSections_.value==="" ? [] : JSON.parse(radSections_.value);

                                                                            this.setState({
                                                                                sections_data: sections_data,
                                                                            });
                                                                        }
                                                                    });

                                                                    home_page_wellcom_texte_attrs.map((attr, attrIndex) => {
                                                                        if( attr.data === data.id) {
                                                                            welcomeTexte_data = data;
                                                                            // console.log(welcomeTexte_data);
                                                                            welcomeTexte_ = attr;
                                                                            this.setState({
                                                                                welcomeTexte: welcomeTexte_,
                                                                                welcomeTextData: welcomeTexte_data,
                                                                            });
                                                                        }
                                                                    });

                                                                    /** Text */
                                                                    home_page_title1_attrs.map((attr, attrIndex) => {
                                                                        if( attr.data === data.id && attr.name==="title1") {
                                                                            title1_data = data;
                                                                            title1_ = attr;
                                                                            this.setState({
                                                                                title1_data: title1_data,
                                                                            });
                                                                        }
                                                                    });
                                                                }

                                                                if(index === _data_.length-1) {
                                                                    console.log(radTables_);
                                                                    this.setState({
                                                                        home_page_class: home_page_class[0],
                                                                        portefolioImageList: portefolioImageList_,
                                                                        partnaires: partnaires_,
                                                                        radTables: radTables_,
                                                                        radSections: radSections_,
                                                                        title1: title1_,
                                                                    });
                                                                }
                                                            });
                                                        });
                                                    });
                                                });
                                            });
                                            });    
                                        });
                                    
                                    });
                                });    
                            });
                            this.setState({
                                loading: false,
                            });
                            return true;
                        });
                    });
                });
            });
        });

        this._getRadExpertise();

        /**Liste des actualités */
        this._getRadArticles((articles)=>{
            if(articles.length>0) {
                this._getRadODD(() => {
                    let articlesList = this.state.articles;
                    this._startGetProjectFolder(articles, 0, articlesList, (list) => {
                        let listN = this.tools._sortByDate(list, "created_at");
                        // console.log( listN )
                        let newArticleList = [];
    
                        listN.map((art, index) => {
                            if( art.status === 2 ) {
                                newArticleList.push( art );
                                // console.log(art);
                            }
                        });
                        listN.map((art, index) => {
                            if( art.status !== 2 ) {
                                newArticleList.push( art );
                            }
                            // if( index === listN.length - 1) {
                            //     console.log( newArticleList );
                            // }
                        })
                        // console.log( newArticleList );
                        this.setState({
                            loading: false,
                            articles: newArticleList,
                        });
                    })
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        });

        /**Liste des elemenets de la page */
        // this.radHomePage._getAll().then((response) => {
        //     this.setState({
        //         pageContent: response.data.radhomepages
        //     }, () => {
        //         this.state.pageContent.map((content, index) => {
        //             this._getValueOfPageContent(content.id).then((result) => {
        //                 this.setState({
        //                     [content.id]: result[0]
        //                 });
        //             });
        //         });
        //     });

        //     /**Liste des partenaire de RAD */
        //     this.radPartnaires._getAll().then((response) => {
        //         // console.log( response.data );
        //         this.setState({
        //             partnaires: response.data.radpartnairess,
        //         });

        //         /**Contenues de chaque sections de la page */
        //         this.radSections._getAll().then((response) => {
        //             this.setState({
        //                 radSections: response.data.radsectionss,
        //             });

        //             /**Liste des tableaux */
        //             this.radTables._getAll().then((response) => {
        //                 this.setState({
        //                     loading: false,
        //                     radTables: response.data.radtabless,
        //                 });

        //                 /**Liste des elements du portefolio */
        //                 this._getRadHomePortefolio();
        //                 /**Liste des domaines d'expertises de rad */
                        
                    
        //             }).catch((error) => {
        //                 this.setState({
        //                     loading: false,
        //                 });
        //                 console.log( error );
        //             });
        //         }).catch((error) => {
        //             this.setState({
        //                 loading: false,
        //             });
        //             console.log( error );
        //         });
        //     }).catch((error) => {
        //         console.log( error );
        //     });
        // }).catch((error) => {
        //     console.log( error );
        // });

    }

    _startGetProjectFolder(articleList, index, articlesList, callback) {
        let newArticle = {
            name: articleList[index].name,
            title: articleList[index].title,
            shortDescription: parse(articleList[index].desc),
            content: parse(articleList[index].content.replace(/<p>&nbsp;<\/p>/g, '<br/>')),
            oddIDs: articleList[index].odds,
            tagIDs: articleList[index].tags,
            oddList: [],
            galerie: [],
            cover: "",
            cover2: "", 
            start_date: articleList[index].start_date,
            end_date: articleList[index].end_date,
            competencesDomaine: [],
            status: articleList[index].status,
            article: articleList[index].article,
            refs: articleList[index].refs,
            created_at: articleList[index].created_at,
        }

        // console.log( newArticle );
        // console.log( index );

        this.state.radODD.map((odd) => {
            if(  articleList[index].odds.includes(odd.id) ) {
                newArticle.oddList = newArticle.oddList.concat( odd.logo );
            }
        });
        
        this.state.radExpertise.map((expertise) => {
            if(  articleList[index].tags.includes(expertise.id) ) {
                newArticle.competencesDomaine = newArticle.competencesDomaine.concat( expertise.name );
            }
        });
    
        this._getProjectFolder( articleList[index].name, ( articleFolder )=>{
            
            newArticle.cover = articleFolder.length > 0 ? imagesURL + "upload/" + articleFolder[0][0] : "";
            newArticle.cover2 = articleFolder.length > 0 ? imagesURL + "upload/" + articleFolder[0][0] : "";
            newArticle.imagesList = articleFolder;
        
            for( let k=0; k<articleFolder.length; k ++) {
                articleFolder[k] = {
                    src: imagesURL + "upload/" + articleFolder[k][0],
                    src2: imagesURL + "upload/" + articleFolder[k][0],
                    text: articleFolder[k][1]
                };
            }
        
            newArticle.galerie = articleFolder;
            articlesList = articlesList.concat( newArticle );
    
            if( index === articleList.length - 1 ) {
                callback(articlesList);
            }
            else {
                // console.log( index + 1 );
                this._startGetProjectFolder(articleList, index + 1, articlesList, callback);
            }
        });
    }

    _getProjectFolder( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            // console.log( response );
            response.data.results.map((expertise, index) => {
                tab.push( expertise );
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getProjectFolder( search, callback, tab, page + 1 );
            }

            else {
                if( tab.length > 0 ){
                    tab.map((element, index) => {
                        // console.log( element.name );
                        // console.log( element.title, "----", search );
                        if( element.name === search || element.label === search ) {
                            
                            let folder = JSON.parse( element.value );
                            for( let j=0; j<folder.length; j++ ) {
                                folder[j] = folder[j].split("*****");
                            }
                            return callback( folder );
                        }
                    });
                }
                else {
                    return callback([]);
                }
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadODD( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            radODD: [],
        });
        this.radODD._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadODD( callback, tab, page + 1 );
            }

            else {
                this.setState({
                    radODD: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    callback();
                });
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadArticles( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            loading: true,
            projects: [],
        });

        this.radArticle._getAll(true, null, page).then((response) => {
            // console.log( response.data );
            tab = tab.concat( response.data.results );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadArticles( callback,tab, page + 1 );
            }

            else {
                callback( this.tools._sortByDate(tab, 'created_at') );
            }
        }).catch((e) => {
            
            if( e.response ) {
                if( e.response.status === 401 ) {
                    
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadHomePortefolio() {
        this.radHomePortefolio._getAll().then((response) => {
            // console.log( response );
            let dataParse = this._returnDataPars( response.data.radhomeportefolios );
            this.setState({
                loading: false,
                portefolioImageList: dataParse,
            });
        }).catch((error) => {
            this.setState({
                loading: false,
            });
            console.log( error );
        });
    }

    _getValueOfPageContent( value ) {
        return (
            Promise.all( this.state.pageContent.filter( obj  => {
                if ( obj.id === value ) {
                    return true;
                } else {
                    return false;
                }
            }))
        )
    }

    _getRadExpertise() {
        this.setState({
            radExpertise: [],
        });
        this.radExpertise._getAll(true).then((response) => {
            // console.log( response );
            let expertiseList = [];
            response.data.results.map((expertise, index) => {
                expertiseList.push( expertise );
            });
            this.setState({
                radExpertise: this.tools._sortByDate(expertiseList, 'created_at'),
                loading: false,
            });
        }).catch((e) => {
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    componentWillUnmount() {
        animer.stop();
    }

    render() {
        return (
            <>
                <div className="homeView">
                    <ImageSlider1
                        delay="5000"
                        imageList={this.state.portefolioImageList.value ? this.state.portefolioImageList.value : []}
                    />
                    {
                        this.state.articles.length > 0 &&
                            <div className="shortDescriptionOfRad_right_domaine">
                                <div className="titleStyle1 title"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="2s"
                                >
                                    <span>Notre actualité</span>
                                    <span className="underline"></span>
                                </div>
                            </div>
                    }
                    
                    <div className="ActuView_more">
                        {
                            this.state.articles.map((project, index) => (
                                index < 3 &&
                                <div key={index} className="ActuView_more_card x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="2s"
                                >
                                    <ActualityCard project={project} />
                                </div>
                            ))
                        }
                    </div>

                    <div className="shortDescriptionOfRad">
                        <div className="shortDescriptionOfRad_left">
                            {
                                this.state.radSections.value && this.state.radSections.value.map((section, index) => (
                                    <div className="card x-animation"
                                        x-animation="from-bottom"
                                        x-animation-delay="0s"
                                        x-animation-duration="2s"
                                        key={"section" + index}
                                    >
                                        <div className="card_left">
                                        
                                        </div>
                                        <div className="card_right">
                                            <div className="card_title">
                                                <h2>
                                                    {
                                                        parse(section.name)
                                                    }
                                                </h2>
                                            </div>
                                            <div className="card_text text-sizes-1 textForm">
                                                {
                                                    parse(section.text)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <div
                            className="shortDescriptionOfRad_right x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="2s"
                        >
                            <div className="titleStyle3 title">
                                <span>
                                    {
                                        ( (this.state.title1) && (this.state.title1.value || this.state.title1.value ==='') )&&
                                            parse( this.state.title1.value )
                                    }
                                </span>
                            </div>
                            <div className="titleStyle2 title">
                                <p>
                                    {
                                        ( (this.state.subtitle1) && (this.state.subtitle1.value || this.state.subtitle1.value ==='') )&&
                                            parse(this.state.subtitle1.value)
                                    }
                                </p>
                            </div>
                            <div className="shortDescriptionOfRad_right_text textForm">
                               Bienvenu
                            </div>
                            <div className="shortDescriptionOfRad_right_logo">
                                <img
                                    className="shortDescriptionOfRad_right_logo_" style={{borderRadius: "5px"}}
                                    src={radPDG}
                                    alt="RAD-ONG"
                                />
                            </div>
                            <div className="titleStyle2 title">
                                {
                                    ( (this.state.subtitle2) && (this.state.subtitle2.value || this.state.subtitle2.value ==='') )&&
                                        parse(this.state.subtitle2.value)
                                }
                            </div>
                            <div className="shortDescriptionOfRad_right_text textForm">
                                {
                                    ( (this.state.welcomeTexte) && (this.state.welcomeTexte.value || this.state.welcomeTexte.value ==='') )&&
                                        parse( this.state.welcomeTexte.value )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="shortDescriptionOfRad_right_domaine">
                        <div className="titleStyle1 title x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="2s"
                        >
                            <span>Nos domaines de compétence</span>
                            <span className="underline"></span>
                        </div>

                        {
                            this.state.radExpertise.map((expertise, index) => (
                                <div className="shortDescriptionOfRad_right_domaine_ x-animation"
                                    x-animation="from-left"
                                    x-animation-delay="0s"
                                    x-animation-duration="2s"
                                    key={"expertise" + index}
                                >
                                    <Link className="link_menu" variant="contained" to={"/Domaine-de-competences:?competence=" + expertise.name}>
                                        <div className="shortDescriptionOfRad_right_domaine_one">
                                            <span>{expertise.name}</span>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        }
                        
                        {/* <div className="shortDescriptionOfRad_right_domaine_ x-animation"
                            x-animation="from-left"
                            x-animation-delay="0s"
                            x-animation-duration="2s"
                        >
                            <Link className="link_menu" variant="contained" to="/Domaine-de-competences:?competence=Accompagnement">
                                <div className="shortDescriptionOfRad_right_domaine_one">
                                    <span>Accompagnement</span>
                                </div>
                            </Link>
                        </div>
                        <div className="shortDescriptionOfRad_right_domaine_ x-animation"
                            x-animation="from-left"
                            x-animation-delay="0s"
                            x-animation-duration="2s"
                        >
                            <Link className="link_menu" variant="contained" to="/Domaine-de-competences:?competence=Conseil">
                                <div className="shortDescriptionOfRad_right_domaine_one">
                                    <span>Conseil</span>
                                </div>
                            </Link>
                        </div>
                        <div className="shortDescriptionOfRad_right_domaine_ x-animation"
                            x-animation="from-left"
                            x-animation-delay="0s"
                            x-animation-duration="2s"
                        >
                            <Link className="link_menu" variant="contained" to="/Domaine-de-competences:?competence=Accès aux marchés">
                                <div className="shortDescriptionOfRad_right_domaine_one">
                                    <span>Accès aux marchés</span>
                                </div>
                            </Link>
                        </div>
                        <div className="shortDescriptionOfRad_right_domaine_ x-animation"
                            x-animation="from-left"
                            x-animation-delay="0s"
                            x-animation-duration="2s"
                        >
                            <Link className="link_menu" variant="contained" to="/Domaine-de-competences:?competence=Accès aux financements">
                                <div className="shortDescriptionOfRad_right_domaine_one">
                                    <span>Accès aux financements</span>
                                </div>
                            </Link>
                        </div> */}
                    </div>

                    <div className="rad_partnaires x-animation" 
                        x-animation="from-bottom"
                        x-animation-delay="0s"
                        x-animation-duration="2s"                    
                    >
                        <div className="rad_partnaires_title titleStyle1 title">
                            <span>
                                Nos partenaires
                            </span>
                            <span className="underline"></span>
                        </div>
                        <div className="text-sizes-1">
                            <SliderComponent delay={30000} viewList={this.state.partnaires.value ? this.state.partnaires.value : []} />
                        </div>
                    </div>


                    <div className="rad_number x-animation"
                        x-animation="from-bottom"
                        x-animation-delay="0s"
                        x-animation-duration="2s" 
                    >
                        <div className="rad_number_title titleStyle1 title">
                            <span>
                                Nos chiffres en bref
                            </span>
                            <span className="underline"></span>
                        </div>

                        <div className='tabContainer'>
                            {
                                this.state.radTables.value && this.state.radTables.value.slice(0).reverse().map((tab, index) => (
                                    <div className='tabContainer_' key={"radTable" + index}>
                                        <div className='tabContainer_name title'>
                                            <span>
                                                { parse(tab.tabName) }
                                            </span>
                                        </div>
                                        <div className="tabStyle">
                                            {parse(tab.tabData)}
                                        </div>
                                        
                                        {/* <div className='bottomLine_green'></div> */}
                                    </div>
                                ))
                            }
                        </div>
                        {/* <div className="rad_number_ca title">
                            <span>
                                Coopératives accompagnées
                            </span>
                        </div>
                        <div className="rad_number_table">
                            <table className='tb'>
                                <thead>
                                    <tr>
                                        <th className='tb'>
                                            <div className='tb_'>
                                                <img alt='' src={soja_logo} />
                                                <span className='tb_span'>SOJA</span>
                                            </div>
                                        </th>
                                        <th className='tb'>
                                            <div className='tb_'>
                                                <img alt='' src={logo_ananas} />
                                                <span className='tb_span'>ANANAS</span>
                                            </div>
                                        </th>
                                        <th className='tb'>
                                            <div className='tb_'>
                                                <img alt='' src={logo_karite} />
                                                <span className='tb_span'>KARITÉ</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='tb'>
                                            <div>1000</div>
                                        </td>
                                        <td className='tb'>
                                            <div>1000</div>
                                        </td>
                                        <td className='tb'>
                                            <div>1000</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="rad_number_ca title">
                            <span>
                                PME
                            </span>
                        </div>
                        <div className="rad_number_table">
                            <table className='tb'>
                                <tbody>
                                    <tr>
                                        <td className='tb'>
                                            <div>1000</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="rad_number_ca title">
                            <span>
                                Crédits facilités
                            </span>
                        </div>
                        <div className="rad_number_table">
                            <table className='tb'>
                                <thead>
                                    <tr>
                                        <th className='tb'>
                                            <div>2019</div>
                                        </th>
                                        <th className='tb'>
                                            <div>2020</div>
                                        </th>
                                        <th className='tb'>
                                            <div>2021</div>
                                        </th>
                                        <th className='tb'>
                                            <div>2022</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='tb'>
                                            <div>1000</div>
                                        </td>
                                        <td className='tb'>
                                            <div>1000</div>
                                        </td>
                                        <td className='tb'>
                                            <div>1000</div>
                                        </td>
                                        <td className='tb'>
                                            <div>1000</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
                    </div>

                </div>
            </>
        )
    }
}

export default HomeView;