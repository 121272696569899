import React from 'react';

/**Importation des composants de la page */
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import faker from 'faker';

/** Importation des screens */


/**Importation des styles de la page */
import './styles/dashboard.home.style.css';
import './styles/dashboard.home.responsive.style.css';

/**Importation des images*/
// import pageView from '../assets/icons/paveView.png';


/**Importation des helpers */
import visiteHistory from '../helpers/visiteHistory.helper';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

  
//   const labels = ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021'];
  
//   const data = {
//     labels,
//     datasets: [
//       {
//         label: '',
//         data: labels.map(() => faker.datatype.number({ min: 20, max: 23428 })),
//         borderColor: '#5dc269',
//         backgroundColor: '#5dc269',
//       },
//     ],
//   };

class DashboardHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            history: {
                today: 0,
                this_week: 0,
                this_month: 0,
                this_year: 0,
                all: 0,
                data: [],
                dayData: {
                    labels: [],
                    data: [],
                },
                monthData: {
                    labels: [],
                    data: [],
                },
                yearData: {
                    labels: [],
                    data: [],
                },
            },
        }

        this.visiteHistory = new visiteHistory();
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this._getHistory();
    }

    _getHistory() {
        this.visiteHistory._getAll().then((response) => {
            // console.log( response );
            let data = response.data.history.sort();
            let newData = {
                day: [],
                month: [],
                year: [],
                week: [],
            };

            data.map((item, index) => {
                if( item !== false && index === 0) {
                    let lt = item.split("--/--");
                    let li_d = lt[0].split("-");
                    let nlt = {
                        date: {
                            day: li_d[2],
                            month: li_d[1],
                            year: li_d[0],
                            time: lt[1],
                            number_week: lt[2],
                        },
                        type: lt[3]
                    };

                    let day = {
                        label: lt[0],
                        number: 1,
                    };

                    let month = {
                        label: nlt.date.year + "-" + nlt.date.month,
                        number: 1,
                    };

                    let year = {
                        label: nlt.date.year,
                        number: 1,
                    };

                    let week = {
                        label: nlt.date.year + "-" + nlt.date.month + "/" + nlt.date.number_week ,
                        number: 1,
                    }

                    newData.day = newData.day.concat( day );
                    newData.month = newData.month.concat( month );
                    newData.week = newData.week.concat( week );
                    newData.year = newData.year.concat( year );
                }

                if( item !== false && index > 0) {
                    let lt = item.split("--/--");
                    let li_d = lt[0].split("-");
                    let nlt = {
                        date: {
                            day: li_d[2],
                            month: li_d[1],
                            year: li_d[0],
                            time: lt[1],
                            number_week: lt[2],
                        },
                        type: lt[3]
                    };

                    let day = {
                        label: lt[0],
                        number: 1,
                    };

                    let month = {
                        label: nlt.date.year + "-" + nlt.date.month,
                        number: 1,
                    };

                    let year = {
                        label: nlt.date.year,
                        number: 1,
                    };

                    let week = {
                        label: nlt.date.year + "-" + nlt.date.month + "/" + nlt.date.number_week ,
                        number: 1,
                    }

                    if( newData.day[newData.day.length - 1].label === day.label ) {
                        newData.day[newData.day.length - 1].number = newData.day[newData.day.length - 1].number + 1;
                    }else {
                        newData.day = newData.day.concat( day );
                    }

                    if( newData.month[newData.month.length - 1].label === month.label ) {
                        newData.month[newData.month.length - 1].number = newData.month[newData.month.length - 1].number + 1;
                    }else {
                        newData.month = newData.month.concat( month );
                    }

                    if( newData.week[newData.week.length - 1].label === week.label ) {
                        newData.week[newData.week.length - 1].number = newData.week[newData.week.length - 1].number + 1;
                    }else {
                        newData.week = newData.week.concat( week );
                    }

                    if( newData.year[newData.year.length - 1].label === year.label ) {
                        newData.year[newData.year.length - 1].number = newData.year[newData.year.length - 1].number + 1;
                    }else {
                        newData.year = newData.year.concat( day );
                    }
                    // newData.month = newData.month.concat( month );
                    // newData.week = newData.week.concat( week );
                    // newData.year = newData.year.concat( year );
                }
            });

            let history = {
                today: 0,
                this_week: 0,
                this_month: 0,
                this_year: 0,
                all: 0,
                data: newData,
                dayData: {
                    labels: [],
                    data: [],
                },
                monthData: {
                    labels: [],
                    data: [],
                },
                yearData: {
                    labels: [],
                    data: [],
                },
            };

            newData.day.map((day, index) => {
                // console.log( response );
                if( Number(day.label.split("-")[2]) === Number(response.data.current_day) ) {
                    history.today = day.number;
                }
            });

            newData.day.slice(0).reverse().map((day, index) => {
                if( index <= 7 ) {
                    // console.log( day );
                    history.dayData.labels = [day.label, ...history.dayData.labels];
                    history.dayData.data = [day.number, ...history.dayData.data];
                }
            });

            newData.month.map((month, index) => {
                if( Number(month.label.split("-")[1]) === Number(response.data.current_month) ) {
                    history.this_month = month.number;
                }
                history.monthData.labels.push(month.label);
                history.monthData.data.push(month.number);
            });

            newData.week.map((week, index) => {
                if( Number(week.label.split("/")[1]) === Number(response.data.current_week) ) {
                    history.this_week = week.number;
                }
            });

            newData.year.map((year, index) => {
                if( Number(year.label) === Number(response.data.current_year) ) {
                    history.this_year = year.number;
                }
                history.yearData.labels.push( "20" + year.label);
                history.yearData.data.push(year.number);
            });

            history.all = response.data.history.length - 1;

            this.setState({
                history: history,
            });
            // console.log( newData );
        })
    }

    render() {
        return (
            <>
                <div className='dashboardHome'>
                    <div className='dashboardHome_title title'>
                        <span>
                            Nombres de visites sur RAD-ONG :
                        </span>
                    </div>

                    <div className='dashboardHome_contentCard'>
                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        Aujourd'hui
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.history.today}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        Cette semaine
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.history.this_week}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        Ce mois ci
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.history.this_month}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        cette année
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.history.this_year}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='dashboardHome_content_card'>
                            <div className='cardCircle'>
                                <div className='cardCircle_'>
                                    <div className='cardCircle_p'></div>
                                </div>
                            </div>
                            <div className='dashboardHome_content_card_'>
                                <div className='dashboardHome_content_card_title'>
                                    <span>
                                        En tout
                                    </span>
                                </div>

                                <div className='dashboardHome_content_card_number'>
                                    <span>
                                        {this.state.history.all}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='dashboardHome_contentChart'>
                        <Line options={{
                            responsive: true,
                            tension : 0.3,
                            plugins: {
                            legend: {
                                position: '0',
                            },
                            title: {
                                display: true,
                                text: 'Visites sur les 7 derniers jours',
                            },
                            },
                        }} data={{
                            labels: this.state.history.dayData.labels,
                            datasets: [
                                {
                                    label: '',
                                    data: this.state.history.dayData.data,
                                    borderColor: '#5dc269',
                                    backgroundColor: '#5dc269',
                                },
                            ],
                        }} />
                    </div>
                    <br/>
                    <div className='dashboardHome_contentChart'>
                        <Line options={{
                            responsive: true,
                            tension : 0.3,
                            plugins: {
                            legend: {
                                position: '0',
                            },
                            title: {
                                display: true,
                                text: 'Visites sur les différents mois',
                            },
                            },
                        }} data={{
                            labels: this.state.history.monthData.labels,
                            datasets: [
                                {
                                    label: '',
                                    data: this.state.history.monthData.data,
                                    borderColor: '#5dc269',
                                    backgroundColor: '#5dc269',
                                },
                            ],
                        }} />
                    </div>
                    <br/>
                    <div className='dashboardHome_contentChart'>
                        <Line options={{
                            responsive: true,
                            tension : 0.3,
                            plugins: {
                            legend: {
                                position: '0',
                            },
                            title: {
                                display: true,
                                text: 'Visites sur les différentes années',
                            },
                            },
                        }} data={{
                            labels: this.state.history.yearData.labels,
                            datasets: [
                                {
                                    label: '',
                                    data: this.state.history.yearData.data,
                                    borderColor: '#5dc269',
                                    backgroundColor: '#5dc269',
                                },
                            ],
                        }} />
                    </div>
                </div>
            </>
        )
    }
}

export default DashboardHome;