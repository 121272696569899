import React from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

/**Importation des composants de la page */
import ActualityCard from '../components/actualityCard/actualityCard.component';
import PageSliderComponent from '../components/pageSlider.component/pageSlider.component';

/**Importation des styles de la page */
import './styles/actuView.style.css';
import './styles/actuView.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';

/**Importation des helpers */
import Xanimation from '../helpers/x-animations';
import radProject from '../helpers/project.helper';
import radArticle from '../helpers/article.helper';
import radODD from '../helpers/ODD.helper';
import radExpertise from '../helpers/expertise.helper';
import tools from '../helpers/tools.helper';
import radDataAttr from '../helpers/data-attr.helper';
import imagesURL from '../helpers/imagesURL.helper';


const animer = new Xanimation();
animer.init( 50 );


class ActuView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            currentPage: 1,
            viewPageSlider: false,
        }

        this.radProject = new radProject();
        this.radArticle = new radArticle();
        this.radODD = new radODD();
        this.radExpertise = new radExpertise();
        this.tools = new tools();
        this.radDataAttr = new radDataAttr();


        this._getRadArticles = this._getRadArticles.bind( this );
        this._getRadODD = this._getRadODD.bind( this );
        this._getRadExpertise = this._getRadExpertise.bind( this );
    }

    componentDidMount() {
        // console.log( this.props.match.params.testvalue );
        // window.scrollTo(0, 0);
        window.onload = animer.start();

        let page = window.location.href.split("?page=").length > 1 ? window.location.href.split("?page=")[1] : 1;

        // console.log(page);

        this.setState({
            loading: true,
        });

        this._getRadArticles((articles)=>{
            if(articles.length>0) {
                this._getRadODD(() => {
                    this._getRadExpertise(() => {
                        // console.log( articles );
                        
                        let articlesList = this.tools._sortByDate(this.state.articles, "created_at");;
                        this._startGetProjectFolder(articles, 0, articlesList, (list) => {
                            let listN = this.tools._sortByDate(list, "created_at");
                            // console.log( listN )
                            let newArticleList = [];
    
                            listN.map((art, index) => {
                                if( art.status === 2 ) {
                                    newArticleList.push( art );
                                    // console.log(art);
                                }
                            });
                            listN.map((art, index) => {
                                if( art.status !== 2 ) {
                                    newArticleList.push( art );
                                }
                                // if( index === listN.length - 1) {
                                //     console.log( newArticleList );
                                // }
                            })
                            // console.log( newArticleList );
                            this.setState({
                                loading: false,
                                articles: newArticleList,
                                currentPage: page,
                                viewPageSlider: true,
                            });
                        })

                        this.setState({
                            loading: false,
                        });
                    });
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        });
    }

    _changeCurrentPage(page) {
        this.setState({
            currentPage: page,
        });
    }

    _startGetProjectFolder(articleList, index, articlesList, callback) {
        let newArticle = {
            name: articleList[index].name,
            title: articleList[index].title,
            shortDescription: parse(articleList[index].desc),
            content: parse(articleList[index].content.replace(/<p>&nbsp;<\/p>/g, '<br/>')),
            oddIDs: articleList[index].odds,
            tagIDs: articleList[index].tags,
            oddList: [],
            galerie: [],
            cover: "",
            cover2: "", 
            start_date: articleList[index].start_date,
            end_date: articleList[index].end_date,
            competencesDomaine: [],
            status: articleList[index].status,
            article: articleList[index].article,
            refs: articleList[index].refs,
            created_at: articleList[index].created_at,
        }

        // console.log( newArticle );
        // console.log( index );

        this.state.radODD.map((odd) => {
            if(  articleList[index].odds.includes(odd.id) ) {
                newArticle.oddList = newArticle.oddList.concat( odd.logo );
            }
        });
        
        this.state.radExpertise.map((expertise) => {
            if(  articleList[index].tags.includes(expertise.id) ) {
                newArticle.competencesDomaine = newArticle.competencesDomaine.concat( expertise.name );
            }
        });
    
        this._getProjectFolder( articleList[index].name, ( articleFolder )=>{
            
            newArticle.cover = articleFolder.length > 0 ? imagesURL + "upload/" + articleFolder[0][0] : "";
            newArticle.cover2 = articleFolder.length > 0 ? imagesURL + "upload/" + articleFolder[0][0] : "";
            newArticle.imagesList = articleFolder;
        
            for( let k=0; k<articleFolder.length; k ++) {
                articleFolder[k] = {
                    src: imagesURL + "upload/" + articleFolder[k][0],
                    src2: imagesURL + "upload/" + articleFolder[k][0],
                    text: articleFolder[k][1]
                };
            }
        
            newArticle.galerie = articleFolder;
            articlesList = articlesList.concat( newArticle );
    
            if( index === articleList.length - 1 ) {
                callback(articlesList);
            }
            else {
                // console.log( index + 1 );
                this._startGetProjectFolder(articleList, index + 1, articlesList, callback);
            }
        });
    }

    _getProjectFolder( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            // console.log( response );
            response.data.results.map((expertise, index) => {
                tab.push( expertise );
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getProjectFolder( search, callback, tab, page + 1 );
            }

            else {
                if( tab.length > 0 ){
                    tab.map((element, index) => {
                        // console.log( element.name );
                        // console.log( element.title, "----", search );
                        if( element.name === search || element.label === search ) {
                            
                            let folder = JSON.parse( element.value );
                            for( let j=0; j<folder.length; j++ ) {
                                folder[j] = folder[j].split("*****");
                            }
                            return callback( folder );
                        }
                    });
                }
                else {
                    return callback([]);
                }
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadExpertise( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            radExpertise: [],
        });
        this.radExpertise._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadExpertise( callback, tab, page + 1 );
            }

            else {
                this.setState({
                    radExpertise: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    callback();
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadODD( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            radODD: [],
        });
        this.radODD._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadODD( callback, tab, page + 1 );
            }

            else {
                this.setState({
                    radODD: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    callback();
                });
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadArticles( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            loading: true,
            projects: [],
        });

        this.radArticle._getAll(true, null, page).then((response) => {
            // console.log( response.data );
            tab = tab.concat( response.data.results );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadArticles( callback,tab, page + 1 );
            }

            else {
                callback( this.tools._sortByDate(tab, 'created_at') );
            }
        }).catch((e) => {
            
            if( e.response ) {
                if( e.response.status === 401 ) {
                    
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    componentWillUnmount() {
        animer.stop();
    }

    render() {
        return (
            <>
                <div className="ActuView">
                    {
                        this.state.articles.length > 0 &&
                        <div className="ActuView_current">
                            <div className="ActuView_current_left">
                                <img
                                    className="ActuView_current_left_img"
                                    src={this.state.articles[0].cover}
                                    alt="RAD-ONG, A la une"
                                    onError={(e) => {
                                        if( e.target.alt === "RAD-ONG, A la une"){
                                            e.target.alt = "RAD-ONG, A la une-";
                                            e.target.src = this.state.articles[0].cover2;
                                        }
                                    }}
                                />
                            </div>
                            <div className="ActuView_current_right">
                                <div className="ActuView_current_right_">
                                    <div className="ActuView_current_right_title">
                                        <span className="title">
                                            {
                                                this.state.articles[0].title
                                            }
                                        </span>
                                    </div>

                                    <div className="ActuView_current_right_body">
                                        <span className="">
                                            {
                                                this.state.articles[0].shortDescription
                                            }
                                        </span>
                                    </div>
                                    
                                    <Link to={"/Actualité:?article=" + this.state.articles[0].title}>
                                    <div className="ActuView_current_right_viewMore">
                                        <span>Voir plus</span>
                                    </div>
                                    </Link>

                                    <div className="ActuView_current_right_date">
                                        <span>
                                            {
                                                new Date( this.state.articles[0].created_at ).getDate() + " / " + ( new Date( this.state.articles[0].created_at ).getMonth() + 1 ) + " / " + new Date( this.state.articles[0].created_at ).getFullYear()
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="ActuView_more" id='allView'>
                        {
                            this.state.articles.map((project, index) => (
                                ( (index < this.state.currentPage*6) && (index >= ((this.state.currentPage-1) *6)) ) &&
                                <div key={index} className="ActuView_more_card x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="2s"
                                >
                                    <ActualityCard project={project} />
                                </div>
                            ))
                        }
                    </div>

                    <div className='pageSlider_container'
                        x-animation="from-bottom"
                        x-animation-delay="0ms"
                        x-animation-duration={"1s"}
                    >
                        {
                            this.state.viewPageSlider &&
                                <PageSliderComponent
                                    current={this.state.currentPage}
                                    limit={10}
                                    total={this.state.articles.length/6}
                                    link={"#"}
                                    action={(page) => {
                                        this._changeCurrentPage(page);
                                    }}
                                />
                        }
                    </div>


                    {
                        this.state.loading &&
                        <div className='loading'>
                            <img src={loader} alt='' />
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default ActuView;