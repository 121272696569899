import React from 'react';
import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import {Link} from 'react-router-dom';

/**Importation des composants de la page */



/**Importation des styles de la page */
import './styles/dashboard.menu.style.css';
import './styles/dashboard.menu.responsive.style.css';

/**Importation des images*/
import radLogo from '../assets/logos/rad-logo.png';


class DashboardMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rad: "",
            slug: "",
            currentItemOfMenu: 0,
        }

        this.rad = "RAD-ONG";
        this.slug = "Au cœur de l'agribusiness"; 

        this.timeInterval1 = null;
        this.timeInterval2 = null;

        this._writeRade = this._writeRade.bind( this );
        this._writeSlug = this._writeSlug.bind( this );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._writeRade();
    }

    _writeRade() {
        var i = 0;
        this.timeInterval1 = setInterval(() => {
            if( i < this.rad.length ){
                this.setState({
                    rad: this.state.rad + this.rad[i]
                })
            }

            else {
                clearInterval( this.timeInterval1 );
                this._writeSlug();
            }

            i ++;
        }, 70); 
    }

    _writeSlug() {
        var i = 0;
        this.timeInterval2 = setInterval(() => {
            if( i < this.slug.length ){
                this.setState({
                    slug: this.state.slug + this.slug[i]
                })
            }

            else {
                clearInterval( this.timeInterval2 );
            }

            i ++;
        }, 70); 
    }

    _clickMenuItem( index ) {
        this.setState({
            currentItemOfMenu: index,
        });
    }

    render() {
        return (
            <>
               <div className='dashboardMenu'>
                    <div>
                        <Link className="dashboardMenu_icon" variant="contained" to="/dashboard"
                            onClick={() => {
                                this._clickMenuItem(0);
                            }}
                        >
                            <div className="dashboardMenu_icon_"></div>
                            <div className="dashboardMenu_icon_text">
                                <div className="dashboardMenu_icon_text_name">
                                    <span>
                                        { this.state.rad }
                                    </span>
                                </div>
                                <div className="dashboardMenu_icon_text_slug">
                                    <span>
                                        { this.state.slug }
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className='dashboardMenu_itemContainer'>
                        <Link className="dashboard_link_menu" variant="contained" to="/dashboard/general"
                            onClick={() => {
                                this._clickMenuItem(1);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Informations générales
                                </span>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 1 ? "100%" : "0px"
                            }}></div>
                        </Link>
                        <Link className="dashboard_link_menu" variant="contained" to="/dashboard/rubrique"
                            onClick={() => {
                                this._clickMenuItem(2);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Rubriques
                                </span>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 2 ? "100%" : "0px"
                            }}></div>
                        </Link>
                        <Link className="dashboard_link_menu" variant="contained" to="/dashboard/view-projects"
                            onClick={() => {
                                this._clickMenuItem(8);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Liste des projets
                                </span>
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 8 ? "100%" : "0px"
                            }}></div>
                        </Link>
                        <Link className="dashboard_link_menu" variant="contained" to="/dashboard/add-project"
                            onClick={() => {
                                this._clickMenuItem(7);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Ajouter un projet
                                </span>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 7 ? "100%" : "0px"
                            }}></div>
                        </Link>
                        <Link className="dashboard_link_menu" variant="contained" to="/dashboard/view-actualities"
                            onClick={() => {
                                this._clickMenuItem(12);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Liste des actualités
                                </span>
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 12 ? "100%" : "0px"
                            }}></div>
                        </Link>
                        <Link className="dashboard_link_menu" variant="contained" to="/dashboard/add-actuality"
                            onClick={() => {
                                this._clickMenuItem(11);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Ajouter une actualité
                                </span>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 11 ? "100%" : "0px"
                            }}></div>
                        </Link>
                        <Link className="dashboard_link_menu" variant="contained" to="/dashboard/expertise"
                            onClick={() => {
                                this._clickMenuItem(5);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Domaines de compétence
                                </span>
                                {/* <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg> */}
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 5 ? "100%" : "0px"
                            }}></div>
                        </Link>
                        <Link className="dashboard_link_menu" variant="contained" to="/dashboard/odd"
                            onClick={() => {
                                this._clickMenuItem(6);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Liste des ODD
                                </span>
                                {/* <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg> */}
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 6 ? "100%" : "0px"
                            }}></div>
                        </Link>

                        <Link className="dashboard_link_menu" variant="contained" to="/dashboard/add-event"
                            onClick={() => {
                                this._clickMenuItem(9);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Ajouter un évènement
                                </span>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 9 ? "100%" : "0px"
                            }}></div>
                        </Link>
                        <Link className="dashboard_link_menu" variant="contained" to="/dashboard/list-event"
                            onClick={() => {
                                this._clickMenuItem(10);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Liste des évènements
                                </span>
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 10 ? "100%" : "0px"
                            }}></div>
                        </Link>
                        
                        {/* <Link className="dashboard_link_menu" variant="contained" to="/dashboard/add-activity"
                            onClick={() => {
                                this._clickMenuItem(9);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Publier une activité
                                </span>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 9 ? "100%" : "0px"
                            }}></div>
                        </Link> */}
                        {/* <Link className="dashboard_link_menu" variant="contained" to="/dashboard/add-project"
                            onClick={() => {
                                this._clickMenuItem(10);
                            }}
                        >
                            <div className='dashboardMenu_item'>
                                <span>
                                    Liste des activités
                                </span>
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-list-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </div>
                            <div className='dashboardMenu_item_line' style={{
                                width: this.state.currentItemOfMenu === 10 ? "100%" : "0px"
                            }}></div>
                        </Link> */}
                    </div>
               </div>
            </>
        )
    }
}

export default DashboardMenu;