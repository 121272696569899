import axios from "axios";
import URL2 from "./url2.helper";

class radODD {
    _getAll(_public, access, page = 0) {
        if (_public) {
            return (
                axios({
                    method: 'get',
                    url: (page > 1) ? URL2 + "api/odds/?page=" + page : URL2 + "api/odds/",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
            )
        } else {
            return (
                    axios({
                        method: 'get',
                        url: (page > 1) ? URL2 + "api/odds/?page=" + page : URL2 + "api/odds/",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    })
                )
                // return (
                //     axios({
                //         method: 'get',
                //         url: (page > 1) ? URL2 + "api/admin/odd/?page=" + page : URL2 + "api/admin/odd/",
                //         headers: {
                //             'Content-Type': 'application/json',
                //             "Authorization": "Bearer " + access
                //         },
                //     })
                // )
        }
    }

    _getOne(id, access) {
        return (
            axios({
                method: 'get',
                url: URL2 + "api/admin/odd/" + id,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _update(id, data, access) {
        return (
            axios({
                method: 'put',
                url: URL2 + "api/admin/odd/" + id,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _post(data) {
        return (
            axios({
                method: 'post',
                url: URL2 + "api/admin/odd/",
                data: data,
            })
        )
    }

    _delete(id) {
        return (
            axios({
                method: 'delete',
                url: URL2 + "api/admin/odd/" + id,
            })
        )
    }
}

export default radODD;