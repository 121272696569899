/**Importation des actions du store */
import SET_ODD_LIST from '../store_actions/globalActions/setODD.action';
import SET_EXPERTISE_LIST from '../store_actions/globalActions/setExpertise.action'

const initialState = {
    oddList: [],
    expertiseList: [],
}

function Global(state = initialState, action) {
    switch (action.type) {
        case 'SET_ODD_LIST':
            return SET_ODD_LIST(state, action.value);

        case 'SET_EXPERTISE_LIST':
            return SET_EXPERTISE_LIST(state, action.value);

        default:
            return state
    }

}

export default Global;