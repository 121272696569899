import React from 'react';
import parse from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';

/**Importation des composants de la page */
import ProjectCard from '../components/projectCard/projectCard.component';

/**Importation des styles de la page */
import './styles/projectView.style.css';
import './styles/projectView.responsive.style.css';

/**Importation des images*/
import globe from '../assets/icons/globe2.png';
import prociva from '../assets/images/images_for_test/prociva2021/more/Picture1.png';
import loader from '../assets/gif/loader2.gif';


/**Importation des helpers */
import Xanimation from '../helpers/x-animations';
import radProject from '../helpers/project.helper';
import radODD from '../helpers/ODD.helper';
import radExpertise from '../helpers/expertise.helper';
import tools from '../helpers/tools.helper';
import radDataAttr from '../helpers/data-attr.helper';
import imagesURL from '../helpers/imagesURL.helper';


const animer = new Xanimation();
animer.init( 100 );


class ProjectsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            projectsStatus: [],
            projects: [],
            radODD: [],
            radExpertise: [],
            projectsList: [],
            projectsList2: [
                {
                    name: "ProCIVA 2018",
                    shortDescription: "Dans le cadre de la mise en œuvre du volet de promotion des PME du secteur agro-alimentaire, par l’initiative d’innovations managériales, notamment le SME Business Training and Coaching Loop, le ProCIVA accompagne la mise en œuvre des innovations par les PME. ",
                    oddList: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,17,16,17],
                    cover: prociva,
                    competencesDomaine: [
                        "Renforcement de capacités",
                        "Accompagnement",
                        "Conseil",
                    ]
                }
            ]
        }

        this.radProject = new radProject();
        this.radODD = new radODD();
        this.radExpertise = new radExpertise();
        this.tools = new tools();
        this.radDataAttr = new radDataAttr();

        this._getRadProjects = this._getRadProjects.bind( this );
        this._getProjectsStatus = this._getProjectsStatus.bind( this );
        this._getRadODD = this._getRadODD.bind( this );
    }

    componentDidMount() {
        // window.scrollTo(0, 0);
        window.onload = animer.start();

        this.setState({
            loading: true,
        });
        this._getProjectsStatus();
    }

    componentWillUnmount() {
        animer.stop();
    }

    _getProjectFolder( search, callback = ()=>{}, tab = [], page = 1 ) {
        // this.setState({
        //     radExpertise: [],
        // });
        this.radDataAttr._getOne(search, page).then((response) => {
            response.data.results.map((expertise, index) => {
                if( expertise.logo == null || expertise.logo === '' || expertise.logo === undefined) {
                    tab.push( expertise );
                }
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getProjectFolder( search, callback, tab, page + 1 );
            }

            else {
                // console.log( tab, search );
                if( tab.length > 0 ){
                    tab.map((element, index) => {
                        if( element.name === search ||  element.label === search) {
                            let folder = JSON.parse( element.value );
                            for( let j=0; j<folder.length; j++ ) {
                                folder[j] = folder[j].split("*****");
                            }
                            callback( folder );
                        }
                    });
                }
                else {
                    callback([]);
                }
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadExpertise( tab = [], page = 1 ) {
        this.setState({
            radExpertise: [],
        });
        this.radExpertise._getAll(true, null, page).then((response) => {
            response.data.results.map((expertise, index) => {
                if( expertise.logo == null || expertise.logo === '' || expertise.logo === undefined) {
                    tab.push( expertise );
                }
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadExpertise( tab, page + 1 );
            }

            else {
                this.setState({
                    radExpertise: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    this._startGetProjectFolder(this.state.projects, 0, [], (newProjectsList) => {
                        this.setState({
                            projectsList: newProjectsList,
                        }, () => {
                            this.setState({
                                loading: false,
                            });
                        });
                    });
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _startGetProjectFolder(projects, index, newProjectsList, callback) {
        let newProject = {
            name: projects[index].name,
            shortDescription: parse(projects[index].desc),
            oddIDs: projects[index].odds,
            tagIDs: projects[index].tags,
            oddList: [],
            cover: "",
            imagesList: [],
            competencesDomaine: [],
            status: projects[index].status,
        }
        
        this.state.radODD.map((odd) => {
            if(  projects[index].odds.includes(odd.id) ) {
                newProject.oddList = newProject.oddList.concat( odd.logo );
            }
        });

        // console.log(projects[index]);
        // console.log(this.state.radExpertise);
        this.state.radExpertise.map((expertise) => {
            // console.log(projects[index].tags);
            if(  projects[index].tags.includes(expertise.id) ) {
                // console.log(newProject);
                newProject.competencesDomaine = newProject.competencesDomaine.concat( expertise.name );
            }
        });

        // console.log( projects[index] );
        this._getProjectFolder( projects[index].name, ( projectFolder )=>{
            newProject.cover = projectFolder.length > 0 ? imagesURL + "upload/" +projectFolder[0][0] : "";
            newProject.imagesList = projectFolder;

            newProjectsList.push( newProject );

            if( index === projects.length -1 ) {
                callback(newProjectsList);
            }

            else {
                this._startGetProjectFolder(projects, index+1, newProjectsList, callback);
            }
        });
    }

    _getRadODD( tab = [], page = 1 ) {
        this.setState({
            radODD: [],
        });
        this.radODD._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadODD( tab, page + 1 );
            }

            else {
                this.setState({
                    radODD: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    this._getRadExpertise();
                });
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadProjects( tab = [], page = 1 ) {
        this.setState({
            projects: [],
        });

        this.radProject._getProjects(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if(tab.length>0) {
                if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                    this._getRadProjects( tab, page + 1 );
                }
    
                else {
                    this.setState({
                        projects: this.tools._sortByDate(tab, 'created_at'),
                    }, () => {
                        this._getRadODD();
                    });
                }
            } else {
                this.setState({
                    loading: false,
                });
            }
        }).catch((e) => {
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getProjectsStatus( tab = [], page = 1 ) {
        this.setState({
            projectStatus: [],
        });
        this.radProject._getProjectsStatus(true, null).then((response) => {
            console.log(response);
            tab = tab.concat( response.data.results );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getProjectsStatus( tab, page + 1 );
            }

            else {
                this.setState({
                    projectsStatus: this.tools._sortByDate2(tab, 'created_at'),
                }, () => {
                    this._getRadProjects();
                });
            }
        }).catch((e) => {
            if( e.response ){
                
            }
            this.setState({
                loading: false,
            });
            if (e.toString() === "Error: Network Error") {
                alert("Aucun accès à internet :(");
            }
        });
    }

    render() {
        return (
            <>
                <div className="projectsView">
                    <div className="projectsView_Section1">
                        <div>
                            <div className="projectsView_Section1_">
                                <img className="globe" src={globe} alt="globe-benin"/>
                                <span className="projectsView_Section1_text titleStyle3 title">NOS PROJETS</span>
                            </div>
                            <div className="projectsView_Section1_button">
                                {/* <HashLink className="link_menu" variant="contained" to="/Nos-projets/#voir"> */}
                                    <span className="projectsView_Section1_button_text background_primary2"
                                        onClick={() => {
                                            window.scrollTo(0, document.getElementById("voir").offsetTop)
                                        }}
                                    >VOIR</span>
                                {/* </HashLink> */}
                            </div>
                        </div>
                    </div>

                    <div className="projectsView_Section2" id="voir">

                        {
                            this.state.projectsStatus.slice(0).reverse().map((status, statusIndex) => (
                                <div key={"status" + statusIndex}>
                                    <div className="projectsView_Section2_titleContainer x-animation"
                                        x-animation="from-bottom"
                                        x-animation-delay="0s"
                                        x-animation-duration="2s"
                                    >
                                        <div className="projectsView_Section2_title">
                                            <span>
                                                Nos projets { status.name.toString().toLowerCase() }
                                            </span>
                                        </div>
                                    </div>

                                    <div className="projectsView_Section2_body">
                                        <div className="projectsView_Section2_body_">
                                            {
                                                this.state.projectsList.map((project, index) => (
                                                    (project.status === status.id )&&
                                                    <div key={index} className="projectsView_Section2_body_project x-animation"
                                                        x-animation="from-bottom"
                                                        x-animation-delay="0s"
                                                        x-animation-duration="2s"
                                                    >
                                                        <ProjectCard project={project} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        {/* <div className="projectsView_Section2_titleContainer x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="2s"
                        >
                            <div className="projectsView_Section2_title ">
                                <span>
                                    Nos projets en terminés
                                </span>
                            </div>
                        </div>

                        <div className="projectsView_Section2_body">
                            <div className="projectsView_Section2_body_">
                                {
                                    this.state.projectsList2.map((project, index) => (
                                        <div key={index} className="projectsView_Section2_body_project x-animation"
                                            x-animation="from-bottom"
                                            x-animation-delay="0s"
                                            x-animation-duration="2s"
                                        >
                                            <ProjectCard project={project} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div> */}
                    </div>

                    {
                        this.state.loading &&
                        <div className='loading'>
                            <img src={loader} alt='' />
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default ProjectsView;