import React from 'react';

/**Importation des composants de la page */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ImageSelector from '../components/imageSelector/imageSelector.component';


/** Importation des screens */
// Screen de la page d'accueil


/**Importation des styles de la page */
import './styles/dashboard.addProject.style.css';
import './styles/dashboard.addProject.responsive.style.css';

/**Importation des images*/



class DashboardAddActivity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ImageSelectedList: [],
            ImageSelectedPreviewLink: [],
        }

        this.competences = [
            "Renforcement de capacités",
            "Accompagnement",
            "Conseil",
            "Accès aux marchés",
            "Accès aux financements"
        ]

        this.odd = [
            1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17
        ]

        this.project = [
            {
                name: "SAFEVEG"
            },
            {
                name: "OKP Bénin"
            },
            {
                name: "Partenariat CISAE"
            },
            {
                name: "ACMA2"
            },
            {
                name: "ProCIVA 2021"
            },
            {
                name: "ProAgri4"
            },
            {
                name: "TMT+"
            },
            {
                name: "Enabel DEFIA"
            },
        ]
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    _deleteImage( imageIndex ) {
        let ImageSelectedList = this.state.ImageSelectedList;
        let ImageSelectedPreviewLink = this.state.ImageSelectedPreviewLink;

        let filteredImageSelectedList = ImageSelectedList.filter(function(value, index, arr) {
            return index !== imageIndex;
        });

        let filteredImageSelectedPreviewLink = ImageSelectedPreviewLink.filter(function(value, index, arr) {
            return index !== imageIndex;
        });

        this.setState({
            ImageSelectedList: filteredImageSelectedList,
            ImageSelectedPreviewLink: filteredImageSelectedPreviewLink,
        });
    }

    _deleteAllImages() {
        this.setState({
            ImageSelectedList: [],
            ImageSelectedPreviewLink: [],
        });
    }

    render() {
        return (
            <>
                <div className='add'>
                    <div className='add_head'>
                        <span>
                            Editez le contenu de votre activité
                        </span>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Titre de l'activité
                            </span>
                        </div>
                        <div className='add_content_input'>
                            <input type='text' placeholder='Entrez le titre du projet ici' />
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Courte présentation de l'activité
                            </span>
                        </div>
                        <div className='add_content_input'>
                            <textarea placeholder="Donnez une petite description du projet ici"></textarea>
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Domaines de compétences associés
                            </span>
                        </div>
                        <div className='add_content_checkBox'>
                            {
                                this.competences.map((competence, index) => (
                                    <label key={index} for={'dc' + index} className='add_content_checkBox_ competenceCheckbox'>
                                        <input id={'dc' + index} name={'dc' + index} type='checkbox'/>
                                        <label for={'dc' + index}>
                                            { competence }
                                        </label>
                                    </label>
                                ))
                            }
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Objectifs de développement associés
                            </span>
                        </div>
                        <div className='add_content_checkBox'>
                            {
                                this.odd.map((odd, index) => (
                                    <label key={index} for={'odd' + index} className='add_content_checkBox_ oddCheckbox'>
                                        <input id={'odd' + index} name={'odd' + index} type='checkbox'/>
                                        <label for={'odd' + index}>
                                            <img className='add_content_checkBox_odd' src={"/ODD/odd" + odd + ".png"} alt={"ODD"} />
                                        </label>
                                    </label>
                                ))
                            }
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Projets associés
                            </span>
                        </div>
                        <div className='add_content_checkBox'>
                            {
                                this.project.map((project, index) => (
                                    <label key={index} for={'project' + index} className='add_content_checkBox_ oddCheckbox'>
                                        <input id={'project' + index} name={'project' + index} type='checkbox'/>
                                        <label for={'project' + index}>
                                            { project.name }
                                        </label>
                                    </label>
                                ))
                            }
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Editez le contenu de votre activité
                            </span>
                        </div>
                        <div className='add_content_editor'>
                            <CKEditor
                                editor={ ClassicEditor }
                                data=""
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                        </div>
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Ajoutez des images
                            </span>
                        </div>
                        <div className='add_content_imageSelector'>
                            <ImageSelector
                                onChangeImage={ async (e) => {
                                    const files = Array.from( e );

                                    let ImageSelectedList = this.state.ImageSelectedList;
                                    ImageSelectedList.concat( e );

                                    let tab = this.state.ImageSelectedPreviewLink;

                                    Promise.all( files.map( file  => {
                                        return (
                                            new Promise((resolve, reject) => {
                                                const reader = new FileReader();
                                                reader.addEventListener('load', (ev) => {
                                                    resolve( ev.target.result );
                                                });
                                                reader.addEventListener('error', reject);
                                                reader.readAsDataURL( file );
                                            })
                                        )
                                    })).then( images => {
                                        tab = tab.concat( images );
                                        // console.log( tab );
                                        this.setState({
                                            ImageSelectedList: ImageSelectedList,
                                            ImageSelectedPreviewLink: tab,
                                        });
                                    });
                                    
                                }}
                            />
                        </div>

                        {
                            this.state.ImageSelectedPreviewLink.length > 0 &&
                            <div className='delete_all_image'>
                                <span className='delete_all_image_text' onClick={() => {
                                    this._deleteAllImages();
                                }}>
                                    <span>
                                        Supprimer toutes les images
                                    </span>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                    </svg>
                                </span>
                            </div>
                        }

                        <div className="previewImageSelected_container">
                            {
                                this.state.ImageSelectedPreviewLink.map((link, index) => (
                                    <div className="previewImageSelected_">
                                        <svg onClick={() => {
                                            this._deleteImage( index );
                                        }} className='previewImageSelected_cross' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529"/>
                                            <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529"/>
                                        </svg>
                                        <img className="previewImageSelected" key={index} src={link} alt="RAD-ONG"/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className='add_button'>
                        <span>
                            Ajouter
                        </span>
                    </div>
                </div>
            </>
        )
    }
}

export default DashboardAddActivity;