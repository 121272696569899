import React from 'react';
import { connect } from 'react-redux';

/**Importation des composants de la page */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//--------Le menu de la page--------------



/** Importation des screens */


/**Importation des styles de la page */
import './styles/dashboard.odd.style.css';
// import './styles/dashboard.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';


/** Importation des helpers */
import radODD from '../helpers/ODD.helper';
import tools from '../helpers/tools.helper';
import refreshConnection from '../helpers/refresh.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}


class DashboardODD extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            radODD: [],
            loading: false,
        }

        this.accessStore = new AccessStore( this.props.dispatch);
        this.radODD = new radODD();
        this.tools = new tools();
        this._getRadODD = this._getRadODD.bind( this );
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });
        this._getRadODD();
    }

    _makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++)
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    _setNewAccessToUser( callback ) {
        refreshConnection( this.props.user.refresh ).then((response) => {
            
            let newUser = this.props.user;
            newUser.access = response.data.access;
            
            if( response.data.refresh ) {
                newUser.refresh = response.data.refresh;
            }

            // console.log( response, this.props.user, newUser );

            this.accessStore.setRedux('SET_USER', newUser, callback);
            // this._getRadExpertise();
        }).catch((e) => {
            if( e.response ){
                if( e.response.status === 401 ) {
                    this.accessStore.setRedux('LOG_OUT', {});
                }
            }
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    } 

    _getRadODD( tab = [], page = 1 ) {
        this.setState({
            radODD: [],
        });
        this.radODD._getAll(false, this.props.user.access, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadODD( tab, page + 1 );
            }

            else {
                this.setState({
                    radODD: this.tools._sortByDate(tab, 'created_at'),
                    loading: false,
                });
            }
        }).catch((e) => {
            console.log( e );
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._getRadODD );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _isObjEmpty(obj) {
		for (var prop in obj) {
		  if (obj.hasOwnProperty(prop)) return false;
		}
		return true;
	}

    _updateODD( odd, type ) {
        // console.log( odd );
        this.setState({
            loading: true,
        });
        if( type === "update" ){
            this.radODD._update( odd.id, odd, this.props.user.access).then((response) => {
                if( response.status === 200 ) {
                    this._getRadODD();
                }
                else {
                    alert("Une erreur est survenue lors de la mofification");
                }
            }).catch((e) => {
                this.setState({
                    loading: false,
                });
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._updateODD( odd, type ) );
                }
                if( e.toString() === "Error: Network Error" ) {
                    alert( "Aucun accès à internet :(" );
                }
            });
        }

        else if( type === "add" ) {
            this.radODD._post(odd).then((response) => {
                if( response.status === 201 ) {
                    this._getRadODD();
                }
                else {
                    alert("Une erreur est survenue lors de l'ajout");
                }
            }).catch((e) => {
                this.setState({
                    loading: false,
                });
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._updateODD( odd, type ) );
                }
                if( e.toString() === "Error: Network Error" ) {
                    alert( "Aucun accès à internet :(" );
                }
            });
        }

        else if( type === "delete" ) {
            this.radODD._delete(odd.id).then((response) => {
                // console.log( response );
                if( response.status === 200 ) {
                    this._getRadODD();
                }
                else {
                    alert("Une erreur est survenue lors de la suppression");
                }
            }).catch((e) => {
                this.setState({
                    loading: false,
                });
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._updateODD( odd, type ) );
                }
                if( e.toString() === "Error: Network Error" ) {
                    alert( "Aucun accès à internet :(" );
                }
            });
        }
    }

    render() {
        return (
            <>
                <div className='radOddContainer'>
                    <div className='radExpertise_title'>
                        <span className='radSections_title_ radExpertise_title_'>
                            Liste des ODD
                        </span>
                    </div>
                    <div className='radExpertise_container'>
                        {
                            this.state.radODD.map((odd, index) => (
                                <div className='dashboardODD' key={"odd" + index}>
                                    <img className='dashboardODD_image' src={"/ODD/odd" + odd.logo + ".png"} alt='RAD-ONG ODD' />
                                    <div className='radODD_title title'>
                                        <span>
                                            Objectif : { odd.logo }
                                        </span>
                                    </div>
                                    <div className='radODD'>
                                        <div className='radODD_text'>
                                            {
                                                odd.details
                                            }
                                        </div>
                                        <span className='table1_th_Add'
                                            onClick={() => {
                                                let newDetails = window.prompt('Modofiez le text ici', odd.details);
                                                if( newDetails !== '' && newDetails !== undefined && newDetails !== null ) {
                                                    let newOdd = {
                                                        details: newDetails,
                                                        id: odd.id,
                                                        name: odd.name,
                                                    };
                                                    this._updateODD( newOdd, "update" );
                                                }
                                            }}
                                        >
                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                        </span>
                                    </div>

                                    <div className='radODD_title title'>
                                        <span>
                                            Lien :
                                        </span>
                                    </div>
                                    <div className='radODD'>
                                        <div className='radODD_text'>
                                            <a href={odd.link} target="_blank">
                                                {
                                                    odd.link
                                                }
                                            </a>
                                        </div>
                                        <span className='table1_th_Add'
                                            onClick={() => {
                                                let newLink = window.prompt('Modofiez le text ici', odd.link);
                                                if( newLink !== '' && newLink !== undefined && newLink !== null ) {
                                                    let newOdd = {
                                                        id: odd.id,
                                                        link: newLink,
                                                        name: odd.name,
                                                    };
                                                    this._updateODD( newOdd, "update" );
                                                }
                                            }}
                                        >
                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                        </span>
                                    </div>

                                    <div className='radODD_title title'>
                                        <span>
                                            Description :
                                        </span>
                                    </div>
                                    <div className='add_content_editor2 radODD_editor'>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={odd.desc !== null ? odd.desc : ''}
                                            onReady={ editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log( 'Editor is ready to use!', editor );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                
                                                let oddList = this.state.radODD;
                                                
                                                let newOdd = {
                                                    name: odd.name,
                                                    id: odd.id,
                                                    logo: odd.logo,
                                                    details: odd.details,
                                                    desc: data,
                                                    link: odd.link,
                                                };

                                                oddList[index] = newOdd;

                                                // console.log( oddList );

                                                this.setState({
                                                    radODD: oddList,
                                                });
                                            }}
                                        />
                                        <div className='updateRadContentButton'>
                                            <span className='update'
                                                onClick={() => {
                                                    if( window.confirm("Voulez-vous vraiment modifier?") === true ){
                                                        let newOdd = {
                                                            name: odd.name,
                                                            id: odd.id,
                                                            desc: odd.desc,
                                                        };
                                                        this._updateODD( newOdd, "update" );
                                                    }
                                                }}
                                            >
                                                Modifier
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                {
                    this.state.loading &&
                    <div className='loading'>
                        <img src={loader} alt='' />
                    </div>
                }
            </>
        )
    }
}

DashboardODD = connect(mapStateToProps, null)(DashboardODD);

export default DashboardODD;