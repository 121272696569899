import React from 'react';

import './style/style.css';
import './style/responsive.style.css';

import loader from '../../assets/gif/loader5.gif';

class VideoComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            play: false,
            currentTime: 0,
            currentDuration: 0,
            duration: 0,
            rangeValue: 0,
            loaded: false,
            volume: 100,
            view_volume_controller: false,
        }

        this.src = [this.props.src];

        this.video = React.createRef();
        this.video_range = React.createRef();
        this.video_volume = React.createRef();
    }

    componentDidMount() {
        // window.onload = () => {
        //     this.setState({
        //         duration: this._formatTime(this.video.current.duration),
        //     });
        // }
    }

    componentWillUnmount() {
        // this._stopSlide();
    }

    _startSlide() {
        this.timeOut = setInterval(() => {
            this.state.currentImageIndex < this.props.imageList.length - 1  ?
                this._changeCurrentImage( this.state.currentImageIndex + 1 ) :
                this._changeCurrentImage(0)
        }, this.props.delay);
    }

    _stopSlide() {
        clearInterval( this.timeOut );
    }

    _PLayPause(bool) {
        if( bool === true ) {
            this.video.current.play() ;
        }else {
            this.video.current.pause() ;
        }
        this.setState({
            play: bool,
        });
    }

    _formatTime(audio_time ){
        var hours = Math.floor( audio_time / 3600 ) ;
        var mins = Math.floor( ( audio_time % 3600 ) / 60 ) ;
        var secs = Math.floor( audio_time % 60 ) ;
        if ( hours ){
            if ( mins < 10 ) 
            {
            mins = "0" + mins ;
            }
            return hours + ":" + mins + ":" + secs ;
        }else{
            return mins + ":" + secs ;
        }
    }
  
    render() {
        return (
            <div className="Video_container">
                <video
                    src={this.props.src}
                    controls={false}
                    className="videoComponent_video"
                    ref={this.video}
                    onTimeUpdate={(e) => {
                        let duration = e.target.duration ;
                        let time_audio = e.target.currentTime ;
                        let fraction = time_audio / duration ;
                        let percent = Math.ceil( fraction * 100 ) ;

                        this.video_range.current.value = percent;
                        this.setState({
                            rangeValue: percent,
                            currentDuration:  this._formatTime(e.target.currentTime ),
                            duration: this._formatTime(e.target.duration),
                        });
                        // console.log(e.target.readyState);
                    }}
                    onPlay={(e) => {
                        this._PLayPause(true);
                    }}
                    onPause={(e) => {
                        this._PLayPause(false);
                    }}
                    onCanPlay={() => {
                        this.setState({
                            loaded: false,
                        })
                    }}
                    onLoadedMetadata={(e) => {
                        if( this.props.src !== this.src[0] ) {
                            this.setState({
                                play: false,
                                currentTime: 0,
                                currentDuration: 0,
                                duration: 0,
                                rangeValue: 0,
                            });
                            this.video.current.currentTime = 0;
                            this.src = [this.props.src ];
                        }
                        this.setState({
                            loaded: true,
                        });
                    }}
                    onEnded={() => {
                        this._PLayPause(false);
                    }}

                    // onLoadedData={() => {
                    //     console.log( 9 );
                    // }}
                ></video>
                {
                    this.state.loaded && 
                    <div className='video_loader'>
                        <img src={loader} alt="RAD-ONG"/>
                    </div>
                }
                <div className='controller'>
                    <div className='controller_playPause'>
                        <div className='controller_playPause_'>
                            <button className='controller_playPause_play'
                                style={{
                                    transform: !this.state.play ? "scale(1)" : "scale(0)",
                                    position: !this.state.play ? "relative" : "absolute",
                                    zIndex: !this.state.play ? "1" : "-100",
                                }}
                                onClick={() => {
                                    this._PLayPause(true);
                                }}
                            >
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-play" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M10.804 8L5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"/>
                                </svg>
                            </button>
                            <button className='controller_playPause_pause'
                                style={{
                                    transform: this.state.play ? "scale(1)" : "scale(0)",
                                    position: this.state.play ? "relative" : "absolute",
                                    zIndex: this.state.play ? "1" : "-100",
                                }}
                                onClick={()=>{
                                    this._PLayPause(false);
                                }}
                            >
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pause" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"/>
                                </svg>
                            </button>
                        </div>
                        
                    </div>
                    
                    <div className='controller_rangeOther'>
                        <div className='controller_rangeOther_range'
                            style={{
                                transform: !this.state.view_volume_controller ? "translateX(0)" : "translateX(-150%)"
                            }}
                        >
                            <input
                                ref={this.video_range}
                                type='range'
                                dir='ltr'
                                min={0}
                                max={100}
                                defaultValue={0}
                                onChange={(e) => {
                                    this.video.current.currentTime = Math.ceil( ( e.target.value * this.video.current.duration ) / 100 ) ;
                                    this.setState({
                                        rangeValue: e.target.value,
                                        loaded: true,
                                    })
                                }}
                            />
                            <div className='controller_rangeOther_range2_container'>
                                <div className='controller_rangeOther_range2'
                                    style={{
                                        width: this.state.rangeValue + "%",
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className='video_options'
                            style={{
                                transform: !this.state.view_volume_controller ? "translateY(0)" : "translateY(150%)"
                            }}
                        >
                            <div className='video_duration'>
                                <span>{this.state.currentDuration}</span>
                                / 
                                <span>{this.state.duration}</span>
                            </div>
                            <div className='video_volume_option video_screen'>
                                <button
                                    onClick={(e) =>{
                                        this.setState({
                                            view_volume_controller: !this.state.view_volume_controller
                                        });
                                    }}
                                >
                                    {
                                        this.state.volume >= 50 ?
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-volume-up" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM6 5.04L4.312 6.39A.5.5 0 0 1 4 6.5H2v3h2a.5.5 0 0 1 .312.11L6 10.96V5.04z"/>
                                                <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
                                                <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
                                                <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707z"/>
                                            </svg>
                                        :
                                            (
                                                this.state.volume < 50 && this.state.volume > 0 ?
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-volume-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M8.717 3.55A.5.5 0 0 1 9 4v8a.5.5 0 0 1-.812.39L5.825 10.5H3.5A.5.5 0 0 1 3 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM8 5.04L6.312 6.39A.5.5 0 0 1 6 6.5H4v3h2a.5.5 0 0 1 .312.11L8 10.96V5.04z"/>
                                                        <path d="M10.707 11.182A4.486 4.486 0 0 0 12.025 8a4.486 4.486 0 0 0-1.318-3.182L10 5.525A3.489 3.489 0 0 1 11.025 8c0 .966-.392 1.841-1.025 2.475l.707.707z"/>
                                                    </svg>
                                                :
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-volume-mute" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM6 5.04L4.312 6.39A.5.5 0 0 1 4 6.5H2v3h2a.5.5 0 0 1 .312.11L6 10.96V5.04zm7.854.606a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708l4-4a.5.5 0 0 1 .708 0z"/>
                                                        <path fill-rule="evenodd" d="M9.146 5.646a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0z"/>
                                                    </svg>
                                            )
                                    }
                                </button>
                            </div>
                            <div className='video_screen'>
                                <button
                                    onClick={(e) => {
                                        if(this.video.current.mozRequestFullScreen) {
                                            this.video.current.mozRequestFullScreen();
                                        }else if(this.video.current.webkitRequestFullScreen) {
                                            this.video.current.webkitRequestFullScreen();
                                        }
                                    }}
                                >
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-fullscreen" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='video_volume'
                        style={{
                            transform: this.state.view_volume_controller ? "translateX(0)" : "translateX(150%)",
                        }}
                    >
                        <div className='video_volume_content'
                            style={{
                                transform: this.state.view_volume_controller ? "translateX(0)" : "translateY(-150%)",
                                transitionDelay: this.state.view_volume_controller ? "0ms" : "200ms",
                            }}
                        >
                            <input
                                ref={this.video_volume}
                                type='range'
                                dir='ltr'
                                min={0}
                                max={100}
                                defaultValue={100}
                                className="volume_range"
                                orient="vertical"
                                name='volume'
                                onChange={(e) => {
                                    this.video.current.volume = e.target.value/100;
                                    this.setState({
                                        volume: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className='volume_cross'
                            style={{
                                transform: this.state.view_volume_controller ? "translateX(0)" : "translateY(150%)",
                                transitionDelay: this.state.view_volume_controller ? "0ms" : "200ms",
                            }}
                        >
                            <button
                                 onClick={(e) =>{
                                    this.setState({
                                        view_volume_controller: false,
                                    });
                                }}
                            >
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }   
}

export default VideoComponent;