import React from 'react';
import parse from 'html-react-parser';

import { connect } from 'react-redux';

/**Importation des composants de la page */
import ImageSelector from '../components/imageSelector/imageSelector.component';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

/**Importation des styles de la page */
import './styles/dashboard.rubrique.home.style.css';
import './styles/dashboard.rubrique.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';

/** Importation des helpers */
import uploadFiles from '../helpers/upload_file.helper'
import getPreviewFiles from '../helpers/getPreviewFiles.helper';

import radDataClass from '../helpers/data-class.helper';
import radDataAttr from '../helpers/data-attr.helper';
import radData from '../helpers/data.helper';

import refreshConnection from '../helpers/refresh.helper';
import ImgURL from '../helpers/imgUrl.helper';
// import { flattenDiagnosticMessageText } from 'typescript';

import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}

class DashboardRubriqueHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            home_page_class: {},
            portefolioImageList:{},
            portefolio_data: {},
            patnaires_data: {},
            welcomeTextData: {},
            tab_data: {},
            sections_data: {},

            title1_data : {},
            text1_data : {},
            subtitle1_data : {},
            title2_data : {},
            text2_data : {},
            subtitle2_data : {},


            ImageSelectedList: [],
            pageContent:[],
            loading: false,
            partnaires: {},
            welcomeTexte: {},
            radYieldCooperatives: [],
            radSections: {},
            addSection: false,
            newSection: {
                name: '',
                text: ''
            },
            currentPartnaire: {
                logo: '',
                slug: '',
                link: '',
                name: '',
                description: '',
                id: '',
            },
            currentPartnaireIndex: 0,
            updatePartnaire: false,
            uploadFiles: false,
            typeUpdate: "add",
            currentUploadProgression: 0,
            currentUploadMessage: '',
            radTables: {},
            currentRadTab: {
                tabName: '',
                tabData: ''
            },
            currentRadTabIndex: 0,
            updateTab: false,

            title1: {},
            text1: {},
            subtitle1: {},

            title2: {},
            text2: {},
            subtitle2: {},
            
        }

        this.currentUploadFiles = [];

        this._getRadHomeInfos = this._getRadHomeInfos.bind( this );

        this.radDataClass = new radDataClass();
        this.radData = new radData();
        this.radDataAttr = new radDataAttr();

        this.accessStore = new AccessStore(this.props.dispatch);
    }

    _makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++)
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    _returnDataPars( data ) {
        let dataParse = data;
        for( var i=0; i< dataParse.length; i++ ) {
            dataParse[i].text = eval(dataParse[i].text);
        }
        return dataParse;
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });

        this._getRadHomeInfos();
    }

    _getRadHomeInfos() {
        this._searchDataClass( "home_page", ( home_page_class ) => {
            
            this._getDataAttr( "portefolio", ( home_page_portefolio_attrs)=> {

                this._getDataAttr( "patnaires", ( home_page_patnaires_attrs)=> {

                    this._getDataAttr( "tab_cf", ( home_page_tab_attrs)=> {

                        this._getDataAttr( "sections", ( home_page_sections_attrs )=> {

                            this._getDataAttr( "title1", ( home_page_title1_attrs )=> {
                                this._getDataAttr( "text1", ( home_page_text1_attrs )=> {
                                    this._getDataAttr( "subtitle1", ( home_page_subtitle1_attrs )=> {
                                        
                                        this._getDataAttr( "title2", ( home_page_title2_attrs )=> {
                                            this._getDataAttr( "text2", ( home_page_text2_attrs )=> {
                                                this._getDataAttr( "subtitle2", ( home_page_subtitle2_attrs )=> {
                                                    this._getDataAttr( "wellcom_texte", ( home_page_wellcom_texte_attrs)=> {
                                                        // console.log(home_page_text1_attrs);

                                                        let home_page_class_ = home_page_class;
                                                        home_page_class_.map((HP, index) => {
                                                            
                                                            this._searchData( HP.name, (_data_)=>{
                                                                // console.log(_data_);
                                                                let portefolioImageList_ = [];
                                                                let partnaires_ = {};
                                                                let welcomeTexte_ = {};
                                                                let radTables_ = {};
                                                                let radSections_ = {}

                                                                let title1_ = {};
                                                                // let text1_ = {};
                                                                // let subtitle1_ = {};

                                                                // let title2_ = {};
                                                                // let text2_ = {};
                                                                // let subtitle2_ = {};
                                        
                                                                _data_.map((data, index) => {
                                                                    // console.log(data.classname === HP.id);
                                                                    if( data.classname === HP.id ) {
                                                                        let portefolio_data = {};
                                                                        let patnaires_data = {};
                                                                        let tab_data = {};
                                                                        let sections_data = {};
                                                                        let welcomeTexte_data = {};

                                                                        let title1_data = {};
                                                                        // let text1_data = {};
                                                                        // let subtitle1_data = {};

                                                                        // let title2_data = {};
                                                                        // let text2_data = {};
                                                                        // let subtitle2_data = {};

                                                                        home_page_portefolio_attrs.map((attr, attrIndex) => {
                                                                            if( attr.data === data.id ) {
                                                                                portefolio_data = data;
                                                                                portefolioImageList_= attr;
                                                                                portefolioImageList_.value = portefolioImageList_.value==="" ? [] : JSON.parse(portefolioImageList_.value);

                                                                                this.setState({
                                                                                    portefolio_data: portefolio_data,
                                                                                });
                                                                            }
                                                                        });

                                                                        home_page_patnaires_attrs.map((attr, attrIndex) => {
                                                                            if( attr.data === data.id ) {
                                                                                patnaires_data = data;
                                                                                partnaires_= attr;
                                                                                partnaires_.value = partnaires_.value==="" ? [] : JSON.parse(partnaires_.value);

                                                                                this.setState({
                                                                                    patnaires_data: patnaires_data,
                                                                                });
                                                                            }
                                                                        });

                                                                        home_page_tab_attrs.map((attr, attrIndex) => {
                                                                            if( attr.data === data.id ) {
                                                                                tab_data = data;
                                                                                radTables_= attr;
                                                                                radTables_.value = radTables_.value==="" ? [] : JSON.parse(radTables_.value);

                                                                                this.setState({
                                                                                    tab_data: tab_data,
                                                                                });
                                                                            }
                                                                        });

                                                                        home_page_sections_attrs.map((attr, attrIndex) => {
                                                                            if( attr.data === data.id ) {
                                                                                sections_data = data;
                                                                                radSections_= attr;
                                                                                radSections_.value = radSections_.value==="" ? [] : JSON.parse(radSections_.value);

                                                                                this.setState({
                                                                                    sections_data: sections_data,
                                                                                });
                                                                            }
                                                                        });

                                                                        home_page_wellcom_texte_attrs.map((attr, attrIndex) => {
                                                                            if( attr.data === data.id) {
                                                                                welcomeTexte_data = data;
                                                                                // console.log(welcomeTexte_data);
                                                                                welcomeTexte_ = attr;
                                                                                this.setState({
                                                                                    welcomeTexte: welcomeTexte_,
                                                                                    welcomeTextData: welcomeTexte_data,
                                                                                });
                                                                            }
                                                                        });

                                                                        /** Text */
                                                                        home_page_title1_attrs.map((attr, attrIndex) => {
                                                                            if( attr.data === data.id && attr.name==="title1") {
                                                                                title1_data = data;
                                                                                title1_ = attr;
                                                                                this.setState({
                                                                                    title1_data: title1_data,
                                                                                });
                                                                            }
                                                                        });
                                                                    }

                                                                    if(index === _data_.length-1) {
                                                                        // console.log(radSections_);
                                                                        this.setState({
                                                                            home_page_class: home_page_class[0],
                                                                            portefolioImageList: portefolioImageList_,
                                                                            partnaires: partnaires_,
                                                                            radTables: radTables_,
                                                                            radSections: radSections_,
                                                                            title1: title1_,
                                                                        });
                                                                    }
                                                                });
                                                            });
                                                        });
                                                    });
                                                    
                                                });
                                            });    
                                        });
                                    
                                    });
                                });    
                            });
                            this.setState({
                                loading: false,
                            });
                            return true;
                        });
                    });
                });
            });
        });
    }

    _getDataAttr( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            // console.log( response.data );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getDataAttr( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchData( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radData._getOne(search, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchData( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _searchDataClass( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataClass._getOne(search, page).then((response) => {
            response.data.results.map((result, index)=>{
                if( result.label === search ) {
                    tab = tab.concat( result );
                }
            });
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._searchDataClass( search, callback, tab, page + 1 );
            }
            else {
                return callback( tab );
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _post_dataAttr( dataAttr, callback=()=>{} ) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._post(JSON.stringify(dataAttr), this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            // console.log( e );
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._post_dataAttr( dataAttr, callback ));
                }
                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            // alert("Ce poste existe déjà");
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _update_dataAttr(id, data, callback=()=>{}) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._update(id, JSON.stringify(data), this.props.user.access).then((response) => {
            this.setState({
                loading: false,
            });
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._update_dataAttr(id, data, callback) );
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _setNewAccessToUser(callback) {
        refreshConnection(this.props.user.refresh).then((response) => {
            let newUser = this.props.user;
            newUser.access = response.data.access;

            if (response.data.refresh) {
                newUser.refresh = response.data.refresh;
            }

            // console.log( response, this.props.user, newUser );

            this.accessStore.setRedux('SET_USER', newUser, callback);
            // this._getRadExpertise();
        }).catch((e) => {
            if (e.response) {
                if (e.response.status === 401) {
                    this.accessStore.setRedux('LOG_OUT', {});
                }
            }
            if (e.toString() === "Error: Network Error") {
                alert("Aucun accès à internet :(");
            }
        });
    }

    _addTextToImage(id) {
        let inputContainer = document.getElementById(id);
        inputContainer.style.position = "relative";
        inputContainer.style.opacity = 1;
        inputContainer.style.transform = "scaleY(1)";
        inputContainer.style.zIndex = 100;
    }
    _clickToBackAddText( id ) {
        let inputContainer = document.getElementById(id);
        inputContainer.style.position = "absolute";
        inputContainer.style.opacity = 0;
        inputContainer.style.transform = "scaleY(0)";
        inputContainer.style.zIndex = -1000;
    }

    _getValueOfPageContent( value ) {
        return (
            Promise.all( this.state.pageContent.filter( obj  => {
                if ( obj.id === value ) {
                    return true;
                } else {
                    return false;
                }
            }))
        )
    }

    _updateHomePage( id, value, name ) {
        if( window.confirm("Voulez-vous vraiment modifier le texte ?") === true ){
            let data = null;
            if( name ) {
                data = {
                    data : {
                        name: name
                    }
                }
            }

            else {
                data = {
                    data : {
                        value: value
                    }
                }
            }
            
            this.setState({
                loading: true,
            });
            this.radHomePage._update( id, data ).then((response) => {
                // console.log( response );
                this.radHomePage._getAll().then((response) => {
                    this.setState({
                        loading: false,
                        pageContent: response.data.radhomepages
                    },() => {
                        this._getValueOfPageContent(id).then((result) => {
                            this.setState({
                                [id] : result[0]
                            });
                        });
                    });
                }).catch((error) => {
                    console.log( error );
                })
            }).catch((error) => {
                this.setState({
                    loading: false,
                });
                console.log( error );
            });
        }
    }


    _updatePartnaires( partnaire, type, patnaireIndex ) {
        // console.log( this.currentUploadFiles  );

        if( type === "add" ) {
            if( window.confirm("Voulez-vous vraiment ajouter ce partenaire ?") === true ){
                this.setState({
                    uploadFiles: true,
                })
                uploadFiles( 'partnaires', this.currentUploadFiles, (event) => {
                    this.setState({
                        currentUploadProgression: Math.round((100 * event.loaded) / event.total),
                    });
                }).then((response) => {
                    // console.log( response.data );
                    if( response.data.code === 201 ){
                        alert('Fichier envoyé avec succès');
                        this.setState({
                            currentUploadMessage: 'Fichier envoyé avec succès',
                            uploadFiles: false,
                            loading: true,
                        });
                        
                        let data_ = this.state.partnaires;

                        data_.value.push({
                            logo: "partnaires/" + this.currentUploadFiles[0].name,
                            link:  /^http/.test(partnaire.link) ? partnaire.link : "http://" + partnaire.link,
                            slug: partnaire.slug,
                            name: partnaire.name,
                            description: partnaire.description,
                        });

                        let data = {
                            value: JSON.stringify(data_.value),
                            data: this.state.patnaires_data.id,
                            name: data_.name,
                        };
                        this._update_dataAttr(this.state.partnaires.id, data, (response) => {
                            this.setState({
                                loading: false,
                                updatePartnaire: false,
                                currentPartnaire: {
                                    logo: '',
                                    slug: '',
                                    link: '',
                                    name: '',
                                    id: '',
                                    description: '',
                                },
                            });
                            alert( "Partenaire ajouté avec succès" );
                        });
                    }

                    else {
                        alert('Echec de l\'envois du fichier. Veuillez réessayer');
                        this.setState({
                            uploadFiles: false,
                            loading: false,
                        });
                    }
                }).catch((e) => {
                    this.setState({
                        uploadFiles: false,
                        loading: false,
                    });
                    alert('Echec de l\'envois du fichier. Veuillez réessayer');
                    console.log( e );
                });
            }
        }

        else if( type === "update" ) {
            if( window.confirm("Voulez-vous vraiment modifier le partenaire ?") === true ){
                if( partnaire.logo !== '' ) {
                    this.setState({
                        uploadFiles: true,
                    })
                    uploadFiles( 'partnaires', this.currentUploadFiles, (event) => {
                        this.setState({
                            currentUploadProgression: Math.round((100 * event.loaded) / event.total),
                        });
                    }).then((response) => {
                        // console.log( response.data );
                        if( response.data.code === 201 ){
                            alert('Fichier envoyé avec succès');
                            this.setState({
                                currentUploadMessage: 'Fichier envoyé avec succès',
                                uploadFiles: false,
                                loading: true,
                            });

                            let data_ = this.state.partnaires;

                            data_.value[patnaireIndex] = {
                                logo: "partnaires/" + this.currentUploadFiles[0].name,
                                link:  /^http/.test(partnaire.link) ? partnaire.link : "http://" + partnaire.link,
                                slug: partnaire.slug,
                                name: partnaire.name,
                                description: partnaire.description,
                            }
    
                            let data = {
                                value: JSON.stringify(data_.value),
                                data: this.state.patnaires_data.id,
                                name: data_.name,
                            };
                            this._update_dataAttr(this.state.partnaires.id, data, (response) => {
                                this.setState({
                                    loading: false,
                                    updatePartnaire: false,
                                    currentPartnaire: {
                                        logo: '',
                                        slug: '',
                                        link: '',
                                        name: '',
                                        id: '',
                                        description: '',
                                    },
                                });
                                alert( "Partenaire modifié avec succès" );
                            });
                        }
    
                        else {
                            alert('Echec de l\'envois du fichier. Veuillez réessayer');
                        }
                    }).catch((e) => {
                        this.setState({
                            loading: false,
                        });
                        alert('Echec de l\'envois du fichier. Veuillez réessayer');
                        console.log( e );
                    });
                }

                else {
                    this.setState({
                        loading: true,
                    });

                    let data_ = this.state.partnaires;
                    let logo = data_.value[patnaireIndex].logo;

                    data_.value[patnaireIndex] = {
                        logo: logo,
                        link:  /^http/.test(partnaire.link) ? partnaire.link : "http://" + partnaire.link,
                        slug: partnaire.slug,
                        name: partnaire.name,
                        description: partnaire.description,
                    }

                    let data = {
                        value: JSON.stringify(data_.value),
                        data: this.state.patnaires_data.id,
                        name: data_.name,
                    };
                    this._update_dataAttr(this.state.partnaires.id, data, (response) => {
                        this.setState({
                            loading: false,
                            updatePartnaire: false,
                            currentPartnaire: {
                                logo: '',
                                slug: '',
                                link: '',
                                name: '',
                                id: '',
                                description: '',
                            },
                        });
                        alert( "Partenaire modifié avec succès" );
                    });
                }
            }
        }

        else if( type === "delete" ) {
            if( window.confirm("Voulez-vous vraiment supprimer ce partenaire?") === true ){
                this.setState({
                    loading: true,
                });
                let data_ = this.state.partnaires;

                data_.value.splice(patnaireIndex, 1);

                let data = {
                    value: JSON.stringify(data_.value),
                    data: this.state.patnaires_data.id,
                    name: data_.name,
                };
                this._update_dataAttr(this.state.partnaires.id, data, (response) => {
                    this.setState({
                        loading: false,
                        updatePartnaire: false,
                        currentPartnaire: {
                            logo: '',
                            slug: '',
                            link: '',
                            name: '',
                            id: '',
                            description: '',
                        },
                    });
                    alert( "Partenaire supprimé avec succès" );
                });
            }
        }
    }



    _updateSection( section, index ) {
        if( window.confirm("Voulez-vous vraiment modifier le texte de la section?") === true ){
            this.setState({
                loading: true,
            });

            let data_ = this.state.radSections;

            data_.value[index] = section;

            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.sections_data.id,
                name: data_.name,
            };
            this._update_dataAttr(this.state.radSections.id, data, (response) => {
                this.setState({
                    loading: false,
                    addSection: false,
                });
                alert( "Section modifiée avec succès" );
            });
        }
    }

    _updateWelcomeText() {
        if( window.confirm("Voulez-vous vraiment modifier le mot du directeur?") === true ){
            this.setState({
                loading: true,
            });

            let data_ = this.state.welcomeTexte
            console.log(this.state.welcomeTextData);

            let data = {
                value: data_.value,
                data: this.state.welcomeTextData.id,
                name: data_.name,
            };
            // console.log(data);
            this._update_dataAttr(data_.id, data, (response) => {
                this.setState({
                    loading: false,
                    addSection: false,
                });
                alert( "Mot du directeur modifiée avec succès" );
            });
        }
    }

    _addSection( section ) {
        if( window.confirm("Voulez-vous vraiment ajouter cette section?") === true ){
            this.setState({
                loading: true,
            });

            let data_ = this.state.radSections;

            data_.value.push({
                name: section.name,
                text: section.text
            });

            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.sections_data.id,
                name: data_.name,
            };
            this._update_dataAttr(this.state.radSections.id, data, (response) => {
                this.setState({
                    loading: false,
                    addSection: false,
                });
                alert( "Section ajoutée avec succès" );
            });
        }
    }

    _deleteSection( index ) {
        if( window.confirm("Voulez-vous vraiment supprimer cette section?") === true ){
            this.setState({
                loading: true,
            });
            let data_ = this.state.radSections;
            data_.value.splice(index, 1);
            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.sections_data.id,
                name: data_.name,
            };
            this._update_dataAttr(this.state.radSections.id, data, (response) => {
                this.setState({
                    loading: false,
                });
                alert("Section supprimé avec succès");
            });
        }
    }

    // _deleteSection( section ){
    //     if( window.confirm("Voulez-vous vraiment supprimer cette section?") === true ){
    //         this.setState({
    //             loading: true,
    //         });
    //         this.radSections._delete( section.id ).then((response) => {
    //             // console.log( response );
    //             this.radSections._getAll().then((response) => {
    //                 this.setState({
    //                     loading: false,
    //                     radSections: response.data.radsectionss,
    //                 });
    //             }).catch((error) => {
    //                 console.log( error );
    //             })
    //         }).catch((error) => {
    //             this.setState({
    //                 loading: false,
    //             });
    //             console.log( error );
    //         });
    //     }
    // }

    _updateTab( tab, type, index ) {
        if( type === "update" ) {
            if( window.confirm("Voulez-vous vraiment modifier le tableau ?") === true ){
                this.setState({
                    loading: true,
                });

                let data_ = this.state.radTables;

                data_.value[index] = {
                    tabName: tab.tabName,
                    tabData: tab.tabData,
                };

                let data = {
                    value: JSON.stringify(data_.value),
                    data: this.state.tab_data.id,
                    name: data_.name,
                };
                this._update_dataAttr(this.state.radTables.id, data, (response) => {
                    this.setState({
                        loading: false,
                        currentRadTab: {
                            tabName: '',
                            tabData: ''
                        },
                        updateTab: false,
                    });
                    alert( "Tableau modifié avec succès" );
                });
            }
        }

        else if( type === "add" ) {
            if( window.confirm("Voulez-vous vraiment ajouté le tableau ?") === true ){
                this.setState({
                    loading: true,
                });

                let data_ = this.state.radTables;

                data_.value.push({
                    tabName: tab.tabName,
                    tabData: tab.tabData,
                });

                let data = {
                    value: JSON.stringify(data_.value),
                    data: this.state.tab_data.id,
                    name: data_.name,
                };
                this._update_dataAttr(this.state.radTables.id, data, (response) => {
                    this.setState({
                        loading: false,
                        currentRadTab: {
                            tabName: '',
                            tabData: ''
                        },
                        updateTab: false,
                    });
                    alert( "Tableau ajouté avec succès" );
                });
            }
        }

        else if( type === "delete" ) {
            if( window.confirm("Voulez-vous vraiment supprimer le tableau ?") === true ){
                this.setState({
                    loading: true,
                });

                let data_ = this.state.radTables;

                data_.value.splice(index, 1);

                let data = {
                    value: JSON.stringify(data_.value),
                    data: this.state.tab_data.id,
                    name: data_.name,
                };
                this._update_dataAttr(this.state.radTables.id, data, (response) => {
                    this.setState({
                        loading: false,
                        currentRadTab: {
                            tabName: '',
                            tabData: ''
                        },
                        updateTab: false,
                    });
                    alert( "Tableau supprimé avec succès" );
                });
            }
        }
    }

    _updatePortefolio( tab, type ) {
        if( type === "add" ) {
            if( window.confirm("Voulez-vous vraiment ajouter au porte-folio de la page d'accueil?") === true ){
                this.setState({
                    uploadFiles: true,
                })
                uploadFiles( 'radHomePortefolio', tab, (event) => {
                    this.setState({
                        currentUploadProgression: Math.round((100 * event.loaded) / event.total),
                    });
                }).then((response) => {
                    console.log(response);
                    if( response.data.code === 201 ){
                        alert('Fichier envoyer avec succès');
                        this.setState({
                            currentUploadMessage: 'Fichier envoyer avec succès',
                            uploadFiles: false,
                            loading: true,
                        });

                        let data_ = this.state.portefolioImageList;

                        response.data.files_uploaded_list.images.map((img, index) => {
                            data_.value.push({
                                src: img.name,
                                text: []
                            });
                        });

                        let data = {
                            value: JSON.stringify(data_.value),
                            data: this.state.portefolio_data.id,
                            name: data_.name,
                        };
                        // console.log( data );
                        this._update_dataAttr(this.state.portefolioImageList.id, data, (response) => {
                            alert("Images ajoutées avec succès");
                        });
                    }

                    else {
                        alert('Echec de l\'envois du fichier. Veuillez réessayer');
                        this.setState({
                            loading: false,
                            uploadFiles: false,
                        });
                    }
                }).catch((e) => {
                    this.setState({
                        loading: false,
                        uploadFiles: false,
                    });
                    alert('Echec de l\'envois du fichier. Veuillez réessayer');
                    console.log( e );
                });
            }
        }

        if( type === "update" ) {
            this.setState({
                loading: true,
            });
            
            let data_ = this.state.portefolioImageList;

            let data = {
                value: JSON.stringify(data_.value),
                data: this.state.portefolio_data.id,
                name: data_.name,
            };
            // console.log( data );
            this._update_dataAttr(this.state.portefolioImageList.id, data, (response) => {
                alert("Informations modifiées avec succès");
            });
        }

        if( type === "delete" ) {
            if( window.confirm("Voulez-vous vraiment supprimer cette image?") === true ){
                this.setState({
                    loading: true,
                });
                this.radHomePortefolio._delete( tab.id ).then((response) => {
                    this._getRadHomePortefolio();
                }).catch((error) => {
                    console.log( error );
                    this.setState({
                        loading: false,
                    });
                });
            }
        }
    }

    render() {
        return (
            <>
                <div className='dashboardRubrique_home'>
                    <div className='dashboardRubrique_home_head title'>
                        <span className='title'>
                            Rubrique "Accueil"
                        </span>
                    </div>
                    <div className='dashboardRubrique_home_title'>
                        <span className='title'>
                            Porte-folio :
                        </span>
                    </div>

                    <div className='porteffolio'>
                        {
                            this.state.portefolioImageList.value && this.state.portefolioImageList.value.map((image, index) => (
                                <div className='porteffolio_' key={"rtf" + index}>
                                    {/*<img className='porteffolio_image' src={"https://www.rad-ong.org/upload/radHomePortefolio/" + image.src} alt='RAD-ONG'/>*/}
                                    <img className='porteffolio_image' src={ImgURL + image.src} alt='RAD-ONG'/>
                                    <div className='porteffolio_text scrollVBr'>
                                        {
                                            image.text.map((text, textIndex) => (
                                                <>
                                                    <div key={"text" + textIndex }>
                                                        {
                                                            text
                                                        }
                                                        <span className='table1_th_Add'
                                                            onClick={() => {
                                                                let newText = window.prompt('Entrez le text à ajouter ici', text);

                                                                if( newText !== '' && newText !== undefined && newText !== null ) {
                                                                    let data = image;

                                                                    // console.log( JSON.parse( data.text ) );
                                                                    // data.text = JSON.parse( data.text );
                                                                    data.text[textIndex] = newText;

                                                                    this._updatePortefolio( data, "update" );
                                                                }
                                                            }}
                                                        >
                                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                                        </span>
                                                        <span className='table1_th_Add'
                                                            onClick={() => {
                                                                if( window.confirm("Voulez-vous vraiment supprimé ce text?") === true ){
                                                                    let data = image;
                                                                    
                                                                    data.text.splice( textIndex, 1 );

                                                                    this._updatePortefolio( data, "update" );
                                                                }
                                                            }}
                                                        >
                                                            <i className="fa fa-trash-o colorRed" aria-hidden="true"></i>
                                                        </span>
                                                    </div>
                                                    <br/>
                                                </>
                                            ))
                                        }
                                    </div>
                                    <div className='porteffolio_bottom'>
                                        <span className='update'
                                            onClick={() => {
                                                let newText = window.prompt('Entrez le text à ajouter ici');

                                                if( newText !== '' && newText !== undefined && newText !== null ) {
                                                    let data = this.state.portefolioImageList;

                                                    // console.log( JSON.parse( data.text ) );
                                                    // data.text = JSON.parse( data.text );
                                                    data.value[index].text.push( newText );
                                                    this.setState({
                                                        portefolioImageList: data,
                                                    }, () => {
                                                        this._updatePortefolio( data, "update" );
                                                    });
                                                }
                                            }}
                                        >
                                            Ajouter du text +
                                        </span>
                                        <span className='delete'
                                            onClick={() => {
                                                if( window.confirm("Voulez-vous vraiment supprimer cette image?") === true ){
                                                    let data = this.state.portefolioImageList;
                                                    data.value.splice(index, 1);
                                                    this.setState({
                                                        portefolioImageList: data,
                                                    }, () => {
                                                        this._updatePortefolio( data, "update" );
                                                    });
                                                }
                                            }}
                                        >
                                            Supprimer cette image
                                        </span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className='add_content'>
                        <div className='add_content_label'>
                            <span>
                                Ajoutez des images au Porte-folio
                            </span>
                        </div>
                        <div className='add_content_imageSelector'>
                            <ImageSelector
                                onChangeImage={ async (e) => {
                                    const files = Array.from( e );

                                    // let ImageSelectedList = this.state.ImageSelectedList;
                                    // ImageSelectedList.concat( e );

                                    // let tab = this.state.imageToSlideList;

                                    this._updatePortefolio( e, 'add' );

                                    Promise.all( files.map( file  => {
                                        return (
                                            new Promise((resolve, reject) => {
                                                const reader = new FileReader();
                                                reader.addEventListener('load', (ev) => {
                                                    resolve( ev.target.result );
                                                });
                                                reader.addEventListener('error', reject);
                                                reader.readAsDataURL( file );
                                            })
                                        )
                                    })).then( images => {
                                        // images.map((image) => {
                                        //     tab = tab.concat({
                                        //         src: image,
                                        //         text: []
                                        //     });
                                        // });
                                        // this.setState({
                                        //     ImageSelectedList: ImageSelectedList,
                                        //     imageToSlideList: tab,
                                        // });
                                    });
                                    
                                }}
                            />
                        </div>
                    </div>

                    <div className='textContent'>
                        {
                            (
                                this.state.title1 && false) &&
                            <div className='textContent_ radSections_title_content'>
                                <div className='radSections_title radSections_title_label'>
                                    <span className='radSections_title_'>
                                        Titre 1 :
                                    </span>
                                </div>
                                <div className='textContent_text'>
                                    {
                                        this.state.title1.value ==='' ?
                                            <>Ajouter un texte ici</>
                                        :
                                            this.state.title1.value
                                    }
                                </div>
                                <div className='textContent_buttonForModify'>
                                    <span className='update'
                                        onClick={() => {
                                            this._addTextToImage( this.state.title1.id + "container" );
                                        }}
                                    >
                                        Modifier
                                    </span>
                                </div>
                                <div className='porteffolio_bottom_input' id={ this.state.title1.id + "container" }>
                                    <input className='porteffolio_bottom_input_ textContent_inputForModify' id={ this.state.title1.id } type='text'/>
                                    <span className='update_2'
                                        onClick={() => {
                                            this._updateHomePage( this.state.title1.id, document.getElementById( this.state.title1.id ).value );
                                        }}
                                    >
                                        Valider
                                    </span>
                                    <span className='delete_2'
                                        onClick={() => {
                                            this._clickToBackAddText( this.state.title1.id + "container" );
                                        }}
                                    >
                                        Annuler
                                    </span>
                                </div>
                            </div>
                        }

                        {
                            ( (this.state.subtitle1) && (this.state.subtitle1.value || this.state.subtitle1.value ==='') )&&
                            <div className='textContent_ radSections_title_content'>
                                <div className='radSections_title radSections_title_label'>
                                    <span className='radSections_title_'>
                                        Sous-titre 1 :
                                    </span>
                                </div>
                                <div className='textContent_text'>
                                    {
                                        this.state.subtitle1.value ==='' ?
                                            <>Ajouter un texte ici</>
                                        :
                                            this.state.subtitle1.value
                                    }
                                </div>
                                <div className='textContent_buttonForModify'>
                                    <span className='update'
                                        onClick={() => {
                                            this._addTextToImage( this.state.subtitle1.id + "container" );
                                        }}
                                    >
                                        Modifier
                                    </span>
                                </div>
                                <div className='porteffolio_bottom_input' id={ this.state.subtitle1.id + "container" }>
                                    <input className='porteffolio_bottom_input_ textContent_inputForModify' id={ this.state.subtitle1.id } type='text'/>
                                    <span className='update_2'
                                        onClick={() => {
                                            this._updateHomePage( this.state.subtitle1.id, document.getElementById( this.state.subtitle1.id ).value );
                                        }}
                                    >
                                        Valider
                                    </span>
                                    <span className='delete_2'
                                        onClick={() => {
                                            this._clickToBackAddText( this.state.subtitle1.id + "container" );
                                        }}
                                    >
                                        Annuler
                                    </span>
                                </div>
                            </div>
                        }
                    </div>

                    <div className='add_content'>
                        <div className='radSections_title radSections_title_label'>
                            <span className='radSections_title_'>
                                Mots du directeur de RAD-ONG
                            </span>
                        </div>
                        <br/>
                        <div className='add_content_editor'>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={(this.state.welcomeTexte) && (this.state.welcomeTexte.value) && this.state.welcomeTexte.value }
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    // console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    let welcomeTexte = this.state.welcomeTexte;
                                    welcomeTexte.value = data;
                                    this.setState({
                                        welcomeTexte: welcomeTexte,
                                    });
                                }}
                            />
                        </div>
                        <div className='add_content_area_button'>
                            <span className='update'
                                 onClick={() => {
                                    this._updateWelcomeText();
                                }}
                            >
                                Modifier
                            </span>
                        </div>
                    </div>

                    {/* <div className='textContent'>
                        {
                            ( (this.state.subtitle2) && (this.state.subtitle2.value || this.state.subtitle2.value ==='') )&&
                            <div className='textContent_ radSections_title_content'>
                                <div className='radSections_title radSections_title_label'>
                                    <span className='radSections_title_'>
                                        Sous-titre 2 :
                                    </span>
                                </div>
                                <div className='textContent_text'>
                                    {
                                        this.state.subtitle2.value ==='' ?
                                            <>Ajouter un texte ici</>
                                        :
                                            this.state.subtitle2.value
                                    }
                                </div>
                                <div className='textContent_buttonForModify'>
                                    <span className='update'
                                        onClick={() => {
                                            this._addTextToImage( this.state.subtitle2.id + "container" );
                                        }}
                                    >
                                        Modifier
                                    </span>
                                </div>
                                <div className='porteffolio_bottom_input' id={ this.state.subtitle2.id + "container" }>
                                    <input className='porteffolio_bottom_input_ textContent_inputForModify' id={ this.state.subtitle2.id } type='text'/>
                                    <span className='update_2'
                                        onClick={() => {
                                            this._updateHomePage( this.state.subtitle2.id, document.getElementById( this.state.subtitle2.id ).value );
                                        }}
                                    >
                                        Valider
                                    </span>
                                    <span className='delete_2'
                                        onClick={() => {
                                            this._clickToBackAddText( this.state.subtitle2.id + "container" );
                                        }}
                                    >
                                        Annuler
                                    </span>
                                </div>
                            </div>
                        }
                    </div>

                    <div className='add_content'>
                        <div className='add_content_editor'>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={(this.state.text2) && (this.state.text2.value) && this.state.text2.value }
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    // console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    let text2 = this.state.text2;
                                    text2.value = data;
                                    this.setState({
                                        text2: text2,
                                    });
                                }}
                            />
                        </div>
                        <div className='add_content_area_button'>
                            <span className='update'
                                 onClick={() => {
                                    this._updateHomePage( this.state.text2.id, this.state.text2.value );
                                }}
                            >
                                Modifier
                            </span>
                        </div>
                    </div> */}

                    
                    <br/>
                    <br/>
                    
                    <div className='radSections'>
                        <div className='radSections_title'>
                            <span className='radSections_title_'>
                                Sections
                            </span>
                            <span className='update_2'
                                onClick={() => {
                                    this.setState({
                                        addSection: true,
                                    });
                                }}
                            >
                                Ajouter une section +
                            </span>
                        </div>

                        <div>
                            {
                                this.state.radSections.value && this.state.radSections.value.map((section, index) => (
                                    <div className='radSection' key={"section" + index + section.id }>
                                        <span className='radSection_name'>
                                            {
                                                section.name
                                            }
                                        </span>
                                        <span className='table1_th_Add radSection_editIcon'
                                            onClick={() => {
                                                let promptValue = prompt("Modifier le titre de la section ici");
                                                let radSections = this.state.radSections;
                                                radSections.value[index].name = promptValue;
                                                this.setState({
                                                    radSections: radSections,
                                                }, () => {
                                                    this._updateSection( section, index );
                                                });
                                            }}
                                        >
                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                        </span>
                                        <div className='radSection_editText'>
                                            <CKEditor
                                                editor={ ClassicEditor }
                                                data={ section.text}
                                                onReady={ editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    // console.log( 'Editor is ready to use!', editor );
                                                } }
                                                onChange={ ( event, editor ) => {
                                                    const data = editor.getData();
                                                    let radSections = this.state.radSections;
                                                    radSections.value[index].text = data;
                                                    this.setState({
                                                        radSections: radSections,
                                                    });
                                                }}
                                            />
                                            <div className='update_sectionText'>
                                                <span className='update'
                                                    onClick={() => {
                                                        this._updateSection( section, index );
                                                    }}
                                                >
                                                    Modifier le texte
                                                </span>
                                            </div>
                                        </div>

                                        <div className="deleteSection">
                                            <span
                                                onClick={() => {
                                                    this._deleteSection( index );
                                                }}
                                            >
                                                Supprimer la section
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <div className='radSections_title'>
                        <span className='radSections_title_'>
                            Les partenaires de RAD-ONG :
                        </span>

                            <span className='update_2'
                                onClick={() => {
                                    this.setState({
                                        updatePartnaire: true,
                                        typeUpdate: 'add',
                                    });
                                }}
                            >
                                Ajouter un partenaire +
                            </span>
                    </div>

                    <div>
                        <table className='table1'>
                            <thead>
                                <tr className='table1_trth'>
                                    <th className='table1_th'>
                                        Logo
                                    </th>
                                    <th className='table1_th'>
                                        Lien
                                    </th>
                                    <th className='table1_th'>
                                        Courte description
                                    </th>
                                    <th className='table1_th table1_th_'>
                                        Description
                                    </th>
                                    <th className='table1_th'>
                                        modifier
                                    </th>
                                    <th className='table1_th'>
                                        supprimer
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.partnaires.value && this.state.partnaires.value.map((partnaire, index) => (
                                    <tr
                                        className='table1_tr'
                                        key={"tabPartnaires" + index }
                                        style={{
                                            backgroundColor: index % 2 !== 0 ? "#00000011" : "transparent"
                                        }}
                                    >
                                        <td className='table1_th'>
                                            <img className='partnaireLogo' src={"https://www.rad-ong.org/upload/" + partnaire.logo} alt="Partenaire de RAD-ONG" />
                                        </td>
                                        <td className='table1_th'>
                                            <a href={partnaire.link}>{partnaire.link}</a>
                                        </td>
                                        <td className='table1_th'>
                                            { parse(partnaire.slug) }
                                        </td>
                                        <td className='table1_th'>
                                            { parse(partnaire.description) }
                                        </td>
                                        
                                        <td className='table1_th table1_th_center'>
                                            <span className='table1_th_Add'
                                                onClick={() => {
                                                    let data = this.state.currentPartnaire;
                                                    data.logo = '';
                                                    data.link = partnaire.link;
                                                    data.name = partnaire.name;
                                                    data.description = partnaire.description;
                                                    data.slug = partnaire.slug;
                                                    data.id = partnaire.id;

                                                    this.setState({
                                                        currentPartnaire: data,
                                                        currentPartnaireIndex: index,
                                                        updatePartnaire: true,
                                                        typeUpdate: 'update',
                                                    });
                                                }}
                                            >
                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                            </span>
                                        </td>
                                        <td className='table1_th table1_th_center'>
                                            <span className='table1_th_Add'
                                                onClick={() => {
                                                    let data = this.state.currentPartnaire;
                                                    data.logo = '';
                                                    data.link = partnaire.link;
                                                    data.name = partnaire.name;
                                                    data.description = partnaire.description;
                                                    data.slug = partnaire.slug;
                                                    data.id = partnaire.id;

                                                    this.setState({
                                                        typeUpdate: 'delete',
                                                        currentPartnaire: data,
                                                    }, () => {
                                                        this._updatePartnaires(this.state.currentPartnaire, this.state.typeUpdate, index);
                                                    });
                                                }}
                                            >
                                                <i className="fa fa-trash-o colorRed" aria-hidden="true"></i>
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <br/>
                        <br/>
                        <div className='radSections_title'>
                            <span className='radSections_title_'>
                                Tableaux :
                            </span>
                            <span className='update_2'
                                onClick={() => {
                                    this.setState({
                                        typeUpdate: 'add',
                                        updateTab: true,
                                    });
                                }}
                            >
                                Ajouter un tableau +
                            </span>
                        </div>
                        
                        <br/>
                        <br/>
                        <div className='tabContainer'>
                            {
                                this.state.radTables.value && this.state.radTables.value.map((tab, index) => (
                                    <div className='tabContainer_' key={"radTable" + index}>
                                        <div className='tabContainer_name title'>
                                            <span>
                                                { parse(tab.tabName) }
                                            </span>
                                        </div>
                                        {parse(tab.tabData)}
                                        <div>
                                            <div className='updateSocial_inputSubmit_container'>
                                                <span className='updateSocial_inputSubmit'
                                                    
                                                    onClick={() => {
    
                                                        this.setState({
                                                            typeUpdate: 'update',
                                                            currentRadTab: tab,
                                                            currentRadTabIndex: index,
                                                            updateTab: true,
                                                        });
                                                    }}
                                                
                                                >
                                                    Modifier
                                                </span>
                                                <span className='updateSocial_inputBack'
                                                    onClick={() => {
                                                        this._updateTab( tab, 'delete' , index);
                                                    }}
                                                >
                                                    supprimer
                                                </span>
                                            </div>
                                        </div>
                                        <div className='bottomLine_green'></div>
                                    </div>
                                ))
                            }
                        </div>

                        <br/>
                        <br/>
                    </div>

                    {
                        this.state.addSection &&
                        <div className='addSection'>
                            <div className='addSection_'>
                                <div className='addSection_title'>
                                    <span>
                                        Ajouter une section à la page
                                    </span>
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Titre
                                    </span>
                                    <input type='text'
                                        value={this.state.newSection.name}
                                        onChange={(e) => {
                                            let newSection = this.state.newSection;
                                            newSection.name = e.target.value;
                                            this.setState({
                                                newSection: newSection,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='addSection_text'>
                                    <span className='addSection_text_'>
                                        Text
                                    </span>
                                    <div className='addSection_text_editor'>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={''}
                                            onReady={ editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log( 'Editor is ready to use!', editor );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                let newSection = this.state.newSection;
                                                newSection.text = data;
                                                this.setState({
                                                    newSection: newSection,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='updateSocial_inputSubmit_container'>
                                    <span className='updateSocial_inputSubmit'
                                        onClick={() => {
                                            this._addSection( this.state.newSection );
                                        }}
                                    >
                                        Valider
                                    </span>
                                    <span className='updateSocial_inputBack'
                                        onClick={() => {
                                            this.setState({
                                                addSection: false,
                                                newSection: {
                                                    name: '',
                                                    text: ''
                                                }
                                            });
                                        }}
                                    >
                                        Annuler
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.updatePartnaire&&
                        <div className='addSection'>
                            <div className='addSection_'>
                                <div className='addSection_title'>
                                    <span>
                                        { this.state.typeUpdate === "add" ? "Ajouter un partenaire de RAD-ONG" : "Modifier un partenaire de RAD-ONG"}
                                    </span>
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Logo du partenaire
                                    </span>
                                    <img className='currentLogoContainer' src={this.state.currentPartnaire.logo} alt='' />
                                    <div>
                                        <input className='choice_image' type="file" title='Choisir une image'
                                            onChange={(e) => {

                                                const files = Array.from( e.target.files );
                                                this.currentUploadFiles = e.target.files;
                                                // let file = files[0];

                                                getPreviewFiles( files ).then( images => {
                                                    let currentPartnaire = this.state.currentPartnaire;
                                                    currentPartnaire.logo = images[0];
                                                    this.setState({
                                                        currentPartnaire: currentPartnaire,
                                                    });
                                                    // console.log( images[0] );
                                                });
                                            }} 
                                        />
                                    </div>
                                    
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Lien vers le site web du partenaire
                                    </span>
                                    <input type='text'
                                        value={this.state.currentPartnaire.link}
                                        onChange={(e) => {
                                            let currentPartnaire = this.state.currentPartnaire;
                                            currentPartnaire.link = e.target.value;
                                            this.setState({
                                                currentPartnaire: currentPartnaire,
                                            });
                                        }}
                                    />
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Nom du partenaire
                                    </span>
                                    <input type='text'
                                        value={this.state.currentPartnaire.name}
                                        onChange={(e) => {
                                            let currentPartnaire = this.state.currentPartnaire;
                                            currentPartnaire.name = e.target.value;
                                            this.setState({
                                                currentPartnaire: currentPartnaire,
                                            });
                                        }}
                                    />
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Courte description du partenaire
                                    </span>
                                    <input type='text'
                                        value={this.state.currentPartnaire.slug}
                                        onChange={(e) => {
                                            let currentPartnaire = this.state.currentPartnaire;
                                            currentPartnaire.slug = e.target.value;
                                            this.setState({
                                                currentPartnaire: currentPartnaire,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='addSection_text'>
                                    <span className='addSection_text_'>
                                        Longue description du partenaire
                                    </span>
                                    <div className='addSection_text_editor'>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={this.state.currentPartnaire.description}
                                            onReady={ editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log( 'Editor is ready to use!', editor );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                let currentPartnaire = this.state.currentPartnaire;
                                                currentPartnaire.description = data;
                                                this.setState({
                                                    currentPartnaire: currentPartnaire,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='updateSocial_inputSubmit_container'>
                                    <span className='updateSocial_inputSubmit'
                                        onClick={() => {
                                            this._updatePartnaires( this.state.currentPartnaire, this.state.typeUpdate, this.state.currentPartnaireIndex);
                                        }}
                                    >
                                        Valider
                                    </span>
                                    <span className='updateSocial_inputBack'
                                        onClick={() => {
                                            this.setState({
                                                updatePartnaire: false,
                                                currentPartnaire: {
                                                    logo: '',
                                                    slug: '',
                                                    link: '',
                                                    name: '',
                                                    id: '',
                                                    description: '',
                                                },
                                            });
                                        }}
                                    >
                                        Annuler
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.updateTab&&
                        <div className='addSection'>
                            <div className='addSection_'>
                                <div className='addSection_title'>
                                    <span>
                                        { this.state.typeUpdate === "add" ? "Ajouter un tableaiu" : "Modifier un tableau"}
                                    </span>
                                </div>

                                <div className='addSection_input'>
                                    <span>
                                        Titre du tableau
                                    </span>
                                    <input type='text'
                                        value={this.state.currentRadTab.tabName}
                                        onChange={(e) => {
                                            let currentRadTab = this.state.currentRadTab;
                                            currentRadTab.tabName = e.target.value;
                                            this.setState({
                                                currentRadTab: currentRadTab,
                                            });
                                        }}
                                    />
                                </div>

                                <div className='addSection_text'>
                                    <span className='addSection_text_'>
                                        Editer le tableau ici
                                    </span>
                                    <div className='addSection_text_editor'>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={this.state.currentRadTab.tabData}
                                            onReady={ editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log( 'Editor is ready to use!', editor );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                let currentRadTab = this.state.currentRadTab;
                                                currentRadTab.tabData = data;
                                                this.setState({
                                                    currentRadTab: currentRadTab,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='updateSocial_inputSubmit_container'>
                                    <span className='updateSocial_inputSubmit'
                                        onClick={() => {
                                            this._updateTab( this.state.currentRadTab, this.state.typeUpdate, this.state.currentRadTabIndex);
                                        }}
                                    >
                                        Valider
                                    </span>
                                    <span className='updateSocial_inputBack'
                                        onClick={() => {
                                            this.setState({
                                                updateTab: false,
                                                currentRadTab: {
                                                    tabName: '',
                                                    tabData: ''
                                                },
                                            });
                                        }}
                                    >
                                        Annuler
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.uploadFiles&&
                        <div className='addSection'>
                            <div className='addSection_ uploadProgressPlatform'>
                                <div className='addSection_title'>
                                    <span>
                                        Envois des fichiers en cours
                                    </span>
                                </div>

                                <div className='addSection_input'>
                                    <div className='progress'>
                                        <div className='progressBar'
                                            style={{
                                                width: this.state.currentUploadProgression + "%"
                                            }}
                                        >

                                        </div>
                                    </div>
                                </div>

                                <div className="progressText">
                                    <span>
                                        {
                                            this.state.currentUploadProgression + "%"
                                        }
                                    </span>
                                </div>

                                {/* <div className="progressMessage">
                                    <span>
                                        {
                                            this.state.currentUploadMessage
                                        }
                                    </span>
                                </div> */}
                            </div>
                        </div>
                    }

                    {
                        this.state.loading &&
                        <div className='loading'>
                            <img src={loader} alt='' />
                        </div>
                    }
                </div>
            </>
        )
    }
}

DashboardRubriqueHome = connect(mapStateToProps, null)(DashboardRubriqueHome);
export default DashboardRubriqueHome;