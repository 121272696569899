import React from 'react';
import parse from 'html-react-parser';

/**Importation des composants de la page */
import ProjectCard from '../components/projectCard/projectCard.component';
import ActualityCard from '../components/actualityCard/actualityCard.component';

/**Importation des styles de la page */
import './styles/onCompetence.style.css';
import './styles/onCompetence.responsive.style.css';


/**Importation des images*/
import loader from '../assets/gif/loader2.gif';



/**Importation des helpers */
import Xanimation from '../helpers/x-animations';
import radProject from '../helpers/project.helper';
import radArticle from '../helpers/article.helper';
import radODD from '../helpers/ODD.helper';
import radExpertise from '../helpers/expertise.helper';
import tools from '../helpers/tools.helper';
import radDataAttr from '../helpers/data-attr.helper';
import imagesURL from '../helpers/imagesURL.helper';

const animer = new Xanimation();
animer.init( 50 );


class OnCompetenceView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            current_competence: {},
            loading: false,
            radODD: [],
            radExpertise: [],
            articles: [],
            projects: [],
        }

        this.radProject = new radProject();
        this.radArticle = new radArticle();
        this.radODD = new radODD();
        this.radExpertise = new radExpertise();
        this.tools = new tools();
        this.radDataAttr = new radDataAttr();

        this._getRadArticles = this._getRadArticles.bind( this );
        this._getRadODD = this._getRadODD.bind( this );
        this._getRadExpertise = this._getRadExpertise.bind( this );
        this._start = this._start.bind( this );
    }

    componentDidMount() {
        // console.log( this.props.match.params.testvalue );
        // window.scrollTo(0, 0);
        window.onload = animer.start();

        this._start();
    }

    componentWillUnmount() {
        animer.stop();
    }

    _start( params = null ) {
        this.setState({
            loading: true,
            current_odd: {},
            articles: [],
            projects: [],
        });
        // window.scrollTo(0, 0);
        
        this.query = new URLSearchParams( this.props.location.search );
        let expertise_ = this.query.get( "competence" );

        if( params !== null ) {
            expertise_ = params;
        }

        // console.log( expertise_ );
        
        this._getRadExpertise(()=>{
            this.state.radExpertise.map((expertise, index) => {
                if( expertise.name === expertise_ ) {
                    // console.log( odd );
                    let current_expertise = expertise;
                    current_expertise.desc = parse(current_expertise.desc.replace(/<p>&nbsp;<\/p>/g, '<br/>'));
                    this.setState({
                        current_competence: current_expertise,
                    }, () => {
                        this._getRadODD(() => {
                            this._getRadProjects(( projectList )=>{
                                let projects = this.state.projects;

                                projectList.map((project, index) => {
                                    if( project.tags.includes( expertise.id ) ) {
                                        let newProject = {
                                            name: project.name,
                                            shortDescription: parse(project.desc),
                                            oddIDs: project.odds,
                                            tagIDs: project.tags,
                                            oddList: [],
                                            cover: "",
                                            imagesList: [],
                                            competencesDomaine: [],
                                            status: project.status,
                                        }

                                        // console.log( newProject );

                                        this.state.radODD.map((odd) => {
                                            if(  project.odds.includes(odd.id) ) {
                                                newProject.oddList = newProject.oddList.concat( odd.logo );
                                            }
                                        });
                    
                                        this.state.radExpertise.map((expertise) => {
                                            if(  project.tags.includes(expertise.id) ) {
                                                newProject.competencesDomaine = newProject.competencesDomaine.concat( expertise.name );
                                            }
                                        });

                                        this._getProjectFolder( project.name, ( projectFolder )=>{
                                            newProject.cover = projectFolder.length > 0 ? imagesURL + "upload/" + projectFolder[0][0] : "";
                                            newProject.imagesList = projectFolder;
                    
                                            // console.log( newProject );
                                            projects.push( newProject );

                                            this.setState({
                                                projects: projects,
                                                loading: false,
                                            });
                                        });
                                    }

                                    if( index === projectList.length - 1 ) {
                                        this.setState({
                                            loading: false,
                                        });
                                    }
                                });
                            });

                            this._getRadArticles((articles)=>{
                                let articlesList = this.state.articles;
                                articles.map((article, index) => {
                                    if( article.tags.includes( expertise.id ) ) {
                                        let newArticle = {
                                            name: article.name,
                                            title: article.title,
                                            shortDescription: parse(article.desc),
                                            content: parse(article.content.replace(/<p>&nbsp;<\/p>/g, '<br/>')),
                                            oddIDs: article.odds,
                                            tagIDs: article.tags,
                                            oddList: [],
                                            galerie: [],
                                            cover: "",
                                            cover2: "", 
                                            start_date: article.start_date,
                                            end_date: article.end_date,
                                            competencesDomaine: [],
                                            status: article.status,
                                            article: article.article,
                                            refs: article.refs,
                                            created_at: article.created_at,
                                        }
                
                                        this.state.radODD.map((odd) => {
                                            if(  article.odds.includes(odd.id) ) {
                                                newArticle.oddList = newArticle.oddList.concat( odd.logo );
                                            }
                                        });
                    
                                        this.state.radExpertise.map((expertise) => {
                                            if(  article.tags.includes(expertise.id) ) {
                                                newArticle.competencesDomaine = newArticle.competencesDomaine.concat( expertise.name );
                                            }
                                        });
                
                                        this._getProjectFolder( article.name, ( articleFolder )=>{
                                            newArticle.cover = articleFolder.length > 0 ? imagesURL + "upload/" + articleFolder[0][0] : "";
                                            newArticle.cover2 = articleFolder.length > 0 ? imagesURL + "upload/" + articleFolder[0][0] : "";
                                            newArticle.imagesList = articleFolder;
                    
                                            for( let k=0; k<articleFolder.length; k ++) {
                                                articleFolder[k] = {
                                                    src: imagesURL + "upload/" + articleFolder[k][0],
                                                    src2: imagesURL + "upload/" + articleFolder[k][0],
                                                    text: articleFolder[k][1]
                                                };
                                            }
                    
                                            newArticle.galerie = articleFolder;
                                            articlesList = articlesList.concat( newArticle );
                
                                            if( index === articles.length - 1 ) {
                                                articlesList = this.tools._sortByDate(articlesList, "created_at");
                                                this.setState({
                                                    loading: false,
                                                    articles: this.tools._sortByDate(articlesList, "created_at"),
                                                });
                                            }
                                        });
                                    }
                                });
                            });
                        });
                    });
                }
            })
        })
    }

    _getRadArticles( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            loading: true,
            // projects: [],
        });

        this.radArticle._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadArticles( callback,tab, page + 1 );
            }

            else {
                callback( this.tools._sortByDate(tab, 'created_at') );
            }
        }).catch((e) => {
            
            if( e.response ) {
                if( e.response.status === 401 ) {
                    
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadProjects(callback = ()=>{}, tab = [], page = 1 ) {
        this.radProject._getProjects(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadProjects( callback, tab, page + 1 );
            }

            else {
                callback( this.tools._sortByDate(tab, 'created_at') );
            }
        }).catch((e) => {
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getProjectFolder( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            response.data.results.map((expertise, index) => {
                tab.push( expertise );
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getProjectFolder( search, callback, tab, page + 1 );
            }

            else {
                if( tab.length > 0 ){
                    tab.map((element, index) => {
                        if( element.name === search ) {
                            let folder = JSON.parse( element.value );
                            for( let j=0; j<folder.length; j++ ) {
                                folder[j] = folder[j].split("*****");
                            }
                            return callback( folder );
                        }
                    });
                }
                else {
                    return callback([]);
                }
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadExpertise( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            radExpertise: [],
        });
        this.radExpertise._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadExpertise( callback, tab, page + 1 );
            }

            else {
                this.setState({
                    radExpertise: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    callback();
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadODD( callback = ()=>{}, tab = [], page = 1 ) {
        this.setState({
            radODD: [],
        });
        this.radODD._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadODD( callback, tab, page + 1 );
            }

            else {
                this.setState({
                    radODD: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    callback();
                });
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    render() {
        return (
            <>
                <div className="OnCompetenceView">
                    <div className="OnCompetenceView_top">
                        <div className="OnCompetenceView_top_subtext">
                            <span className="OnCompetenceView_top_subtext_ title">
                                #Domaines_de_compétences
                            </span>
                        </div>
                        <div className="OnCompetenceView_top_title">
                            { this.state.current_competence.name &&
                                <span>
                                    {this.state.current_competence.name}
                                </span>
                            }
                        </div>
                    </div>
                    <div className="OnCompetenceView_body">
                        <div className="OnProjectsView_body_head">
                            <div className="OnProjectsView_body_head_text title">
                                <span className="">Présentation</span>
                            </div>
                            {/* <div></div> */}
                        </div>
                        <div className="OnProjectsView_body_subDescription text-sizes-1 left-border">
                            { this.state.current_competence.desc &&
                                <p className="textForm">
                                    {this.state.current_competence.desc}
                                </p>
                            }
                        </div>

                        <div className="OnCompetenceView_body_">
                            <div className="projectsView_Section2_titleContainer">
                                <div className="projectsView_Section2_title">
                                    <span>
                                        Projets en rapport
                                    </span>
                                </div>
                            </div>

                            <div className="projectsView_Section2_body">
                                <div className="projectsView_Section2_body_">
                                    {
                                        this.state.projects.map((project, index) => (
                                            <div key={index} className="projectsView_Section2_body_project x-animation"
                                                x-animation="from-bottom"
                                                x-animation-delay="0s"
                                                x-animation-duration="2s"
                                            >
                                                <ProjectCard project={project} reload2={true} actionTag={(expertise)=>{this._start(expertise)}}/>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="projectsView_Section2_titleContainer x-animation"
                                x-animation="from-bottom"
                                x-animation-delay="0s"
                                x-animation-duration="2s"
                            >
                                <div className="projectsView_Section2_title ">
                                    <span>
                                        Actualités en rapport
                                    </span>
                                </div>
                            </div>

                            <div className="projectsView_Section2_body">
                                <div className="projectsView_Section2_body_">
                                    {
                                        this.state.articles.map((project, index) => (
                                            <div key={index} className="projectsView_Section2_body_project x-animation"
                                                x-animation="from-bottom"
                                                x-animation-delay="0s"
                                                x-animation-duration="2s"
                                            >
                                                <ActualityCard project={project} reload2={true} actionTag={(expertise)=>{this._start(expertise)}}/>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.loading &&
                        <div className='loading'>
                            <img src={loader} alt='' />
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default OnCompetenceView;