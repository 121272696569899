import React from 'react';
import { connect } from 'react-redux';


/**Importation des composants de la page */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ImageSelector from '../components/imageSelector/imageSelector.component';


/** Importation des screens */
// Screen de la page d'accueil


/**Importation des styles de la page */
import './styles/dashboard.viewProject.style.css';
// import './styles/dashboard.addProject.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';


/**Importation des helpers */
import radExpertise from '../helpers/expertise.helper';
import radODD from '../helpers/ODD.helper';
import tools from '../helpers/tools.helper';
import refreshConnection from '../helpers/refresh.helper';
import uploadFiles from '../helpers/upload_file.helper';
import radProject from '../helpers/project.helper';
import radArticle from '../helpers/article.helper';
import radDataAttr from '../helpers/data-attr.helper';
import imagesURL from '../helpers/imagesURL.helper';

import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}

class DashboardViewArticle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            radExpertise: [],
            radODD: [],
            projectStatus: [],
            loading: false,

            uploadFiles: false,
            currentUploadProgression: 0,
            currentUploadMessage: '',

            projects: [],
            articles: [],
            articlesFolders: [],

            isOpened: -1,
        }

        this.accessStore = new AccessStore(this.props.dispatch);
        this.radExpertise = new radExpertise();
        this.radODD = new radODD();
        this.tools = new tools();
        this.radProject = new radProject();
        this.radArticle = new radArticle();
        this.radDataAttr = new radDataAttr();

        this._getRadProjects = this._getRadProjects.bind( this );
        this._getRadArticles = this._getRadArticles.bind( this );
    }

    _setNewAccessToUser(callback) {
        refreshConnection(this.props.user.refresh).then((response) => {
            let newUser = this.props.user;
            newUser.access = response.data.access;

            if (response.data.refresh) {
                newUser.refresh = response.data.refresh;
            }

            // console.log( response, this.props.user, newUser );

            this.accessStore.setRedux('SET_USER', newUser, callback);
            // this._getRadExpertise();
        }).catch((e) => {
            if (e.response) {
                if (e.response.status === 401) {
                    this.accessStore.setRedux('LOG_OUT', {});
                }
            }
            if (e.toString() === "Error: Network Error") {
                alert("Aucun accès à internet :(");
            }
        });
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });
        this._getRadODD();
        this._getRadExpertise();
        this._getProjectsStatus();

        this._getRadProjects();
        this._getRadArticles();
    }

    _getArticleFolder( search, callback = ()=>{}, tab = [], page = 1 ) {
        // console.log(search);
        this.radDataAttr._getOne(search, page).then((response) => {
            response.data.results.map((content, index) => {
                tab = tab.concat( content );
                // console.log(tab);
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getArticleFolder( search, callback, tab, page + 1 );
            }

            else {
                // console.log( tab );
                if( tab.length > 0 ){
                    tab.map((element, index) => {
                        if( element.label === search ) {
                            if( element.value !== "" ){
                                let folder = JSON.parse( element.value );
                                for( let j=0; j<folder.length; j++ ) {
                                    folder[j] = folder[j].split("*****");
                                    folder[j][2] = element.label;
                                }
                                // console.log( folder );
                                callback( folder, element.id );
                            }
                            else {
                                callback( [], element.id );
                            }
                            // console.log( folder );
                        } else {
                            callback( [], element.id );
                        }
                    });
                }
                else {
                    callback([]);
                }
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadProjects( tab = [], page = 1 ) {
        this.setState({
            loading: true,
            projects: [],
        });

        this.radProject._getProjects(false, this.props.user.access, page).then((response) => {
            tab = tab.concat( response.data.results );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadProjects( tab, page + 1 );
            }

            else {
                this.setState({
                    projects: this.tools._sortByDate(tab, 'created_at'),
                    loading: false,
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this.componentDidMount );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadArticles( tab = [], page = 1 ) {
        this.setState({
            loading: true,
            projects: [],
        });

        this.radArticle._getAll(false, this.props.user.access, page).then((response) => {
            tab = tab.concat( response.data.results );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadArticles( tab, page + 1 );
            }

            else {
                // console.log( tab );
                tab.map((article, index) => {
                    console.log(article);
                    this._getArticleFolder( article.name, ( folder, folderId ) => {
                        article.folder = folder;
                        article.folder_id = folderId;
                        // console.log( article );

                        if( index === tab.length-1 ) {
                            this.setState({
                                articles: this.tools._sortByDate(tab, 'created_at'),
                                loading: false,
                            });
                        }
                    });
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this.componentDidMount );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }


    _getRadExpertise( tab = [], page = 1 ) {
        this.setState({
            loading: true,
            radExpertise: [],
        });
        this.radExpertise._getAll(true, this.props.user.access, page).then((response) => {
            // console.log( response );
            response.data.results.map((expertise, index) => {
                if( expertise.logo == null || expertise.logo === '' || expertise.logo === undefined) {
                    tab.push( expertise );
                }
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadExpertise( tab, page + 1 );
            }

            else {
                this.setState({
                    radExpertise: this.tools._sortByDate(tab, 'created_at'),
                    loading: false,
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this.componentDidMount );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _deleteFolderImage_(articleIndex, imageIndex) {
        let articles = this.state.articles;
        let article = articles[ articleIndex ]

        let articleFolder = article.folder.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        article.folder = articleFolder;

        this.setState({
           articles: articles,
        });
    }

    _deleteImage(articleIndex, imageIndex) {
        let articles = this.state.articles;
        let article = articles[ articleIndex ]

        let filteredImageSelectedList = article.ImageSelectedList.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        let filteredImageSelectedPreviewLink = article.ImageSelectedPreviewLink.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        let filteredFilesSelected = article.filesSelected.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        article.ImageSelectedList = filteredImageSelectedList;
        article.ImageSelectedPreviewLink = filteredImageSelectedPreviewLink;
        article.filesSelected = filteredFilesSelected;

        this.setState({
           articles: articles,
        });
    }

    _deleteAllImages(articleIndex) {
        let articles = this.state.articles;
        let article = articles[ articleIndex ]
        article.ImageSelectedList = [];
        article.ImageSelectedPreviewLink = [];
        article.filesSelected = [];
        this.setState({
            articles: articles
        });
    }

    _getRadODD( tab = [], page = 1 ) {
        this.setState({
            radODD: [],
        });
        this.radODD._getAll(false, this.props.user.access, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadODD( tab, page + 1 );
            }

            else {
                this.setState({
                    radODD: this.tools._sortByDate(tab, 'created_at'),
                    loading: false,
                });
            }
        }).catch((e) => {
            // console.log( e );
            if( e.response ) {
                if( e.response.status === 401 ) {
                    // this._setNewAccessToUser( this._getRadODD );
                    this._setNewAccessToUser( this.componentDidMount );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getProjectsStatus() {
        this.setState({
            projectStatus: [],
        });
        this.radProject._getProjectsStatus(false, this.props.user.access).then((response) => {
            // console.log( response.data );
            this.setState({
                projectStatus: this.tools._sortByDate2(response.data.results, 'created_at'),
                loading: false,
            });
        }).catch((e) => {
            if( e.response ){
                if (e.response.status === 401) {
                    // this._setNewAccessToUser(this._getProjectsStatus);
                    this._setNewAccessToUser( this.componentDidMount );
                }
            }
            this.setState({
                loading: false,
            });
            if (e.toString() === "Error: Network Error") {
                alert("Aucun accès à internet :(");
            }
        });
    }

    isTarget( bool, target, type, index, key ) {
        let articles = this.state.articles;

        if( bool === true ) {
            articles[index][key] = articles[index][key].concat(target.id);
            this.setState({
                articles: articles,
            });
        }

        else {
            for( var j=0; j<articles[index][key].length; j++) {
                if( articles[index][key][j] === target.id ) {
                    articles[index][key].splice(j, 1);
                }
            }
            this.setState({
                articles: articles,
            });
        }
    }

    _projectIsRef( bool, articleId, index ) {
        let articles = this.state.articles;

        if( bool === true ) {
            articles[index].refs = articles[index].refs.concat(articleId);
            this.setState({
                articles: articles,
            });
        }

        else {
            for( var j=0; j<articles[index].refs.length; j++) {
                if( articles[index].refs[j] === articleId ) {
                    articles[index].refs.splice(j, 1);
                }
            }
            this.setState({
                articles: articles,
            });
        }
    }

    _changeArticle( value, index, key ) {
        let articles = this.state.articles;
        articles[index][key] = value;
        this.setState({
            articles: articles,
        });
    }

    _changeFolder( value, index, key ) {
        let articles = this.state.articles;
        articles[index]["folder"] = value;
        this.setState({
            articles: articles,
        });
    }

    _deleteArticle( articleIndex, retry=false ) {
        let article = this.state.articles[ articleIndex ];
        // console.log( article );
        this.setState({
            loading: true,
        });

        /**
         * Si on a des images à envoyer
         */

        let confirm = null;

        if( retry === true ) {
            confirm = true;
        }

        else {
            confirm = window.confirm("Voulez-vous vraiment supprimer cet actualité?");
        }

        if( confirm ) {
            this.radDataAttr._delete(article.folder_id, this.props.user.access).then((response) => {
                this.radArticle._delete(article.id, this.props.user.access).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    this._getRadProjects();
                    this._getRadArticles();
                    alert("Actualité supprimée avec succès");
                }).catch((e) => {
                    if( e.response ) {
                        if( e.response.status === 401 ) {
                            this._setNewAccessToUser( this._deleteArticle( articleIndex, true ) );
                        }
    
                        if( e.response.data ) {
                            // console.log( e.response.data );
                            if( e.response.data.non_field_errors ) {
                                
                            }
                        }
                    }
    
                    this.setState({
                        loading: false,
                    });
                    if( e.toString() === "Error: Network Error" ) {
                        alert( "Aucun accès à internet :(" );
                    }
                });
            }).catch((e) => {
                if( e.response ) {
                    if( e.response.status === 401 ) {
                        this._setNewAccessToUser( this._deleteArticle( articleIndex, true ) );
                    }

                    if( e.response.data ) {
                        // console.log( e.response.data );
                        if( e.response.data.detail ) {
                            if( e.response.data.detail === 'Not found.' ) {
                                this.radArticle._delete(article.id, this.props.user.access).then((response) => {
                                    this.setState({
                                        loading: false,
                                    });
                                    this._getRadProjects();
                                    this._getRadArticles();
                                    alert("Actualité supprimée avec succès");
                                }).catch((e) => {
                                    if( e.response ) {
                                        if( e.response.status === 401 ) {
                                            this._setNewAccessToUser( this._deleteArticle( articleIndex, true ) );
                                        }
                    
                                        if( e.response.data ) {
                                            // console.log( e.response.data );
                                            if( e.response.data.non_field_errors ) {
                                                
                                            }
                                        }
                                    }
                    
                                    this.setState({
                                        loading: false,
                                    });
                                    if( e.toString() === "Error: Network Error" ) {
                                        alert( "Aucun accès à internet :(" );
                                    }
                                });
                            }
                        }
                        if( e.response.data.non_field_errors ) {
                            
                        }
                    }
                }

                this.setState({
                    loading: false,
                });
                if( e.toString() === "Error: Network Error" ) {
                    alert( "Aucun accès à internet :(" );
                }
            });
        }

        else {
            this.setState({
                loading: false,
            });
        }
    }

    _updateArticle(articleIndex, article, article_) {
        this.setState({
            loading: true,
        });
        this.radArticle._update(article.id, article_, this.props.user.access).then((res) => {
            alert("Actualité modifiée avec succès");
            this.setState({
                loading: false,
            });
            this._getRadProjects();
            this._getRadArticles();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._updateArticle(articleIndex, article, article_));
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }

            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _update_dataAttr_Snd_send_article(articleIndex, article, dataAttr) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._update(article.folder_id, JSON.stringify(dataAttr), this.props.user.access).then((response) => {
            let article_ = {
                name: article.name,
                desc: article.desc,
                content: article.content,
                title: article.title,
                status: article.status,
                tags: article.tags,
                odds: article.odds,
                refs: article.refs,
            };

            this._updateArticle(articleIndex, article, article_);
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._update_dataAttr_Snd_send_article(articleIndex, article, dataAttr) );
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _post_dataAttr_Snd_send_article(articleIndex, article, dataAttr) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._post(JSON.stringify(dataAttr), this.props.user.access).then((response) => {
            let article_ = {
                name: article.name,
                desc: article.desc,
                content: article.content,
                title: article.title,
                status: article.status,
                tags: article.tags,
                odds: article.odds,
                refs: article.refs,
            };
            this._updateArticle(articleIndex, article, article_);
            
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._post_dataAttr_Snd_send_article(articleIndex, article, dataAttr) );
                }

                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }
    

    _UpdateArticle( articleIndex, retry=false ) {
        let article = this.state.articles[ articleIndex ];
        this.setState({
            loading: true,
        });
        /**
         * Si on a des images à envoyer
         */
        let confirm = null;
        if( retry === true ) {
            confirm = true;
        }
        else {
            confirm = window.confirm("Voulez-vous vraiment modifier cet article?");
        }
        if( article.ImageSelectedList && article.ImageSelectedList.length > 0 ) {
            if( confirm ){
                this.setState({
                    loading: true,
                    uploadFiles: true,
                });
                uploadFiles( "article-" + article.name, article.filesSelected, (event) => {
                    this.setState({
                        currentUploadProgression: Math.round((100 * event.loaded) / event.total),
                    });
                }).then((response) => {
                    // console.log( response );
                    if( response.data.code === 201 ){
                        this.setState({
                            uploadFiles: false,
                        });
    
                        let imagesUploaded = [];
    
                        response.data.files_uploaded_list.images.map((img, index) => {
                            for (var i in article.ImageSelectedList) {
                                if (img.name.split("/")[img.name.split("/").length-1] === article.ImageSelectedList[i].file.name) {
                                    imagesUploaded = imagesUploaded.concat( "article-" + article.name + "/" + article.ImageSelectedList[i].file.name + "*****" + article.ImageSelectedList[i].description );
                                }
                            }
                        });

                        if( article.folder_id !== null && article.folder_id !== undefined && article.folder_id !== "null" && article.folder_id !== "undefined" ) {
                            let newFolder = [];
                            for (var i in article.folder) {
                                newFolder[i] =  article.folder[i][0] + "*****" + article.folder[i][1];
                            }
                            let dataAttr = {
                                value: JSON.stringify( newFolder.concat(imagesUploaded) ),
                                name: article.name,
                                data: 1,
                            };
                            /**
                             * On envois les informations des fichiers du projet(nom de fichier et description)
                             */
                            this._update_dataAttr_Snd_send_article(articleIndex, article, dataAttr);
                        }

                        else {
                            let dataAttr = {
                                label: article.name,
                                desc: "Le contenu folder de " + article.name ,
                                value: JSON.stringify(imagesUploaded),
                                name: article.name,
                                type: 2,
                                data: 1,
                            }
                            this._post_dataAttr_Snd_send_article(articleIndex, article, dataAttr);
                        }
                        /**
                         * On envois les informations des fichiers du projet(nom de fichier et description)
                         */
                    }
    
                    else {
                        alert('Echec de l\'envois du fichier. Veuillez réessayer');
                        this.setState({
                            loading: false,
                            uploadFiles: false,
                        });
                    }
                }).catch((e) => {
                    this.setState({
                        loading: false,
                        uploadFiles: false,
                    });
                    alert('Echec de l\'envois du fichier. Veuillez réessayer');
                    // console.log( e );
                });
            }

            else {
                this.setState({
                    loading: false,
                });
            }
        }

        /**
         * Si on a pas des images à envoyer
         */

        else {
            if( confirm ){
                this.setState({
                    loading: true,
                });
                /**
                 * 
                 * SI le folder ID exist, alors on update
                 */
                
                if( article.folder_id !== null && article.folder_id !== undefined && article.folder_id !== "null" && article.folder_id !== "undefined" ) {
                    let newFolder = [];
                    for (var i in article.folder) {
                        newFolder[i] = article.folder[i][0] + "*****" + article.folder[i][1];
                    }
                    let dataAttr = {
                        value: JSON.stringify( newFolder ),
                        name: article.name,
                        data: 1,
                    };
                    /**
                     * On envois les informations des fichiers du projet(nom de fichier et description)
                     */
                    this._update_dataAttr_Snd_send_article(articleIndex, article, dataAttr);
                }

                /**
                 * SI NON, SI
                 * le folder ID n'exist pas, alors on post
                 */
                else {
                    let dataAttr = {
                        label: article.name,
                        desc: "Le contenu folder de " + article.name ,
                        value: JSON.stringify([]),
                        name: article.name,
                        type: 2,
                        data: 1,
                    }
                    this._post_dataAttr_Snd_send_article(articleIndex, article, dataAttr);
                }                        
                /**
                 * On envois les informations des fichiers du projet(nom de fichier et description)
                 */
            }
            else {
                this.setState({
                    loading: false,
                });
            }
        }
    }

    render() {
        return (
            <>
                <div className='add'>
                    <div className='add_head'>
                        <span>
                            Liste des actualités
                        </span>
                    </div>
                    <div className='ProjectsContainer'>
                        {
                            this.state.projectStatus.map((status, index) => (
                                <div className='projectStatus' key={"rad-status--/--" + index}>
                                    <div className='projectStatus_'>
                                        <div className='status-name'>
                                            <div className="projectsView_Section2_titleContainer x-animation"
                                                x-animation="from-bottom"
                                                x-animation-delay="0s"
                                                x-animation-duration="2s"
                                            >
                                                <div className="projectsView_Section2_title">
                                                    <span>
                                                        Actualités { status.name.toString().toLowerCase() }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            {
                                                this.state.articles.map((article, index) => (
                                                    <div className='projects_' key={"rad-project--/--" + index}>
                                                        {
                                                            article.status === status.id ?
                                                                <div className='projects__'>
                                                                    <div className='project-name'
                                                                        onClick={() => {
                                                                            if( this.state.isOpened === article.id ) {
                                                                                this.setState({
                                                                                    isOpened: -1,
                                                                                })
                                                                            }
                                                                            else {
                                                                                this.setState({
                                                                                    isOpened: article.id,
                                                                                })
                                                                            }
                                                                        }}

                                                                        style={{
                                                                            width: this.state.isOpened === article.id ? "100%" : "auto",
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {
                                                                                article.title
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className='project-content-container'
                                                                        style={{
                                                                            height: this.state.isOpened === article.id ? "auto" : "0px"
                                                                        }}
                                                                    >
                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Titre de l'actualité
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_input'>
                                                                                <input
                                                                                    value={article.title}
                                                                                    type='text'
                                                                                    placeholder='Entrez le titre du projet ici'
                                                                                    onChange={(e) => {
                                                                                        this._changeArticle(e.target.value, index, "title");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Statut de l'actualité
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_input'>
                                                                                <select value={article.status} onChange={(e) => {
                                                                                    // console.log( project.status );
                                                                                    if( window.confirm("Voulez-vous vraiment changé le status de cet article?")){
                                                                                        this._changeArticle(e.target.value, index, "status");
                                                                                        this._UpdateArticle( index );
                                                                                    }
                                                                                }}>
                                                                                    {
                                                                                        this.state.projectStatus.map((status, index) => (
                                                                                            <option key={"status" + index} value={status.id}>
                                                                                                {status.name}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Date de l'actualité
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_input'>
                                                                                <input
                                                                                    value={this.tools.formatDate(article.created_at)}
                                                                                    type='date'
                                                                                    placeholder=''
                                                                                    onChange={(e) => {
                                                                                        this._changeArticle(e.target.value, index, "created_at");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Courte présentation de l'actualité
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_editor2 radODD_editor'>
                                                                                <CKEditor
                                                                                    editor={ClassicEditor}
                                                                                    data={article.desc}
                                                                                    onReady={editor => {
                                                                                        // You can store the "editor" and use when it is needed.
                                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                                    }}
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        this._changeArticle(data, index, "desc");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Domaines de compétences associés
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_checkBox'>
                                                                                {
                                                                                    this.state.radExpertise.map((expertise, index_1) => (
                                                                                        <label key={index_1} for={'dc' + article.id + index_1} className='add_content_checkBox_ competenceCheckbox'>
                                                                                            <input id={'dc' + article.id + index_1} name={'dc' + article.id + index_1} type='checkbox' checked={ article.tags.includes(expertise.id) }
                                                                                                onChange={(e)=>{
                                                                                                    // console.log( e.target.checked );
                                                                                                    this.isTarget( e.target.checked, expertise, "expertise", index, "tags" );
                                                                                                }}
                                                                                            />
                                                                                            <label for={'dc' + article.id + index_1}>
                                                                                                {expertise.name}
                                                                                            </label>
                                                                                        </label>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Objectifs de développement associés
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_checkBox'>
                                                                                {
                                                                                    this.state.radODD.map((odd, index_2) => (
                                                                                        <label key={index_2} for={'odd' + article.id + index_2} className='add_content_checkBox_ oddCheckbox'>
                                                                                            <input id={'odd' + article.id + index_2} name={'odd' + article.id + index_2} type='checkbox' checked={ article.odds.includes(odd.id) }
                                                                                                onChange={(e)=>{
                                                                                                    this.isTarget( e.target.checked, odd, "odd", index, "odds" );
                                                                                                }}
                                                                                            />
                                                                                            <label for={'odd' + article.id + index_2}>
                                                                                                <img className='add_content_checkBox_odd' src={"/ODD/odd" + odd.logo + ".png"} alt={"ODD"} />
                                                                                            </label>
                                                                                        </label>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Projets associés
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_checkBox'>
                                                                                {
                                                                                    this.state.projects.map((project, project_index) => (
                                                                                        <label key={'project' + project_index + "-" + article.name} htmlFor={'project' + project_index + "-" + article.name} className='add_content_checkBox_ competenceCheckbox'>
                                                                                            <input id={'project' + project_index + "-" + article.name} name={'project' + project_index + "-" + article.name} type='checkbox' checked={ article.refs.includes(project.article)}
                                                                                                onChange={(e)=>{
                                                                                                    this._projectIsRef( e.target.checked, project.article, index );
                                                                                                    // console.log( article )
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor={'project' + project_index + "-" + article.name}>
                                                                                                {project.name}
                                                                                            </label>
                                                                                        </label>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Contenu du l'actualité
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_editor'>
                                                                                <CKEditor
                                                                                    editor={ClassicEditor}
                                                                                    data={ article.content ? article.content : "" }
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        this._changeArticle(data, index, "content");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Galerie de l'actualité
                                                                                </span>
                                                                            </div>
                                                                            <div className="previewImageSelected_container">
                                                                                {
                                                                                    article.folder && article.folder.map((folder, FolderIndex) => (
                                                                                        <div className='previewImageContainer'>
                                                                                            <div className="previewImageSelected_">
                                                                                                <svg onClick={() => {
                                                                                                    this._deleteFolderImage_(index, FolderIndex);
                                                                                                }} className='previewImageSelected_cross' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529" />
                                                                                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529" />
                                                                                                </svg>
                                                                                                <img className="previewImageSelected" key={"image" + index + "-" + FolderIndex} src={ folder.length > 0 ? imagesURL + "upload/" + folder[0] : "" } alt="RAD-ONG"
                                                                                                    onError={(e) => {
                                                                                                        if( e.target.alt === "RAD-ONG" ) {
                                                                                                            e.target.alt = "RAD-ONG-2"
                                                                                                            // e.target.src = folder.length > 0 ? imagesURL + "upload/article-" + folder[2] + "/" + folder[0] : ""
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div className='imageDescription'>
                                                                                                <input type="text" placeholder="Description de l'image ici" value={folder[1]}
                                                                                                    onChange={(e) => {
                                                                                                        let folder_ = article.folder;
                                                                                                        folder_[ FolderIndex ][1] = e.target.value;
                                                                                                        this._changeFolder(folder_, index);
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>


                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Ajoutez des images
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_imageSelector'>
                                                                                <ImageSelector
                                                                                    onChangeImage={async (e) => {
                                                                                        const files = Array.from(e);

                                                                                        let article_ = article;

                                                                                        if( !article_.filesSelected || article_.filesSelected === null || article_.filesSelected === undefined ) {
                                                                                            article_.filesSelected = [];
                                                                                        }

                                                                                        if( !article_.ImageSelectedList || article_.ImageSelectedList === null || article_.ImageSelectedList === undefined ) {
                                                                                            article_.ImageSelectedList = [];
                                                                                        }

                                                                                        
                                                                                        if( !article_.ImageSelectedPreviewLink || article_.ImageSelectedPreviewLink === null || article_.ImageSelectedPreviewLink === undefined ) {
                                                                                            article_.ImageSelectedPreviewLink = [];
                                                                                        }

                                                                                        article_.filesSelected = article_.filesSelected.concat(files);
                                                                                        files.map((file, index)=>{
                                                                                            article_.ImageSelectedList = article_.ImageSelectedList.concat({
                                                                                                file: file,
                                                                                                description: '',
                                                                                            });
                                                                                        });

                                                                                        Promise.all(files.map(file => {
                                                                                            return (
                                                                                                new Promise((resolve, reject) => {
                                                                                                    const reader = new FileReader();
                                                                                                    reader.addEventListener('load', (ev) => {
                                                                                                        resolve(ev.target.result);
                                                                                                    });
                                                                                                    reader.addEventListener('error', reject);
                                                                                                    reader.readAsDataURL(file);
                                                                                                })
                                                                                            )
                                                                                        })).then(images => {
                                                                                            article_.ImageSelectedPreviewLink = article_.ImageSelectedPreviewLink.concat(images);
                                                                                            let allArticles = this.state.articles;
                                                                                            allArticles[index] = article_;
                                                                                            // console.log( allProjects );
                                                                                            this.setState({
                                                                                                projects: allArticles,
                                                                                            });
                                                                                        });

                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            {
                                                                                article.ImageSelectedPreviewLink && article.ImageSelectedPreviewLink.length > 0 &&
                                                                                <div className='delete_all_image'>
                                                                                    <span className='delete_all_image_text' onClick={() => {
                                                                                        this._deleteAllImages( index );
                                                                                    }}>
                                                                                        <span>
                                                                                            Supprimer toutes les images
                                                                                        </span>
                                                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            }

                                                                            <div className="previewImageSelected_container">
                                                                                {
                                                                                    article.ImageSelectedPreviewLink && article.ImageSelectedPreviewLink.map((link, imageIndex) => (
                                                                                        <div className='previewImageContainer'>
                                                                                            <div className="previewImageSelected_">
                                                                                                <svg onClick={() => {
                                                                                                    this._deleteImage(index, imageIndex);
                                                                                                }} className='previewImageSelected_cross' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529" />
                                                                                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529" />
                                                                                                </svg>
                                                                                                <img className="previewImageSelected" key={"preview" + index + "-" + imageIndex} src={link} alt="RAD-ONG" />
                                                                                            </div>
                                                                                            <div className='imageDescription'>
                                                                                                <input type="text" placeholder="Description de l'image ici" value={article.ImageSelectedList[imageIndex].description}
                                                                                                    onChange={(e) => {

                                                                                                        let ImageSelectedList = article.ImageSelectedList;
                                                                                                        ImageSelectedList[imageIndex].description = e.target.value;

                                                                                                        this._changeArticle(ImageSelectedList, index, "ImageSelectedList");
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>

                                                                            <div>
                                                                                <div className='updateSocial_inputSubmit_container button-container'>
                                                                                    <span className='updateSocial_inputSubmit'
                                                                                        onClick={() => {
                                                                                            this._UpdateArticle( index );
                                                                                            return true;
                                                                                        }}
                                                                                    >
                                                                                        Modifier l'actualité
                                                                                    </span>
                                                                                    <span className='updateSocial_inputBack'
                                                                                        onClick={() => {
                                                                                            this._deleteArticle( index );
                                                                                            return true;
                                                                                        }}
                                                                                    >
                                                                                        Supprimer l'actualité
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                    {
                        this.state.uploadFiles&&
                        <div className='addSection'>
                            <div className='addSection_ uploadProgressPlatform'>
                                <div className='addSection_title'>
                                    <span>
                                        Envois des fichiers en cours
                                    </span>
                                </div>

                                <div className='addSection_input'>
                                    <div className='progress'>
                                        <div className='progressBar'
                                            style={{
                                                width: this.state.currentUploadProgression + "%"
                                            }}
                                        >

                                        </div>
                                    </div>
                                </div>

                                <div className="progressText">
                                    <span>
                                        {
                                            this.state.currentUploadProgression + "%"
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                {
                    this.state.loading &&
                    <div className='loading'>
                        <img src={loader} alt='' />
                    </div>
                }
            </>
        )
    }
}


DashboardViewArticle = connect(mapStateToProps, null)(DashboardViewArticle);
export default DashboardViewArticle;