import React from 'react';
import { Link } from 'react-router-dom';

import './style/style.css';
import './style/responsive.style.css';

var projectLink = '';

class ActualityCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectLink: '',
        }

    }

    componentDidMount() {
        projectLink = this.props.projectLink;
    }


    render() {
        return (
            <div className="actualityCard">
                <div className="actualityCard_imageContainer">
                    <Link to={"/Actualité:?article=" + encodeURIComponent(this.props.project.title)}>
                        <img className="actualityCard_image" alt={this.props.project.name + 1} src={this.props.project.cover}
                            onError={(e) => {
                                if( e.target.alt === this.props.project.name + 1){
                                    e.target.alt = this.props.project.name + 2;
                                    e.target.src = this.props.project.cover2;
                                }
                            }}
                        />
                    </Link>
                    <div className="actualityCard_odd">
                        {
                            this.props.project.oddList.slice(0).reverse().map((odd, index) => (
                                <div key={"ODD" + index} className="odd">
                                    <Link to={"/ODD:?odd=" + encodeURIComponent(odd)}
                                        onClick={()=>{
                                            if( this.props.reload ) {
                                                this.props.actionOdd(odd);
                                            }
                                        }}
                                    >
                                        <img className="odd_" alt="odd" src={"./ODD/odd" +odd+ ".png"}/>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="actualityCard_body">
                    <Link className="link" to={"/Actualité:?article=" + encodeURIComponent(this.props.project.title)}>
                        <div className="actualityCard_body_title">
                            <span>{ this.props.project.title }</span>
                        </div>
                    </Link>
                    <div className="card_separator_line"></div>
                    <div className="actualityCard_body_text textForm lineHeight1">
                        <span>{ this.props.project.shortDescription }</span>
                    </div>
                    <div className="actualityCard_competence">
                        {
                            this.props.project.competencesDomaine.map((competence, index) => (
                                <div key={"competence" + index} className="competence">
                                    <Link className="link" to={"/Domaine-de-competences:?competence=" + encodeURIComponent(competence)}
                                        onClick={()=>{
                                            if( this.props.reload2 ) {
                                                this.props.actionTag(competence);
                                            }
                                        }}
                                    >
                                        <span>
                                            { competence }
                                        </span>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="actualityCard_footer">
                    <Link className="link" to={"/Actualité:?article=" + encodeURIComponent(this.props.project.title)}>
                        <span>Voir plus ›</span>
                    </Link>
                </div>
            </div>
        )
    }
}

export default ActualityCard;