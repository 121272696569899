import axios from "axios";
const url = "https://www.rad-ong.org/rad-dev/get-folder-content-dashboard.php";

// const url = "http://localhost/test/get-folder-content-dashboard.php";

function getFolderContent(folder_name, getSubFolder = false) {
    return (
        axios({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                getSubFolder: getSubFolder,
                folder_name: folder_name,
            }
        })
    )
}

export default getFolderContent;