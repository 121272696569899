import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

/**Importation des composants de la page */
import ImageSlider2 from '../components/imageSlider2/imageSlider2.component';
import VideoSlider from '../components/videoSlider/videoSlider.component';
import ActualityCard from '../components/actualityCard/actualityCard.component';

/**Importation des styles de la page */
import './styles/onProjectView.style.css';
import './styles/onProjectView.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';


/**Importation des helpers */
import Xanimation from '../helpers/x-animations';
import radProject from '../helpers/project.helper';
import radArticle from '../helpers/article.helper';
import radODD from '../helpers/ODD.helper';
import radExpertise from '../helpers/expertise.helper';
import tools from '../helpers/tools.helper';
import radDataAttr from '../helpers/data-attr.helper';
import imagesURL from '../helpers/imagesURL.helper';



const animer = new Xanimation();
animer.init( 50 );


class OnProjectsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            radODD: [],
            radExpertise: [],
            sourceProject: {},
            project: {
                name: "",
                shortDescription: "",
                oddList: [],
                cover: "",
                galerie: [],
                videos: [],
                competencesDomaine: [],
                start: "",
                status: 0,
                content: "",
                numberMonth: 0,
                progression: 0,
            },
            interval: "month",
            articles: [],
        }

        const search = this.props.location.search;
        this.query = new URLSearchParams( search );


        this.radProject = new radProject();
        this.radArticle = new radArticle();
        this.radODD = new radODD();
        this.radExpertise = new radExpertise();
        this.tools = new tools();
        this.radDataAttr = new radDataAttr();

        this._getRadProject = this._getRadProject.bind( this );
        this._getRadODD = this._getRadODD.bind( this );
        this._getRadExpertise = this._getRadExpertise.bind( this );
    }

    componentDidMount() {
        // console.log( this.props.match.params.testvalue );
        // window.scrollTo(0, 0);
        window.onload = animer.start();

        this.setState({
            loading: true,
        });
        // console.log( encodeURI( this.query.get( "project" ) ));
        this._getRadProject( this.query.get( "project" ) );
    }

    componentWillUnmount() {
        animer.stop();
    }

    _getProjectArticle(search, callback = ()=>{}, tab = [], page = 1) {
        this.radArticle._getOne( true, search, null, page ).then((response) => {

            tab = tab.concat(response.data.results);
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getProjectArticle( search, callback, tab, page + 1 );
            }

            else {
                // console.log( tab );
                let bool = false;
                tab.map((result, index) => {
                    if( result.name === search ) {
                        callback( result, tab );
                        bool = true;
                        return true;
                    }
                });
                if( bool === false ) {
                    callback( {}, tab );
                }
            }
        }).catch((e) => {
            
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getProjectFolder( search, callback = ()=>{}, tab = [], page = 1 ) {
        // console.log(search);
        this.radDataAttr._getOne(search, page).then((response) => {
            response.data.results.map((expertise, index) => {
                tab.push( expertise );
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getProjectFolder( search, callback, tab, page + 1 );
            }

            else {
                // console.log(tab);
                // console.log( tab, search );
                if( tab.length > 0 ){
                    tab.map((element, index) => {
                        if( element.name === search || element.label === search ) {
                            let folder = JSON.parse( element.value );
                            for( let j=0; j<folder.length; j++ ) {
                                folder[j] = folder[j].split("*****");
                            }
                            return callback( folder );
                        }
                    });
                }
                else {
                    return callback([]);
                }
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadExpertise( tab = [], page = 1 ) {
        this.setState({
            radExpertise: [],
        });
        this.radExpertise._getAll(true, null, page).then((response) => {
            response.data.results.map((expertise, index) => {
                if( expertise.logo == null || expertise.logo === '' || expertise.logo === undefined) {
                    tab.push( expertise );
                }
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadExpertise( tab, page + 1 );
            }

            else {
                this.setState({
                    radExpertise: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    // console.log( this.state.sourceProject );
                    let newProject = {
                        name: this.state.sourceProject.name,
                        shortDescription: parse(this.state.sourceProject.desc.replace(/<p>&nbsp;<\/p>/g, '<br/>')),
                        oddIDs: this.state.sourceProject.odds,
                        tagIDs: this.state.sourceProject.tags,
                        oddList: [],
                        cover: "",
                        galerie: [],
                        videos: [],
                        start_date: this.state.sourceProject.start_date,
                        end_date: this.state.sourceProject.end_date,
                        competencesDomaine: [],
                        status: this.state.sourceProject.status,
                        article: this.state.sourceProject.article,
                        content: "",
                        numberDay: 0,
                        numberYear: 0,
                        numberMonth: 0,
                        progression: 0,
                    }

                    let numberMonth = 0;
                    let progression = 0;
                    let numberDay = 0;
                    let numberYear = 0;

                    if( this.state.sourceProject.end_date != null ) {
                        if( this.state.sourceProject.start_date != null ) {
                            numberMonth = Math.round( this.tools._monthBetween(new Date(this.state.sourceProject.start_date), new Date(this.state.sourceProject.end_date)) );
                            if(numberMonth === 0) {
                                numberDay = Math.round( this.tools._dayBetween(new Date(this.state.sourceProject.start_date), new Date(this.state.sourceProject.end_date)) );
                            }
                            progression = Math.round( this.tools._dateProgression(new Date(this.state.sourceProject.start_date), new Date(this.state.sourceProject.end_date)) );
                        }
                        else {
                            numberMonth = Math.round( this.tools._monthBetween(new Date(this.state.sourceProject.created_at), new Date(this.state.sourceProject.end_date)) );
                            if(numberMonth === 0) {
                                numberDay = Math.round( this.tools._dayBetween(new Date(this.state.sourceProject.created_at), new Date(this.state.sourceProject.end_date)) );
                            }
                            progression = Math.round( this.tools._dateProgression(new Date(this.state.sourceProject.created_at), new Date(this.state.sourceProject.end_date)) );
                        }
                    }

                    else {
                        if( this.state.sourceProject.start_date != null ) {
                            numberMonth = Math.round( this.tools._monthBetween(new Date(this.state.sourceProject.start_date),  new Date()) );
                            if(numberMonth === 0) {
                                numberDay = Math.round( this.tools._dayBetween(new Date(this.state.sourceProject.start_date),  new Date()) );
                            }
                            progression = Math.round( this.tools._dateProgression(new Date(this.state.sourceProject.start_date), new Date()) );
                        }
                        else {
                            numberMonth = Math.round( this.tools._monthBetween(new Date(this.state.sourceProject.created_at), new Date()) );
                            if(numberMonth === 0) {
                                numberDay = Math.round( this.tools._dayBetween(new Date(this.state.sourceProject.created_at), new Date()) );
                            }
                            progression = Math.round( this.tools._dateProgression(new Date(this.state.sourceProject.created_at), new Date()) );
                        }
                        // Math.round( this.tools._monthBetween(new Date(this.state.sourceProject.start_date), new Date()) );
                    }

                    newProject.numberDay = numberDay;
                    newProject.numberMonth = numberMonth;
                    newProject.progression = progression;

                    this.state.radODD.map((odd) => {
                        if(  this.state.sourceProject.odds.includes(odd.id) ) {
                            newProject.oddList = newProject.oddList.concat( odd.logo );
                        }
                    });

                    this.state.radExpertise.map((expertise) => {
                        if(  this.state.sourceProject.tags.includes(expertise.id) ) {
                            newProject.competencesDomaine = newProject.competencesDomaine.concat( expertise.name );
                        }
                    });

                    this._getProjectFolder( this.state.sourceProject.name, ( projectFolder )=>{
                        newProject.cover = projectFolder.length > 0 ? imagesURL + "upload/" +projectFolder[0][0] : "";
                        newProject.imagesList = projectFolder;

                        // console.log( projectFolder );

                        for( let k=0; k<projectFolder.length; k ++) {
                            projectFolder[k] = {
                                src: imagesURL + "upload/" + projectFolder[k][0],
                                text: projectFolder[k][1]
                            };
                        }

                        newProject.galerie = projectFolder;

                        newProject.galerie.map((file, index) => {
                            let file_ =  file.src.split('.');
                            let extension = file_[file_.length - 1].toString().toLowerCase();
                            if(
                                extension==="webm" ||
                                extension==="mp4" ||
                                extension==="m4p" ||
                                extension==="m4v" ||
                                extension==="mpg" ||
                                extension==="mp2" ||
                                extension==="mpeg" ||
                                extension==="mpe" ||
                                extension==="mpv" ||
                                extension==="ogg" ||
                                extension==="avi" ||
                                extension==="wmv" ||
                                extension==="mov" ||
                                extension==="qt" ||
                                extension==="flv" ||
                                extension==="swf" ||
                                extension==="avchd"
                            ) {
                                newProject.videos.push(file);
                                newProject.galerie.splice(index, 1);
                            }
                        });

                        // console.log( newProject );

                        this.setState({
                            project: newProject,
                        }, () => {
                            this._getProjectArticle( this.state.project.name, ( article, allArticles ) => {
                                // let project = this.state.project;
                                // console.log( article );
                                // project.content = article.content.replace(/<p>&nbsp;<\/p>/g, '<br/>');
                                // this.setState({
                                //     project: project,
                                // });

                                let articles = this.state.articles;

                                // console.log( allArticles )
                                if( allArticles.length > 0 ) {
                                    allArticles.map((onArticle, artIndex) => {

                                        // console.log( onArticle );
                                        let newArticle = {
                                            name: onArticle.name,
                                            title: onArticle.title,
                                            shortDescription: parse(onArticle.desc),
                                            oddIDs: onArticle.odds,
                                            tagIDs: onArticle.tags,
                                            oddList: [],
                                            galerie: [],
                                            cover: "",
                                            cover2: "", 
                                            start_date: onArticle.start_date,
                                            end_date: onArticle.end_date,
                                            competencesDomaine: [],
                                            status: onArticle.status,
                                            article: onArticle.article,
                                        }
    
                                        this.state.radODD.map((odd) => {
                                            if(  onArticle.odds.includes(odd.id) ) {
                                                newArticle.oddList = newArticle.oddList.concat( odd.logo );
                                            }
                                        });
    
                                        // console.log( this.state.radExpertise );
                    
                                        this.state.radExpertise.map((expertise) => {
                                            
                                            if(  onArticle.tags.includes(expertise.id) ) {
                                                newArticle.competencesDomaine = newArticle.competencesDomaine.concat( expertise.name );
                                            }
                                        });

                                        // console.log( newArticle );
    
                                        this._getProjectFolder( onArticle.name, ( articleFolder )=>{
                                            newArticle.cover = articleFolder.length > 0 ? imagesURL + "upload/" + articleFolder[0][0] : "";
                                            newArticle.cover2 = articleFolder.length > 0 ? imagesURL + "upload/" + articleFolder[0][0] : "";
                                            newArticle.imagesList = articleFolder;
                    
                                            // console.log( articleFolder );
                    
                                            for( let k=0; k<articleFolder.length; k ++) {
                                                articleFolder[k] = {
                                                    src: imagesURL + "upload/" + articleFolder[k][0],
                                                    src2: imagesURL + "upload/" + articleFolder[k][0],
                                                    text: articleFolder[k][1]
                                                };
                                            }
                    
                                            newArticle.galerie = articleFolder;
                                            articles = articles.concat( newArticle );
                                        
                                            if( artIndex === allArticles.length - 1 ) {
                                                this.setState({
                                                    articles: articles,
                                                    loading: false,
                                                });
                                            }
                                        });
                                    })
                                }

                                else {
                                    this.setState({
                                        loading: false,
                                    });
                                }
                            })
                        });
                    });
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadODD( tab = [], page = 1 ) {
        this.setState({
            radODD: [],
        });
        this.radODD._getAll(true, null, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadODD( tab, page + 1 );
            }

            else {
                this.setState({
                    radODD: this.tools._sortByDate(tab, 'created_at'),
                }, () => {
                    this._getRadExpertise();
                });
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadProject( project_name, tab = [], page = 1 ) {
        this.radProject._getOne(project_name, page).then((response) => {
            tab = tab.concat( response.data.results );
            // console.log( tab );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadProject( project_name, tab, page + 1 );
            }

            else {
                tab.map((element, index) => {
                    if( element.name === project_name ) {
                        this.setState({
                            sourceProject: element,
                        }, () => {
                            this._getRadODD();
                        });
                    }
                });
            }
        }).catch((e) => {
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    render() {
        return (
            <>
                <div className="OnProjectsView">
                    <div className="OnProjectsView_top">
                        <div className="OnProjectsView_top_text">
                            <span className="OnProjectsView_top_text_ title">
                                {this.state.project.name}
                            </span>
                        </div>
                        <div className="OnProjectsView_top_subtext">
                            <span className="OnProjectsView_top_subtext_ title">
                                #Domaines_de_compétences
                            </span>
                        </div>
                        <div className="OnProjectsView_top_competence">
                            {
                                this.state.project.competencesDomaine.map((competence, index) => (
                                    <Link to={"/Domaine-de-competences:?competence=" + competence} className="OnProjectsView_top_competence_" key={index}>
                                        <span className="OnProjectsView_top_competence_text">
                                            { competence }
                                        </span>
                                    </Link>
                                ))
                            }
                        </div>
                    </div>

                    <div className="OnProjectsView_image">
                        <img className="OnProjectsView_image_" src={this.state.project.cover} alt={this.state.project.name} />
                    </div>

                    <div className="OnProjectsView_odd">
                        {
                            this.state.project.oddList.map((odd, index) => (
                                <div key={"ODD" + index} className="OnProjectsView_odd_ x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="2s"
                                >
                                    <Link to={"/ODD:?odd=" + odd}>
                                        <img className="OnProjectsView_odd_img" alt="odd" src={"./ODD/odd" +odd+ ".png"}/>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>

                    <div className="OnProjectsView_info">
                        <div className="OnProjectsView_info_calendarIcon">
                            <i class="fa fa-calendar calendarIcon" aria-hidden="true"></i>
                        </div>
                        <div className="OnProjectsView_info_text x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <span className="OnProjectsView_info_text_top">
                                {
                                    this.state.project.numberMonth === 0 ?
                                        this.state.project.numberDay :
                                        this.state.project.numberMonth
                                }
                            </span>
                            <span className="OnProjectsView_info_text_bottom">
                                {
                                    this.state.project.numberMonth === 0 ?
                                        "Jour(s)" :
                                        "Mois"
                                }
                            </span>
                        </div>
                    </div>

                    <div className="OnProjectsView_progress x-animation"
                        x-animation="from-bottom"
                        x-animation-delay="0s"
                        x-animation-duration="1s"
                    >
                        <div className="OnProjectsView_progress_text">
                            <span className="OnProjectsView_progress_text_">
                                AVANCEMENT DU PROJET
                            </span>
                        </div>
                        <div className="OnProjectsView_progress_bare">
                            <div className="OnProjectsView_progress_bare_line"></div>
                            <div className="OnProjectsView_progress_bare_linePlain"
                                style={{
                                    width: this.state.project.progression + "%",
                                }}
                                
                            >
                                <div className="OnProjectsView_progress_bare_linePlain_"></div>
                                <div className="OnProjectsView_progress_bare_linePlain_pont"></div>
                            </div>
                        </div>

                        <div className="OnProjectsView_progress_bare_linePlain_value">
                            <span className="OnProjectsView_progress_bare_linePlain_dateStart">{this.state.project.start}</span>
                            <span>{this.state.project.progression + "%"}</span>
                        </div>
                    </div>

                    <div className="OnProjectsView_body">
                        {/* <div className="OnProjectsView_body_head x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <div className="OnProjectsView_body_head_text title">
                                <span className="">Présentation</span>
                            </div>
                        </div> */}
                        <div className="OnProjectsView_body_subDescription content x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            { this.state.project.shortDescription }
                        </div>

                        {/* <div className='content'>
                            {
                                parse(this.state.project.content)
                            }
                        </div> */}

                        {/* <div className="OnProjectsView_body_description text-sizes-1 left-border x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <p className="textForm">
                                Dans le cadre de la poursuite de ses interventions dans les zones à forte potentialité entrepreneuriale agricole, la DDC, par une contribution financière, a confié à la GIZ à travers le ProCIVA, la mise en œuvre des innovations par les PME dans les CVA soja, riz, volaille, maïs, manioc, maraîchage et/ou igname. Ledit partenariat étant mis en œuvre au cours du triennat 2018-2020, un avenant a été signé avec la DDC pour accompagner 100 PME supplémentaires pour le compte de l’année 2021. A cet effet, la RAD-ONG a été mandaté par le ProCIVA, pour accompagner 30 PME (dont 05 appartiennent au groupe de contrôle) dans le département des Collines, notamment dans les communes de Dassa et Glazoué suivant l’approche SME BUSINESS TRAINING AND COACHING LOOP, à travers la formation, le coaching et la facilitation à l’accès aux financements, dans le but de contribuer au développement de leurs affaires.
                            </p>
                        </div>

                        <div className="OnProjectsView_body_head OnProjectsView_body_head2 x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <div className="OnProjectsView_body_head_text title">
                                <span className="">Activités réalisées</span>
                            </div>
                        </div>

                        <div className="OnProjectsView_body_description text-sizes-1 left-border x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <p className="textForm">
                                Au nombre des activités réalisées par la RAD-ONG à ce jour, nous pouvons citer entre autres :
                                <br/>
                                <br/>
                                <ul>
                                    <li>
                                        Renforcement de capacités de 25 entrepreneurs (19 Femmes et 06 Hommes) sur la gestion d’entreprise suivant la méthodologie utilisant des outils GERME du BIT (1ère et 2ème sessions de formation) ;
                                    </li>
                                    <br/>
                                    <li>
                                        Collecte d’informations pour l’élaboration du Plan de Développement Entrepreneurial (PDE) au profit des 25 entreprises accompagnées ;
                                    </li>
                                    <br/>
                                    <li>
                                        Coaching individuel et rapproché des 25 entrepreneurs accompagnés avec plus de 08 visites effectuées par PME après deux mois d’accompagnement ;
                                    </li>
                                    <br/>
                                    <li>
                                        Appui à la formalisation de neuf (09) entreprises ;
                                    </li>
                                    <br/>
                                    <li>
                                        Appui dans le développement des liens d’affaires entre les PME de la classe et entre les PME de la classe actuelle et celles des classes antérieures ;
                                    </li>
                                </ul>
                            </p>
                        </div> */}

                        {
                            this.state.project.galerie.length > 0 &&
                            <>
                                <div className="OnProjectsView_body_head OnProjectsView_body_head2 x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="1s"
                                >
                                    <div className="OnProjectsView_body_head_text title">
                                        <span className="">Galerie images du projet</span>
                                    </div>
                                </div>

                                <div className="OnProjectsView_body_galerie x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="1s"
                                >
                                    <ImageSlider2 projectName={this.state.project.name} imageList={this.state.project.galerie}/>
                                </div>
                            </>
                        }

                        {
                            this.state.project.videos.length > 0 &&
                            <>
                                <div className="OnProjectsView_body_head OnProjectsView_body_head2 x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="1s"
                                >
                                    <div className="OnProjectsView_body_head_text title">
                                        <span className="">Galerie vidéos du projet</span>
                                    </div>
                                </div>

                                <div className="OnProjectsView_body_galerie x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="1s"
                                >
                                    <VideoSlider projectName={this.state.project.name} videoList={this.state.project.videos}/>
                                </div>
                            </>
                        }

                        <div className="OnProjectsView_body_head OnProjectsView_body_head2 x-animation"
                            x-animation="from-bottom"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <div className="OnProjectsView_body_head_text title">
                                <span className="">Actualitées associés</span>
                            </div>
                        </div>

                        <div className="OnProjectsView_body_description OnProjectsView_body_actuality">
                            <div className="OnProjectsView_body_actuality_">
                                {
                                    this.state.articles.map((project, index) => (
                                        <div key={index} className="OnProjectsView_body_actuality_card"
                                            x-animation="from-bottom"
                                            x-animation-delay="0s"
                                            x-animation-duration="2s"
                                        >
                                            <ActualityCard project={project} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="OnProjectsView_body_footer x-animation"
                            x-animation="from-right"
                            x-animation-delay="0s"
                            x-animation-duration="1s"
                        >
                            <span className="OnProjectsView_body_head_text title">
                                Responsable du projet
                            </span>
                            <span className="OnProjectsView_body_footer_text">
                                Christophe KINHA
                            </span>
                        </div>
                        
                    </div>

                    {
                        this.state.loading &&
                        <div className='loading'>
                            <img src={loader} alt='' />
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default OnProjectsView;