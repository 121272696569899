import React from 'react';

import VideoComponent from '../videoComponent/video.component';

import './style/style.css';
import './style/responsive.style.css';
class VideoSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentVideoIndex: 0,
            slide: false,
        }

        this.timeOut = null;
    }

    componentDidMount() {
        // window.onload = this._startSlide();
    }

    componentWillUnmount() {
        // this._stopSlide();
    }

    _startSlide() {
        this.timeOut = setInterval(() => {
            this.state.currentVideoIndex < this.props.videoList.length - 1  ?
                this._changeCurrentVideo( this.state.currentVideoIndex + 1 ) :
                this._changeCurrentVideo(0)
        }, this.props.delay);
    }

    _stopSlide() {
        clearInterval( this.timeOut );
    }

    _changeCurrentVideo( newVideoIndex ) {
        // console.log( newVideoIndex );
        this.setState({
            slide: true,
        });
        setTimeout(() => {
            if( newVideoIndex > this.state.currentVideoIndex ) {
                for( let i =  this.state.currentVideoIndex; i < newVideoIndex ; i++ ) {
                    let current = document.getElementById( "video" + i );
                    current.style.marginLeft = "-100%" ;
                }
            }

            else if( newVideoIndex < this.state.currentVideoIndex ) {
                for( let i =  this.state.currentVideoIndex; i >= newVideoIndex ; i-- ) {
                    let current = document.getElementById( "video" + i );
                    current.style.marginLeft = "0%" ;
                }
                // let current = document.getElementById( "video" + newVideoIndex );
                // current.style.marginLeft = "0%" ;
            }

            this.setState({
                currentVideoIndex: newVideoIndex,
                slide: false,
            }, () => {
                // this._stopSlide();
                // this._startSlide();
            });
        }, 250);
    }

  
    render() {
        return (
            <div className="VideoSlider_container">
                <div className="VideoSlider_container_">
                    <div className="VideoSlider_container_videos">
                        {
                            this.props.videoList.map((video, index) => (
                                <div
                                    className="VideoSlider_container_videos_"
                                    id={"video" + index }
                                    key={index}
                                >
                                    <VideoComponent src={video.src}/>
                                    {/* <img
                                        src={ video.src }
                                        alt="RAD-ONG"
                                        className="VideoSlider_container_videos_img"
                                        onError={(e) => {
                                            if( e.target.alt === "RAD-ONG"){
                                                e.target.alt = "RAD-ONG-";
                                                e.target.src = video.src2;
                                            }
                                        }}
                                    /> */}
                                </div>
                            ))
                        }
                    </div>

                    {
                        this.props.videoList[this.state.currentVideoIndex].text.length > 0 &&
                            <div className="VideoSlider_container_divText">
                                <div className="VideoSlider_container_divTextctn"
                                    style={{
                                        opacity: this.state.slide ? 0 : 1,
                                    }}
                                >
                                    <span className="VideoSlider_container_divTextctn_title">
                                        #Activité
                                    </span>
                                    <div className="VideoSlider_container_divText_">
                                        <p className="VideoSlider_container_divText_span">
                                            {
                                                this.props.videoList[this.state.currentVideoIndex].text
                                            }
                                        </p>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                    }
                </div>
                <div className="VideoSlider_container_controller">
                    <div className="VideoSlider_container_controller_">
                        {
                            this.props.videoList.map((video, index) => (
                                <div
                                    className="dot2"
                                    key={index}
                                    onClick={() => {
                                        this._changeCurrentVideo( index );
                                    }}
                                    style={{
                                        backgroundColor: this.state.currentVideoIndex === index ? "#5dc269" : "#ffffff",
                                        color: this.state.currentVideoIndex === index ? "#ffffff" : "#5dc269",
                                        transform: this.state.currentVideoIndex === index ? "scale(0.8)" : "scale(1)",
                                    }}
                                >
                                    <span>
                                        {index + 1}
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

        )
    }
}

export default VideoSlider;