import React from 'react';
import parse from 'html-react-parser';
// import { HashLink } from 'react-router-hash-link';

/**Importation des composants de la page */

/**Importation des styles de la page */
import './styles/dashboard.viewEvents.style.css';
import './styles/projectView.responsive.style.css';

/**Importation des images*/
// import prociva from '../assets/images/images_for_test/prociva2021/more/Picture1.png';
import loader from '../assets/gif/loader2.gif';


/**Importation des helpers */
import Xanimation from '../helpers/x-animations';

import radEvent from '../helpers/event.helper';


const animer = new Xanimation();
animer.init( 100 );


class EventsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            projects: [],
        }

        this.radEvent = new radEvent();
        this._getRadEvents = this._getRadEvents.bind( this );
    }

    componentDidMount() {
        // window.scrollTo(0, 0);
        window.onload = animer.start();

        this.setState({
            loading: true,
        });

        this._getRadEvents();
    }

    componentWillUnmount() {
        animer.stop();
    }

    _getRadEvents( tab = [], page = 1 ) {
        this.setState({
            loading: true,
            projects: [],
        });

        this.radEvent._getEvents(true).then((response) => {
            tab = tab.concat( response.data.evenements );
            console.log( tab );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadEvents( tab, page + 1 );
            }

            else {
                this.setState({
                    projects: tab,
                    loading: false,
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this.componentDidMount );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    render() {
        return (
            <>
                <div className="projectsView">
                    <div className="projectsView_Section1">
                        <div>
                            <div className="projectsView_Section1_">
                                {/* <img className="globe" src={globe} alt="globe-benin"/> */}
                                <span style={{fontSize: "2.2em"}} className="projectsView_Section1_text titleStyle3 title">NOS ÉVÈNEMENTS</span>
                            </div>
                            <div className="projectsView_Section1_button">
                                {/* <HashLink className="link_menu" variant="contained" to="/Nos-projets/#voir"> */}
                                    <span className="projectsView_Section1_button_text background_primary2"
                                        onClick={() => {
                                            window.scrollTo(0, document.getElementById("voir").offsetTop)
                                        }}
                                    >VOIR</span>
                                {/* </HashLink> */}
                            </div>
                        </div>
                    </div>

                    <div className="projectsView_Section9" id="voir">
                        {
                            this.state.projects.map((project, index) => (
                                <div key={index} className="projectsView_Section9_body_project x-animation"
                                    x-animation="from-bottom"
                                    x-animation-delay="0s"
                                    x-animation-duration="2s"
                                >
                                    <div className="projectsView_Section9_body_project_title" >
                                        {project.nom}
                                    </div>
                                    <div className="projectsView_Section9_body_project_date">
                                        De <strong>{new Date(project.date_debut).getDay() + "/" + (Number(new Date(project.date_debut).getMonth())+1) + "/" + new Date(project.date_debut).getFullYear() + " "}</strong>
                                        à <strong>{new Date(project.date_fin).getDay() + "/" + (Number(new Date(project.date_fin).getMonth())+1) + "/" + new Date(project.date_fin).getFullYear()}</strong>
                                    </div>
                                    <div className="projectsView_Section9_body_project_description">
                                        {parse(project.courte_description)}
                                    </div>
                                    <div className="projectsView_Section9_body_project_description">
                                        {parse(project.description)}
                                    </div>
                                    {/* <div>
                                        <a href='/view-event'>Voir plus</a>
                                    </div> */}
                                </div>
                            ))
                        }
                    </div>

                    {
                        this.state.loading &&
                        <div className='loading'>
                            <img src={loader} alt='' />
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default EventsView;