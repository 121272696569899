import React from 'react';
// import { Link } from 'react-router-dom';

// import fs from 'fs';

/**Importation des composants de la page */
import ImageSelector from '../components/imageSelector/imageSelector.component';


/**Importation des styles de la page */
import './styles/media.style.css';
import './styles/media.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';

/**Importation des helpers */
import Xanimation from '../helpers/x-animations';
import getFolderContent from '../helpers/getFolderContent.helper';
import directoryManager from '../helpers/directory-manager.helper';
import uploadMedia from '../helpers/uploadMedia.helper';

const animer = new Xanimation();
animer.init(50);


class DashboardRubriqueMedia extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            racine: "./rad",
            currentFolder: "./rad",
            currentFolderView: '',
            currentContent: [],
            viewCurrentImage: false,
            currentImage: '',
            loading: false,
            getSubFolder: false,

            currentPath: "",

            addFiles: false,
            ImageSelectedList: [],
            ImageSelectedPreviewLink: [],
            filesSelected: [],

            uploadFiles: false,
            currentUploadProgression: 0,
            totalUploadProgression: 0,
            numberFilesUpload: 0,
            numberFilesRejected: 0,
            totalFiles: 0,
            currentUploadMessage: '',
        }
        this.directoryManager = new directoryManager();
    }

    async componentDidMount() {
        // console.log( this.props.match.params.testvalue );
        window.scrollTo(0, 0);
        window.onload = animer.start();
        // try {
        //     const data = await fs.promises.readdir("./");
        //     console.log(data.toString());
        // }catch (error) {
        //     console.error(`Got an error trying to read the file: ${error.message}`);
        // }
        this.setState({
            loading: true,
        });
        this._getFolderContent()
    }

    _getFolderContent() {
        getFolderContent(this.state.currentFolder).then((response) => {
            // console.log( response.data );
            this.setState({
                loading: false,
                currentContent: response.data,
            });
        });
    }

    getAllContentForFolder(directory, currentName) {
        this.setState({
            currentFolder: directory,
            currentContent: [],
            loading: true,
            currentFolderView: currentName,
        }, () => {
            getFolderContent(this.state.currentFolder,true).then((response) => {
                // console.log( response.data );
                this.setState({
                    loading: false,
                    currentContent: response.data,
                });
            });
        });
    }

    _getNewFolderName( currentFolder ) {
        let promptValue = prompt("Entrez le nom du dossier ici");
        if( promptValue !== null ) {
            let path = currentFolder + "/" + promptValue;
            this._createFolder(path);
        }
    }

    _createFolder(path) {
        if ((path) !== null) {
            this.directoryManager._createDirectory(path).then((response) => {
                // console.log( response.data );
                alert("Dossier créé avec succès");
                this.setState({
                    currentContent: [],
                    loading: true,
                }, () => {
                    getFolderContent(this.state.currentFolder, this.state.getSubFolder).then((response) => {
                        // console.log( response.data );
                        this.setState({
                            loading: false,
                            currentContent: response.data,
                        });
                    });
                });

            }).catch((e) => {
                console.log(e);
            })
        }
    }

    _confirmFolderDeletion( path ) {
        if( window.confirm("Voulez-vous vraiment supprimer?") === true ){
            this._deleteFolder( path );
        }
    }

    _confirmFolderRename( path, newPath ) {
        if( window.confirm("Voulez-vous vraiment renommer?") === true ){
            this._renameFolder( path, newPath );
        }
    }

    _deleteFolder( path ) {
        if ((path) !== null) {
            this.directoryManager._deleteDirectory(path).then((response) => {
                // console.log( response.data );
                alert("Supprimer avec succès");
                this.setState({
                    currentContent: [],
                    loading: true,
                }, () => {
                    getFolderContent(this.state.currentFolder, this.state.getSubFolder).then((response) => {
                        this.setState({
                            loading: false,
                            currentContent: response.data,
                        });
                    });
                });

            }).catch((e) => {
                console.log(e);
            })
        }
    }

    _renameFolder( path , newPath) {
        if ((path) !== null) {
            this.directoryManager._renameDirectory(path, newPath).then((response) => {
                // console.log( response.data );
                alert("Renommer avec succès");
                this.setState({
                    currentContent: [],
                    loading: true,
                }, () => {
                    getFolderContent(this.state.currentFolder, this.state.getSubFolder).then((response) => {
                        this.setState({
                            loading: false,
                            currentContent: response.data,
                        });
                    });
                });
            }).catch((e) => {
                console.log(e);
            })
        }
    }

    _getRename( currentPath ) {
        let newName = window.prompt("Entrez le nouveau nom ici");
        let newPath = "";

        if( newName !== null && newName !== "" ) {
            let decomposePath = currentPath.split("/");
            for(let i=0; i<decomposePath.length -1; i++) {
                newPath = newPath + decomposePath[i] + "/";
            }
            newPath = newPath + newName;
            this._confirmFolderRename( currentPath, newPath );
        }
    }

    _addImageToFolder() {
        this.setState({
            addFiles: true,
        });
    }

    _startUpload( directoryPath ) {
        this.setState({
            loading: true,
            uploadFiles: true,
            numberFilesUpload: 0,
            totalFiles: this.state.filesSelected.length,
            currentUploadProgression: 0,
        });

        if( window.confirm("Voulez-vous vraiment ajouter ses images?") === true ){
            this._uploadImages( directoryPath );
        }
    }

    _uploadImages( directoryPath, currentIndex = 0 ) {
        uploadMedia( directoryPath, [this.state.filesSelected[ currentIndex ] ], (event) => {
            this.setState({
                currentUploadProgression: Math.round((100 * event.loaded) / event.total),
            });
        }).then((response) => {
            if( response.data.code === 201 ){
                // console.log( response.data );
            }else {
                // console.log( response.data );
                this.setState({
                    numberFilesRejected: this.state.numberFilesRejected + 1,
                });
            }
            
            if( currentIndex === this.state.totalFiles ) {
                alert( "Envois finis" );
                this.setState({
                    loading: false,
                    uploadFiles: false,
                    ImageSelectedList: [],
                    ImageSelectedPreviewLink: [],
                    filesSelected: [],
                    currentUploadProgression: 0,
                });
                getFolderContent(this.state.currentFolder, this.state.getSubFolder).then((response) => {
                    this.setState({
                        loading: false,
                        currentContent: response.data,
                    });
                });
            }
            else {
                this.setState({
                    numberFilesUpload: this.state.numberFilesUpload + 1,
                    currentUploadProgression: 0,
                }, () => {
                    this._uploadImages( directoryPath, currentIndex+1 )
                });
            }
        }).catch((e) => {
            this.setState({
                loading: false,
                uploadFiles: false,
            });
            alert('Echec de l\'envois du fichier. Veuillez réessayer');
            // console.log( e );
        });
    }

    componentWillUnmount() {
        animer.stop();
    }

    _backTo() {
        this.setState({
            loading: true,
            currentFolder: this.state.racine,
            currentContent: [],
        }, () => {
            getFolderContent(this.state.currentFolder).then((response) => {
                // console.log( response.data );
                this.setState({
                    loading: false,
                    currentContent: response.data,
                });
            });
        });
    }

    _clickToViewImage(image) {
        this.setState({
            viewCurrentImage: true,
            currentImage: image,
        })
    }

    _clickToBackViewImage() {
        this.setState({
            viewCurrentImage: false,
            currentImage: '',
        })
    }

    _deleteImage(imageIndex) {
        let ImageSelectedList = this.state.ImageSelectedList;
        let ImageSelectedPreviewLink = this.state.ImageSelectedPreviewLink;

        let filteredImageSelectedList = ImageSelectedList.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        let filteredImageSelectedPreviewLink = ImageSelectedPreviewLink.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        this.setState({
            ImageSelectedList: filteredImageSelectedList,
            ImageSelectedPreviewLink: filteredImageSelectedPreviewLink,
        });
    }

    _deleteAllImages() {
        this.setState({
            ImageSelectedList: [],
            ImageSelectedPreviewLink: [],
        });
    }

    render() {
        return (
            <>
                <div className="media">
                    {
                        this.state.racine === this.state.currentFolder ?
                            <div className="media_container">
                                <div className='back'>
                                    <div className='back_'
                                        onClick={() => {
                                            this._getNewFolderName( this.state.currentFolder );
                                            return true;
                                        }}
                                    >
                                        <span>
                                            Créer un dossier
                                        </span>
                                    </div>
                                </div>
                                {
                                    this.state.currentContent.map((content, index) => (
                                        <div className="media_folder" key={index}>
                                            <div className="media_folder_"
                                                onClick={() => {
                                                    this.getAllContentForFolder(this.state.currentFolder + "/" + content.name, content.name);
                                                    this.setState({
                                                        getSubFolder: true,
                                                    });
                                                }}
                                            >
                                                <img
                                                    className="media_folder_cover"
                                                    src={"../" + this.state.currentFolder + "/" + content.name + "/cover/cover.png"}
                                                    alt=""
                                                />
                                                <div className="media_folder_name">
                                                    <span className="media_folder_name_">
                                                        {
                                                            content.name
                                                        }
                                                    </span>
                                                </div>
                                            </div>

                                                <div style={{
                                                    paddingBottom: "10px",
                                                    marginTop: "-3px",
                                                    boxShadow: "10px 20px 15px 3px #00000055",
                                                    backgroundColor: "#ffffff",
                                                    paddingTop: "10px",
                                                }}>
                                                    <div className='back2 back2-'>
                                                        <div className='back2_'
                                                            onClick={() => {
                                                                this._getRename( this.state.currentFolder + "/" + content.name );
                                                                // console.log( content );
                                                                return true;
                                                            }}
                                                        >
                                                            <span>
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='back2 back2-'
                                                        style={{
                                                            backgroundColor: "transparent",
                                                            border: "1px solid red",
                                                        }}
                                                    >
                                                        <div className='back2_'
                                                            onClick={() => {
                                                                this._confirmFolderDeletion(this.state.currentFolder + "/" + content.name);
                                                                return true;
                                                            }}
                                                        >
                                                            <span style={{
                                                                color: "red",
                                                            }}>
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    ))
                                }
                            </div>
                            :
                            null
                    }

                    {
                        this.state.racine !== this.state.currentFolder ?
                            <>
                                <div className='back'>
                                    <div className='back_'
                                        onClick={() => {
                                            this._backTo();
                                            this.setState({
                                                getSubFolder: false,
                                            });
                                        }}
                                    >
                                        <span>
                                            Retour
                                        </span>
                                    </div>
                                </div>
                                <div className='media_folder_container_current'>
                                    {
                                        this.state.currentFolderView
                                    }

                                    <div className='back2'>
                                        <div className='back2_'
                                            onClick={() => {
                                                this._getNewFolderName( this.state.currentFolder );
                                                return true;
                                            }}
                                        >
                                            <span>
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-folder-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M9.828 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91H9v1H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181L15.546 8H14.54l.265-2.91A1 1 0 0 0 13.81 4H9.828zm-2.95-1.707L7.587 3H2.19c-.24 0-.47.042-.684.12L1.5 2.98a1 1 0 0 1 1-.98h3.672a1 1 0 0 1 .707.293z"/>
                                                    <path fill-rule="evenodd" d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="media_folder_container">
                                    {
                                        this.state.currentContent.map((content, index) => (

                                            <>{
                                            content.isDirectory ?
                                                <div key={index} className="media_folder_container_name"
                                                    style={{
                                                        paddingLeft: 20 * content.level + "px",
                                                    }}
                                                >
                                                    <span>
                                                        {content.path}
                                                    </span>

                                                    <div className='back2 back2-'>
                                                        <div className='back2_'
                                                            onClick={() => {
                                                                this._getRename( content.path );
                                                                // console.log( content );
                                                                return true;
                                                            }}
                                                        >
                                                            <span>
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='back2 back2-'>
                                                        <div className='back2_'
                                                            onClick={() => {
                                                                this._getNewFolderName( content.path );
                                                                // console.log( content );
                                                                return true;
                                                            }}
                                                        >
                                                            <span>
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-folder-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M9.828 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91H9v1H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181L15.546 8H14.54l.265-2.91A1 1 0 0 0 13.81 4H9.828zm-2.95-1.707L7.587 3H2.19c-.24 0-.47.042-.684.12L1.5 2.98a1 1 0 0 1 1-.98h3.672a1 1 0 0 1 .707.293z"/>
                                                                    <path fill-rule="evenodd" d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"/>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='back2 back2-'>
                                                        <div className='back2_'
                                                            onClick={() => {
                                                                this.setState({
                                                                    addFiles: true,
                                                                    currentPath: content.path,
                                                                });
                                                                return true;
                                                            }}
                                                        >
                                                            <span>
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-images" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M12.002 4h-10a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1zm-10-1a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-10zm4 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                                    <path fill-rule="evenodd" d="M4 2h10a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1v1a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2h1a1 1 0 0 1 1-1z"/>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='back2 back2-'
                                                        style={{
                                                            backgroundColor: "transparent",
                                                            border: "1px solid red",
                                                        }}
                                                    >
                                                        <div className='back2_'
                                                            onClick={() => {
                                                                this._confirmFolderDeletion(content.path);
                                                                return true;
                                                            }}
                                                        >
                                                            <span style={{
                                                                color: "red",
                                                            }}>
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <div key={index} className="media_content_imageContainer"
                                                        onClick={() => {
                                                            this._clickToViewImage(content);
                                                        }}
                                                    >
                                                        <div className="media_content_image">
                                                            <img className="media_content_image_img" src={"../rad-dev/" + content.compressPath} alt="RAD-ONG" />
                                                            <div className="media_content_image_name">
                                                                {
                                                                    content.name
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        padding: "0px 9.5px",
                                                        border: "0px solid red",
                                                    }}>
                                                        <div style={{
                                                            margin: "auto",
                                                            paddingBottom: "10px",
                                                            marginTop: "-15px",
                                                            boxShadow: "10px 20px 15px 3px #00000055",
                                                            backgroundColor: "#ffffff",
                                                            paddingTop: "10px",
                                                            width: "100%",
                                                        }}>
                                                            <div className='back2 back2-'>
                                                                <div className='back2_'
                                                                    onClick={() => {
                                                                        this._getRename( content.path );
                                                                        // console.log( content );
                                                                        return true;
                                                                    }}
                                                                >
                                                                    <span>
                                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd" d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                                                        </svg>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='back2 back2-'
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    border: "1px solid red",
                                                                }}
                                                            >
                                                                <div className='back2_'
                                                                    onClick={() => {
                                                                        this._confirmFolderDeletion(content.path);
                                                                        return true;
                                                                    }}
                                                                >
                                                                    <span style={{
                                                                        color: "red",
                                                                    }}>
                                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                        </svg>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }</>
                                        ))
                                    }
                                </div>
                            </>
                            :
                            null
                    }

                    {
                        this.state.viewCurrentImage &&
                        <div className='currentImageContainer'>
                            <div className='crossImage'
                                onClick={() => {
                                    this._clickToBackViewImage();
                                }}
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#ffffff" />
                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#ffffff" />
                                </svg>
                            </div>
                            <div className='currentImage'>
                                <img className='currentImage_' src={"../" + this.state.currentImage.path} alt='RAD-ONG' />
                            </div>
                        </div>
                    }
                </div>
                    {
                        this.state.addFiles&&
                        <div className='addSection'>
                            <div className='addSection_'>
                                {/* <div className='addSection_title'>
                                    <span>
                                        { this.state.typeUpdate === "add" ? "Ajouter un membre de RAD-ONG" : "Modifier un membre de RAD-ONG"}
                                    </span>
                                </div> */}

                                <div className='updateSocial_inputSubmit_container'>
                                    <span className='updateSocial_inputSubmit'
                                        onClick={() => {
                                            this._startUpload( this.state.currentPath );
                                            return true;
                                        }}
                                    >
                                        Valider
                                    </span>
                                    <span className='updateSocial_inputBack'
                                        onClick={() => {
                                            this.setState({
                                                addFiles: false,
                                                currentPath: "",
                                                ImageSelectedList: [],
                                                ImageSelectedPreviewLink: [],
                                                filesSelected: [],
                                            });
                                        }}
                                    >
                                        Annuler
                                    </span>
                                </div>

                                <div className='add_content'>
                                    <div className='add_content_label'>
                                        <span>
                                            Ajoutez des images
                                        </span>
                                    </div>
                                    <div className='add_content_imageSelector'>
                                        <ImageSelector
                                            onChangeImage={async (e) => {
                                                const files = Array.from(e);

                                                let ImageSelectedList = this.state.ImageSelectedList;
                                                let filesSelected = this.state.filesSelected;
                                                filesSelected = filesSelected.concat(files);
                                                // console.log( filesSelected );

                                                files.map((file, index)=>{
                                                    ImageSelectedList = ImageSelectedList.concat({
                                                        file: file,
                                                    });
                                                });

                                                // console.log( files );

                                                let tab = this.state.ImageSelectedPreviewLink;

                                                Promise.all(files.map(file => {
                                                    return (
                                                        new Promise((resolve, reject) => {
                                                            const reader = new FileReader();
                                                            reader.addEventListener('load', (ev) => {
                                                                resolve(ev.target.result);
                                                            });
                                                            reader.addEventListener('error', reject);
                                                            reader.readAsDataURL(file);
                                                        })
                                                    )
                                                })).then(images => {
                                                    tab = tab.concat(images);
                                                    // console.log( tab );
                                                    this.setState({
                                                        filesSelected: filesSelected,
                                                        ImageSelectedList: ImageSelectedList,
                                                        ImageSelectedPreviewLink: tab,
                                                    });
                                                });

                                            }}
                                        />
                                    </div>

                                    {
                                        this.state.ImageSelectedPreviewLink.length > 0 &&
                                        <div className='delete_all_image'>
                                            <span className='delete_all_image_text' onClick={() => {
                                                this._deleteAllImages();
                                            }}>
                                                <span>
                                                    Supprimer toutes les images
                                                </span>
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                </svg>
                                            </span>
                                        </div>
                                    }

                                    <div className="previewImageSelected_container">
                                        {
                                            this.state.ImageSelectedPreviewLink.map((link, index) => (
                                                <div className='previewImageContainer'>
                                                    <div className="previewImageSelected_">
                                                        <svg onClick={() => {
                                                            this._deleteImage(index);
                                                        }} className='previewImageSelected_cross' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529" />
                                                            <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529" />
                                                        </svg>
                                                        <img className="previewImageSelected" key={index} src={link} alt="RAD-ONG" />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.uploadFiles&&
                        <div className='addSection'>
                            <div className='addSection_ uploadProgressPlatform'>
                                <div className='addSection_title'>
                                    <span>
                                        Envois des fichiers en cours
                                    </span>
                                </div>

                                <div className="progressText">
                                    <span>
                                        {
                                            this.state.numberFilesUpload + " / " + this.state.totalFiles
                                        }
                                    </span>
                                </div>
                                <div className='addSection_input'>
                                    <div className='progress'>
                                        <div className='progressBar'
                                            style={{
                                                width: ( (this.state.numberFilesUpload * 100) / this.state.totalFiles ) + "%"
                                            }}
                                        >

                                        </div>
                                    </div>
                                </div>

                                <br/>
                                <div className="progressText">
                                    <span>
                                        {
                                            this.state.currentUploadProgression + "%"
                                        }
                                    </span>
                                </div>
                                <div className='addSection_input'>
                                    <div className='progress'>
                                        <div className='progressBar'
                                            style={{
                                                width: this.state.currentUploadProgression + "%"
                                            }}
                                        >

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                {
                    this.state.loading &&
                    <div className='loading'>
                        <img src={loader} alt='' />
                    </div>
                }
            </>
        )
    }
}

export default DashboardRubriqueMedia;