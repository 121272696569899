import axios from "axios";
import URL3 from "./url3.helper";

class radEvent {

    _getEvents(_public, access, page = 0) {
        if (_public) {
            return (
                axios({
                    method: 'get',
                    url: (page > 1) ? URL3 + "fonctions/evenements/?page=" + page : URL3 + "fonctions/evenements/",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            )
        } else {
            return (
                    axios({
                        method: 'get',
                        url: (page > 1) ? URL3 + "fonctions/evenements/?page=" + page : URL3 + "fonctions/evenements/",
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                )
                // return (
                //     axios({
                //         method: 'get',
                //         url: (page > 1) ? URL3 + "api/admin/project/?page=" + page : URL3 + "api/admin/project/",
                //         headers: {
                //             'Content-Type': 'application/json',
                //             "Authorization": "Bearer " + access
                //         },
                //     })
                // )
        }
    }

    _getOne(search, page = 0) {
        return (
            axios({
                method: 'get',
                url: (page > 1) ? URL3 + "api/projects/?search=" + search + "&page=" + page : URL3 + "api/projects/?search=" + search,
            })
        )

    }

    _post(data, access) {
        return (
            axios({
                method: 'post',
                url: URL3 + "fonctions/evenements/ajouter/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _update(id, data, access) {
        return (
            axios({
                method: 'put',
                url: URL3 + "fonctions/evenements/" + id + "/modifier/",
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }

    _delete(id, access) {
        return (
            axios({
                method: 'delete',
                url: URL3 + "fonctions/evenements/" + id + "/supprimer/",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access
                },
            })
        )
    }
}

export default radEvent;