import axios from "axios";
const url = "https://www.rad-ong.org/rad-dev/get-folder-content.php";
// const url2 = "http://localhost/test/get-folder-content.php";

function getNodeResponse(folder_name, getSubFolder = false) {
    return (
        axios({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                getSubFolder: getSubFolder,
                folder_name: folder_name,
            }
        })
    )
}

export default getNodeResponse;