import ImageConvertor from './imageConvertor.helper';
// const MIME_TYPE = "image/jpeg";
// const QUALITY = 0.7;
class Compressor {
    constructor() {
        this.ImageConvertor = new ImageConvertor();
    }

    _image(file, className, MIME_TYPE = "image/jpeg", QUALITY = "0.7") {
        this.ImageConvertor._UrlToFile("https://cdn.shopify.com/s/files/1/0234/8017/2591/products/young-man-in-bright-fashion_925x_f7029e2b-80f0-4a40-a87b-834b9a283c39.jpg", (FILE) => {
            // console.log(FILE);

            /**
             * Créez un Blob avec les données du fichier et obtenez son URL avec createObjectURL
             */
            const blobURL = window.URL.createObjectURL(FILE);

            /**
             * Créez un objet d'image d'assistance et utilisez l'URL du blob comme source
             */
            const img = new Image();
            img.src = blobURL;

            /**
             * Utiliser le onloadrappel pour traiter l'image
             */
            img.onload = function(ev) {
                window.URL.revokeObjectURL(blobURL); // release memory
                // Use the img
            };

            // console.log(img.src);

            /**
             * Créez un élément de canevas définissant.
             */

            const canvas = document.createElement('canvas');
            canvas.classList.add(className);

            /**
             * Créez un objet de contexte 2D et dessinez l'image sur le canevas
             */

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            /**
             * Exportez l'image avec la qualité de sortie souhaitée
             */
            canvas.toBlob(function(blob) {
                // Handle the compressed image
                return canvas;
            }, MIME_TYPE, QUALITY);
        });
    }
}

export default Compressor;