import React from 'react';
import { connect } from 'react-redux';


/**Importation des composants de la page */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ImageSelector from '../components/imageSelector/imageSelector.component';


/** Importation des screens */
// Screen de la page d'accueil


/**Importation des styles de la page */
import './styles/dashboard.viewProject.style.css';
// import './styles/dashboard.addProject.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';


/**Importation des helpers */
import radExpertise from '../helpers/expertise.helper';
import radODD from '../helpers/ODD.helper';
import tools from '../helpers/tools.helper';
import refreshConnection from '../helpers/refresh.helper';
import uploadFiles from '../helpers/upload_file.helper';
import radProject from '../helpers/project.helper';
import radArticle from '../helpers/article.helper';
import radDataAttr from '../helpers/data-attr.helper';
import imagesURL from '../helpers/imagesURL.helper';
import ImgURL from '../helpers/imgUrl.helper';


import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user: state.User.user,
    };
}

class DashboardViewProject extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            radExpertise: [],
            radODD: [],
            projectStatus: [],
            loading: false,

            uploadFiles: false,
            currentUploadProgression: 0,
            currentUploadMessage: '',

            projects: [],
            articles: [],
            projectsFolders: [],

            isOpened: -1,
        }

        this.accessStore = new AccessStore(this.props.dispatch);
        this.radExpertise = new radExpertise();
        this.radODD = new radODD();
        this.tools = new tools();
        this.radProject = new radProject();
        this.radArticle = new radArticle();
        this.radDataAttr = new radDataAttr();

        this._getRadProjects = this._getRadProjects.bind( this );
    }

    _setNewAccessToUser(callback) {
        refreshConnection(this.props.user.refresh).then((response) => {
            let newUser = this.props.user;
            newUser.access = response.data.access;

            if (response.data.refresh) {
                newUser.refresh = response.data.refresh;
            }

            // console.log( response, this.props.user, newUser );

            this.accessStore.setRedux('SET_USER', newUser, callback);
            // this._getRadExpertise();
        }).catch((e) => {
            if (e.response) {
                if (e.response.status === 401) {
                    this.accessStore.setRedux('LOG_OUT', {});
                }
            }
            if (e.toString() === "Error: Network Error") {
                alert("Aucun accès à internet :(");
            }
        });
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });
        this._getRadODD();
        this._getRadExpertise();
        this._getProjectsStatus();

        this._getRadProjects();
    }

    _getProjectArticle(search, callback = ()=>{}, tab = [], page = 1) {
        this.radArticle._getOne( true, search, null, page ).then((response) => {
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getProjectArticle( search, callback, tab, page + 1 );
            }

            else {
                response.data.results.map((result, index) => {
                    if( result.title === search ) {
                        callback( result );
                        return true;
                    }
                })
            }
        }).catch((e) => {
            
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getProjectFolder( search, callback = ()=>{}, tab = [], page = 1 ) {
        this.radDataAttr._getOne(search, page).then((response) => {
            response.data.results.map((content, index) => {
                tab = tab.concat( content );
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getProjectFolder( search, callback, tab, page + 1 );
            }

            else {
                if( tab.length > 0 ){
                    tab.map((element, index) => {
                        // console.log( element );
                        if( element.name === search ) {
                            if( element.value !== "" ){
                                let folder = JSON.parse( element.value );
                                for( let j=0; j<folder.length; j++ ) {
                                    folder[j] = folder[j].split("*****");
                                }
                                callback( folder, element.id );
                            }
                            else {
                                callback([], element.id );
                            }
                        }
                    });
                }
                else {
                    callback([]);
                }
            }
        }).catch((e) => {
            if( e.response ) {
               
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getRadProjects( tab = [], page = 1 ) {
        this.setState({
            loading: true,
            projects: [],
            articles: [],
            projectsFolders: [],
        });

        this.radProject._getProjects(false, this.props.user.access, page).then((response) => {
            tab = tab.concat( response.data.results );
            console.log( tab );

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadProjects( tab, page + 1 );
            }

            else {

                tab.map((project, index) => {
                    // console.log( project );
                    // this._getProjectArticle( project.name, ( article ) => {
                        // console.log( article );
                        // project.article = article

                        this._getProjectFolder( project.name, ( folder, folderId ) => {
                            project.folder = folder;
                            project.folder_id = folderId;

                            if( index === tab.length-1 ) {
                                this.setState({
                                    projects: this.tools._sortByDate(tab, 'created_at'),
                                    loading: false,
                                });
                            } else {
                                this.setState({
                                    loading: false,
                                });
                            }
                        });
                    // })
                });
                // console.log( tab );
            }
        }).catch((e) => {
            
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this.componentDidMount );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }


    _getRadExpertise( tab = [], page = 1 ) {
        this.setState({
            loading: true,
            radExpertise: [],
        });
        this.radExpertise._getAll(true, this.props.user.access, page).then((response) => {
            // console.log( response );
            response.data.results.map((expertise, index) => {
                if( expertise.logo == null || expertise.logo === '' || expertise.logo === undefined) {
                    tab.push( expertise );
                }
            });

            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadExpertise( tab, page + 1 );
            }

            else {
                this.setState({
                    radExpertise: this.tools._sortByDate(tab, 'created_at'),
                    loading: false,
                });
            }
        }).catch((e) => {
            
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this.componentDidMount );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _deleteFolderImage_(projectIndex, imageIndex) {
        let projects = this.state.projects;
        let project = projects[ projectIndex ]

        let filterFolder = project.folder.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        project.folder = filterFolder;

        this.setState({
           projects: projects,
        });
    }

    _deleteImage(projectIndex, imageIndex) {
        let projects = this.state.projects;
        let project = projects[ projectIndex ]

        let filteredImageSelectedList = project.ImageSelectedList.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        let filteredImageSelectedPreviewLink = project.ImageSelectedPreviewLink.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        let filteredFilesSelected = project.filesSelected.filter(function (value, index, arr) {
            return index !== imageIndex;
        });

        project.ImageSelectedList = filteredImageSelectedList;
        project.ImageSelectedPreviewLink = filteredImageSelectedPreviewLink;
        project.filesSelected = filteredFilesSelected;

        this.setState({
           projects: projects,
        });
    }

    _deleteAllImages(projectIndex) {
        let projects = this.state.projects;
        let project = projects[ projectIndex ]
        project.ImageSelectedList = [];
        project.ImageSelectedPreviewLink = [];
        project.filesSelected = [];
        this.setState({
            projects: projects
        });
    }

    _getRadODD( tab = [], page = 1 ) {
        this.setState({
            radODD: [],
        });
        this.radODD._getAll(false, this.props.user.access, page).then((response) => {
            tab = tab.concat( response.data.results );
            if( response.data && response.data.next && response.data.next !== null && response.data.next !== "" && response.data.next !== undefined ){
                this._getRadODD( tab, page + 1 );
            }

            else {
                this.setState({
                    radODD: this.tools._sortByDate(tab, 'created_at'),
                    loading: false,
                });
            }
        }).catch((e) => {
            // console.log( e );
            if( e.response ) {
                if( e.response.status === 401 ) {
                    // this._setNewAccessToUser( this._getRadODD );
                    this._setNewAccessToUser( this.componentDidMount );
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _getProjectsStatus() {
        this.setState({
            loading: true,
            projectStatus: [],
        });
        this.radProject._getProjectsStatus(true, this.props.user.access).then((response) => {
            console.log( response.data );
            this.setState({
                projectStatus: this.tools._sortByDate2(response.data.results, 'created_at'),
                loading: false,
            });
        }).catch((e) => {
            if( e.response ){
                if (e.response.status === 401) {
                    // this._setNewAccessToUser(this._getProjectsStatus);
                    this._setNewAccessToUser( this._getProjectsStatus );
                }
            }
            this.setState({
                loading: false,
            });
            if (e.toString() === "Error: Network Error") {
                alert("Aucun accès à internet :(");
            }
        });
    }

    isTarget( bool, target, type, index, key ) {
        let projects = this.state.projects;

        if( bool === true ) {
            projects[index][key] = projects[index][key].concat(target.id);
            this.setState({
                projects: projects,
            });
        }

        else {
            for( var j=0; j<projects[index][key].length; j++) {
                if( projects[index][key][j] === target.id ) {
                    projects[index][key].splice(j, 1);
                }
            }
            this.setState({
                projects: projects,
            });
        }
    }

    _changeProject( value, index, key ) {
        let projects = this.state.projects;
        projects[index][key] = value;
        this.setState({
            projects: projects,
        });
    }

    _changeArticle( value, index, key ) {
        let projects = this.state.projects;
        projects[index]["article"][key] = value;
        this.setState({
            projects: projects,
        });
    }

    _changeFolder( value, index, key ) {
        let projects = this.state.projects;
        projects[index]["folder"] = value;
        this.setState({
            projects: projects,
        });
    }

    _deleteProject( projectIndex, retry=false ) {
        let project = this.state.projects[ projectIndex ];
        this.setState({
            loading: true,
        });

        /**
         * Si on a des images à envoyer
         */

        let confirm = null;

        if( retry === true ) {
            confirm = true;
        }

        else {
            confirm = window.confirm("Voulez-vous vraiment supprimer ce projet?");
        }

        if( confirm ) {
            this.radDataAttr._delete(project.folder_id, this.props.user.access).then((response) => {
                // this.radArticle._delete(project.article.id, this.props.user.access).then((response) => {
                    this.radProject._delete(project.id, this.props.user.access).then((response) => {
                        this.setState({
                            loading: false,
                        });
                        this._getRadProjects();
                        alert("Projet supprimé avec succès");
                    }).catch((e) => {
                        if( e.response ) {
                            if( e.response.status === 401 ) {
                                this._setNewAccessToUser( this._deleteProject( projectIndex, true ) );
                            }
        
                            if( e.response.data ) {
                                // console.log( e.response.data );
                                if( e.response.data.non_field_errors ) {
                                    
                                }
                            }
                        }
        
                        this.setState({
                            loading: false,
                        });
                        if( e.toString() === "Error: Network Error" ) {
                            alert( "Aucun accès à internet :(" );
                        }
                    });
                // }).catch((e) => {
                //     if( e.response ) {
                //         if( e.response.status === 401 ) {
                //             this._setNewAccessToUser( this._deleteProject( projectIndex, true ) );
                //         }
    
                //         if( e.response.data ) {
                //             // console.log( e.response.data );
                //             if( e.response.data.non_field_errors ) {
                                
                //             }
                //         }
                //     }
    
                //     this.setState({
                //         loading: false,
                //     });
                //     if( e.toString() === "Error: Network Error" ) {
                //         alert( "Aucun accès à internet :(" );
                //     }
                // });
            }).catch((e) => {
                if( e.response ) {
                    if( e.response.status === 401 ) {
                        this._setNewAccessToUser( this._deleteProject( projectIndex, true ) );
                    }

                    if( e.response.data ) {
                        // console.log( e.response.data );
                        if( e.response.data.non_field_errors ) {
                            
                        }
                    }
                }

                this.setState({
                    loading: false,
                });
                if( e.toString() === "Error: Network Error" ) {
                    alert( "Aucun accès à internet :(" );
                }
            });
        }

        else {
            this.setState({
                loading: false,
            });
        }
    }

    _UpdateProject(project, project_, callback=()=>{}) {
        this.setState({
            loading: true,
        });
        this.radProject._update(project.id, project_, this.props.user.access).then((re) => {
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._UpdateProject(project, project_, callback) );
                }
                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _updateArticle( project, callback=()=>{} ) {
        let project_ = {
            name: project.name,
            desc: project.desc,
            start_date: project.start_date,
            end_date: project.end_date,
            status: project.status,
            tags: project.tags,
            odds: project.odds
        }
        // console.log(project);
        callback( project_ );
        // this.setState({
        //     loading: true,
        // });
        // let article = {
        //     content: project.article.content,
        //     name: project.article.name,
        //     title: project.name,
        // };
        // this.radArticle._update(project.article.id, article, this.props.user.access).then((res) => {
        //     let project_ = {
        //         name: project.name,
        //         desc: project.desc,
        //         start_date: project.start_date,
        //         end_date: project.end_date,
        //         status: project.status,
        //         tags: project.tags,
        //         odds: project.odds
        //     }
        //     callback( project_ );
        // }).catch((e) => {
        //     if( e.response ) {
        //         if( e.response.status === 401 ) {
        //             this._setNewAccessToUser( this._updateArticle( project, callback) );
        //         }
        //         if( e.response.data ) {
        //             // console.log( e.response.data );
        //             if( e.response.data.non_field_errors ) {
        //                 if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
        //                 }
        //             }
        //         }
        //     }
        //     this.setState({
        //         loading: false,
        //     });
        //     if( e.toString() === "Error: Network Error" ) {
        //         alert( "Aucun accès à internet :(" );
        //     }
        // });
    }

    _update_dataAttr( project, dataAttr, callback=()=>{} ) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._update(project.folder_id, JSON.stringify(dataAttr), this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._update_dataAttr(project, dataAttr, callback) );
                }

                if( e.response.data ) {
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _post_dataAttr( dataAttr, callback=()=>{} ) {
        this.setState({
            loading: true,
        });
        this.radDataAttr._post(JSON.stringify(dataAttr), this.props.user.access).then((response) => {
            callback();
        }).catch((e) => {
            if( e.response ) {
                if( e.response.status === 401 ) {
                    this._setNewAccessToUser( this._post_dataAttr( dataAttr, callback ));
                }
                if( e.response.data ) {
                    // console.log( e.response.data );
                    if( e.response.data.non_field_errors ) {
                        if( e.response.data.non_field_errors[0] === "The fields data, name must make a unique set.") {
                            
                        }
                    }
                }
            }
            this.setState({
                loading: false,
            });
            if( e.toString() === "Error: Network Error" ) {
                alert( "Aucun accès à internet :(" );
            }
        });
    }

    _StartUpdateProject( projectIndex, retry=false ) {
        let project = this.state.projects[ projectIndex ];
        let confirm= window.confirm("Voulez-vous vraiment modifier cet projet?");
        if( confirm ){
            this.setState({
                loading: true,
            });
            /**
             * Si on a des images à envoyer
             */
            if( project.ImageSelectedList && project.ImageSelectedList.length > 0 ) {
                this.setState({
                    loading: true,
                    uploadFiles: true,
                });
                uploadFiles( project.name, project.filesSelected, (event) => {
                    this.setState({
                        currentUploadProgression: Math.round((100 * event.loaded) / event.total),
                    });
                }).then((response) => {
                    // console.log( response );
                    if( response.data.code === 201 ){
                        this.setState({
                            uploadFiles: false,
                        });
    
                        let imagesUploaded = [];
    
                        response.data.files_uploaded_list.images.map((img, index) => {
                            for (var i in project.ImageSelectedList) {
                                if (img.name.split("/")[img.name.split("/").length-1] === project.ImageSelectedList[i].file.name) {
                                    imagesUploaded = imagesUploaded.concat( project.name + "/" + project.ImageSelectedList[i].file.name + "*****" + project.ImageSelectedList[i].description );
                                }
                            }
                        });

                        if( project.folder_id !== null && project.folder_id !== undefined && project.folder_id !== "null" && project.folder_id !== "undefined" ) {
                            let newFolder = [];
                            for (var i in project.folder) {
                                newFolder[i] = project.folder[i][0] + "*****" + project.folder[i][1];
                            }

                            let dataAttr = {
                                value: JSON.stringify( newFolder.concat(imagesUploaded) ),
                                name: project.name,
                                data: 1,
                            };
                            /**
                             * On envois les informations des fichiers du projet(nom de fichier et description)
                             */
                            this._update_dataAttr( project, dataAttr, ()=>{
                                this._updateArticle( project, ( project_) => {
                                    // console.log(9);
                                    this._UpdateProject( project, project_, ()=>{
                                        alert( "Projet modifié avec succès" );
                                        this.setState({
                                            loading: false,
                                        });
                                        this._getRadProjects();
                                    });
                                });
                            });
                        }

                        else {
                            let dataAttr = {
                                label: project.name,
                                desc: "Le contenu folder de " + project.name ,
                                value: JSON.stringify(imagesUploaded),
                                name: project.name,
                                type: 2,
                                data: 1,
                            }
                            /**
                             * On envois les informations des fichiers du projet(nom de fichier et description)
                             */
                            this._post_dataAttr(dataAttr, () => {
                                this._updateArticle( project, ( project_) => {
                                    this._UpdateProject( project, project_, ()=>{
                                        alert( "Projet modifié avec succès" );
                                        this.setState({
                                            loading: false,
                                        });
                                        this._getRadProjects();
                                    });
                                });
                            });
                        }
                    }
                    else {
                        alert('Echec de l\'envois du fichier. Veuillez réessayer');
                        this.setState({
                            uploadFiles: false,
                            loading: false,
                        });
                    }
                }).catch((e) => {
                    this.setState({
                        loading: false,
                        uploadFiles: false,
                    });
                    alert('Echec de l\'envois du fichier. Veuillez réessayer');
                    // console.log( e );
                });
            }
            

            /**
             * Si on a pas des images à envoyer
             */
            else {
                if( project.folder_id !== null && project.folder_id !== undefined && project.folder_id !== "null" && project.folder_id !== "undefined" ) {    
                    let newFolder = [];
                    for (var i in project.folder) {
                        newFolder[i] = project.folder[i][0] + "*****" + project.folder[i][1];
                    }
                    let dataAttr = {
                        value: JSON.stringify( newFolder ),
                        name: project.name,
                        data: 1,
                    };
                    /**
                     * On envois les informations des fichiers du projet(nom de fichier et description)
                     */
                    this._update_dataAttr( project, dataAttr, ()=>{
                        this._updateArticle( project, ( project_) => {
                            this._UpdateProject( project, project_, ()=>{
                                alert( "Projet modifié avec succès" );
                                this.setState({
                                    loading: false,
                                });
                                this._getRadProjects();
                            });
                        });
                    });
                }
                else {
                    let dataAttr = {
                        label: project.name,
                        desc: "Le contenu folder de " + project.name ,
                        value: JSON.stringify([]),
                        name: project.name,
                        type: 2,
                        data: 1,
                    }
                    /**
                     * On envois les informations des fichiers du projet(nom de fichier et description)
                     */
                    this._post_dataAttr(dataAttr, () => {
                        this._updateArticle( project, ( project_) => {
                            this._UpdateProject( project, project_, ()=>{
                                alert( "Projet modifié avec succès" );
                                this.setState({
                                    loading: false,
                                });
                                this._getRadProjects();
                            });
                        });
                    });
                }
            }
        }
    }


    render() {
        return (
            <>
                <div className='add'>
                    <div className='add_head'>
                        <span>
                            Liste des projets
                        </span>
                    </div>
                    <div className='ProjectsContainer'>
                        {
                            this.state.projectStatus.map((status, index) => (
                                <div className='projectStatus' key={"rad-status--/--" + index}>
                                    <div className='projectStatus_'>
                                        <div className='status-name'>
                                            <div className="projectsView_Section2_titleContainer"
                                                x-animation="from-bottom"
                                                x-animation-delay="0s"
                                                x-animation-duration="2s"
                                            >
                                                <div className="projectsView_Section2_title">
                                                    <span>
                                                        Projets { status.name.toString().toLowerCase() }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            {
                                                this.state.projects.map((project, index) => (
                                                    <div className='projects_' key={"rad-project--/--" + index}>
                                                        {
                                                            project.status === status.id ?
                                                                <div>
                                                                    <div className='project-name'
                                                                        onClick={() => {
                                                                            if( this.state.isOpened === project.id ) {
                                                                                this.setState({
                                                                                    isOpened: -1,
                                                                                })
                                                                            }
                                                                            else {
                                                                                this.setState({
                                                                                    isOpened: project.id,
                                                                                })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {
                                                                                project.name
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className='project-content-container'
                                                                        style={{
                                                                            height: this.state.isOpened === project.id ? "auto" : "0px"
                                                                        }}
                                                                    >
                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Titre du projet
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_input'>
                                                                                <input
                                                                                    value={project.name}
                                                                                    type='text'
                                                                                    placeholder='Entrez le titre du projet ici'
                                                                                    onChange={(e) => {
                                                                                        this._changeProject(e.target.value, index, "name");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content add_content_date'>
                                                                            <div className='add_content_date_'>
                                                                                <div className='add_content_label'>
                                                                                    <span>
                                                                                        Date de début
                                                                                    </span>
                                                                                </div>
                                                                                <div className='add_content_input'>
                                                                                    <input
                                                                                        value={project.start_date !== null ? project.start_date : ''}
                                                                                        type='date'
                                                                                        placeholder='Entrez la date de début ici'
                                                                                        onChange={(e) => {
                                                                                            this._changeProject(e.target.value, index, "start_date");
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='add_content_date_'>
                                                                                <div className='add_content_label'>
                                                                                    <span>
                                                                                        Date de Fin
                                                                                    </span>
                                                                                </div>
                                                                                <div className='add_content_input'>
                                                                                    <input
                                                                                        value={project.end_date !== null ? project.end_date : ''}
                                                                                        type='date'
                                                                                        placeholder='Entrez la date de fin ici'
                                                                                        onChange={(e) => {
                                                                                            this._changeProject(e.target.value, index, "end_date");
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Statut du projet
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_input'>
                                                                                <select value={project.status} onChange={(e) => {
                                                                                    // console.log( project.status );
                                                                                    this._changeProject(e.target.value, index, "status");
                                                                                    this._UpdateProject( index );
                                                                                }}>
                                                                                    {
                                                                                        this.state.projectStatus.map((status, index) => (
                                                                                            <option key={"status" + index} value={status.id}>
                                                                                                {status.name}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Contenu du projet
                                                                                    {/* Courte présentation du projet */}
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_editor2 radODD_editor'>
                                                                                <CKEditor
                                                                                    editor={ClassicEditor}
                                                                                    data={project.desc}
                                                                                    onReady={editor => {
                                                                                        // You can store the "editor" and use when it is needed.
                                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                                    }}
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        this._changeProject(data, index, "desc");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Domaines de compétences associés
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_checkBox'>
                                                                                {
                                                                                    this.state.radExpertise.map((expertise, index_1) => (
                                                                                        <label key={index_1} for={'dc' + project.id + index_1} className='add_content_checkBox_ competenceCheckbox'>
                                                                                            <input id={'dc' + project.id + index_1} name={'dc' + project.id + index_1} type='checkbox' checked={ project.tags.includes(expertise.id) }
                                                                                                onChange={(e)=>{
                                                                                                    // console.log( e.target.checked );
                                                                                                    this.isTarget( e.target.checked, expertise, "expertise", index, "tags" );
                                                                                                }}
                                                                                            />
                                                                                            <label for={'dc' + project.id + index_1}>
                                                                                                {expertise.name}
                                                                                            </label>
                                                                                        </label>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Objectifs de développement associés
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_checkBox'>
                                                                                {
                                                                                    this.state.radODD.map((odd, index_2) => (
                                                                                        <label key={index_2} for={'odd' + project.id + index_2} className='add_content_checkBox_ oddCheckbox'>
                                                                                            <input id={'odd' + project.id + index_2} name={'odd' + project.id + index_2} type='checkbox' checked={ project.odds.includes(odd.id) }
                                                                                                onChange={(e)=>{
                                                                                                    this.isTarget( e.target.checked, odd, "odd", index, "odds" );
                                                                                                }}
                                                                                            />
                                                                                            <label for={'odd' + project.id + index_2}>
                                                                                                <img className='add_content_checkBox_odd' src={"/ODD/odd" + odd.logo + ".png"} alt={"ODD"} />
                                                                                            </label>
                                                                                        </label>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        {/* <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Contenu du projet
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_editor'>
                                                                                <CKEditor
                                                                                    editor={ClassicEditor}
                                                                                    data={ project.article ? project.article.content : "" }
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        this._changeArticle(data, index, "content");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div> */}

                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Galerie du projet
                                                                                </span>
                                                                            </div>
                                                                            <div className="previewImageSelected_container">
                                                                                {
                                                                                    project.folder && project.folder.map((folder, FolderIndex) => (
                                                                                        <div className='previewImageContainer'>
                                                                                            <div className="previewImageSelected_">
                                                                                                <svg onClick={() => {
                                                                                                    this._deleteFolderImage_(index, FolderIndex);
                                                                                                }} className='previewImageSelected_cross' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529" />
                                                                                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529" />
                                                                                                </svg>
                                                                                                <img className="previewImageSelected" key={"image" + index + "-" + FolderIndex} src={ folder.length > 0 ? ImgURL + "upload/" + folder[0] : "" } alt="RAD-ONG" />
                                                                                            </div>
                                                                                            <div className='imageDescription'>
                                                                                                <input type="text" placeholder="Description de l'image ici" value={folder[1]}
                                                                                                    onChange={(e) => {
                                                                                                        let folder_ = project.folder;
                                                                                                        folder_[ FolderIndex ][1] = e.target.value;
                                                                                                        this._changeFolder(folder_, index);
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>


                                                                        <div className='add_content'>
                                                                            <div className='add_content_label'>
                                                                                <span>
                                                                                    Ajoutez des images
                                                                                </span>
                                                                            </div>
                                                                            <div className='add_content_imageSelector'>
                                                                                <ImageSelector
                                                                                    onChangeImage={async (e) => {
                                                                                        const files = Array.from(e);

                                                                                        let project_ = project;

                                                                                        if( !project_.filesSelected || project_.filesSelected === null || project_.filesSelected === undefined ) {
                                                                                            project_.filesSelected = [];
                                                                                        }

                                                                                        if( !project_.ImageSelectedList || project_.ImageSelectedList === null || project_.ImageSelectedList === undefined ) {
                                                                                            project_.ImageSelectedList = [];
                                                                                        }

                                                                                        
                                                                                        if( !project_.ImageSelectedPreviewLink || project_.ImageSelectedPreviewLink === null || project_.ImageSelectedPreviewLink === undefined ) {
                                                                                            project_.ImageSelectedPreviewLink = [];
                                                                                        }

                                                                                        project_.filesSelected = project_.filesSelected.concat(files);
                                                                                        files.map((file, index)=>{
                                                                                            project_.ImageSelectedList = project_.ImageSelectedList.concat({
                                                                                                file: file,
                                                                                                description: '',
                                                                                            });
                                                                                        });

                                                                                        Promise.all(files.map(file => {
                                                                                            return (
                                                                                                new Promise((resolve, reject) => {
                                                                                                    const reader = new FileReader();
                                                                                                    reader.addEventListener('load', (ev) => {
                                                                                                        resolve(ev.target.result);
                                                                                                    });
                                                                                                    reader.addEventListener('error', reject);
                                                                                                    reader.readAsDataURL(file);
                                                                                                })
                                                                                            )
                                                                                        })).then(images => {
                                                                                            project_.ImageSelectedPreviewLink = project_.ImageSelectedPreviewLink.concat(images);
                                                                                            let allProjects = this.state.projects;
                                                                                            allProjects[index] = project_;
                                                                                            // console.log( allProjects );
                                                                                            this.setState({
                                                                                                projects: allProjects,
                                                                                            });
                                                                                        });

                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            {
                                                                                project.ImageSelectedPreviewLink && project.ImageSelectedPreviewLink.length > 0 &&
                                                                                <div className='delete_all_image'>
                                                                                    <span className='delete_all_image_text' onClick={() => {
                                                                                        this._deleteAllImages( index );
                                                                                    }}>
                                                                                        <span>
                                                                                            Supprimer toutes les images
                                                                                        </span>
                                                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            }

                                                                            <div className="previewImageSelected_container">
                                                                                {
                                                                                    project.ImageSelectedPreviewLink && project.ImageSelectedPreviewLink.map((link, imageIndex) => (
                                                                                        <div className='previewImageContainer'>
                                                                                            <div className="previewImageSelected_">
                                                                                                <svg onClick={() => {
                                                                                                    this._deleteImage(index, imageIndex);
                                                                                                }} className='previewImageSelected_cross' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#212529" />
                                                                                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#212529" />
                                                                                                </svg>
                                                                                                <img className="previewImageSelected" key={"preview" + index + "-" + imageIndex} src={link} alt="RAD-ONG" />
                                                                                            </div>
                                                                                            <div className='imageDescription'>
                                                                                                <input type="text" placeholder="Description de l'image ici" value={project.ImageSelectedList[imageIndex].description}
                                                                                                    onChange={(e) => {

                                                                                                        let ImageSelectedList = project.ImageSelectedList;
                                                                                                        ImageSelectedList[imageIndex].description = e.target.value;

                                                                                                        this._changeProject(ImageSelectedList, index, "ImageSelectedList");
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>

                                                                            <div>
                                                                                <div className='updateSocial_inputSubmit_container button-container'>
                                                                                    <span className='updateSocial_inputSubmit'
                                                                                        onClick={() => {
                                                                                            this._StartUpdateProject( index );
                                                                                            return true;
                                                                                        }}
                                                                                    >
                                                                                        Modifier le projet
                                                                                    </span>
                                                                                    <span className='updateSocial_inputBack'
                                                                                        onClick={() => {
                                                                                            this._deleteProject( index );
                                                                                            return true;
                                                                                        }}
                                                                                    >
                                                                                        Supprimer le projet
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                    {
                        this.state.uploadFiles&&
                        <div className='addSection'>
                            <div className='addSection_ uploadProgressPlatform'>
                                <div className='addSection_title'>
                                    <span>
                                        Envois des fichiers en cours
                                    </span>
                                </div>

                                <div className='addSection_input'>
                                    <div className='progress'>
                                        <div className='progressBar'
                                            style={{
                                                width: this.state.currentUploadProgression + "%"
                                            }}
                                        >

                                        </div>
                                    </div>
                                </div>

                                <div className="progressText">
                                    <span>
                                        {
                                            this.state.currentUploadProgression + "%"
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    }

                {
                    this.state.loading &&
                    <div className='loading'>
                        <img src={loader} alt='' />
                    </div>
                }
            </>
        )
    }
}


DashboardViewProject = connect(mapStateToProps, null)(DashboardViewProject);
export default DashboardViewProject;