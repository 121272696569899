import React from 'react';
import { Link } from 'react-router-dom';

// import fs from 'fs';

/**Importation des composants de la page */


/**Importation des styles de la page */
import './styles/media.style.css';
import './styles/media.responsive.style.css';

/**Importation des images*/
import loader from '../assets/gif/loader2.gif';

/**Importation des helpers */
import Xanimation from '../helpers/x-animations';
import getNodeResponse from '../helpers/node.server';
import getAllContent from '../helpers/getAllContent.helper';

import ImgURL from '../helpers/imgUrl.helper';

const animer = new Xanimation();
animer.init( 50 );


class MediaView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            racine: "./rad",
            currentFolder: "./rad",
            currentFolderView: '',
            currentContent: [],
            viewCurrentImage: false,
            currentImage: '',
            loading: false,
        }
    }

    async componentDidMount() {
        // console.log( this.props.match.params.testvalue );
        // window.scrollTo(0, 0);
        window.onload = animer.start();
        // try {
        //     const data = await fs.promises.readdir("./");
        //     console.log(data.toString());
        // }catch (error) {
        //     console.error(`Got an error trying to read the file: ${error.message}`);
        // }
        this.setState({
            loading: true,
        });
        getNodeResponse(this.state.currentFolder).then((response) => {
            // console.log( response.data );
            this.setState({
                loading: false,
                currentContent: response.data,
            });
        });
    }

    getAllContentForFolder( directory, currentName ) {
        this.setState({
            currentFolder: directory,
            currentContent: [],
            loading: true,
            currentFolderView: currentName,
        }, () => {
            getAllContent(this.state.currentFolder).then((response) => {
                // console.log( response.data );
                this.setState({
                    loading: false,
                    currentContent: response.data,
                });
            });
        });
    }

    componentWillUnmount() {
        animer.stop();
    }

    _backTo() {
        this.setState({
            loading: true,
            currentFolder: this.state.racine,
            currentContent: [],
        }, () => {
            getNodeResponse(this.state.currentFolder).then((response) => {
                // console.log( response.data );
                this.setState({
                    loading: false,
                    currentContent: response.data,
                });
            });
        });
    }

    _clickToViewImage( image ) {
        this.setState({
            viewCurrentImage: true,
            currentImage: image,
        })
    }

    _clickToBackViewImage() {
        this.setState({
            viewCurrentImage: false,
            currentImage: '',
        })
    }

    render() {
        return (
            <>
                <div className="media">
                    {
                        this.state.racine === this.state.currentFolder ?
                        <div className="media_container">
                            {
                                this.state.currentContent.map((content, index)=>(
                                    <div className="media_folder" key={index}>
                                        <div className="media_folder_"
                                            onClick={() => {
                                                this.getAllContentForFolder( this.state.currentFolder + "/" + content.name , content.name);
                                            }}
                                        >
                                            <img
                                                className="media_folder_cover"
                                                src={ ImgURL + "rad-dev/" + this.state.currentFolder + "/" + content.name + "/cover/cover.png"}
                                                alt=""
                                            />
                                            <div className="media_folder_name">
                                                <span className="media_folder_name_">
                                                    {
                                                        content.name === "projetsCours" ?
                                                            "Projets en cours"
                                                        :
                                                            (
                                                                content.name === "projetsTermines" ?
                                                                    "Projets terminés"
                                                                :
                                                                    (
                                                                        content.name === "formations" ?
                                                                            "Nos formations"
                                                                        :
                                                                            (
                                                                                content.name === "Assemblée_Générale_2020_RAD" ?
                                                                                    "Assemblée générale 2020 de RAD"
                                                                                :
                                                                                    (
                                                                                        content.name === "more" ?
                                                                                            "Plus de photos"
                                                                                        :
                                                                                            content.name
                                                                                    )
                                                                            )
                                                                    )
                                                            )
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        :
                            null
                    }

                    {
                        this.state.racine !== this.state.currentFolder ?
                        <>
                        <div className='back'>
                            <div className='back_'
                                onClick={() => {
                                    this._backTo();
                                }}
                            >
                                <span>
                                    Retour
                                </span>
                            </div>
                        </div>
                        <div className='media_folder_container_current'>
                            {
                                this.state.currentFolderView === "projetsCours" ?
                                    "Projets en cours"
                                :
                                    (
                                        this.state.currentFolderView === "projetsTermines" ?
                                            "Projets terminés"
                                        :
                                            (
                                                this.state.currentFolderView === "formations" ?
                                                    "Nos formations"
                                                :
                                                    (
                                                        this.state.currentFolderView === "Assemblée_Générale_2020_RAD" ?
                                                            "Assemblée générale 2020 de RAD"
                                                        :
                                                            (
                                                                this.state.currentFolderView === "more" ?
                                                                    "Plus de photos"
                                                                :
                                                                    this.state.currentFolderView
                                                            )
                                                    )
                                            )
                                    )
                            }
                        </div>
                        <div className="media_folder_container">
                            {
                                this.state.currentContent.map((content, index) => (
                                    
                                        
                                    content.isDirectory ?
                                        <div key={index} className="media_folder_container_name"
                                            style={{
                                                paddingLeft: 20 * content.level + "px",
                                            }}
                                        >
                                            <span>
                                                { content.name.split("_").join(" ") }
                                            </span>
                                        </div>
                                    :
                                        <div key={index} className="media_content_imageContainer"
                                            onClick={() => {
                                                this._clickToViewImage( content );
                                            }}
                                        >
                                            <div className="media_content_image">
                                                <img className="media_content_image_img" src={ "https://www.rad-ong.org/rad-dev/" + content.compressPath} alt="RAD-ONG"/>
                                                <div className="media_content_image_name">
                                                    {
                                                        content.name
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        
                                ))
                            }
                        </div>
                        </>
                        :
                            null
                    }

                    {
                        this.state.viewCurrentImage &&
                        <div className='currentImageContainer'>
                            <div className='crossImage'
                                onClick={() => {
                                    this._clickToBackViewImage();
                                }}
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.2426 6.34311L6.34309 16.2426C5.95257 16.6331 5.95257 17.2663 6.34309 17.6568C6.73362 18.0473 7.36678 18.0473 7.75731 17.6568L17.6568 7.75732C18.0473 7.36679 18.0473 6.73363 17.6568 6.34311C17.2663 5.95258 16.6331 5.95258 16.2426 6.34311Z" fill="#ffffff"/>
                                    <path d="M17.6568 16.2426L7.75734 6.34309C7.36681 5.95257 6.73365 5.95257 6.34313 6.34309C5.9526 6.73362 5.9526 7.36678 6.34313 7.75731L16.2426 17.6568C16.6331 18.0473 17.2663 18.0473 17.6568 17.6568C18.0474 17.2663 18.0474 16.6331 17.6568 16.2426Z" fill="#ffffff"/>
                                </svg>
                            </div>
                            <div className='currentImage'>
                                <img className='currentImage_' src={"https://www.rad-ong.org/rad-dev/" + this.state.currentImage.path } alt='RAD-ONG' />
                            </div>
                        </div>
                    }
                </div>

                {
                    this.state.loading &&
                    <div className='loading'>
                        <img src={loader} alt='' />
                    </div>
                }
            </>
        )
    }
}

export default MediaView;